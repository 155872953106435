import React, {useCallback, useEffect, useMemo} from "react";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import {ENV, ImageConfig} from "../../../../constants";
import { CommonService, Communications} from "../../../../helpers";
import { AddRounded } from "@material-ui/icons";
import { Button, Tooltip } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import "./GroupListScreen.scss";
import { SearchRounded } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import { useLocalStorage } from "../../../../components/useLocalStorage";
import { CssTextField } from "../../../../constants/data/styles";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {TableColumnsType} from "antd";



const GroupListScreen = () => {
  const history = useHistory();
  const [filterData,setFilterData]=useLocalStorage("groupListFilterData",{
    search:"",
    sort:{},
  });

  const handleSmsBlast = useCallback((group: any) => {
    history.push({
      pathname: "/sendSmsBlast",
      state: group,
    });
  },[history])

  useEffect(() => {
    Communications.pageTitleSubject.next("Create Group");
    Communications.pageBackButtonSubject.next(null);
  }, []);

  const groupListColumn:TableColumnsType=useMemo(()=>{
    return [
      {
        title:'Created On',
        width:120,
        sorter: (a: any, b: any) => {
          const dateA = new Date(a?.created_at).getTime();
          const dateB = new Date(b?.created_at).getTime();
          return dateA - dateB;
        },
        render:(item:any)=>{
          return (
              <div>
                {CommonService.getUtcDate(item.created_at) || 'N/A'}
              </div>
          )
        }
      },
      {
        title:'Group Name',
        width:250,
        sorter: (a: any, b: any) => (a?.title || '').localeCompare(b?.title || ''),
        render:(item:any)=>{
          return (
              <div>
                {item?.title || 'N/A'}
              </div>
          )
        }
      },
      {
        title:'Total Members',
        width:120,
        sorter: (a: any, b: any) => (a.members_count || 0) - (b.members_count || 0),
        render:(item:any)=>{
          return (
              <div>
                {item?.members_count || 'N/A'}
              </div>
          )
        }
      },
      {
        title:'SMS Blast',
        width:120,
        render:(item:any)=>{
          return (
              <div>
                <Tooltip title={`Send SMS Blast to ${item.title}`}>
                  <div className="d-flex message-wrapper" onClick={() => handleSmsBlast(item)}>
                    <QuestionAnswerIcon className={"sms-blast-icon"} /> &nbsp; <span className="sms-blast">SMS Blast</span>
                  </div>
                </Tooltip>
              </div>
          )
        }
      },
      {
        title:'View Details',
        width:100,
        render:(item:any)=>{
          return (
              <div className={'display-center'}>
                <Tooltip title={`${item.title} view details`}>
                  <Link to={"/group/view/" + item._id} style={{color: '#1e1e1e'}} >
                    <ImageConfig.ArrowRightOutlinedIcon/>
                  </Link>
                </Tooltip>
              </div>
          )
        }
      }
    ]
  },[handleSmsBlast])

  return (
    <>
      <div className={"group-add screen crud-layout pdd-30"}>
        <div className="custom-border pdd-20 mrg-top-20">
          <div className="header">
            <div className="mrg-left-5 filter">
              <div>
                <div className="d-flex">
                  <div className="d-flex position-relative">
                    {!filterData.search ? (
                        <div className={"search_icon"}>
                          <SearchRounded/>
                        </div>
                    ) : (
                        <div className={"search_icon"}>
                          <ClearIcon
                              onClick={(event) => {
                                setFilterData({...filterData, search: ''})
                              }}
                              id="clear_facility_search"
                          />
                        </div>
                    )}
                    <div>
                      <CssTextField
                          defaultValue={""}
                          className="search-cursor searchField"
                          id="input_search_facility"
                          onChange={(event) => {
                            const value = event.target.value;
                            setFilterData({...filterData, search: value});
                          }}
                          value={filterData.search}
                          variant={"outlined"}
                          size={"small"}
                          type={"text"}
                          placeholder={"Search Group"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="action pdd-right-5">
              <div>
                <Tooltip title={"Create New Group"}>
                  <Button variant={"contained"} color={"primary"} component={Link} to={`/group/add`} id="btn-add-group">
                    <AddRounded/>
                    &nbsp;&nbsp;Create New&nbsp;&nbsp;
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className={'mrg-top-20'}>
            <AntTableComponent
              url={ENV.API_URL + "group"}
              method={"get"}
              extraPayload={filterData}
              columns={groupListColumn}
              noDataText={'No Data Found.'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupListScreen;
