import "./ApprovedStaffFilterDrawerComponent.scss";
import {ImageConfig} from "../../../constants";
import React, {useCallback} from "react";
import DatePicker from "react-datepicker";
import {DateRangeOutlined} from "@material-ui/icons";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { approvedListStatus } from "../../../constants/data";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
    option: {
        '&[aria-selected="true"]': {
            backgroundColor: '#41D6C3',
            color: 'white',
        },
    },
}));



interface ApprovedStaffFilterDrawerComponentProps{
    filterData:any;
    setFilterData:any;
    setOpenFilterDrawer?:any;
    setFilterCount:any;
    // dateRange:any;
    // setDateRange:any;
    // status:any;
    // setStatus:any;
    // selectedHcpTypes:any;
    // setSelectedHcpTypes:any;
    isApprovedList?:boolean;
    hcpTypes:any;
}

const ApprovedStaffFilterDrawerComponent=(props:ApprovedStaffFilterDrawerComponentProps)=>{
    const {
        filterData,
        setFilterData,
        setOpenFilterDrawer,
        setFilterCount,
        // dateRange,
        // setDateRange,
        // status,
        // setStatus,
        // selectedHcpTypes,
        // setSelectedHcpTypes,
        hcpTypes,
    }=props;
    // const [startDate, endDate] = dateRange;
    const classes=useStyles();
    const statusList =  approvedListStatus;

    const updatedFilters=useCallback((updatedFilterData:any)=>{
        let selectedFilter=0;

        if (updatedFilterData.start_date) selectedFilter++;
        if (updatedFilterData.end_date) selectedFilter++;

        selectedFilter += (updatedFilterData.hcp_types || []).length;
        if(updatedFilterData?.status){
            selectedFilter++;
        }

        setFilterCount(selectedFilter)
        setFilterData(updatedFilterData);
    },[setFilterCount,setFilterData])



    const handleDateRangeSelection = useCallback((dateRange: [Date | null, Date | null]) => {
        const [newStartDate, newEndDate] = dateRange || [];

        const startDateFormatted = newStartDate
            ? moment(newStartDate).format("YYYY-MM-DD")
            : null;
        const endDateFormatted = newEndDate
            ? moment(newEndDate || newStartDate).format("YYYY-MM-DD")
            : null;

        const updatedFilterData={...filterData}


        if (startDateFormatted) {
            updatedFilterData.start_date = startDateFormatted;
            // Clear the end_date if the user picks a new start_date
            if (filterData.start_date !== startDateFormatted) {
                delete updatedFilterData.end_date;
            }
        }

        if(endDateFormatted){
            updatedFilterData.end_date=endDateFormatted;
        }


        if (!startDateFormatted && !endDateFormatted) {
            delete updatedFilterData.start_date;
            delete updatedFilterData.end_date;
        }


        updatedFilters(updatedFilterData);
    }, [filterData, updatedFilters]);
    const handleStatusChange=useCallback((value:any)=>{
        const updatedFilterData={...filterData}
        updatedFilterData.is_active=value?.code;
        updatedFilters(updatedFilterData);
    },[filterData,updatedFilters])

    const handleHcpTypeChange=useCallback((value:any)=>{
        let updatedFilterData={...filterData};
        updatedFilterData.hcp_types=value?.map((item:any)=>item?.code);
        if (updatedFilterData.hcp_types?.length === 0) {
            delete updatedFilterData.hcp_types;
        }
        updatedFilters(updatedFilterData);
    },[filterData,updatedFilters])

    const handleClearAll=useCallback(()=>{
        let updatedFilterData={...filterData};
        setFilterCount(0);
        delete updatedFilterData.hcp_types;
        delete updatedFilterData.is_active;
        delete updatedFilterData.start_date;
        delete updatedFilterData.end_date;
        setFilterData(updatedFilterData);
    },[filterData,setFilterData,setFilterCount])


    return (
        <div className={'facility-filter-component'}>
            <div className={'facility-filter-header-wrapper'}>
                <div className={'facility-filter-header'}>
                    <div className={'facility-filter-title'}>Filters</div>
                    <div className={'facility-filter-clear-all'} onClick={handleClearAll}>Clear All</div>
                </div>
                <div
                    className={'facility-filter-close-btn'}
                    onClick={() => setOpenFilterDrawer(false)}
                >
                    <img src={ImageConfig.CrossIcon} alt={'cross-icon'}/>
                </div>
            </div>
            <div className={'facility-filter-fields-wrapper'}>
                <div className={'facility-date-range'}>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Select Date"
                        className="facility-custom-input"
                        selectsRange={true}
                        startDate={filterData?.start_date && new Date(`${filterData.start_date}T00:00:00`) }
                        endDate={filterData?.end_date && new Date(`${filterData.end_date}T00:00:00`)}
                        onChange={(update) => {
                            handleDateRangeSelection(update);
                            // setDateRange(update);
                        }}
                        isClearable={true}
                    />
                    {!filterData?.start_date && !filterData?.end_date &&
                        <DateRangeOutlined className="facility-date-icon" fontSize="medium" color="action"/>}
                </div>
                <div>
                    <Autocomplete
                        PaperComponent={({ children }) => <Paper style={{ color: "#1e1e1e" }}>{children}</Paper>}
                        value={statusList.find(status => status.code === filterData.is_active) || null}
                        options={statusList}
                        getOptionLabel={(option: any) => option.name}
                        placeholder={"Select Status"}
                        id="input_select_status"
                        className="mrg-top-10"
                        classes={{
                            option: classes.option,
                        }}
                        onChange={($event, value) =>{
                            handleStatusChange(value)
                        } }
                        renderInput={(params) => <TextField {...params}
                                                            id="select_status"
                                                            variant="outlined"
                                                            value={statusList.find(status => status.code === filterData.is_active) || null}
                                                            placeholder={"Status"} fullWidth />}
                    />
                </div>
                <div>
                    <Autocomplete
                        PaperComponent={({ children }) => <Paper style={{ color: "#1e1e1e" }}>{children}</Paper>}
                        multiple
                        // value={selectedHcpTypes}
                        value={hcpTypes?.filter((type: any) => filterData.hcp_types?.includes(type.code)) || []}
                        options={hcpTypes}
                        getOptionLabel={(option: any) => option?.title}
                        getOptionSelected={(option, value) => option.title === value?.title}
                        placeholder={"Select Staff Type"}
                        id="input_select_hcpType"
                        className="mrg-top-10"
                        onChange={($event, value) =>
                            handleHcpTypeChange(value)
                        }
                        classes={{
                            option: classes.option,
                        }}
                        // renderTags={() => null}
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({ index: 0 })}>
                                    {selectedCount} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.hcp_types?.length === 0 || !filterData?.hcp_types
                                ? "Multiple Staff Types"
                                : "";
                            return <TextField {...params} id="select_hcpType" variant="outlined" placeholder={placeholder} />;
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ApprovedStaffFilterDrawerComponent;