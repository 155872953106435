import {Button} from "@material-ui/core";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-material-ui";
import * as Yup from "yup";
import {ApiService, CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";

interface HcpSentHomeDialogComponentProps {
    hcpStats?: any;
    hcpId?: any;
    handleHcpStatsApiCall?: any;
    setIsHcpScoreEditOpen?: any;
    getList?: any;
}

const hcpScoreValidation = Yup.object({
    score: Yup.number()
        .typeError(" must be valid")
        // .positive().min(0, 'min 0')
        .max(9999999, "max 9999999")
        .nullable(),
});


const EditScoreComponent = (props: HcpSentHomeDialogComponentProps) => {
    const {hcpStats, handleHcpStatsApiCall, hcpId, setIsHcpScoreEditOpen} = props;

    const hcpScoreInitialState = {
        score: hcpStats?.total_score,
    };

    const onAdd = (data: any, {setSubmitting, setErrors, resetForm, setFieldValue}: FormikHelpers<any>) => {
        // confirm();
        const payload = {
            score: data?.score,
        };

        ApiService.post(ENV.API_URL + "hcp/" + hcpId + "/attendance", payload)
            .then((res: any) => {
                CommonService.showToast(res?.msg || "Success", "success");
                setSubmitting(false);
                handleHcpStatsApiCall();
                // getList();
                setIsHcpScoreEditOpen(false);
            })
            .catch((err) => {
                CommonService.showToast(err?.msg || err?.error || "Error", "error");
                setSubmitting(false);
            });
    };

    return (
        <div className="pdd-left-25 pdd-right-25 pdd-bottom-25 hcp-sent-home-dialog-component">
            {/*<h2 className="hcp-sent-home-dialog-heading">Enter the Score</h2>*/}
            <div className="mrg-top-20">
                <Formik initialValues={hcpScoreInitialState} validateOnChange={true} validateOnMount={true}
                        validateOnBlur={true} validationSchema={hcpScoreValidation} onSubmit={onAdd}>
                    {({isSubmitting, handleSubmit, isValid, values, resetForm, setFieldValue}) => (
                        <Form id="add-hcp-sent-home-form" className={"hcp-sent-home form-holder"}>
                            <div className="add-input">
                                <div className="hcp-sent-home-details-container">
                                    <Field
                                        type="number"
                                        size="medium"
                                        variant="outlined"
                                        id="input_actual_hours"
                                        component={TextField}
                                        placeholder="Enter Score"
                                        fullWidth
                                        autoComplete="off"
                                        label="Score"
                                        name="score"
                                    />

                                </div>
                            </div>

                            <div className="mrg-top-40 text-align">
                                <Button variant={"outlined"} id="cancel-employee-btn" size={"medium"} color={"primary"}
                                        onClick={() => setIsHcpScoreEditOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={isSubmitting}
                                    variant={"contained"}
                                    type={"submit"}
                                    size={"medium"}
                                    color={"primary"}
                                    className={isSubmitting ? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30" : "mrg-left-20 pdd-left-30 pdd-right-30"}
                                >
                                    {isSubmitting ? "Saving" : "Save"}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default EditScoreComponent;
