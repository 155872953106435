import React from "react";
import "./AddPaymentDetailsComponent.scss";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { isHCPPaid as hcpPaidOptions } from "../../../../../constants/data";
import { DatePicker } from "formik-material-ui-pickers";
import "./AddPaymentDetailsComponent.scss";
import { ApiService, CommonService } from "../../../../../helpers";
import { ENV } from "../../../../../constants";
import moment from "moment";

interface AddPaymentDetailsComponentProps {
  cancel: () => void;
  confirm: () => void;
  selectedShifts: any[];
}

const showDropDownBelowField = {
  MenuProps: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  },
};

const AddPaymentDetailsComponent = (props: AddPaymentDetailsComponentProps) => {
  const { selectedShifts, confirm, cancel } = props;
  const paymentInitialState = {
    is_hcp_paid: "",
    hcp_pay_date: null,
    comments:"",
  };



  const paymentValidation = Yup.object({
    is_hcp_paid: Yup.mixed().required('required'),
    hcp_pay_date: Yup.date().typeError("invalid").nullable(),
    comments: Yup.string().nullable(),
  });

  const onAdd = (payment: any, { setSubmitting, setErrors, resetForm, setFieldValue }: FormikHelpers<any>) => {
    if(payment?.is_hcp_paid ){
      if(!payment?.hcp_pay_date){
        setErrors({
          "hcp_pay_date": "Required"
        })
        setSubmitting(false)
        return
      }
    }
    const payload = {
      shift_ids: selectedShifts,
      is_hcp_paid: payment?.is_hcp_paid,
      hcp_pay_date: payment?.hcp_pay_date ? moment(payment?.hcp_pay_date).format("YYYY-MM-DD") : "",
      comments: payment?.comments,
    };

    ApiService.put(ENV.API_URL + "shift/payDetails", payload)
      .then((res: any) => {
        CommonService.showToast(res?.msg || "Success", "success");
        setSubmitting(false);
        confirm();
      })
      .catch((err) => {
        CommonService.showToast(err?.msg || err?.error || "Error", "error");
        setSubmitting(false);
      });
  };
  return (
    <div className="">
      {/*<h2>Payment Details</h2>*/}
      <div className="pdd-left-25 pdd-right-25 pdd-bottom-25">
        <Formik initialValues={paymentInitialState} validateOnChange={true} validationSchema={paymentValidation} onSubmit={onAdd}>
          {({ isSubmitting, handleSubmit, isValid, values, resetForm, setFieldValue }) => (
            <Form id="add-payment-form" className={"payment-name form-holder"}>
              <div className="add-input">
                <div className="payment-details-container mrg-top-10 custom-border pdd-top-10">
                  <Field
                    onChange={(e: any) => {
                      if (e.target.value === false) {
                        setFieldValue("hcp_pay_date", null);
                      }

                      setFieldValue("is_hcp_paid", e.target.value);
                    }}
                    SelectProps={showDropDownBelowField}
                    select
                    variant="outlined"
                    fullWidth
                    component={TextField}
                    name="is_hcp_paid"
                    id="input_is_hcp_paid"
                    label="Payment Completed"
                  >
                    <MenuItem value="">Select </MenuItem>
                    {hcpPaidOptions.map((item: any, index) => {
                      return (
                        <MenuItem value={item.value} key={index} id={"is_hcp_paid" + item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Field>

                  {values.is_hcp_paid && (
                    <Field
                     autoOk={true}
                      id="input_hcp_pay_date"
                      variant="inline"
                      orientation="landscape"
                      openTo="date"
                      format="MM/dd/yyyy"
                      views={["year", "month", "date"]}
                      inputVariant="outlined"
                      component={DatePicker}
                      placeholder="MM/DD/YYYY"
                      fullWidth
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      label="Staff Pay Date"
                      name="hcp_pay_date"
                    />
                  )}
                  <Field
                      multiline
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      name="comments"
                      label="Comments"
                      id="input_facility_comments"
                      minRows={3}
                  />
                </div>
              </div>

              <div className="mrg-top-40 text-align">
                <Button variant={"outlined"} id="cancel-employee-btn" size={"medium"} color={"primary"} onClick={() => cancel()}>
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant={"contained"}
                  type={"submit"}
                  size={"medium"}
                  color={"primary"}
                  className={isSubmitting ? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30" : "mrg-left-20 pdd-left-30 pdd-right-30"}
                >
                  {isSubmitting ? "Saving" : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddPaymentDetailsComponent;
