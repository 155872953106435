import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, IconButton, InputAdornment, MenuItem} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {CheckboxWithLabel, TextField} from "formik-material-ui";
import {facilityFormValidation, facilityInitialState} from "../FacilityInitialAndValidationsComponent";
import {ScrollToError} from "../../../../components/ScrollToError";
// import PhoneInputComponent from "../../../../components/phoneInput/PhoneInputComponent";
import CancelIcon from "@material-ui/icons/Cancel";
import {Switch, Typography, Row, Col} from "antd";
import CommonService from "../../../../helpers/common-service";
import {ImageConfig} from "../../../../constants";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import MoreFieldsDrawerComponent from "../MoreFieldsDrawer/MoreFieldsDrawerComponent";
import {FIELDS} from "../../../../data";
import FileUploadComponent from "../../../../components/file-upload/FileUploadComponent";
import FilePreviewComponent from "../../../../components/file-preview/FilePreviewComponent";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {Modal, Input} from "antd";
import {useSelector} from "react-redux";

export interface FacilityAddDetailsComponentProps {
    onAdd: any;
    regions: any;
    americanTimeZone: any;
    fileUpload: any;
    previewFile: any;
    deleteFile: any;
    OnFileSelected: any;
    otHours: any;
    contractFileUpload: any;
    previewContractFile: any;
    deleteContractFile: any;
    OnContractFileSelected: any;
    setOtherDocuments?: any;
    otherDocuments?: any;
    otherFileUpload?: any;
    setOtherFileUpload?: any;
    OnOtherFileSelected?: any;
    previewOtherFile?: any;
    setRequiredDocumentsForStaff?: any;
    setNewlyAddedFacilityDocuments?: any;
    newlyAddedFacilityDocuments?: any
}

interface DocumentToggleProps {
    id: string;
    label: string;
    initialChecked?: boolean;
    onChange: (id: string, checked: boolean) => void;
    disabled?: boolean;
}

const DocumentToggle: React.FC<DocumentToggleProps> = ({id, label, initialChecked = false, onChange, disabled}) => {
    const [checked, setChecked] = useState(initialChecked);

    const handleToggle = (checked: boolean) => {
        setChecked(checked);
        onChange(id, checked);
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.5rem',
                border: '1px solid #ddd',
                borderRadius: '8px',
                backgroundColor: checked ? '#e6f7ff' : '#fff', // Optional: Highlight when checked
            }}
        >
            <Typography>{label}</Typography>
            <Switch size={'small'} disabled={disabled} checked={checked} onChange={handleToggle}
                    className="custom-switch"/>
        </div>
    );
};


// const webMatColumns = ["Document Name", "Delete"];

interface Document {
    key: string;
    title: string;
    // is_required: boolean;
}


const FacilityAddDetailsComponent = (props: any) => {
    const {documents} = useSelector((state: any) => state.meta);
    const [facilityDocuments, setFacilityDocuments] = useState<any>(documents);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currentTitle, setCurrentTitle] = useState<string>("");
    const [error, setError] = useState<string>("");
    const previewOtherFile = props?.previewOtherFile;
    const onAdd = props?.onAdd;
    const setNewlyAddedFacilityDocuments = props?.setNewlyAddedFacilityDocuments
    const regions = props?.regions;
    const americanTimeZone = props?.americanTimeZone;
    const fileUpload = props?.fileUpload;
    const previewFile = props?.previewFile;
    const deleteFile = props?.deleteFile;
    const OnFileSelected = props?.OnFileSelected;
    const contractFileUpload = props?.contractFileUpload;
    const previewContractFile = props?.previewContractFile;
    const deleteContractFile = props?.deleteContractFile;
    const OnContractFileSelected = props?.OnContractFileSelected;
    const otHours = props?.otHours;
    const otherDocuments = props?.otherDocuments;
    const setOtherDocuments = props?.setOtherDocuments;
    const OnOtherFileSelected = props?.OnOtherFileSelected;
    const setRequiredDocumentsForStaff = props?.setRequiredDocumentsForStaff;

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [optionalFields, setOptionalFields] = useState<any>([]);

    useEffect(() => {
        if (documents?.length > 0) {
            const updatedDocuments=documents?.map((doc:any)=>({
                ...doc,
                title:doc?.title === 'License' ? 'Nursing License' : doc?.title,
            }))
            setFacilityDocuments(updatedDocuments)
        }
    }, [documents]);

    const initialDocumentStates = useMemo(() => {
        return documents?.reduce((acc: { [key: string]: boolean }, doc: { key: string; is_mandatory: boolean }) => {
            acc[doc.key] = doc.is_mandatory;
            return acc;
        }, {}) || {};
    }, [documents]);

    const [documentStates, setDocumentStates] = useState<{ [key: string]: boolean }>(initialDocumentStates);

    useEffect(() => {
        if (Object.keys(documentStates).length === 0) {
            // Initialize only if documentStates is empty
            setDocumentStates(initialDocumentStates);
        }
    }, [initialDocumentStates,documentStates]);

    // Function to show the modal
    const handleOpenModal = useCallback(() => {
        setIsModalVisible(true);
        setError("");
    }, []);

    // Function to close the modal
    const handleCloseModal = useCallback(() => {
        setIsModalVisible(false);
        setCurrentTitle("");
        setError("");// Clear the input field when modal closes
    }, []);

    // Function to add a new title
    const handleAddTitle = useCallback(() => {
        const trimmedTitle = currentTitle;
        console.log(trimmedTitle)
        if (trimmedTitle) {
            if (!trimmedTitle) {
                setError("Title cannot be empty.");
                return;
            }
            if(trimmedTitle?.replace(/\s/g, "").toLowerCase() === 'license'){
                setError('Document already exist');
                return;
            }

            // Check for duplicates (case-insensitive)
            const isDuplicate = facilityDocuments?.some(
                (doc: any) =>
                    doc?.title?.replace(/\s/g, "").toLowerCase() ===
                    trimmedTitle?.replace(/\s/g, "").toLowerCase()
            );
            if (isDuplicate) {
                setError("Document already exist");
                return;
            }

            const key = trimmedTitle?.toLowerCase().replace(/\s+/g, "_");
            const newDocument: Document = {
                key,
                title: trimmedTitle,
                //  is_required: false,
            };
            setFacilityDocuments((prevDocuments: any) => [...prevDocuments, newDocument]);
            setNewlyAddedFacilityDocuments((prevDocuments: any) => [...prevDocuments, newDocument])
            handleCloseModal(); // Close the modal after adding
        }
    }, [currentTitle, handleCloseModal, facilityDocuments, setNewlyAddedFacilityDocuments]);


    // Function to handle input change
    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentTitle(event.target.value);
            setError("");
        },
        []
    );


    const deleteOtherFile = (temp: any, itemIndex: any) => {
        const updatedOthersDocument = [...otherDocuments];
        updatedOthersDocument.splice(temp, 1);
        setOtherDocuments(updatedOthersDocument);
    };

    const showDropDownBelowField = {
        MenuProps: {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            getContentAnchorEl: null,
        },
    };
    const onlyNums = /^\d{1,3}(\.$|\.\d{1,3}$|$)/;




    const handleToggleChange = (id: string, checked: boolean) => {
        setDocumentStates((prevStates) => ({
            ...prevStates,
            [id]: checked,
        }));
    };

    console.log(documentStates,"DS")

    const handleSubmit = useCallback(() => {
        if(facilityDocuments?.length > 0){
        const documentChecklist = facilityDocuments?.map((doc: any) => ({
            key: doc.key,
            title: doc.title === 'Nursing License'? 'License' : doc.title,
            is_required: documentStates[doc.key] || false,
        }));

        // Send data to backend
            setRequiredDocumentsForStaff({document_checklist: documentChecklist})
        }
        // console.log("Submitting data to backend:", {document_checklist: documentChecklist});
        // Add your backend call here
        // eslint-disable-next-line
    }, [documentStates, setRequiredDocumentsForStaff]);

    useEffect(() => {
        handleSubmit()
    }, [handleSubmit]);

    async function getLatLong(street: any, city: any, state: any, zipcode: any) {
        // const address = `${street}, ${city}, ${state}, ${zipcode}, USA`;
        const url = `https://nominatim.openstreetmap.org/search?postalcode=${zipcode}&countrycodes=us&format=json&limit=1`;
        // const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&countrycodes=us&format=json&addressdetails=1&limit=1`;
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.length > 0) {
                const location = data[0];
                return {
                    latitude: location.lat,
                    longitude: location.lon,
                };
            } else {
                throw new Error('Geocoding failed: No results found');
            }
        } catch (error) {
            console.error('Error fetching geolocation:', error);
            return null;
        }
    }


    return (
        <div>
            <div>
                {/* Button to open the modal */}


                {/* Modal dialog for adding a document title */}
                <Modal
                    title="Add New Document"
                    open={isModalVisible}
                    onOk={handleAddTitle}
                    onCancel={handleCloseModal}
                    okText="Add Document"
                    cancelText="Cancel Adding Document"
                    className="custom-modal"
                >
                    <Input
                        value={currentTitle}
                        onChange={handleInputChange}
                        placeholder="Enter document title"
                        maxLength={50}
                        allowClear
                    />
                    {error && (
                        <Typography style={{marginTop: "0.5rem", display: "block", color: "red"}}>
                            {error}
                        </Typography>
                    )}
                </Modal>

                {/* List to display all added document titles */}

            </div>
            <Formik initialValues={facilityInitialState} validateOnChange={true} validateOnBlur={false}
                    validationSchema={facilityFormValidation} onSubmit={onAdd}>
                {({isSubmitting, isValid, resetForm, setFieldValue, values}) => (
                    <Form id="facility-add-form" className={"form-holder"}>
                        <DrawerComponent
                            isOpen={openDrawer}
                            onClose={() => setOpenDrawer(false)}
                            title={'More Fields'}
                            showClose={true}

                        >
                            <MoreFieldsDrawerComponent
                                fields={FIELDS}
                                setOpenDrawer={setOpenDrawer}
                                setOptionalFields={setOptionalFields}
                                optionalFields={optionalFields}
                                setFieldValue={setFieldValue}
                            />
                        </DrawerComponent>
                        <ScrollToError/>
                        <div className="facility-basic-details custom-border">
                            <p className="card-header">Basic Details</p>
                            <div className="input-container">
                                <Field variant="outlined" name="name" type={"text"} component={TextField}
                                       label="Facility Name*" fullWidth autoComplete="off"
                                       InputLabelProps={{shrink: true}}
                                       id="input_facility_add_facility_name"/>
                                <Field variant="outlined" name="business_name" type={"text"} component={TextField}
                                       label="Business Name" fullWidth autoComplete="off"
                                       InputLabelProps={{shrink: true}}
                                       id="input_facility_add_business_name"/>
                            </div>

                            <div className="input-container">
                                <Field variant="outlined" name="uid" type={"text"} component={TextField}
                                       label="Facility Unique ID*" fullWidth autoComplete="off"
                                       InputLabelProps={{shrink: true}}
                                       id="input_facility_add_facility_uid"/>
                                <Field variant="outlined" name="short_name" type={"text"} component={TextField}
                                       label="Facility Short Name*" fullWidth autoComplete="off"
                                       InputLabelProps={{shrink: true}}
                                       id="input_facility_add_facility_short_name"/>
                            </div>

                            <div className="input-container">
                                <div className="flex-1">
                                    <Field
                                        inputProps={{
                                            maxLength: 10,
                                            onInput: (e: any) => {
                                                if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10);
                                            },
                                        }}
                                        className="phone_number"
                                        variant="outlined"
                                        name="contact_number"
                                        type={"number"}
                                        component={TextField}
                                        label="Phone Number*"
                                        fullWidth
                                        autoComplete="off"
                                        id="input_facility_member_add_phone_number"
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    +1
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className={'flex-1'}>
                                    <Field variant="outlined" name="email" type={"text"} component={TextField}
                                           label="Email"
                                           InputLabelProps={{shrink: true}}
                                           fullWidth autoComplete="off" id="input_facility_add_email"/>
                                </div>
                            </div>
                            {/*<div className="input-container">*/}

                            {/*   */}
                            {/*</div>*/}

                            <div className="input-container ">
                                <Field variant="outlined" name="address.street" type={"text"} component={TextField}
                                       label="Street*" fullWidth autoComplete="off" InputLabelProps={{shrink: true}}
                                       id="input_facility_add_address_street"/>
                                <Field variant="outlined" name="address.city" type={"text"} component={TextField}
                                       InputLabelProps={{shrink: true}}
                                       label="City*" fullWidth autoComplete="off" id="input_facility_add_address_city"/>
                            </div>

                            <div className="input-container">
                                <div className={'flex-1'}>
                                    <Field
                                        SelectProps={showDropDownBelowField}
                                        variant="outlined"
                                        name="address.state"
                                        type={"text"}
                                        component={TextField}
                                        select
                                        label="State*"
                                        fullWidth
                                        className="flex-1"
                                        autoComplete="off"
                                        id="input_facility_add_address_state"
                                        InputLabelProps={{shrink: true}}
                                    >
                                        {regions &&
                                            regions.map((item: any, index: any) => (
                                                <MenuItem id={`${item}`} value={item.code} key={index}>
                                                    {item.title}
                                                </MenuItem>
                                            ))}
                                    </Field>
                                </div>
                                {/*<Field variant="outlined" name="address.state" type={"text"} component={TextField}*/}
                                {/*       InputLabelProps={{shrink: true}}*/}
                                {/*       label="State*" fullWidth autoComplete="off"*/}
                                {/*       id="input_facility_add_address_state"/>*/}
                                <div className={'flex-1'}>
                                    <Field variant="outlined" name="address.country" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Country*" fullWidth autoComplete="off"
                                           id="input_facility_add_address_country"/>
                                </div>
                            </div>
                            <div className="input-container d-flex">
                                <div style={{width: "50%"}}>
                                    <Field SelectProps={showDropDownBelowField} variant="outlined" name="timezone"
                                           type={"text"} component={TextField} select label="Facility Timezone*"
                                           fullWidth InputLabelProps={{shrink: true}}
                                           autoComplete="off" id="input_facility_add_timezone">
                                        <MenuItem style={{fontWeight: 500}} value="">
                                            Select Timezone
                                        </MenuItem>
                                        {americanTimeZone &&
                                            americanTimeZone.map((item: any, index: any) => (
                                                <MenuItem style={{fontSize: "14px"}} value={item.value} key={index}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                    </Field>
                                </div>
                                <div style={{width: "50%"}} className={'d-flex'}>
                                    <div style={{width: "70%"}}>
                                        <Field
                                            inputProps={{
                                                maxLength: 6,
                                            }}
                                            variant="outlined"
                                            name="address.zip_code"
                                            type={"text"}
                                            component={TextField}
                                            label="Zip Code*"
                                            autoComplete="off"
                                            id="input_facility_add_address_zip_code"
                                            fullWidth
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </div>
                                    <div className={'mrg-left-10 mrg-top-5'}>
                                        <Button variant={'contained'} color={"primary"}
                                            //@ts-ignore
                                                disabled={!values?.address?.zip_code}
                                                onClick={() => {
                                                    //@ts-ignore
                                                    getLatLong('', '', '', values?.address?.zip_code)
                                                        .then((coords: any) => {
                                                            setFieldValue("latitude", coords.latitude);
                                                            setFieldValue("longitude", coords.longitude);
                                                            CommonService.showToast("Latitude and Longitude Fetched", "success")
                                                            // const googleMapsUrl = `https://www.google.com/maps?q=${coords.latitude},${coords.longitude}`;
                                                            // window.open(googleMapsUrl, '_blank');
                                                        })
                                                        .catch((error) => CommonService.showToast("Not Able to fetch Latitude and Longitude due to geocode errors", "error"));
                                                }}>
                                            Check
                                        </Button>
                                    </div>
                                    <div className={'mrg-left-10 mrg-top-5'}>
                                        <Button variant={'contained'} color={"primary"}
                                            //@ts-ignore
                                                disabled={!values.latitude || !values.longitude}
                                                onClick={() => {
                                                    //@ts-ignore
                                                    getLatLong('', '', '', values?.patient_zip_code)
                                                        .then((coords: any) => {
                                                            //@ts-ignore
                                                            if (values.latitude && values.longitude) {
                                                                //@ts-ignore
                                                                const googleMapsUrl = `https://www.google.com/maps?q=${values.latitude},${values.longitude}`;
                                                                window.open(googleMapsUrl, '_blank');
                                                            }
                                                        })
                                                        .catch((error) => console.error(error));
                                                }}>
                                            Map
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="input-container">
                                <Field fullWidth variant="outlined" name="latitude" type={"text"} component={TextField}
                                       label="Latitude*" autoComplete="off" id="input_facility_add_latitude"
                                       InputLabelProps={{shrink: true}}/>
                                <Field fullWidth variant="outlined" name="longitude" type={"text"} component={TextField}
                                       label="Longitude*" autoComplete="off" id="input_facility_add_longitude"
                                       InputLabelProps={{shrink: true}}/>
                            </div>
                            <div className={'input-container'}>
                                <Field variant="outlined" name="website_url" type={"text"} component={TextField}
                                       label="Website" fullWidth autoComplete="off" InputLabelProps={{shrink: true}}
                                       id="input_facility_add_website_url"/>
                                <Field fullWidth variant="outlined" name="parent_company" type={"text"}
                                       component={TextField}
                                       label="SNF Parent Company" autoComplete="off" InputLabelProps={{shrink: true}}
                                       id="input_facility_add_SNF_Parent_company"/>
                            </div>
                            <div className="facility-about ">
                                <p className="card-header">About the Facility</p>
                                <Field variant="outlined" component={TextField} type={"text"} name="about" fullWidth
                                       multiline rows={2} id="input_facility_add_about"/>
                            </div>


                            <div style={{display: "flex", justifyContent: 'space-between'}}>
                                <div>
                                    <p className={'card-header facility-image-header'}>Upload Facility Image</p>
                                    {fileUpload && fileUpload?.wrapper.length >= 1 ?
                                        <FilePreviewComponent
                                            allowedTypes={".jpg,.png,.jpeg"}
                                            uploadedFile={fileUpload?.wrapper}
                                            previewFile={previewFile}
                                            deleteFile={deleteFile}
                                        /> :
                                        <FileUploadComponent
                                            onFileSelected={OnFileSelected}
                                            allowedTypes={".jpg,.png,.jpeg"}
                                        />
                                    }
                                </div>
                                <div>
                                    <p className={'card-header facility-image-header'}>Upload Facility Contract</p>
                                    {contractFileUpload && contractFileUpload?.wrapper?.length >= 1 ?
                                        <FilePreviewComponent
                                            allowedTypes={".jpg,.png,.jpeg,.pdf"}
                                            uploadedFile={contractFileUpload?.wrapper}
                                            previewFile={previewContractFile}
                                            deleteFile={deleteContractFile}
                                        /> :
                                        <FileUploadComponent
                                            onFileSelected={OnContractFileSelected}
                                            allowedTypes={".jpg,.png,.jpeg,.pdf"}
                                        />}
                                </div>
                                <div>
                                    <p className={'card-header facility-image-header'}>Others</p>
                                    <FileUploadComponent
                                        onFileSelected={OnOtherFileSelected}
                                        allowedTypes={".pdf"}
                                    />
                                </div>
                            </div>
                            {otherDocuments?.length > 0 &&
                                <div className={'mrg-top-20'}>
                                    <AntTableComponent
                                        columns={[
                                            {
                                                title: 'Document Name',
                                                width: 'auto',
                                                render: (item: any) => {
                                                    return (
                                                        <div
                                                            style={{cursor: 'pointer', textDecoration: "underline"}}
                                                            onClick={() => previewOtherFile(item?.index, "attachment")}
                                                        >
                                                            {item.name || 'N/A'}
                                                        </div>
                                                    )
                                                }
                                            },
                                            {
                                                title: 'Delete',
                                                width: 90,
                                                render: (item: any) => {
                                                    return (
                                                        <div className={'display-center'}>
                                                            <IconButton
                                                                onClick={() => deleteOtherFile(item?.index, item?.index)}>
                                                                <CancelIcon className="delete-icon"/>
                                                            </IconButton>
                                                        </div>
                                                    )
                                                }
                                            }
                                        ]}
                                        extraPayload={otherDocuments}
                                        data={otherDocuments}
                                        isRowSelection={false}
                                    />
                                </div>
                            }
                        </div>

                        <div className="notification-preferences custom-border mrg-top-10">
                            <p className="card-header">Notification Preferences</p>
                            <div className="notification-preferences-container">
                                <Field type="checkbox" component={CheckboxWithLabel}
                                       name="notification_preferences.sms" Label={{label: "SMS"}}/>
                                <Field type="checkbox" component={CheckboxWithLabel}
                                       name="notification_preferences.email" Label={{label: "Email"}}/>
                                <Field type="checkbox" component={CheckboxWithLabel}
                                       name="notification_preferences.push_notification"
                                       Label={{label: "In-application"}}/>
                            </div>
                        </div>

                        <div className="facility-basic-details custom-border mrg-top-10">
                            <div className={'d-flex justify-content-space-between mrg-bottom-20'}>
                                <div className="card-header mrg-top-5">Mandatory Documents</div>
                                <div>
                                    <Button variant={"contained"} color={'primary'} onClick={handleOpenModal}>
                                        Add New Document
                                    </Button>
                                </div>
                            </div>
                            <Row gutter={[16, 16]}>
                                {facilityDocuments?.map((doc: any, index: any) => (
                                    <Col xs={32} sm={16} md={8} key={doc.key}>
                                        <DocumentToggle initialChecked={documentStates[doc.key]} id={doc.key}
                                                        disabled={doc.is_mandatory}
                                                        label={`${index + 1}. ${doc.title}`}
                                                        onChange={handleToggleChange}/>

                                    </Col>
                                ))}
                            </Row>
                        </div>


                        <div className="facility-other-details mrg-top-10 custom-border">
                            <p className="card-header">Additional Details</p>

                            {/*REQUIRED FIELDS*/}


                            <div className="input-container ">
                                <Field variant="outlined" name="hourly_base_rates.cna" type={"text"}
                                       component={TextField} label="CNA Rate ($/hr)*" fullWidth autoComplete="off"
                                       InputLabelProps={{shrink: true}}
                                       id="input_facility_add_hourly_base_rates_cna" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.cna", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.lvn" type={"text"}
                                       InputLabelProps={{shrink: true}}
                                       component={TextField} label="LVN Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_lvn" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.lvn", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="diff_rates.noc" type={"text"} component={TextField}
                                       InputLabelProps={{shrink: true}}
                                       label="NOC Diff Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_noc" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("diff_rates.noc", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.hazard" type={"text"}
                                       InputLabelProps={{shrink: true}}
                                       component={TextField} label="Hazard Rate ($/hr)*" fullWidth
                                       autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_hazard" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.hazard", e.target.value);
                                    }
                                }}/>
                            </div>

                            <div className="input-container ">
                                <Field variant="outlined" name="diff_rates.pm" type={"text"} component={TextField}
                                       InputLabelProps={{shrink: true}}
                                       label="PM Diff Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_pm" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("diff_rates.pm", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="diff_rates.weekend" type={"text"}
                                       InputLabelProps={{shrink: true}}
                                       component={TextField}
                                       label="Weekend Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_diff_rates_weekend" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("diff_rates.weekend", e.target.value);
                                    }
                                }}/>
                                <Field variant="outlined" name="hourly_base_rates.rn" type={"text"}
                                       InputLabelProps={{shrink: true}}
                                       component={TextField} label="RN Rate ($/hr)*" fullWidth autoComplete="off"
                                       id="input_facility_add_hourly_base_rates_rn" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.rn", e.target.value);
                                    }
                                }}/>

                                <Field variant="outlined" name="hourly_base_rates.holiday" type={"text"}
                                       component={TextField} label="Holiday Multiplier Rate*" fullWidth
                                       autoComplete="off"
                                       InputLabelProps={{shrink: true}}
                                       id="input_facility_add_hourly_base_rates_holiday" onChange={(e: any) => {
                                    if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                        setFieldValue("hourly_base_rates.holiday", e.target.value);
                                    }
                                }}/>
                            </div>

                            {/*REQUIRED FIELDS*/}

                            {/*-----OPTIONAL FIELDS----*/}
                            <div className={'optional-field-wrapper'}>
                                {optionalFields?.map((field: any) => (
                                    field.name === 'conditional_rates.overtime.hours' ? (
                                        <Field
                                            SelectProps={showDropDownBelowField}
                                            variant="outlined"
                                            name={field.name}
                                            type="text"
                                            component={TextField}
                                            select
                                            label={field.label || 'OT Hours (hr/day)'}
                                            fullWidth
                                            autoComplete="off"
                                            id={field.id || 'input_' + field.name}
                                            InputLabelProps={{shrink: true}}
                                        >
                                            <MenuItem>Select</MenuItem>
                                            {otHours &&
                                                otHours.map((item: any, index: any) => (
                                                    <MenuItem value={item.value} key={index}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                    ) : (
                                        <Field
                                            key={field.id}
                                            variant="outlined"
                                            name={field.name || ''}
                                            type="text"
                                            component={TextField}
                                            label={field.label}
                                            fullWidth
                                            autoComplete="off"
                                            id={field.id}
                                            InputLabelProps={{shrink: true}}
                                            onChange={(e: any) => {
                                                if (e.target.value === "" || onlyNums.test(e.target.value)) {
                                                    const value = e.target.value;
                                                    setFieldValue(field.name, value ? parseInt(value) : "");
                                                }
                                            }}
                                        />
                                    )
                                ))}
                            </div>

                            <div className={'add-fields-wrapper'} onClick={(event: any) => {
                                setOpenDrawer(true)
                            }}>
                                <ImageConfig.AddIcon/>
                                <p>Add fields</p>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default FacilityAddDetailsComponent;
