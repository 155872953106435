import { Tooltip } from "@material-ui/core";
// import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { TsDataListOptions, TsDataListState, TsDataListWrapperClass } from '../../../../../classes/ts-data-list-wrapper.class';
// import LoaderComponent from '../../../../../components/LoaderComponent';
// import NoDataCardComponent from '../../../../../components/NoDataCardComponent';
import {ENV, ImageConfig} from '../../../../../constants';
import { ApiService } from '../../../../../helpers';
import './ApprovedHcpApplicationComponent.scss';
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";

export interface ApprovedHcpApplicationComponentProps {
    isAddOpen: Boolean;
}

const ApprovedHcpApplicationComponent = (props: PropsWithChildren<ApprovedHcpApplicationComponentProps>) => {
    const isAddHcpOpen = props?.isAddOpen
    const param = useParams<any>()
    const { id } = param
    const [list, setList] = useState<TsDataListState | null>(null);
    const init = useCallback(() => {
        const options = new TsDataListOptions({
            webMatColumns: ['Staff Name', 'Approved By', 'Applied On', 'Staff Rate', 'Actions'],
            mobileMatColumns: ['Staff Name', 'Approved By', 'Applied On', 'Staff Rate', 'Actions'],
        }, ENV.API_URL + 'requirement/' + id + '/application?status=approved', setList, ApiService, 'get');
        let tableWrapperObj = new TsDataListWrapperClass(options)
        setList({ table: tableWrapperObj });
    }, [id])

    useEffect(() => {
        init()
    }, [init, isAddHcpOpen])

    const approvedListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title: 'Staff Name',
                dataIndex: 'hcp_details',
                key: 'hcp_details',
                width:200,
                render: (hcp_details: any) => {
                    return (
                        <div>
                            {(hcp_details?.first_name + hcp_details?.last_name)?.length > 20 ?
                                <Tooltip title={hcp_details?.first_name + " " + hcp_details?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Approved By',
                dataIndex: 'approved_by',
                key: 'approved_by',
                width: 200,
                render: (approved_by: any) => {
                    return (
                        <div>
                            {(approved_by?.first_name + approved_by?.last_name)?.length > 20 ?
                                <Tooltip title={approved_by?.first_name + " " + approved_by?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {approved_by?.first_name + " " + approved_by?.last_name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {approved_by?.first_name + " " + approved_by?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Applied On',
                dataIndex: 'created_at',
                key: 'created_at',
                width: 120,
                render: (created_at: any) => {
                    return <span>{moment(created_at).format("MM-DD-YYYY") || 'N/A'}</span>
                }
            },
            {
                title: 'Staff Rate',
                dataIndex: 'hcp_details',
                key: 'hcp_details',
                width: 100,
                render: (hcp_details: any) => {
                    return <span>{hcp_details?.contract_details?.rate_per_hour ? hcp_details?.contract_details?.rate_per_hour : (hcp_details?.contract_details?.rate_per_hour === "" ? "N/A" : hcp_details?.contract_details?.rate_per_hour)}</span>
                }
            },
            {
                title: 'View Details',
                width:100,
                render: (actions: any, row: any) => {
                    return <Link to={{ pathname: '/hcp/user/view/' + row['hcp_details']?._id, state: { prevPath: "/shiftsRequirements/view/" + id } }} className="info-link" id={"link_hospital_details" + row['hcp_details']?._id} >
                        <ImageConfig.ArrowRightOutlinedIcon/>
                    </Link>
                }
            }
        ]
    },[id])
    return <div className='approved-hcps-list'>
        <AntTableComponent
            data={list?.table?.data}
            extraPayload={list?.table?.data}
            columns={approvedListColumn}
            noDataText={'No Staff Approved Found.'}
        />

        {/*{list && list.table && <>*/}
        {/*    <TableContainer component={Paper} className={'table-responsive'}>*/}
        {/*    {list && list.table?._isDataLoading && (*/}
        {/*      <div className="">*/}
        {/*        <LoaderComponent position="block" />*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*        <Table stickyHeader className='mat-table table shifts-requirment-approved-list-table'>*/}
        {/*            <TableHead className={"mat-thead"}>*/}
        {/*                <TableRow className={"mat-tr"}>*/}
        {/*                    {list?.table.matColumns.map((column: any, columnIndex: any) => (*/}
        {/*                        <TableCell className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}*/}
        {/*                            key={'header-col-' + columnIndex}*/}
        {/*                        >*/}
        {/*                            {column}*/}
        {/*                        </TableCell>*/}
        {/*                    ))}*/}
        {/*                </TableRow>*/}
        {/*            </TableHead>*/}
        {/*            <TableBody className={"mat-tbody"}>*/}
        {/*                {list.table.canShowNoData() &&*/}
        {/*                    <NoDataCardComponent tableCellCount={list.table.matColumns.length} />*/}
        {/*                }*/}
        {/*                {list?.table.data.map((row: any, rowIndex: any) => {*/}
        {/*        const hcpRate = row["hcp_details"]["contract_details"]?.rate_per_hour*/}

        {/*                    return (*/}
        {/*                        <TableRow className='mat-tr' role="checkbox" tabIndex={-1} key={'row-' + rowIndex}>*/}
        {/*                            <TableCell className="mat-td mat-td-hcp-name">*/}
        {/*                                {row['hcp_details']?.first_name}&nbsp;{row['hcp_details']?.last_name}*/}
        {/*                            </TableCell>*/}
        {/*                            <TableCell className="mat-td mat-td-approved-name">*/}
        {/*                                {row['approved_by']?.first_name} &nbsp;{row['approved_by']?.last_name}*/}
        {/*                            </TableCell>*/}
        {/*                            <TableCell className="mat-td mat-td-hcp-created-at">*/}
        {/*                                {moment(row['created_at']).format("MM-DD-YYYY")}*/}
        {/*                            </TableCell>*/}
        {/*                            <TableCell className="mat-td mat-td-hcp-rate">{hcpRate ? hcpRate : (hcpRate === "" ? "NA" : hcpRate)  }</TableCell>*/}
        {/*                            <TableCell className="mat-td mat-td-sticky mat-td-actions">*/}
        {/*                                <Tooltip title={`View ${row['hcp_details']?.first_name} ${row['hcp_details']?.last_name} Details`}>*/}
        {/*                                    <Link to={{ pathname: '/hcp/user/view/' + row['hcp_details']?._id, state: { prevPath: "/shiftsRequirements/view/" + id } }} className="info-link" id={"link_hospital_details" + rowIndex} >*/}
        {/*                                        {('View Details')}*/}
        {/*                                    </Link>*/}
        {/*                                </Tooltip>*/}
        {/*                            </TableCell>*/}
        {/*                        </TableRow>*/}
        {/*                    )*/}
        {/*                })}*/}
        {/*            </TableBody>*/}
        {/*        </Table>*/}
        {/*    </TableContainer>*/}
        {/*</>}*/}
    </div>;
}

export default ApprovedHcpApplicationComponent;
