import {Button, IconButton, MenuItem, Tooltip} from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import { nanoid } from "nanoid";
import React, {useState, useCallback, useMemo} from "react";
import { shiftType } from "../../../../constants/data";
import { CommonService } from "../../../../helpers";
// import ReadOnlyRow from "./ReadOnlyRow";
import "./ShiftAddComponent.scss";
import { shiftFormValidation } from "./ShiftTimingFormValidation";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {TableColumnsType} from "antd";
import moment from "moment/moment";
import DeleteIcon from "@material-ui/icons/Delete";

type ShiftAddComponentProps = {
  shiftTimings: any;
  setShiftTimings: any;
};

interface ShiftAddType {
  shift_start_time: string;
  shift_end_time: string;
  shift_type: string;
}

const shiftInitalState: ShiftAddType = {
  shift_start_time: "",
  shift_end_time: "",
  shift_type: "",
};

const ShiftAddComponent = ({ shiftTimings, setShiftTimings }: ShiftAddComponentProps) => {
  const [isShifts, setIsShifts] = useState<boolean>(false);
  const showDropDownBelowField = {
    MenuProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    },
  };

  const onAdd = (shift: ShiftAddType, { setSubmitting, setErrors, resetForm }: FormikHelpers<ShiftAddType>) => {
    //console.log(shift);
    const newShiftTimings = {
      id: nanoid(),
      shift_start_time: CommonService.convertHoursToMinutes(shift?.shift_start_time),
      shift_end_time: CommonService.convertHoursToMinutes(shift?.shift_end_time),
      shift_type: shift?.shift_type,
    };

    const newShifts = [...shiftTimings, newShiftTimings];
    setShiftTimings(newShifts);

    resetForm();
    handleCloseShiftForm();
    CommonService.showToast("Shift Timing added", "info");
  };

  const handleCloseShiftForm = () => {
    setIsShifts(false);
  };

  const handleDeleteClick = useCallback((shiftId: number) => {
    const newShiftTimings = [...shiftTimings];
    const index = shiftTimings.findIndex((shiftTiming: any) => shiftTiming.id === shiftId);
    newShiftTimings.splice(index, 1);
    setShiftTimings(newShiftTimings);
    CommonService.showToast("Shift Timing deleted", "success");
  },[shiftTimings,setShiftTimings]);

  const shiftTimingListColumn:TableColumnsType=useMemo(()=>{
    return [
      {
        title: "Start Time",
        dataIndex: "shift_start_time",
        key: "shift_start_time",
        width:50,
        render: (item: any) => {
          return (
              <div>
                {moment(CommonService.convertMinsToHrsMins(item), "hh:mm").format("LT") || 'N/A'}
              </div>
          )
        },
      },
      {
        title:'End Time',
        dataIndex: "shift_end_time",
        key: "shift_end_time",
        width: 50,
        render:(item:any)=>{
          return (
              <div>
                {moment(CommonService.convertMinsToHrsMins(item), "hh:mm").format("LT") || 'N/A'}
              </div>
          )
        }
      },
      {
        title: "Shift Type",
        dataIndex: "shift_type",
        key: "shift_type",
        width:200,
        render:(item:any)=>{
          return (
              <div>
                {item || 'N/A'}
              </div>
          )
        }
      },
      {
        title:'Duration',
        width:40,
        render:(item:any)=>{
          let start_time_to_cal = CommonService.convertMinsToHrsMins(item.shift_start_time);
          let end_time_to_cal = CommonService.convertMinsToHrsMins(item.shift_end_time);

          const start_time_to_get_duration = moment().startOf("day").add(item.shift_start_time, "minutes");
          let end_time_to_get_duration = moment().startOf("day").add(item.shift_end_time, "minutes");
          if (start_time_to_cal > end_time_to_cal) {
            end_time_to_get_duration = moment(end_time_to_get_duration).add(1, "day");
          }
          return (
              <div>
                {CommonService.durationBetweenTimeStamps(start_time_to_get_duration, end_time_to_get_duration) || 'N/A'}
              </div>
          )
        }
      },
      {
        title:'Delete',
        width:60,
        render:(item:any)=>{
          return (
              <Tooltip title={`Delete Shift Timings`}>
                <IconButton onClick={() => handleDeleteClick(item.id)}>
                  <DeleteIcon className="delete-icon" />
                </IconButton>
              </Tooltip>
          )
        }
      }
    ]
  },[handleDeleteClick]);

  return (
    <div className="shift-add-container">
      {isShifts ? (
        <Formik initialValues={shiftInitalState} validateOnChange={true} validationSchema={shiftFormValidation} onSubmit={onAdd}>
          {({ isSubmitting, handleSubmit, isValid, resetForm }) => (
            <Form className={"form-holder"}>
              <div className="shift-add-input">
                <Field fullWidth variant="outlined" type="time" component={TextField} name="shift_start_time" label="Shift Start Time" InputLabelProps={{ shrink: true }} id="input_shift_add_shift_start_time" />
                <Field fullWidth variant="outlined" type="time" component={TextField} name="shift_end_time" label="Shift End Time" InputLabelProps={{ shrink: true }} id="input_shift_add_shift_end_time" />
                <Field select fullWidth SelectProps={showDropDownBelowField} variant="outlined" component={TextField} name="shift_type" label="Shift Type" id="input_shift_add_shift_type" InputLabelProps={{ shrink: true }}>
                  <MenuItem value="">Select Shift Type</MenuItem>
                  {shiftType.length > 0 &&
                    shiftType.map((item: any, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Field>
              </div>
              <div className="shift-add-btn-grp">
                <Button id="btn_add_shift_cancel" color="primary" variant="outlined" onClick={handleCloseShiftForm}>
                  Delete
                </Button>
                <Button id="btn_add_shift_save" variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="shift-add-action">
          <Tooltip title={"Add New Shift Timing"}>
            <p onClick={() => setIsShifts(true)} id="btn_add_shift_submit" className="generic-add-multiple">
              + Add a Shift
            </p>
          </Tooltip>
        </div>
      )}
      {shiftTimings.length > 0 && (
          // <Table className="mrg-top-50">
          //   <TableHead className={"mat-thead"}>
          //      <TableRow className={"mat-tr"}>
          //       <th>Start Time</th>
          //       <th>End Time</th>
          //       <th>Shift Time</th>
          //       <th>Duration</th>
          //       <th>Actions</th>
          //     </TableRow>
          //   </TableHead>
          //  <TableBody className={"mat-tbody"}>
          //     {shiftTimings.map((shiftTiming: any) => (
          //       <ReadOnlyRow key={shiftTiming.id} shiftTimings={shiftTiming} handleDeleteClick={handleDeleteClick} />
          //     ))}
          //   </TableBody>
          // </Table>
          <div className={'mrg-top-20'}>
              <AntTableComponent
                  data={shiftTimings}
                  extraPayload={shiftTimings}
                  noDataText={'No Shift Timings added'}
                  columns={shiftTimingListColumn}
              />
          </div>
      )}
    </div>
  );
};

export default ShiftAddComponent;
