import {Button, Tooltip} from "@material-ui/core";
import {useMemo} from 'react';
import {AddRounded, SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import AccessControlComponent from "../../../components/AccessControl";
import {ENV, ImageConfig} from "../../../constants";
import {CssTextField} from "../../../constants/data/styles";
import {ApiService, Communications} from "../../../helpers";
import CommonService, {
    ACCOUNTMANAGER,
    ADMIN,
    FINANCE,
    HUMANRESOURCE,
    NURSECHAMPION
} from "../../../helpers/common-service";
import "./HcpManagementListScreen.scss";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import WarningIcon from '@material-ui/icons/Warning';
import DrawerComponent from "../../../components/drawer/DrawerComponent";
import HcpFilterDrawerComponent from "../hcp-filter-drawer/HcpFilterDrawerComponent";
import AntTableComponent from "../../../components/ant-table-component/AntTableComponent";
import {TableColumnsType} from "antd";
import {useSessionStorage} from "../../../components/useSessionStorage";

const HcpManagementListScreen = () => {
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    // const [selectedHcpTypes, setSelectedHcpTypes] = useLocalStorage<any[]>("hcpSelectedTypes", []);
    // const [status, setStatus] = useLocalStorage<any>("hcpStatus", {
    //     name: "Pending",
    //     code: "pending",
    // });
    // const [dateRange, setDateRange] = useLocalStorage<any[]>("hcpDateRange", [null, null]);
    const history=useHistory();
    const [selectedHcp, setSelectedHcp] = useState<any>([]);
    const [isRejectedHcpMoved, setIsRejectedHcpMoved] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    //FITTER STATES
    const [filterData,setFilterData]=useSessionStorage<any>("staffFilterData",{
        search:'',
        sort:{},
        status:'pending',
        is_approved:false,
    })
    const [filterCount,setFilterCount]=useSessionStorage<number>("staffFilterCount",0);
    const [openFilterDrawer,setOpenFilterDrawer]=useState<boolean>(false);

    // useEffect(() => {
    //     if (status === null) {
    //         setStatus({name: "Pending", code: "pending"});
    //     }
    // }, [setStatus, status]);


    useEffect(() => {
        Communications.pageTitleSubject.next("Application Tracking System (ATS) ");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    const handleRejectedUserToPending = useCallback(() => {
        setIsRejectedHcpMoved(true);
        const payloadData = {
            "hcp_ids": selectedHcp
        };
        CommonService._api
            .patch(ENV.API_URL + "hcp/recover", payloadData)
            .then((resp) => {
                CommonService.showToast(resp?.msg || "Success", "success");
                let updatedFilterData={...filterData};
                setFilterData(updatedFilterData);
                setSelectedHcp([]);
                setIsRejectedHcpMoved(false);
            })
            .catch((err) => {
                CommonService.showToast(err?.msg || "Error", "error");
                setSelectedHcp([]);
                setIsRejectedHcpMoved(false);
            });
    }, [selectedHcp,filterData,setFilterData]);

    const hcpListColumns:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'',
                width:55,
                render:(item:any)=>{
                    return (
                        <div className={'display-center'}>
                            {(item?.is_license_expired || !item?.hasOwnProperty("license_expiry_date"))&&
                                <Tooltip title={'Nursing License Expired Please Renew It'}>
                                    <div
                                        style={{
                                            color: "red",
                                            cursor: "pointer",
                                        }}
                                        onClick={()=>history.push(`/hcp/edit/${item._id}`)}
                                        >
                                        {(item.is_license_expired || !item?.hasOwnProperty("license_expiry_date")) &&
                                            <WarningIcon/>}
                                    </div>
                                </Tooltip>}
                        </div>
                    )
                }
            },
            {
                title:'Name',
                width:200,
                sorter: (a: any, b: any) => {
                    const fullNameA = (a.first_name + " " + a.last_name).toLowerCase();
                    const fullNameB = (b.first_name + " " + b.last_name).toLowerCase();
                    return fullNameA?.localeCompare(fullNameB);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {(item?.first_name?.length + item?.last_name?.length)>20 ?
                                <Tooltip title={item?.first_name + " " + item?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.first_name + " " + item?.last_name}
                                    </div>
                                </Tooltip>:
                                <div className='facility-name'>
                                    {(item?.first_name + " " + item?.last_name) || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Applied For',
                width:180,
                sorter: (a: any, b: any) => {
                    return a.hcp_type?.localeCompare(b.hcp_type);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp_type}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item.hcp_type}
                                    </div>
                                </Tooltip>
                                :
                                <div className='facility-name'>
                                    {item?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Staff ID',
                width:180,
                sorter: (a: any, b: any) => {
                    return a.hcp_uniqueId?.localeCompare(b.hcp_uniqueId);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.hcp_uniqueId?.length >20 ?
                                <Tooltip title={item?.hcp_uniqueId}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item.hcp_uniqueId}
                                    </div>
                                </Tooltip>
                                :
                                <div className='facility-name'>
                                    {item?.hcp_uniqueId || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Email',
                width:200,
                sorter: (a: any, b: any) => {
                    return a.email?.localeCompare(b.email);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.email?.length > 20 ?
                                <Tooltip title={item?.email}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item.email}
                                    </div>
                                </Tooltip>
                                :
                                <div className='facility-name'>
                                    {item?.email || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Created On',
                width:130,
                sorter: (a: any, b: any) => {
                    return moment(a.created_at).unix() - moment(b.created_at).unix();
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {moment(item.created_at).utc().format("MM-DD-YYYY") || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Contact Number',
                width:160,
                // sorter: (a: any, b: any) => {
                //     return a.contact_number?.localeCompare(b.contact_number);
                // },
                render:(item:any)=>{
                    return (
                        <div>
                            {CommonService.formatPhoneNumber(item.contact_number?.replace(/^\+1/,'')) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Status',
                width:120,
                render:(item:any)=>{
                    return (
                        <div>{item?.status || 'N/A'}</div>
                    )
                }
            },
            {
                title:'View Details',
                width:130,
                fixed:'right',
                render:(item:any)=>{
                    return (
                        <div className={'display-center'}>
                            <Link to={`/hcp/view/${item._id}`}
                                  style={{color: '#1e1e1e'}}
                            >
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </Link>
                        </div>
                    )
                }
            }
        ]
    }, [history]);

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};
        payload.is_approved = false;

        if (filterData?.hcp_types?.length > 0) {
            // payload.hcp_types = selectedHcpTypes.map((item: any) => item?.title);
            payload.hcp_types=filterData?.hcp_types;
        }

        if (filterData?.status && filterData?.status !== "all") {
            payload.status = filterData.status;
        }

        if (filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");

            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        if (filterData?.search) {
            payload.search = filterData.search;
        }

        ApiService.post(ENV.API_URL + "hcp/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [filterData]);

    return (
        <>
            <DrawerComponent isOpen={openFilterDrawer} onClose={()=>setOpenFilterDrawer(false)}>
                <HcpFilterDrawerComponent
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    setFilterCount={setFilterCount}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    hcpTypes={hcpTypes}
                />
            </DrawerComponent>
            <div className={"hcp-list screen crud-layout pdd-30"}>
                <div className="custom-border pdd-10  pdd-top-20 pdd-bottom-0">
                    <div className="header">
                        <div className="mrg-left-5 filter">
                            <div>
                                <div className="d-flex">
                                    <div className="d-flex position-relative">
                                        {!filterData.search ? (
                                            <div className={"search_icon"}>
                                                <SearchRounded/>
                                            </div>
                                        ) : (
                                            <div className={"search_icon"}>
                                                <ClearIcon
                                                    onClick={(event) => {
                                                        setFilterData({...filterData, search: '', page: 1})
                                                    }}
                                                    id="clear_hcp_search"
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <CssTextField
                                                defaultValue={""}
                                                className="search-cursor searchField"
                                                id="input_search_hcp"
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setFilterData({...filterData, search: value, page: 1});
                                                }}
                                                value={filterData.search}
                                                variant={"outlined"}
                                                size={"small"}
                                                type={"text"}
                                                placeholder={"Search Staff"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button
                                variant={"outlined"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    setOpenFilterDrawer(true)
                                }}
                            >
                                {filterCount > 0 &&
                                    <span className={'filter-btn-badge'}>{filterCount}</span>
                                }
                                &nbsp;&nbsp;Filters&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div className="action mrg-left-20 d-flex">
                            <AccessControlComponent
                                role={[ADMIN, HUMANRESOURCE, NURSECHAMPION, ACCOUNTMANAGER, FINANCE]}>
                                <div className="hcp-list-download">
                                    <Tooltip title="Download Pending Staff List">
                                        <Button variant={"outlined"} color="primary" onClick={handleDownload}
                                                className={!isDownloading ? "" : "has-loading-spinner"}
                                                disabled={isDownloading}>
                                            &nbsp;Download
                                        </Button>
                                    </Tooltip>
                                </div>
                            </AccessControlComponent>
                        </div>
                        {filterData.status === 'rejected' && <>
                                <div className={'mrg-left-20'}>
                                    <Tooltip title="Move to Pending">
                                        <Button variant={"contained"} disabled={isRejectedHcpMoved} color={"primary"}
                                                onClick={handleRejectedUserToPending}>
                                            &nbsp;&nbsp;Move to pending&nbsp;&nbsp;
                                        </Button>
                                    </Tooltip>
                                </div>
                        </>}
                        <div className="action mrg-left-20">
                            <AccessControlComponent role={[HUMANRESOURCE, ADMIN]}>
                                <Tooltip title="Add New Hcp">
                                    <Button variant={"contained"} id={'add_facility_btn'} color={"primary"}
                                            component={Link} to={`/hcp/add`}>
                                        <AddRounded/>
                                        &nbsp;&nbsp;Add Staff&nbsp;&nbsp;
                                    </Button>
                                </Tooltip>
                            </AccessControlComponent>
                        </div>
                    </div>
                    <div className={'hcp-table-component'}>
                        <AntTableComponent
                            url={ENV.API_URL + "hcp/list"}
                            method={"post"}
                            columns={hcpListColumns}
                            extraPayload={filterData}
                            isRowSelection={filterData?.status === 'rejected' && filterData?.status !== null}
                            selectedRowKeys={selectedHcp}
                            setSelectedRowKeys={setSelectedHcp}
                            noDataText={filterData.search ? 'Oops! It seems like there are no Staff Available for the name you have searched.' : 'No Staff Data Found.'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default HcpManagementListScreen;
