import {Tooltip} from "@material-ui/core";
// import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, {PropsWithChildren, useCallback, useEffect, useState,useMemo} from 'react';
import { useParams} from 'react-router-dom';
import {
    TsDataListOptions,
    TsDataListState,
    TsDataListWrapperClass
} from '../../../../../classes/ts-data-list-wrapper.class';
// import LoaderComponent from '../../../../../components/LoaderComponent';
// import NoDataCardComponent from '../../../../../components/NoDataCardComponent';
import {ENV} from '../../../../../constants';
import {ApiService, CommonService} from '../../../../../helpers';
import './UnApprovedHcpApplicationComponent.scss';
import Button from "@material-ui/core/Button";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";

export interface UnApprovedHcpApplicationComponentProps {
    isAddOpen: Boolean;
}

const UnApprovedHcpApplicationComponent = (props: PropsWithChildren<UnApprovedHcpApplicationComponentProps>) => {
    const isAddOpen = props?.isAddOpen;
    const param = useParams<any>();
    const {id} = param;
    const [list, setList] = useState<TsDataListState | null>(null);
    const [isMovingToPendingLoading, setIsMovingToPendingLoading] = useState(new Array(list?.table.data.length).fill(false));

    const init = useCallback(() => {
        const options = new TsDataListOptions({
            webMatColumns: ['Staff Name', 'Applied On', 'Staff Rate', 'Rejected By', 'Reason', 'Actions'],
            mobileMatColumns: ['Staff Name', 'Applied On', 'Staff Rate', 'Rejected By', 'Reason', 'Actions'],
        }, ENV.API_URL + 'requirement/' + id + '/application?status=rejected', setList, ApiService, 'get');
        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({table: tableWrapperObj});
    }, [id]);

    const moveToPending = useCallback((facilityId: any,index:any) => {
        const newLoadingStates = [...isMovingToPendingLoading];
        newLoadingStates[index] = true;
        setIsMovingToPendingLoading(newLoadingStates);

        CommonService._api.patch(ENV.API_URL + "application/" + facilityId + "/reapply").then((resp) => {
            CommonService.showToast("Success", "success");
            init();
            const newLoadingStates = [...isMovingToPendingLoading];
            newLoadingStates[index] = false;
            setIsMovingToPendingLoading(newLoadingStates);
        })
            .catch((err) => {
                console.log(err);
                const newLoadingStates = [...isMovingToPendingLoading];
                newLoadingStates[index] = false;
                setIsMovingToPendingLoading(newLoadingStates);
                CommonService.showToast(err || "Error", "error");
            });
    }, [ init,isMovingToPendingLoading]);

    useEffect(() => {
        init();
    }, [init, isAddOpen]);

    const rejectedListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title: 'Staff Name',
                dataIndex: 'hcp_details',
                key: 'hcp_details',
                width:200,
                render: (hcp_details: any) => {
                    return (
                        <div>
                            {(hcp_details?.first_name + hcp_details?.last_name)?.length > 20 ?
                                <Tooltip title={hcp_details?.first_name + " " + hcp_details?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Applied On',
                dataIndex: 'created_at',
                key: 'created_at',
                width:120,
                render: (created_at: any) => {
                    return <span>{moment(created_at).format("MM-DD-YYYY") || 'N/A'}</span>
                }
            },
            {
                title: 'Staff Rate',
                dataIndex: 'hcp_details',
                key: 'hcp_details',
                width:120,
                render: (hcp_details: any) => {
                    return <span>{hcp_details?.contract_details?.rate_per_hour ? hcp_details?.contract_details?.rate_per_hour : "N/A"}</span>
                }
            },
            {
                title: 'Rejected By',
                dataIndex: 'rejected_by',
                key: 'rejected_by',
                width:200,
                render: (rejected_by: any) => {
                    return (
                        <div>
                            {(rejected_by?.first_name + rejected_by?.last_name)?.length > 20 ?
                                <Tooltip title={rejected_by?.first_name + " " + rejected_by?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {rejected_by?.first_name + " " + rejected_by?.last_name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {rejected_by?.first_name + " " + rejected_by?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Reason',
                dataIndex: 'rejected_reason',
                key: 'rejected_reason',
                width:200,
                render: (item: any) => {
                    return (
                        <div>
                            {(item)?.length > 20 ?
                                <Tooltip title={item}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                width: 200,
                render: (text: any, record: any) => {
                    return <Button variant={"contained"} color={"primary"} onClick={() => {
                        moveToPending(record["_id"], record)
                    }}>Move to pending</Button>
                }
            }
        ]
    }, [moveToPending]);

    return <div className="unapproved-hcps-list">
            <AntTableComponent
                columns={rejectedListColumn}
                data={list?.table?.data}
                extraPayload={list?.table?.data}
                noDataText={'No Staff Rejected Data Found.'}
            />
    {/*{list && list.table && <>*/}
    {/*        <TableContainer component={Paper} className={'table-responsive'}>*/}
    {/*            {list && list.table?._isDataLoading && (*/}
    {/*                <div className="">*/}
    {/*                    <LoaderComponent position="block"/>*/}
    {/*                </div>*/}
    {/*            )}*/}
    {/*            <Table stickyHeader className="mat-table table shifts-requirment-unapproved-list-table">*/}
    {/*                <TableHead className={"mat-thead"}>*/}
    {/*                    <TableRow className={"mat-tr"}>*/}
    {/*                        {list?.table?.matColumns?.map((column: any, columnIndex: any) => {*/}
    {/*                            return (*/}
    {/*                                <TableCell className={"mat-th"}*/}
    {/*                                           key={'header-col-' + columnIndex}*/}
    {/*                                >*/}
    {/*                                    {column}*/}
    {/*                                </TableCell>*/}
    {/*                            );*/}
    {/*                        })}*/}
    {/*                        <TableCell className={"mat-th mat-th-sticky"}*/}
    {/*                                   key={'header-col-last'}*/}
    {/*                        >*/}
    {/*                            Re Approve*/}
    {/*                        </TableCell>*/}
    {/*                    </TableRow>*/}
    {/*                </TableHead>*/}
    {/*                <TableBody className={"mat-tbody"}>*/}
    {/*                    {list.table.canShowNoData() &&*/}
    {/*                        <NoDataCardComponent tableCellCount={list.table.matColumns.length}/>*/}
    {/*                    }*/}
    {/*                    {list?.table.data.map((row: any, rowIndex: any) => {*/}
    {/*                        const hcpRate = row["hcp_details"]["contract_details"]?.rate_per_hour;*/}
    {/*                        console.log(list?.table.data);*/}
    {/*                        return (*/}
    {/*                            <TableRow className="mat-tr" role="checkbox" tabIndex={-1} key={'row-' + rowIndex}>*/}
    {/*                                <TableCell className="mat-td mat-td-hcp-name">*/}
    {/*                                    {row['hcp_details']?.first_name}&nbsp;{row['hcp_details']?.last_name}*/}
    {/*                                </TableCell>*/}
    {/*                                <TableCell className="mat-td mat-td-created-at">*/}
    {/*                                    {moment(row['created_at']).format("MM-DD-YYYY")}*/}
    {/*                                </TableCell>*/}
    {/*                                <TableCell*/}
    {/*                                    className="mat-td mat-td-hcp-rate">{hcpRate ? hcpRate : (hcpRate === "" ? "NA" : hcpRate)}</TableCell>*/}

    {/*                                <TableCell className="mat-td mat-td-rejected-name">*/}
    {/*                                    {row['rejected_by']?.first_name} &nbsp;{row['rejected_by']?.last_name}*/}
    {/*                                </TableCell>*/}
    {/*                                <TableCell className="mat-td mat-td-rejected-reason">*/}
    {/*                                    {row['rejected_reason']}*/}
    {/*                                </TableCell>*/}
    {/*                                <TableCell className="mat-td mat-td-sticky mat-td-actions">*/}
    {/*                                    <Tooltip*/}
    {/*                                        title={`View ${row['hcp_details']?.first_name} ${row['hcp_details']?.last_name} Details`}>*/}
    {/*                                        <Link to={{*/}
    {/*                                            pathname: '/hcp/user/view/' + row['hcp_details']?._id,*/}
    {/*                                            state: {prevPath: "/shiftsRequirements/view/" + id}*/}
    {/*                                        }} className="info-link" id={"link_hospital_details" + rowIndex}>*/}
    {/*                                            {('View Details')}*/}
    {/*                                        </Link>*/}
    {/*                                    </Tooltip>*/}
    {/*                                </TableCell>*/}
    {/*                                <TableCell className="mat-td mat-td-rejected-reason">*/}
    {/*                                    <Button variant={"contained"}*/}
    {/*                                            disabled={isMovingToPendingLoading[rowIndex]}*/}
    {/*                                            className={isMovingToPendingLoading[rowIndex] ? "normal mrg-left-30 has-loading-spinner" : " mrg-left-30"}*/}
    {/*                                            color={"primary"} onClick={() => {*/}
    {/*                                        moveToPending(row["_id"],rowIndex);*/}
    {/*                                    }}>Move to pending</Button>*/}
    {/*                                </TableCell>*/}
    {/*                            </TableRow>*/}
    {/*                        );*/}
    {/*                    })}*/}
    {/*                </TableBody>*/}
    {/*            </Table>*/}
    {/*        </TableContainer>*/}
    {/*    </>}*/}
    </div>;
};

export default UnApprovedHcpApplicationComponent;
