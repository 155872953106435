import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import LoaderComponent from "../../../../components/LoaderComponent";
import {ENV} from "../../../../constants";
import {CommonService} from "../../../../helpers";
import HcpContractComponent from "../contract/HcpContractComponent";
import "./HcpDetailsComponent.scss";
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import GetAppIcon from "@material-ui/icons/GetApp";
import DialogComponent from "../../../../components/DialogComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


// const webMatColumns = ["Document Name", "Expires On", "Download"];
const HcpDetailsComponent = (props: any) => {
    const params = useParams<{ id: string }>();
    const classes = useStyles();
    const {id} = params;
    const hcpBasicDetails = props?.hcpBasicDetails;
    const [attachmentsDetails, setAttachmentsDetails] = useState<any | null>(null);
    const [sortedAttachments, setSortedAttachments] = useState<any | null>(null);
    const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);
    const [isDownload, setIsDownloading] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [open, setOpen] = useState(false);
    const [documentFile,setDocumentFile]=useState<any>(null)

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getAttachmentsDetails = useCallback(() => {
        setAttachmentLoading(true);
        CommonService._api.get(ENV.API_URL + "hcp/" + id + "/attachments").then((resp) => {
            setAttachmentsDetails(resp?.data);
            setAttachmentLoading(false);
        }).catch((err) => {
            console.log(err);
            setAttachmentLoading(false);
        });
    }, [id]);

    useEffect(() => {
        getAttachmentsDetails();
    }, [getAttachmentsDetails]);

    useEffect(() => {
        const required_attachments = [
            {name: "Resume", index: -1},
            {name: "Physical Test", index: -1},
            {name: "TB Test", index: -1},
            {name: "Chest X-ray", index: -1},
            {name: "CPR/BLS Card", index: -1},
            {name: "Driver License", index: -1},
            {name: "SSN Card", index: -1},
            {name:"Nursing License",index:-1},
            {name: "License", index: -1},
            {name: "Covid Certificate", index: -1},
            {name: "Covid Vaccine Card", index: -1},
            {name: "Covid Test Result", index: -1},
            {name: "Live Scan", index: -1},
            {name: "Vaccine Exemption Letter", index: -1},
        ];
        let tempAttachemnts: any = [];
        required_attachments?.forEach((item: any) => {
            attachmentsDetails?.forEach((attachment: any, index: any) => {
                if (item.name === attachment?.attachment_type) {
                    attachmentsDetails.splice(index, 1);
                    tempAttachemnts.push(attachment);
                }
            });
        });

        attachmentsDetails?.forEach((attachment: any) => {
            tempAttachemnts.push(attachment);
        });
        setSortedAttachments([...tempAttachemnts]);
    }, [attachmentsDetails]);

    const handleDownload = useCallback((file_key: any) => {
        setIsDownloading(true);
        let payload = {
            file_key: file_key
        };
        CommonService._api.post(ENV.API_URL + "downloadAttachment", payload).then((resp) => {
            const link = document.createElement('a');
            link?.setAttribute('href', resp?.data);
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
        }).catch((err) => {
            console.log(err);
            setIsDownloading(false);
        });
    }, []);


    // const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    //     setExpanded(isExpanded ? panel : false);
    // };

    const StyledLoader = () => {
        return (
            <div className="pdd-20" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <LoaderComponent position="block"/>
            </div>
        );
    };

    const previewFile = useCallback((data:any) => {
        setOpen(true)
        setDocumentFile(data);
    }, [])
    const cancelPreviewFile = useCallback(() => {
        setOpen(false)
        setDocumentFile(null)
    }, [])
    const confirmPreviewFile = useCallback(() => {
        setOpen(false)
        setDocumentFile(null)
    }, [])

    return (
        <div className="hcp_details">
            <div className="custom-border pdd-20 mrg-top-15">
                <Accordion expanded={expanded === 'BASIC_DETAILS'} onChange={handleChange('BASIC_DETAILS')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Basic Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'hcp-details-wrapper'}>
                            <div className="d-flex hcp-status">
                                <h2 className="status mrg-right-0">Status</h2> <h2
                                className="status">&nbsp;:&nbsp;{hcpBasicDetails?.status || 'N/A'}</h2>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>First Name</h4>
                                    <p>{hcpBasicDetails?.first_name || 'N/A'} </p>
                                </div>
                                <div className="flex-1">
                                    <h4>Last Name</h4>
                                    <p>{hcpBasicDetails?.last_name || 'N/A'} </p>
                                </div>
                                <div className="flex-1">
                                    <h4>Contact Number</h4>
                                    <p>{CommonService.formatPhoneNumber(hcpBasicDetails?.contact_number?.replace(/^\+1/, '')) || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Email</h4>
                                    <p>{hcpBasicDetails?.email || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Gender</h4>
                                    <p>{hcpBasicDetails?.gender || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Role</h4>
                                    <p>{hcpBasicDetails?.hcp_type || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                {/*<div className="flex-1">*/}
                                {/*    <h4>Region Name</h4>*/}
                                {/*    <p>{hcpBasicDetails?.address?.region || 'N/A'}</p>*/}
                                {/*</div>*/}
                                <div className="flex-1">
                                    <h4>Staff ID</h4>
                                    <p>{hcpBasicDetails?.hcp_uniqueId || "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Street</h4>
                                    <p>{hcpBasicDetails?.address?.street || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>City</h4>
                                    <p>{hcpBasicDetails?.address?.city || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>State</h4>
                                    <p>{hcpBasicDetails?.address?.state || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Country</h4>
                                    <p>{hcpBasicDetails?.address?.country || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Zip Code</h4>
                                    <p>{hcpBasicDetails?.address?.zip_code || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Latitude</h4>
                                    <p>{hcpBasicDetails?.location?.coordinates?.[1] || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Longitude</h4>
                                    <p>{hcpBasicDetails?.location?.coordinates?.[0] || 'N/A'}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>DOB</h4>
                                    <p>{hcpBasicDetails?.dob?.split("-").reverse().join("-") || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="flex-1 mrg-top-10">
                                <h4>About the Staff</h4>
                                <p className="summary">{hcpBasicDetails?.about ? hcpBasicDetails?.about : "N/A"}</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>


            {hcpBasicDetails?.status === "rejected" ?
                <div className="mrg-top-15 custom-border pdd-20">
                    <div className="">
                        <h3>Reason for Rejection</h3>
                        <p>{hcpBasicDetails?.rejected_details?.reason || 'N/A'}</p>
                    </div>
                    <div className="reject-by-wrapper d-flex">
                        <div>
                            <h4>Rejected By:</h4>
                            <p>
                                {hcpBasicDetails?.rejected_details?.rejected_by?.first_name || hcpBasicDetails?.rejected_details?.rejected_by?.last_name
                                    ? `${hcpBasicDetails?.rejected_details?.rejected_by?.first_name || ''} ${hcpBasicDetails?.rejected_details?.rejected_by?.last_name || ''}`.trim()
                                    : 'N/A'}
                            </p>
                        </div>
                        <div className="mrg-left-50">
                            <h4>Role:</h4>
                            <p>{hcpBasicDetails?.rejected_details?.rejected_by?.role || 'N/A'}</p>
                        </div>
                        <div className="mrg-left-50">
                        <h4>Rejected Date:</h4>
                            <p>{hcpBasicDetails?.rejected_details?.rejected_date ? moment(hcpBasicDetails?.rejected_details?.rejected_date).format("MM-DD-YYYY") : "N/A"}</p>
                        </div>
                    </div>
                </div> : <></>}
            <div className="custom-border mrg-top-10 pdd-20">
                <Accordion expanded={expanded === 'Professional Details'} onChange={handleChange('Professional Details')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Professional Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'hcp-details-wrapper'}>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>Years of Experience</h4>
                                    <p>{hcpBasicDetails?.professional_details?.hasOwnProperty('experience') ? hcpBasicDetails?.professional_details?.experience : "N/A"}</p>
                                </div>
                                <div className="flex-1">
                                    <h4>Speciality</h4>
                                    <p>{hcpBasicDetails?.professional_details?.speciality ? hcpBasicDetails?.professional_details?.speciality : "N/A"}</p>
                                </div>
                            </div>
                            <div>
                                <h4>Professional Summary</h4>
                                <p className="summary">{hcpBasicDetails?.professional_details?.summary ? hcpBasicDetails?.professional_details?.summary : "N/A"}</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            {!attachmentLoading && sortedAttachments ? (
                <div className="custom-border mrg-top-20 pdd-20">
                    <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                        <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile} previewData={documentFile} />
                    </DialogComponent>
                    <Accordion expanded={expanded === 'Attachments/Documents'} onChange={handleChange('Attachments/Documents')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1bh-content"
                            id="panel1bhs-header">
                            <Typography className={classes.heading}>Attachments/Documents</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={'hcp-details-wrapper'}>
                               <AntTableComponent
                                data={sortedAttachments}
                                noDataText={'No Data Found.'}
                                columns={[
                                    {
                                        title:'Document Name',
                                        width:300,
                                        render:(item:any)=>{
                                            return (
                                                <div style={{cursor: 'pointer', textDecoration: "underline"}}
                                                     onClick={()=>previewFile(item)}>
                                                    {item?.attachment_type ? item?.attachment_type ==='License'? "Nursing License" : item?.attachment_type : 'N/A'}
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        title:'Expires On',
                                        width:100,
                                        render:(item:any)=>{
                                            return (
                                                <div>
                                                    {item?.expiry_date || 'N/A'}
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        title:'Download',
                                        width:100,
                                        render:(item:any)=>{
                                            return (
                                                <div className={'display-center'}>
                                                    <IconButton onClick={(e) => handleDownload(item?.file_key)}>
                                                        <GetAppIcon  aria-disabled={isDownload} className="download-icon"/>
                                                    </IconButton>
                                                </div>
                                            )
                                        }
                                    }
                                ]}
                               />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            ) : (
                <div className="custom-border mrg-top-10 pdd-20">
                    <StyledLoader/>
                </div>
            )}
            <div className={'mrg-top-20'}>
                <HcpContractComponent hcpDetails={hcpBasicDetails}/>
            </div>


            <div className="custom-border mrg-top-10 pdd-20 ">
                <Accordion expanded={expanded === 'Travel Preference'} onChange={handleChange('Travel Preference')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Travel Preference</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'hcp-details-wrapper'}>
                            <div className="d-flex mrg-top-20">
                                {hcpBasicDetails?.nc_details?.travel_preferences && Array.isArray(hcpBasicDetails?.nc_details?.travel_preferences) && hcpBasicDetails?.nc_details?.travel_preferences?.length > 0 ? hcpBasicDetails?.nc_details?.travel_preferences?.map((item: any) =>
                                    <p className="flex-1">{item} Miles</p>) : <p>N/A</p>}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="custom-border mrg-top-10 pdd-20">
                <Accordion expanded={expanded === 'NOTIFICATION_PREFERENCES'}
                           onChange={handleChange('NOTIFICATION_PREFERENCES')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>Notification Preference</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'hcp-details-wrapper'}>
                            <div className="mrg-top-20" style={{display: "flex"}}>

                                {hcpBasicDetails?.hasOwnProperty("notification_preferences") ? (
                                    <>
                                        {hcpBasicDetails?.notification_preferences?.sms ||
                                        hcpBasicDetails?.notification_preferences?.email ||
                                        hcpBasicDetails?.notification_preferences?.push_notification ? (
                                            <>
                                                {hcpBasicDetails?.notification_preferences?.sms && (
                                                    <p className="flex-1">SMS</p>
                                                )}
                                                {hcpBasicDetails?.notification_preferences?.email && (
                                                    <p className="flex-1">Email</p>
                                                )}
                                                {hcpBasicDetails?.notification_preferences?.push_notification && (
                                                    <p className="flex-1">In-application</p>
                                                )}
                                            </>
                                        ) : (
                                            <p className="flex-1">NA</p>
                                        )}
                                    </>
                                ) : (
                                    <p className="flex-1">N/A</p>
                                )}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="custom-border mrg-top-10 pdd-20">
                <Accordion expanded={expanded === 'NC_SECTION'} onChange={handleChange('NC_SECTION')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bhs-header">
                        <Typography className={classes.heading}>NC Section</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={'hcp-details-wrapper'}>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <h4>DNR</h4>
                                    <p>{hcpBasicDetails?.nc_details?.dnr ? hcpBasicDetails?.nc_details?.dnr : 'N/A'}</p>
                                </div>

                                <div className="flex-1">
                                        <h4>Contact Type</h4>
                                        <p>{hcpBasicDetails?.nc_details?.contact_type !== "" ? CommonService.nameCoverterToNormal(hcpBasicDetails?.nc_details?.contact_type) : "NA"}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h4>Preferred Location to Work</h4>
                                        <p>{hcpBasicDetails?.nc_details?.location_preference ? hcpBasicDetails?.nc_details?.location_preference : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Preference Shift Type</h4>
                                        <p>{hcpBasicDetails?.nc_details?.shift_type_preference ? hcpBasicDetails?.nc_details?.shift_type_preference : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Zone Assignment</h4>
                                        <p>{hcpBasicDetails?.nc_details?.zone_assignment !== "" ? hcpBasicDetails?.nc_details?.zone_assignment : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Last Call Date</h4>
                                        <p>{hcpBasicDetails?.nc_details?.last_call_date ? moment(hcpBasicDetails?.nc_details?.last_call_date).format("MMMM Do YYYY") : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Vaccine</h4>
                                        <p>{!hcpBasicDetails?.nc_details?.vaccine ? "N/A" : hcpBasicDetails?.nc_details?.vaccine === "half" ? "1st Dose" : hcpBasicDetails?.nc_details?.vaccine}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h4>First Shot Date</h4>
                                        <p>{hcpBasicDetails?.nc_details?.vaccination_dates?.first_shot ? hcpBasicDetails?.nc_details?.vaccination_dates?.first_shot : "N/A"}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h4>Latest Shot Date</h4>
                                        <p>{hcpBasicDetails?.nc_details?.vaccination_dates?.latest_shot ? hcpBasicDetails?.nc_details?.vaccination_dates?.latest_shot : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Do you have a Full-time Job ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_fulltime_job !== "" ? (hcpBasicDetails?.nc_details?.is_fulltime_job === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Covid (or) Non Covid Facility ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.covid_facility_preference ? hcpBasicDetails?.nc_details?.covid_facility_preference : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>What Is More Important for You ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.more_important_preference !== "" ? hcpBasicDetails?.nc_details?.more_important_preference : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Is this a Supplement to your Income ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_supplement_to_income !== "" ? (hcpBasicDetails?.nc_details?.is_supplement_to_income === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Are you Studying ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_studying !== "" ? (hcpBasicDetails?.nc_details?.is_studying === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                    {/*<div className="flex-1">*/}
                                    {/*    <h4>Gusto</h4>*/}
                                    {/*    <p>{hcpBasicDetails?.nc_details?.gusto_type !== "" ? CommonService.nameCoverterToNormal(hcpBasicDetails?.nc_details?.gusto_type) : "N/A"}</p>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Is Gusto Invited ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_gusto_invited !== "" ? (hcpBasicDetails?.nc_details?.is_gusto_invited === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Is Gusto Onboarded ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_gusto_onboarded !== "" ? (hcpBasicDetails?.nc_details?.is_gusto_onboarded === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h4>Require Sponsorship for Employment in United States?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_require_employment_sponsorship !== "" ? (hcpBasicDetails?.nc_details?.is_require_employment_sponsorship === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Legally Authorized to work in United States ?</h4>
                                        <p>{hcpBasicDetails?.nc_details?.is_authorized_to_work !== "" ? (hcpBasicDetails?.nc_details?.is_authorized_to_work === "true" ? "Yes" : "No") : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Family Considerations</h4>
                                        <p className="summary">{hcpBasicDetails?.nc_details?.family_consideration ? hcpBasicDetails?.nc_details?.family_consideration : "N/A"}</p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <h4>Other Information Gathered</h4>
                                        <p>{hcpBasicDetails?.nc_details?.other_information ? hcpBasicDetails?.nc_details?.other_information : "N/A"}</p>
                                    </div>
                                </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
</div>
)
    ;
};

export default HcpDetailsComponent;
