import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import {ENV} from "../../../../constants";
import "./BulkUploadComponent.scss"

import {
    Button,
    FormControlLabel,
    InputAdornment, Radio,
    RadioGroup as NormalRadioGroup,
    Tooltip,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import DialogComponent from "../../../../components/DialogComponent";
import FilePickerComponent from "../../../../components/file-picker/FilePickerComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import {TableColumnsType} from "antd";
import {Table, Typography as Text} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BulkUploadComponent = () => {
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const [selectedFacility, setSelectedFacility] = useState<any | null>(null);
    const [selectedFacilityForUpload, setSelectedFacilityForUpload] = useState<any | null>(null);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [value, setValue] = useState<any>(0);
    const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
    const [isBulkUploadDialogOpen, setIsBulkUploadDialogOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<any>("facility");
    const [shiftUploadFile, setShiftUploadFile] = React.useState<any>([]);
    const [filterData, setFilterData] = useState<any>({
        search: '',
        sort: {},
        // type: "facility"
        type:'contract'
    })

    const [uploadData, setUploadData] = useState([]);


    useEffect(() => {
        if (value === 0) {
            // setSelectedValue("facility")
            setSelectedValue('contract')
        }
        if (value === 1) {
            setSelectedValue("home")
        }
        if (value === 2) {
            setSelectedValue("contract")
        }
    }, [value]);

    const ContractColumns: any = [
        // {
        //     title: "Errors",
        //     dataIndex: "errors",
        //     key: "errors",
        //     render: (errors: string[]) => (
        //         <>
        //             {errors?.map((error, index) => (
        //                 <Tag color="red" key={index}>
        //                     {error}
        //                 </Tag>
        //             ))}
        //         </>
        //     ),
        //     fixed: "left", // Fix the "Errors" column to the left
        //     width: 200,
        // },
        {
            title: "Errors",
            dataIndex: "errors",
            key: "errors",
            render: (errors: string[]) =>
                errors && errors?.length > 0 ? (
                    <Tooltip
                        title={errors?.map((error, index) => (
                            <div key={index}>
                                <div color="red">{error}</div>
                            </div>
                        ))}
                    >
                        <ExclamationCircleOutlined style={{color: "red", fontSize: "16px"}}/>
                    </Tooltip>
                ) : null,
            fixed: "left",
            width: 80,
        },

        {
            title: "Start Date",
            dataIndex: ["row", "Start Date"],
            key: "startDate",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "End Date",
            dataIndex: ["row", "End Date"],
            key: "endDate",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Staff Type",
            dataIndex: ["row", "Staff Type"],
            key: "staffType",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Staff Speciality",
            dataIndex: ["row", "Staff Speciality"],
            key: "staffSpeciality",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Hours Per Shift",
            dataIndex: ["row", "Hours Per Shift"],
            key: "hoursPerShift",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Job Type",
            dataIndex: ["row", "Job Type"],
            key: "jobType",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "No. Of Days Per Week",
            dataIndex: ["row", "No. Of Days Per Week"],
            key: "noOfDaysPerWeek",
            width: 190,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "No. Of Positions",
            dataIndex: ["row", "No. Of Positions"],
            key: "noOfPositions",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "No. of Weeks",
            dataIndex: ["row", "No. of Weeks"],
            key: "noOfWeeks",
            width: 130,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Shift Type",
            dataIndex: ["row", "Shift Type"],
            key: "shiftType",
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
            width: 130,

        },


        {
            title: "Cancel Policy",
            dataIndex: ["row", "Cancel Policy"],
            key: "cancelPolicy",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
    ];
    const perDiemColumn: any = [
        // {
        //     title: "Errors",
        //     dataIndex: "errors",
        //     key: "errors",
        //     render: (errors: string[]) => (
        //         <>
        //             {errors?.map((error, index) => (
        //                 <Tag color="red" key={index}>
        //                     {error}
        //                 </Tag>
        //             ))}
        //         </>
        //     ),
        //     fixed: "left",
        //     width: 200,
        // },
        {
            title: "Errors",
            dataIndex: "errors",
            key: "errors",
            render: (errors: string[]) =>
                errors && errors?.length > 0 ? (
                    <Tooltip
                        title={errors?.map((error, index) => (
                            <div key={index}>
                                <div color="red">{error}</div>
                            </div>
                        ))}
                    >
                        <ExclamationCircleOutlined style={{color: "red", fontSize: "16px"}}/>
                    </Tooltip>
                ) : null,
            fixed: "left",
            width: 80,
        },
        {
            title: "HCP Speciality",
            dataIndex: ["row", "HCP Speciality"],
            key: "hcpSpeciality",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "HCP Type",
            dataIndex: ["row", "HCP Type"],
            key: "hcpType",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Hazard rate",
            dataIndex: ["row", "Hazard rate"],
            key: "hazardRate",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "No. of HCPs",
            dataIndex: ["row", "No. of HCPs"],
            key: "noOfHCPs",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Requested By",
            dataIndex: ["row", "Requested By"],
            key: "requestedBy",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Rush Rate (Yes or No)",
            dataIndex: ["row", "Rush Rate(Yes or No)"],
            key: "rushRate",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Shift Date",
            dataIndex: ["row", "Shift Date"],
            key: "shiftDate",
            width: 150,
            render: (value: string) =>
                value ? CommonService.getUtcDate(value) : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Shift End Time",
            dataIndex: ["row", "Shift End Time"],
            key: "shiftEndTime",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Shift Req Details",
            dataIndex: ["row", "Shift Req Details"],
            key: "shiftReqDetails",
            width: 300,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Shift Start Time",
            dataIndex: ["row", "Shift Start Time"],
            key: "shiftStartTime",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Shift Type",
            dataIndex: ["row", "Shift Type"],
            key: "shiftType",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Shift Rate",
            dataIndex: ["row", "Shift rate"],
            key: "shiftRate",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Show Shift Rate on App (Yes or No)",
            dataIndex: ["row", "Shift rate (Show On Mobile app.)(Yes or No)"],
            key: "showShiftRate",
            width: 300,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
    ];
    const HomeHealthColumn: any = [
        // {
        //     title: "Errors",
        //     dataIndex: "errors",
        //     key: "errors",
        //     render: (errors: string[]) => (
        //         <>
        //             {errors?.map((error, index) => (
        //                 <Tag color="red" key={index}>
        //                     {error}
        //                 </Tag>
        //             ))}
        //         </>
        //     ),
        //     fixed: "left", // Fix the errors column to the left
        //     width: 300,
        // },
        {
            title: "Errors",
            dataIndex: "errors",
            key: "errors",
            render: (errors: string[]) =>
                errors && errors?.length > 0 ? (
                    <Tooltip
                        title={errors?.map((error, index) => (
                            <div key={index}>
                                <div color="red">{error}</div>
                            </div>
                        ))}
                    >
                        <ExclamationCircleOutlined style={{color: "red", fontSize: "16px"}}/>
                    </Tooltip>
                ) : null,
            fixed: "left",
            width: 80,
        },
        {
            title: "Gender",
            dataIndex: ["row", "Gender"],
            key: "gender",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Patient City",
            dataIndex: ["row", "Patient City"],
            key: "patientCity",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Patient DOB",
            dataIndex: ["row", "Patient DOB"],
            key: "patientDOB",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Patient First Name",
            dataIndex: ["row", "Patient First Name"],
            key: "patientFirstName",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Patient Last Name",
            dataIndex: ["row", "Patient Last Name"],
            key: "patientLastName",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Patient Latitude",
            dataIndex: ["row", "Patient Latitude"],
            key: "patientLatitude",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Patient Longitude",
            dataIndex: ["row", "Patient Longitude"],
            key: "patientLongitude",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Patient Phone Number",
            dataIndex: ["row", "Patient Phone Number"],
            key: "patientPhoneNumber",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Patient State",
            dataIndex: ["row", "Patient State"],
            key: "patientState",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Patient Street",
            dataIndex: ["row", "Patient Street"],
            key: "patientStreet",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Patient ZIP",
            dataIndex: ["row", "Patient ZIP"],
            key: "patientZIP",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Staff Speciality",
            dataIndex: ["row", "Staff Speciality"],
            key: "staffSpeciality",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Staff Type",
            dataIndex: ["row", "Staff Type"],
            key: "staffType",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Visit Date",
            dataIndex: ["row", "Visit Date"],
            key: "visitDate",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Visit Rate",
            dataIndex: ["row", "Visit Rate"],
            key: "visitRate",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Visit Time",
            dataIndex: ["row", "Visit Time"],
            key: "visitTime",
            width: 150,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
        {
            title: "Visit Type",
            dataIndex: ["row", "Visit Type"],
            key: "visitType",
            width: 200,
            render: (value: string) =>
                value ? value : <Text style={{color: "red"}}>Missing</Text>,
        },
    ];

    const handleChanges = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setUploadData([])
    }, []);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);

    useEffect(() => {
        getFacilityData()
    }, [getFacilityData]);

    useEffect(() => {
        if (uploadData?.length > 0) {
            Communications.pageTitleSubject.next("Bulk Upload / Invalid Entries");
        } else {
            Communications.pageTitleSubject.next("Bulk Upload");
        }
        Communications.pageBackButtonSubject.next("/shiftrequirementMaster/list");
    }, [uploadData]);


    const handleDownload = useCallback(() => {
        let fileUrl = '';
        // if (value === 0) {
        //     fileUrl = "https://s3.us-east-2.amazonaws.com/bulkupload.template.com/bulkupload/per_diem_shift_template.xlsx";
        // }
        // changed because of requirement need to revert back to original
        if (value === 0) {
            fileUrl = "https://s3.us-east-2.amazonaws.com/bulkupload.template.com/bulkupload/contract_shift_req_template.xlsx";
        }

        if (value === 1) {
            fileUrl = "https://s3.us-east-2.amazonaws.com/bulkupload.template.com/bulkupload/home_visit_shift_req_template.xlsx";
        }
        // if (value === 2) {
        //     fileUrl = "https://s3.us-east-2.amazonaws.com/bulkupload.template.com/bulkupload/contract_shift_req_template.xlsx";
        // }
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'facility_upload_template.xlsx'; // Optional: specify a filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [value]);


    const handleParticularDataDownload = useCallback((key: any) => {
        let payload: any = {};
        payload.file_key = key;
        ApiService.post(ENV.API_URL + "downloadAttachment", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                } else {
                    CommonService.showToast(res?.error || "No Data to Download", "info");
                }
            })
            .catch((err) => {
                CommonService.showToast(err?.error || "Error", "error");
            });
    }, [])


    const handleChange = useCallback((event: any) => {
        console.log(event?.target);
        setSelectedValue(event.target.value);
        // if (event.target.value === "facility") {
        //     setValue(0)
        // }
        if (event.target.value === "home") {
            setValue(1)
        }
        if (event.target.value === "contract") {
            setValue(0)
        }
    }, []);

    const removeFile = (index: number) => {
        // Create a new array excluding the file at the given index
        const newTimesheetFile = [...shiftUploadFile];
        newTimesheetFile.splice(index, 1);
        // Update the timesheetFile state with the new array
        setShiftUploadFile([]);
    };


    const onAddBulkFileUpload = useCallback(() => {
        setIsFileUploading(true)
        let url: any = "requirement/bulkUpload"
        const formData = new FormData();

        if (shiftUploadFile?.length > 0) {
            shiftUploadFile.forEach((file: File) => {
                // Ensure each file is appended correctly
                formData.append("attachment", file);
            });
        } else {
            console.error("No files to upload");
            return;  // Early return if no files are uploaded
        }

        // Append other fields
        formData.append("facility_id", selectedFacilityForUpload?._id || "");
        formData.append("type", selectedValue || "");

        ApiService.upload(ENV.API_URL + url, formData)
            .then((res) => {
                CommonService.showToast(res?.message, "info");
                setIsBulkUploadDialogOpen(false)
                setValue(selectedValue === "facility" ? 0 : selectedValue === "home" ? 1 : 2);
                setFilterData({
                    ...filterData,
                    type: selectedValue
                })
                setSelectedFacilityForUpload(null);
                setShiftUploadFile([])
                setUploadData([])
                setIsFileUploading(false)
            })
            .catch((err) => {
                setSelectedFacilityForUpload(null);
                setIsBulkUploadDialogOpen(false);
                setUploadData(err?.data || [])
                setShiftUploadFile([])
                setIsFileUploading(false)
                CommonService.showToast(err?.error?.message || "Error", "error");
            });
    }, [shiftUploadFile, selectedFacilityForUpload?._id, selectedValue, filterData])

    useEffect(() => {
        console.log(selectedValue)
    }, [selectedValue]);

    const bulkUploadColumns: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Facility Name',
                width: 160,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 20 ?
                                <Tooltip title={item?.facility?.name || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Uploaded On',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.formatDateTime(item?.updated_at) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Uploaded By',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.uploaded_by?.first_name + " " + item?.uploaded_by?.last_name}
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                width: 120,
                render: (item: any) => {
                    return (
                        <div>
                            <Button variant={"outlined"}
                                    color="primary"
                                    onClick={() => {
                                        handleParticularDataDownload(item?.s3_path);
                                    }}
                                //  className={!isDownloading ? "" : "has-loading-spinner"}
                                //   disabled={isDownloading}
                            >
                                &nbsp;Download
                            </Button>
                        </div>
                    )
                }
            },

        ]
    }, [handleParticularDataDownload])

    console.log(value, "value")
    console.log(selectedValue, "selectedValue")
    // @ts-ignore
    return (
        <>
            <div className={'bulk-upload-container-component'}>
                <DialogComponent isEditableDialog={true} open={isBulkUploadDialogOpen} cancel={() => {
                    setIsBulkUploadDialogOpen(false)
                }}>
                    <div className={'mrg-20 bulk-upload-dialog'}>
                        <div className={'form-header-text mrg-bottom-20'}>Bulk Upload</div>
                        <div>
                            {(!isFacilityListLoading && facilityList) &&
                                <Autocomplete
                                    disableClearable
                                    PaperComponent={({children}) => <Paper
                                        style={{
                                            color: "#1e1e1e",
                                        }}>{children}</Paper>}
                                    value={selectedFacilityForUpload}
                                    options={facilityList}
                                    getOptionLabel={(option: any) => option.name}
                                    placeholder={"Time Sheet"}
                                    id="input_select_status"
                                    onChange={($event, value) => {
                                        setSelectedFacilityForUpload(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{
                                                minWidth: "200px",
                                                padding: "0px !important"
                                            }} {...params}
                                            id="select_requested_by"
                                            variant="outlined"
                                            placeholder={"Facility"}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                        {selectedFacilityForUpload ? (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => setSelectedFacilityForUpload(null)}
                                                                    edge="end"
                                                                >
                                                                    <ClearIcon/>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ) : null}
                                                    </>
                                                ),
                                            }}

                                        />
                                    )}
                                />}
                        </div>
                        <div>
                            <NormalRadioGroup aria-label="gender" defaultValue={selectedValue}
                                              name="radio-buttons-group"
                                              className="mrg-top-10">
                                <div className={'d-flex mrg-bottom-20'}>
                                {/*    {*/}
                                {/*        name: "Per Diem",*/}
                                {/*        code: "facility"*/}
                                {/*    }, {*/}
                                {/*    name: "Per Job / Visit",*/}
                                {/*    code: "home"*/}
                                {/*},*/}
                                    {[{
                                        name: "Contract / Travel",
                                        code: "contract"
                                    }]?.map((item: any) => {
                                        return (
                                            <FormControlLabel value={item?.code}
                                                              disabled={shiftUploadFile?.length > 0}
                                                              style={{color: "red !important"}}
                                                              control={<Radio style={{color: "#0DB9D8"}}/>}
                                                              onChange={(event) => handleChange(event)}
                                                              label={item?.name}/>
                                        );
                                    })}
                                </div>
                            </NormalRadioGroup>
                        </div>
                        {shiftUploadFile?.length === 0 &&
                            <FilePickerComponent
                                height={'470px'}
                                id={" sc_upload_btn"}
                                // btnText={"Upload"}
                                // disabled={timesheetFile?.length === 3}
                                multiple={false}
                                isBulkUpload={true}
                                showDropZone={false}
                                onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                    if (acceptedFiles && acceptedFiles.length > 0) {
                                        const file = acceptedFiles[0];
                                        //   setTimesheetFile(file);
                                        setShiftUploadFile([...shiftUploadFile, file]);
                                    }
                                }}
                                acceptedFileTypes={["xlsx"]}
                                acceptedFilesText={"Only .xlsx files are supported"}
                            />

                        }

                        <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                            {shiftUploadFile && shiftUploadFile?.map((file: any, index: number) => {
                                return (
                                    <>
                                        <div
                                            className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>
                                            <div>  {file?.name}</div>
                                            <div className={'cursor-pointer'}
                                                 onClick={() => {
                                                     removeFile(index);
                                                 }}>
                                                <DeleteIcon/>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        <div className={'d-flex justify-content-center'}>
                            <div className="actions">
                                <Button variant={"outlined"} color="primary"
                                        onClick={() => {
                                            setIsBulkUploadDialogOpen(false)
                                        }}
                                >
                                    &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                </Button>
                            </div>
                            <div className="mrg-left-10">
                                <Button
                                    onClick={onAddBulkFileUpload}
                                    disabled={shiftUploadFile?.length === 0 || !selectedFacilityForUpload || isFileUploading}
                                    className={!isFileUploading ? "" : "has-loading-spinner"}
                                    // disabled={isDownloading}
                                    variant={"contained"}
                                    color={"primary"}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogComponent>
                <div>

                    <Box sx={{width: '100%'}}>
                        <Box style={{borderBottom: "1px solid #e4e9ea"}}>
                            <Tabs value={value} onChange={(e: any, value: any) => {

                                handleChanges(e, value);
                                setFilterData({
                                    ...filterData,
                                    // type: value === 0 ? "facility" : value === 1 ? "home" : "contract"
                                    type:value === 0 ? "contract": value === 1 ? 'home': "contract"
                                })
                            }
                            } aria-label="tab"
                                  TabIndicatorProps={{
                                      style: {
                                          backgroundColor: "#56ebff",
                                      }
                                  }}
                                  style={{
                                      color: "black",
                                  }}>
                                {/*<Tab label="Per Diem" {...a11yProps(0)} />*/}
                                {/*<Tab*/}
                                {/*    label="Per Job / Visit" {...a11yProps(1)} />*/}
                                <Tab
                                    label="Contract / Travel" {...a11yProps(0)} />
                            </Tabs>
                        </Box>
                        <div className="hcp_view_details screen crud-layout pdd-30">
                            <TabPanel value={value} index={value}>
                                <div className="custom-border pdd-10  pdd-bottom-0">
                                    <div className={'d-flex '}>
                                        <div className="header d-flex justify-content-end" style={{width: '100%'}}>
                                            <div className="d-flex bulk-facility-filter">
                                                <div>
                                                    {(!isFacilityListLoading && facilityList && uploadData?.length === 0) &&
                                                        <Autocomplete
                                                            disableClearable
                                                            PaperComponent={({children}) => <Paper
                                                                style={{
                                                                    color: "#1e1e1e",
                                                                }}>{children}</Paper>}
                                                            value={selectedFacility}
                                                            options={facilityList}
                                                            getOptionLabel={(option: any) => option.name}
                                                            placeholder={"Time Sheet"}
                                                            id="input_select_status"
                                                            onChange={($event, value) => {
                                                                setSelectedFacility(value);
                                                                setFilterData({...filterData, facility_id: value?._id});
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    style={{
                                                                        minWidth: "200px",
                                                                        padding: "0px !important"
                                                                    }} {...params}
                                                                    id="select_requested_by"
                                                                    variant="outlined"
                                                                    placeholder={"Facility"}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <>
                                                                                {params.InputProps.endAdornment}
                                                                                {selectedFacility ? (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton
                                                                                            onClick={() => {
                                                                                                setSelectedFacility(null)
                                                                                                const {
                                                                                                    facility_id,
                                                                                                    ...updatedFilterData
                                                                                                } = filterData; // Remove the key
                                                                                                setFilterData(updatedFilterData);
                                                                                            }}
                                                                                            edge="end"
                                                                                        >
                                                                                            <ClearIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ) : null}
                                                                            </>
                                                                        ),
                                                                    }}

                                                                />
                                                            )}
                                                            disableCloseOnSelect
                                                        />}
                                                </div>
                                                <div className="actions">
                                                    <Button variant={"outlined"} color="primary"
                                                            onClick={handleDownload}
                                                        // className={!isDownloading ? "" : "has-loading-spinner"}
                                                        // disabled={isDownloading}
                                                    >
                                                        &nbsp;
                                                        {/*Download {value === 0 ? "Per Diem" : value === 1 ? "Per Job / Visit" : "Contract / Travel"} Template*/}
                                                        Download Contract / Travel Template
                                                    </Button>
                                                </div>
                                                <div className="mrg-left-10">
                                                    <Button
                                                        onClick={() => {
                                                            setIsBulkUploadDialogOpen(true);
                                                        }}
                                                        variant={"contained"}
                                                        color={"primary"}>
                                                        &nbsp;&nbsp;{uploadData?.length === 0 ? "Upload File" : "Re-Upload File"}&nbsp;&nbsp;
                                                    </Button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {uploadData?.length === 0 &&
                                        <div className={'open-table-wrapper'}>
                                            <AntTableComponent
                                                url={ENV.API_URL + "requirement/uploadLogslist"}
                                                method={'post'}
                                                extraPayload={filterData}
                                                columns={bulkUploadColumns}
                                                noDataText={'No Open Shift Found.'}
                                                isRowSelection={false}
                                            />
                                        </div>}
                                    {uploadData?.length !== 0 &&
                                        <div className="responsive-table-container">
                                            <Table
                                                //@ts-ignore
                                                dataSource={uploadData?.map((item, index) => ({...item, key: index}))}
                                                columns={selectedValue === "facility" ? perDiemColumn : selectedValue === "home" ? HomeHealthColumn : ContractColumns}
                                                bordered
                                                pagination={false}
                                                scroll={{
                                                    x: 1500, // Horizontal scrolling width
                                                    y: 400, // Vertical scrolling height
                                                }}
                                            />
                                        </div>}
                                </div>
                            </TabPanel>

                        </div>
                    </Box>
                </div>
            </div>
        </>
    )
}

export default BulkUploadComponent;
