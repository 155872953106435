import {Button, Radio, RadioGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import Lottie from "react-lottie";
import animationData from "../../../../../assets/animations/NoData.json";
import LoaderComponent from "../../../../../components/LoaderComponent";
import {ENV} from "../../../../../constants";
import {CssTextField, useStyles} from "../../../../../constants/data/styles";
import {ApiService, CommonService} from "../../../../../helpers";
import "./AddHcpComponent.scss";


export interface AddHcpToShiftComponentProps {
    cancel: () => void;
    confirm: () => void;
    hcp_type: any;
    shiftId: any;
}


const AddHcpComponent = (props: PropsWithChildren<AddHcpToShiftComponentProps>) => {
    const classes = useStyles();
    const {confirm, cancel: afterCancel, hcp_type, shiftId} = props;
    const [selectedHcp, setSelectedHcp] = useState('');
    const [hcpList, sethcpList] = React.useState<any>(null);
    const [searchHcp, setSearchHcp] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleSubmit = () => {
        setIsSubmitting(true);
        // const payload = {
        //     hcp_user_id: selectedHcp,
        // };
        ApiService.patch(ENV.API_URL + "shift/" + shiftId + "/changeHCP/" + selectedHcp)
            .then((res: any) => {
                setIsSubmitting(false);
                confirm();
                CommonService.showToast(res?.msg || "Success", "success");
            })
            .catch((err) => {
                CommonService.showToast(err?.msg || err?.error || "Error", "error");
                setIsSubmitting(false);
            });
    };

    const defaultOptions = {
        animationData,
    };

    const init = useCallback(() => {
        setIsLoading(true);
        let payload: any = {
            is_approved: true,
            hcp_type: hcp_type,
            is_active: true
        };
        if (searchHcp !== "") {
            payload = {
                is_approved: true,
                hcp_type: hcp_type,
                search: searchHcp,
            };
        }
        CommonService._api
            .post(ENV.API_URL + "hcp/lite", payload)
            .then((resp) => {
                sethcpList(resp?.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [hcp_type, searchHcp]);

    const handleChange = (event: any) => {
        setSelectedHcp(event.target.value);
    };

    const cancel = (resetForm: any) => {
        if (afterCancel) {
            afterCancel();
        }
    };

    useEffect(() => {
        init();
    }, [init]);

    return (
        <div className="pdd-left-30 pdd-right-30 pdd-bottom-30 add-hcp-requirment">
            <div className={""}>
                {/*<h2 className={classes.title}>Change Staff for Shift</h2>*/}
                <FormLabel component="legend" className="mrg-left-0">
                    List Of Staff
                </FormLabel>
                <div id="alert-dialog-title">
                    <div className="mrg-top-20">
                        <div>
                            <div className="d-flex">
                                <div className="d-flex position-relative">
                                    <CssTextField
                                        defaultValue={""}
                                        onChange={(event) => {
                                            setSearchHcp(event?.target?.value);
                                        }}
                                        className="searchField"
                                        variant={"outlined"}
                                        size={"small"}
                                        type={"text"}
                                        placeholder={"Search Staff"}
                                        value={searchHcp}
                                    />
                                    {searchHcp === "" ? (
                                        <div className={"search_icon"}>
                                            <SearchRounded/>
                                        </div>
                                    ) : (
                                        <div className={"search_icon"}>
                                            <ClearIcon onClick={(event) => setSearchHcp("")} id="clear_hcp_search"/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mrg-top-20 change-staff-wrapper">
                        {isLoading && <LoaderComponent position="block"/>}
                        <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" value={selectedHcp}
                                    name="controlled-radio-buttons-group" onChange={handleChange}>
                            {hcpList?.map((item: any, index: any) => {
                                return (
                                    <div key={index}>
                                        <FormControlLabel value={item?.user_id} control={<Radio/>}
                                                          label={item?.first_name + " " + item?.last_name}/>
                                    </div>
                                );
                            })}
                        </RadioGroup>
                    </div>
                </div>

                {!isLoading && hcpList?.length === 0 && (
                    <div className={"display-flex flex-one mrg-top-20 mrg-bottom-20"}>
                        <Lottie width={400} height={400} speed={1} options={defaultOptions}/>
                    </div>
                )}

                {hcpList && hcpList.length > 0 ? (
                    <div className={classes.assignNcActions}>
                        <Button type={"submit"} size="large" variant={"outlined"} className={"normal"} onClick={cancel}>
                            Cancel
                        </Button>
                        <Button className={isSubmitting ? "has-loading-spinner mrg-left-30" : "mrg-left-30"}
                                type={"submit"} color={"primary"} size="large" disabled={!selectedHcp || isSubmitting}
                                variant={"contained"} onClick={handleSubmit}>
                            {
                                isSubmitting ? "Saving" : "Save"
                            }
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default AddHcpComponent;
