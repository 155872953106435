import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Button, Checkbox, DialogActions, DialogContent, Paper} from "@material-ui/core";
import {ENV} from "../../../constants";
import {CommonService} from "../../../helpers";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {InputBase, RadioGroup} from "formik-material-ui";
import * as Yup from "yup";
import React, {PropsWithChildren, useState} from "react";
import {useParams} from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {FacilityCancelled, NoShow, NurseCancelled, othersCancelled, VitawerksCancelled} from "../../../constants/data";
import TextField from "@material-ui/core/TextField";

const formValidation = Yup.object({
	reason: Yup.string().required("Required"),
});

export interface RejectShiftComponentProps {
	cancel: () => void;
	confirm: () => void;
	selectedShifts: any;
	isMultipleReasons?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(3),
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			// boxShadow: theme.shadows[5],
			// padding: "30px 50px",
			padding:"0 26px 26px 26px",
			margin: "auto",
			marginTop:'10px',
		},
		assignNcActions: {
			margin: "auto",
			marginTop: "100px",
			textAlign: "center",
			justifyContent: "center",
		},
		selectReason: {
			color: "#10c4d3 !important",
		},
		selectSubReason: {
			color: "#10c4d3 !important",
			marginTop: "20px"
		}
	})
);

const RejectShiftComponent = (props: PropsWithChildren<RejectShiftComponentProps>) => {
	const params = useParams<{ id: string }>();
	const { id } = params;
	const afterCancel = props?.cancel;
	const afterConfirm = props?.confirm;
	const classes = useStyles();
	const { user } = useSelector((state: StateParams) => state.auth);
	const selectedShifts = props?.selectedShifts;
	let reasonsList: any[] = [];
	const [subReason, setSubReason] = useState<any>(null);
	const [isUpdateOpenReq,setUpdateOpenReq]=useState<boolean>(false);

	if (props?.isMultipleReasons) {
		reasonsList = ["No Show", "Nurse Cancelled", "Facility Cancelled", "Agency Cancelled", "Other"];
	}

	const onCancelShift = (payload: any, { setSubmitting, setErrors, resetForm }: FormikHelpers<any>) => {
		payload = {
			...payload,
			cancelled_by: user?._id,
			sub_reason: subReason?.value,
			is_req_edited:isUpdateOpenReq
		};

		CommonService._api
			.patch(ENV.API_URL + "shift/" + id + "/cancel", payload)
			.then((resp) => {
				setSubmitting(false);
				if (afterConfirm) {
					CommonService.showToast(resp?.msg || "Success", "success");
					afterConfirm();
					resetForm({});
				}
			})
			.catch((err) => {
				CommonService.handleErrors(setErrors, err);
				setSubmitting(false);
			});
	};

	const handleCancelMultipleShifts = (payload: any, { setSubmitting, setErrors, resetForm }: FormikHelpers<any>) => {
		payload = {
			...payload,
			cancelled_by: user?._id,
			shift_ids: selectedShifts,
			sub_reason: subReason?.value,
			is_req_edited:isUpdateOpenReq
		};

		CommonService._api
			.patch(ENV.API_URL + "shift/cancel", payload)
			.then((resp) => {
				setSubmitting(false);
				CommonService.showToast(resp?.msg || "Success", "success");
				if (afterConfirm) {
					afterConfirm();
					resetForm({});
				}
			})
			.catch((err) => {
				CommonService.handleErrors(setErrors, err);
				CommonService.showToast(err?.errors?.sub_reason[0] || "Error", "error");
				setSubmitting(false);
			});
	};

	const cancel = () => {
		if (afterCancel) {
			afterCancel();
		}
	};

	return (
		<div>
			<div className={classes.paper}>
				{/*<h2>{selectedShifts && selectedShifts?.length > 1 ? "Cancel Shifts" : "Cancel Shift"}</h2>*/}
				<Formik
					initialValues={{reason: "", sub_reason: ""}}
					validateOnChange={true}
					validationSchema={formValidation}
					onSubmit={selectedShifts ? handleCancelMultipleShifts : onCancelShift}
				>
					{({values, isSubmitting, isValid, resetForm, dirty}) => (
						<Form className={"form-holder"}>
							<DialogContent>
								{reasonsList.length > 0 && (
									<>
										<FormLabel component="legend" color={"primary"}
												   className={classes.selectReason}>
											Select Reason
										</FormLabel>
										<Field component={RadioGroup} name="reason" id="radio_reason_reject"
											   className="mrg-top-20">
											<>
												{reasonsList?.map((item: any) => {
													return (<>
															<FormControlLabel
																value={item}
																control={<Radio disabled={isSubmitting}
																				color={"primary"}/>}
																label={item}
																id="radio_reason_add"
																className={"reson-div "}
																name="reason"
																disabled={isSubmitting}
																onChange={() => {
																	setSubReason(null);
																}}
															/>
														</>
													);
												})}
												{values?.reason &&
													<FormLabel component="legend" color={"primary"}
															   className={classes.selectSubReason}>
														Select Sub-Reason
													</FormLabel>}
												{values?.reason === "No Show" && <>
													<div className="form-field-item">
														<Autocomplete
															disableClearable
															PaperComponent={({children}) => <Paper
																style={{color: "#1e1e1e"}}>{children}</Paper>}
															value={subReason}
															options={NoShow}
															getOptionLabel={(option: any) => option.label}
															placeholder={"Facility Confirmation"}
															id="input_select_status"
															className="mrg-top-10"
															onChange={($event, value) => {
																setSubReason(value);
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	id="select_fac_confirm"
																	variant="outlined"
																	placeholder={"Sub Reason"}
																/>
															)}
														/>
													</div>
												</>}
												{values?.reason === "Nurse Cancelled" && <>
													<div className="form-field-item">
														<Autocomplete
															disableClearable
															PaperComponent={({children}) => <Paper
																style={{color: "#1e1e1e"}}>{children}</Paper>}
															value={subReason}
															options={NurseCancelled}
															getOptionLabel={(option: any) => option.label}
															placeholder={"Facility Confirmation"}
															id="input_select_status"
															className="mrg-top-10"
															onChange={($event, value) => {
																setSubReason(value);
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	id="select_fac_confirm"
																	variant="outlined"
																	placeholder={"Sub Reason"}
																/>
															)}
														/>
													</div>
												</>}

												{values?.reason === "Facility Cancelled" && <>
													<div className="form-field-item">
														<Autocomplete
															disableClearable
															PaperComponent={({children}) => <Paper
																style={{color: "#1e1e1e"}}>{children}</Paper>}
															value={subReason}
															options={FacilityCancelled}
															getOptionLabel={(option: any) => option.label}
															placeholder={"Facility Confirmation"}
															id="input_select_status"
															className="mrg-top-10"
															onChange={($event, value) => {
																setSubReason(value);
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	id="select_fac_confirm"
																	variant="outlined"
																	placeholder={"Sub Reason"}
																/>
															)}
														/>
													</div>
												</>}

												{values?.reason === "Agency Cancelled" && <>
													<div className="form-field-item">
														<Autocomplete
															disableClearable
															PaperComponent={({children}) => <Paper
																style={{color: "#1e1e1e"}}>{children}</Paper>}
															value={subReason}
															options={VitawerksCancelled}
															getOptionLabel={(option: any) => option.label}
															placeholder={"Facility Confirmation"}
															id="input_select_status"
															className="mrg-top-10"
															onChange={($event, value) => {
																setSubReason(value);
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	id="select_fac_confirm"
																	variant="outlined"
																	placeholder={"Sub Reason"}
																/>
															)}
														/>
													</div>
												</>}

												{values?.reason === "Other" && <>
													<div className="form-field-item">
														<Autocomplete
															disableClearable
															PaperComponent={({children}) => <Paper
																style={{color: "#1e1e1e"}}>{children}</Paper>}
															value={subReason}
															options={othersCancelled}
															getOptionLabel={(option: any) => option.label}
															placeholder={"Facility Confirmation"}
															id="input_select_status"
															className="mrg-top-10"
															onChange={($event, value) => {
																setSubReason(value);
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	id="select_fac_confirm"
																	variant="outlined"
																	placeholder={"Sub Reason"}
																/>
															)}
														/>
													</div>
												</>}


											</>
										</Field>
									</>
								)}
								<Field
									component={InputBase}
									type={"text"}
									name="reason"
									fullWidth
									multiline
									rows={4}
									className="mrg-top-20"
									placeholder={props?.isMultipleReasons ? ' If others, please type the reason' : "Type the reason for Cancellation"}
									variant="outlined"
									style={{border: '1px solid #ccc'}}
								/>
								<div className={'d-flex align-items-center mrg-top-20'}>
									<div>
										<Checkbox id={"cb_"}
												  checked={isUpdateOpenReq}
												  onChange={(event) => {
													  setUpdateOpenReq(event.target.checked)
												  }
												  }/>
									</div>
									<div style={{display: "flex", width: "350px"}}>
									<div style={{
										color:'#0c80e3',
										fontSize:'1rem'
									}}> Update Open Shift Requirements</div>
								</div>
								</div>
							</DialogContent>
							<DialogActions className="mrg-top-20">
								<Button
									variant="outlined"
									onClick={() => cancel()}
									color="primary"
									id="btn_cancel"
									className="pdd-left-30 pdd-right-30"
								>
									Cancel
								</Button>

								<Button
									type={"submit"}
									id="btn_reject_application"
									className={isSubmitting ? "submit has-loading-spinner" : "submit"}
									disabled={!dirty || isSubmitting || !isValid || !subReason}
									variant={"contained"}
									color="primary"
									autoFocus
								>
									{isSubmitting
										? "Cancelling Shift"
										: selectedShifts && selectedShifts?.length > 1
											? "Cancel Shifts"
											: "Cancel Shift"}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default RejectShiftComponent;
