import {Button, Paper, TextField as NormalTextField} from "@material-ui/core";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {TextField as FormikTextFiled} from "formik-material-ui";
import React, {useCallback, useEffect, useState} from "react";
import * as Yup from "yup";
import {ENV} from "../../../constants";
import {ApiService, CommonService} from "../../../helpers";
import FormLabel from "@material-ui/core/FormLabel";
import {DatePicker as FormikDatePicker} from "formik-material-ui-pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ShiftRateComponent from "./shift-holiday-rate-component/ShiftRateComponent";
import moment from "moment";


interface EditShiftDetailsDialogProps {
    cancel: () => void;
    confirm: () => void;
    shiftId?: string;
    shiftDetails: any;
    isApprovedTab?: any;
}

const ApprovedShiftEditComponent = (props: EditShiftDetailsDialogProps) => {
    const {confirm, cancel, shiftId, shiftDetails} = props;
    const [facilities, setFacilities] = useState<any[]>([]);
    const [facilityData, setFacilityData] = useState<any>([]);
    const [totalShiftRate, setTotalShiftRate] = useState<any>(0);
    const [otherRate, setOtherRate] = useState<any>({});
    const [facilityId, setFacilityId] = useState<any>({
        name: shiftDetails?.facility?.name,
        _id: shiftDetails?.facility?._id
    });

    const getFacilityExtraData = useCallback(() => {
        ApiService.get(ENV.API_URL + "facility/" + facilityId?._id)
            .then((res) => {
                setFacilityData(res?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [facilityId]);

    useEffect(() => {
        getFacilityExtraData()
    }, [getFacilityExtraData]);

    const getFacilityData = useCallback(() => {
        const payload = {is_active: true};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilities(res?.data || []);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getFacilityData()
    }, [getFacilityData]);

    const handleFacilitySelect = (facility: any) => {

        if (facility) {
            setFacilityId(facility);
            // getFacilityShiftTimings(facility?._id);
        }
    };

    const approvedShiftInitialState = {
        facility_id: shiftDetails?.facility_id,
        start_time: CommonService.convertToUTCTimeFormat(shiftDetails?.expected?.shift_start_time) ?? "",
        // start_time: "08:00",
        end_time: CommonService.convertToUTCTimeFormat(shiftDetails?.expected?.shift_end_time) ?? "",
        shift_date: CommonService?.getUtcDate(shiftDetails?.shift_date || null),
        shift_rate: shiftDetails?.requirement?.shift_rate ?? "",
    }

    const approvedShiftValidation = Yup.object({
        start_time: Yup.string(),
        end_time: Yup.string(),
        shift_date: Yup.string(),
        shift_rate: Yup.string().required("Required"),
    });


    const onApprovedDetailsEdit = (data: any, {
        setSubmitting,
        setErrors,
        resetForm,
        setFieldValue
    }: FormikHelpers<any>) => {
        const {start_time, end_time, shift_date} = data;
        console.log(shift_date, CommonService.convertToUTCDateFormat(shift_date));

        let payload: any = {
            start_time: CommonService.convertHoursToMinutes(start_time),
            end_time: CommonService.convertHoursToMinutes(end_time),
            shift_date: moment(shift_date).format("YYYY-MM-DD"),
            facility_id: facilityId?._id,
            hcp_hazard_rate: otherRate.hazard,
            hcp_noc_diff: otherRate.noc,
            hcp_pm_diff: otherRate.pm,
            hcp_weekend_rate: otherRate.weekend,
            hcp_rush_rate: otherRate.rush,
            shift_rate: totalShiftRate,
            base_shift_rate: otherRate?.base_shift_rate,
            is_rush_rate: otherRate?.is_rush_rate,
            is_noc_diff: otherRate?.is_noc_diff,
            is_pm_diff: otherRate?.is_pm_diff,
            is_weekend_rate: otherRate?.is_weekend_rate,
            is_hazard_rate: otherRate?.is_hazard_rate,
            is_holiday: otherRate?.is_holiday
        };

        ApiService.put(ENV.API_URL + "shift/editApprovedShift/" + shiftId, payload)
            .then((res: any) => {
                CommonService.showToast(res?.msg || "Success", "success");
                setSubmitting(false);
                confirm();
            })
            .catch((err) => {
                // if (err) {
                setSubmitting(false);
                //     if (typeof err?.error === 'string') {
                //         CommonService.showToast(err?.error || "Error", "error")
                //     } else {
                //         const formattedError = Object.entries(err?.error);
                //         formattedError.map((err: any) => {
                //             let hcpName = err[0];
                //             let hcpDate = moment(err[1]).format("MM-DD-YYYY");
                //             return CommonService.showToast(`${hcpName} already approved on ${hcpDate}` || "Error", "error");
                //         });
                //     }
                // }
                if (typeof err?.error === 'object' && err?.error !== null) {
                    const formattedError = Object.entries(err?.error);
                    formattedError.map((err: any) => {
                        let hcpName = err[0];
                        let hcpDate = moment(err[1]).format("MM-DD-YYYY");
                        return CommonService.showToast(`${hcpName} already approved on ${hcpDate}` || "Error", "error");
                    });
                } else {
                    CommonService.showToast(err?.error || "Error", "error");
                }
            });
    };


    return (
        <div className="pdd-20 show-shift-rate-screen" style={{minWidth: "680px", maxWidth: "680px"}}>
            <div className="main-heading" onClick={() => {
                console.log(shiftDetails)
            }}>Shift Details
            </div>
            <div className="mrg-top-10 ">
                <Formik
                    initialValues={approvedShiftInitialState}
                    validateOnChange={true}
                    validationSchema={approvedShiftValidation}
                    onSubmit={onApprovedDetailsEdit}
                >
                    {({isSubmitting, handleSubmit, isValid, values, resetForm, setFieldValue, touched}) => (
                        <Form id="show-shift-rate-form" className={"shift-rate-form form-holder"}>
                            {facilities !== null && (
                                <Autocomplete
                                    disableClearable
                                    PaperComponent={({children}) => <Paper
                                        style={{color: "#1e1e1e"}}>{children}</Paper>}
                                    options={facilities}
                                    getOptionLabel={(option: any) => option.name}
                                    getOptionSelected={(option: any, value) => option.name === value?.name}
                                    placeholder={"Select Facility"}
                                    id="input_select_facility"
                                    value={facilityId}
                                    onChange={($event, value) => {
                                        handleFacilitySelect(value);
                                    }}
                                    renderInput={(params) => (
                                        <NormalTextField
                                            {...params}
                                            id="select_region"
                                            variant="outlined"
                                            placeholder={"Select (or) Search Facility"}
                                        />
                                    )}
                                />
                            )}
                            <div className="d-flex mrg-top-20">
                                <div style={{width: "50%", marginRight: "10px"}}>
                                    <FormLabel onClick={() => {
                                        console.log(shiftDetails)
                                    }}>Start Time</FormLabel>
                                    <Field style={{width: "100%"}} disabled={false} fullWidth variant="outlined"
                                           type="time"
                                           component={FormikTextFiled}
                                           required
                                           name="start_time"
                                        //label="Start Time"
                                        //  InputLabelProps={{shrink: true}}
                                           id="input_shift_add_shift_start_time"
                                           onChange={(e: any) => {
                                               setFieldValue("start_time", e.target.value);
                                           }}
                                    />
                                </div>
                                <div style={{width: "50%"}}>
                                    <FormLabel>End Time</FormLabel>
                                    <Field style={{width: "100%"}} disabled={false} fullWidth variant="outlined"
                                           type="time"
                                           component={FormikTextFiled}
                                           required
                                           name="end_time"
                                        //label="Start Time"
                                        //  InputLabelProps={{shrink: true}}
                                           id="input_shift_add_shift_start_time"
                                           onChange={(e: any) => {
                                               setFieldValue("end_time", e.target.value);
                                           }}
                                    />
                                </div>
                            </div>


                            <div className="d-flex">
                                <div style={{width: "50%", marginRight: "10px", marginTop: "20px"}}>
                                    <FormLabel>Shift Date</FormLabel>
                                    <Field
                                        variant="inline"
                                        orientation="landscape"
                                        openTo="date"
                                        format="MM/dd/yyyy"
                                        views={["year", "month", "date"]}
                                        inputVariant="outlined"
                                        component={FormikDatePicker}
                                        placeholder="DD/MM/YYYY"
                                        fullWidth
                                        autoComplete="off"
                                        // InputLabelProps={{shrink: true}}
                                        //label="DOB*"
                                        name="shift_date"
                                    />

                                </div>
                                {/*<div style={{width: "50%", marginTop: "20px", display: "none"}}>*/}
                                {/*    <FormLabel>Shift Rate*</FormLabel>*/}
                                {/*    <Field*/}
                                {/*        style={{width: "100%"}}*/}
                                {/*        name="shift_rate"*/}
                                {/*        //inputProps={{ maxLength: 5 }}*/}
                                {/*        value={values.shift_rate}*/}
                                {/*        component={TextField}*/}
                                {/*        variant={"outlined"}*/}
                                {/*        color={"primary"}*/}
                                {/*        onChange={(e: any) => {*/}
                                {/*            //	if (e.target.value === "" || onlyNums.test(e.target.value)) {*/}
                                {/*            setFieldValue("shift_rate", e.target.value);*/}
                                {/*            //	}*/}
                                {/*        }}*/}
                                {/*        id="hcp_differential"*/}
                                {/*        placeholder={"Enter Value"}*/}
                                {/*        InputProps={{*/}
                                {/*            startAdornment:*/}
                                {/*                <InputAdornment*/}
                                {/*                    position="start">$</InputAdornment>,*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>

                            <div className={'mrg-top-30'}>
                                <ShiftRateComponent
                                    shiftDetails={shiftDetails}
                                    facilityData={facilityData}
                                    //                 totalShiftRate={totalShiftRate}
                                    //                 currentHCPType={currentHCPType}
                                    setTotalShiftRate={setTotalShiftRate}
                                    setOtherRate={setOtherRate}
                                />
                            </div>


                            <div className="mrg-top-10 text-align">
                                <Button
                                    variant={"outlined"}
                                    id="cancel-employee-btn"
                                    size={"medium"}
                                    color={"primary"}
                                    onClick={() => cancel()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={isSubmitting || !touched}
                                    variant={"contained"}
                                    type={"submit"}
                                    size={"medium"}
                                    color={"primary"}
                                    className={
                                        isSubmitting
                                            ? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30"
                                            : "mrg-left-20 pdd-left-30 pdd-right-30"
                                    }
                                >
                                    {isSubmitting ? "Saving" : "Save"}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ApprovedShiftEditComponent;
