import React from 'react';
import {CommonService} from "../../../../helpers";

const EmployeeBasicDetailsComponent = (props: any) => {
    const basicDetails = props?.basicDetails;

    return <div>
        <div className='mrg-top-10 custom-border pdd-40'>
            <h3 className='mrg-top-0'>Basic Details</h3>
            <div className="d-flex">
                <div className="flex-1">
                    <h4>First Name</h4>
                    <p>{basicDetails?.first_name || 'N/A'} </p>
                </div>
                <div className="flex-1">
                    <h4>Last Name</h4>
                    <p>{basicDetails?.last_name || 'N/A'} </p>
                </div>
            </div>
            <div className="d-flex">
                <div className="flex-1">
                    <h4>Contact Number</h4>
                    <p>{CommonService.formatPhoneNumber(basicDetails?.contact_number?.replace(/^\+1/,'')) || 'N/A'}</p>
                </div>
                <div className="flex-1">
                    <h4>Email</h4>
                    <p>{basicDetails?.email || 'N/A'}</p>
                </div>
            </div>
            <div className="d-flex">
                <div className="flex-1">
                    <h4>Gender</h4>
                    <p className="summary">{basicDetails?.gender || 'N/A'}</p>
                </div>
                {/*<div className="flex-1">*/}
                {/*    <h4>Region Name</h4>*/}
                {/*    <p>{basicDetails?.address?.region || 'N/A'}</p>*/}
                {/*</div>*/}
                <div className="flex-1">
                    <h4>Street</h4>
                    <p>{basicDetails?.address?.street || 'N/A'}</p>
                </div>
            </div>
            <div className='d-flex'>
                <div className="flex-1">
                    <h4>City</h4>
                    <p>{basicDetails?.address?.city || 'N/A'}</p>
                </div>
                <div className="flex-1">
                    <h4>State</h4>
                    <p>{basicDetails?.address?.state || 'N/A'}</p>
                </div>
            </div>
            <div className="d-flex">

                <div className="flex-1">
                    <h4>Country</h4>
                    <p>{basicDetails?.address?.country || 'N/A'}</p>
                </div>
                <div className="flex-1">
                    <h4>Zip Code</h4>
                    <p>{basicDetails?.address?.zip_code || 'N/A'}</p>
                </div>
            </div>
        </div>
    </div>;
}


export default EmployeeBasicDetailsComponent;