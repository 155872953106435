import {Avatar, Button, Tab, Tabs, Tooltip} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {TsDataListOptions, TsDataListState, TsDataListWrapperClass} from "../../../classes/ts-data-list-wrapper.class";
import AccessControlComponent from "../../../components/AccessControl";
import LoaderComponent from "../../../components/LoaderComponent";
import {useLocalStorage} from "../../../components/useLocalStorage";
import {ENV} from "../../../constants";
import {ApiService, CommonService, Communications} from "../../../helpers";
import {ADMIN} from "../../../helpers/common-service";
import EmployeeBasicDetailsComponent from "./basicDetails/EmployeeBasicDetailsComponent";
import "./EmployeeViewScreen.scss";
import NcAssignedHcpsListComponent from "./nc/NcAssignedHcpsListComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserBasicDetailsRequest} from "../../../store/actions/employee-management.action";
import {StateParams} from "../../../store/reducers";
import ConfirmationDialogAction from "../../../components/confirmation-component/ConfirmationDialogAction";
import DialogComponent from "../../../components/DialogComponent";

const EmployeeViewScreen = () => {
    const dispatch = useDispatch();
    const param = useParams<any>();
    const {userBasicDetails, isUserBasicDetailsLoading} = useSelector((state: StateParams) => state.employeeManagement);
    const {id} = param;
    const [list, setList] = useState<TsDataListState | null>(null);
    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("hcpPageSizeIndex", 10);
    //const [basicDetails, setBasicDetails] = useState<any | null>(null);
    const [statusSubmitting, setStatusSubmitting] = useState<boolean>(false);
    const [employeeDeletionSubmitting, setEmployeeDeletionSubmitting] = useState(false);
    //const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState("basic_details");
    const history = useHistory();
    const [openGeneratePopup, setGeneratePoopupOpen] = useState<boolean>(false);
    const handleTabChange = (event: any, value: any) => {
        setTabValue(value);
    };

    // const init = useCallback(() => {
    // 	setIsLoading(true);
    // 	// config
    // 	CommonService._api
    // 		.get(ENV.API_URL + "user/" + id)
    // 		.then((resp) => {
    // 			setBasicDetails(resp.data);
    // 			setIsLoading(false);
    // 		})
    // 		.catch((err) => {
    // 		//	console.log(err);
    // 			setIsLoading(false);
    // 		});
    // }, [id]);

    const handleStatus = () => {
        setStatusSubmitting(true);
        const payload = {
            is_active: userBasicDetails?.is_active ? false : true,
        };
        CommonService._api
            .put(ENV.API_URL + "user/" + id, payload)
            .then((resp) => {
                setStatusSubmitting(false);
                CommonService.showToast("Employee status updated" || "Success", "success");
                dispatch(fetchUserBasicDetailsRequest(id));
            })
            .catch((err) => {
                CommonService.showToast(err?.msg || "Error", "error");
                setStatusSubmitting(false);
            });
    };

    const handleDeleteUser = useCallback(() => {
        setEmployeeDeletionSubmitting(true);
        CommonService._api
            .delete(ENV.API_URL + "user/" + id)
            .then((resp) => {
                setEmployeeDeletionSubmitting(false);
                CommonService.showToast("Employee deleted successfully" || "Success", "success");
                // dispatch(fetchUserBasicDetailsRequest(id));
                history.push("/employee/list");
            })
            .catch((err) => {
                CommonService.showToast(err?.msg || "Error", "error");
                setEmployeeDeletionSubmitting(false);
            });
    }, [history, id]);

    const getHcpsList = useCallback(() => {
        let url = "hcp/list";
        let payload: any = {};
        payload.nurse_champion_id = id;
        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...list?.table?.pagination,
                    pageSize: pageSizeIndex,
                },
                extraPayload: payload,
                webMatColumns: ["Staff Name", "Contact No.", "Type Of Staff", "Status", "Actions"],
                mobileMatColumns: ["Staff Name", "Contact No.", "Type Of Staff", "Status", "Actions"],
            },
            ENV.API_URL + url,
            setList,
            ApiService,
            "post"
        );

        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({table: tableWrapperObj});
        // eslint-disable-next-line
    }, []);

    const getList = useCallback(() => {
        if (!list) {
            getHcpsList();
            return;
        }

        let payload: any = {};
        payload.nurse_champion_id = id;
        list?.table?.getList(1);
        // eslint-disable-next-line
    }, []);

    const cancelOpenGeneratePopup = useCallback(() => {
        setGeneratePoopupOpen(false);
    }, []);

    useEffect(() => {
        dispatch(fetchUserBasicDetailsRequest(id));
        getHcpsList();
        Communications.pageTitleSubject.next("Employee Details");
        Communications.pageBackButtonSubject.next("/employee/list");
    }, [dispatch,getHcpsList, id]);

    if (isUserBasicDetailsLoading) {
        return <LoaderComponent/>;
    }

    return (
        <div>
            <>
                <DialogComponent isEditableDialog={true} cancel={cancelOpenGeneratePopup} open={openGeneratePopup}>
                    <ConfirmationDialogAction headerText={"DELETE EMPLOYEE"} cancel={cancelOpenGeneratePopup}
                                              confirm={handleDeleteUser}
                                              confirmationText="Are you sure you want to delete the employee?"
                                              notext={"NO"} yestext={"YES"} isConfirm={employeeDeletionSubmitting}/>
                </DialogComponent>
                <div className="employee_view_details pdd-30 screen crud-layout">
                    <AccessControlComponent role={[ADMIN]}>
                        <div className="profile-actions d-flex">
                            <Tooltip title="Change the Employee Status to Inactive">
                                <Button
                                    className={employeeDeletionSubmitting ? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30" : "mrg-left-20 pdd-left-30 pdd-right-30"}
                                    disabled={employeeDeletionSubmitting} variant={"outlined"} color={"primary"}
                                    onClick={() => {
                                        setGeneratePoopupOpen(true);
                                    }}>
                                    Delete Employee
                                </Button>
                            </Tooltip>
                            {userBasicDetails?.is_active ? (
                                <Tooltip title="Change the Employee Status to Inactive">
                                    <Button
                                        className={statusSubmitting ? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30" : "mrg-left-20 pdd-left-30 pdd-right-30"}
                                        disabled={statusSubmitting} variant={"outlined"} color={"primary"}
                                        onClick={handleStatus}>
                                        {statusSubmitting ? "Inactivating" : "Make Inactive"}
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Change the Employee Status to Active">
                                    <Button
                                        className={statusSubmitting ? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30" : "mrg-left-20 pdd-left-30 pdd-right-30"}
                                        disabled={statusSubmitting} variant={"outlined"} color={"primary"}
                                        onClick={handleStatus}>
                                        {statusSubmitting ? "Activating" : "Make Active"}
                                    </Button>
                                </Tooltip>
                            )}
                            <Tooltip title="Edit">
                                <Button variant={"contained"} color={"primary"} component={Link}
                                        to={`/employee/edit/${id}`} className="mrg-left-20 pdd-left-40 pdd-right-40">
                                    Edit
                                </Button>
                            </Tooltip>
                        </div>
                    </AccessControlComponent>

                    <div className="mrg-top-10 custom-border pdd-20 ac-profile d-flex align-items-center">
                        <div className="d-flex items-center align-items-center">
                            {userBasicDetails?.image_url ? (
                                <Avatar alt="user photo" style={{height: "80px", width: "80px"}}
                                        src={userBasicDetails?.image_url}></Avatar>
                            ) : (
                                <Avatar alt="user photo" style={{height: "80px", width: "80px"}}>
                                    {userBasicDetails?.first_name?.toUpperCase().charAt("0")}
                                </Avatar>
                            )}
                            <div className="mrg-left-20">
                                <h3>
                                    {userBasicDetails?.first_name}&nbsp;{userBasicDetails?.last_name}
                                </h3>
                                <div className="d-flex items-center location pdd-0">
                                    <p className="role" onClick={() => {
                                        console.log(userBasicDetails);
                                    }}>{userBasicDetails?.role}</p>
                                </div>
                            </div>
                        </div>
                        <p className="status"> {userBasicDetails && userBasicDetails?.is_active ? "Active" : "Inactive"}</p>
                    </div>
                    {userBasicDetails?.role === "NC" ? (
                        <>
                            <div className="tabs_header mrg-top-10">
                                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary"
                                      textColor="primary" variant="fullWidth" scrollButtons="auto">
                                    <Tab label="Basic Details" value={"basic_details"} className="mi-tab"/>
                                    <Tab label={`Staff (${list?.table?.pagination?.totalItems})`} value={"assigned_hcps"}
                                         className="mi-tab"/>
                                </Tabs>
                            </div>
                            <div>
                                <div
                                    className={tabValue !== "basic_details" ? "hidden" : ""}>{tabValue === "basic_details" ?
                                    <EmployeeBasicDetailsComponent basicDetails={userBasicDetails}/> : <></>}</div>
                                <div className={tabValue !== "assigned_hcps" ? "hidden" : ""}>
                                    <>{tabValue === "assigned_hcps" ?
                                        <NcAssignedHcpsListComponent basicDetails={userBasicDetails} list={list}
                                                                     setPageSizeIndex={setPageSizeIndex}
                                                                     getList={getList}
                                        /> : <></>}</>
                                </div>
                            </div>
                            {" "}
                        </>
                    ) : (
                        <EmployeeBasicDetailsComponent basicDetails={userBasicDetails}/>
                    )}
                </div>
            </>
        </div>
    );
};

export default EmployeeViewScreen;
