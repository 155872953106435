import "./LoaderComponent.scss";

import React from 'react';
import {CircularProgress } from '@material-ui/core';

interface LoaderComponentProps {
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    type?: "progress" | "spinner";
    color?:  "primary" | "secondary"  | undefined;
}

const sizeMap = {
    "xs": 20,
    "sm": 30,
    "md": 40,
    "lg": 50,
    "xl": 60,
}

const LoaderComponent = (props: LoaderComponentProps) => {

    const type = props.type || "progress";
    const size = props.size || "lg";
    const color = props.color || "inherit";

    return (
        <>
            {type === "spinner" &&
                <CircularProgress color={color} size={sizeMap[size]}/>
            }
        </>
    );
};

export default LoaderComponent;
