import {
    FETCH_REGIONS_REQUEST,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_FAILURE,
    FETCH_HCP_TYPES_REQUEST,
    FETCH_HCP_TYPES_SUCCESS,
    FETCH_HCP_TYPES_FAILURE,
    GET_SPECIALITIES_REQUEST,
    GET_SPECIALITIES_SUCCESS, GET_SPECIALITIES_FAILURE, GET_FACILITY_DOCUMENTS, SET_FACILITY_DOCUMENTS
} from "../actions/meta.action";

export interface MetaParams {
    regions: any[];
    documents: any[];
    isLoading: boolean;
    error: any;
    hcpTypes: any[];
    isHcpTypesLoading: boolean;
    hcpTypesError: any;
    specialitiesMasterList: any[];
    specIsLoading: boolean;
    specError: string | null;
}

const initialData: MetaParams = {
    regions: [],
    documents: [],
    isLoading: true,
    error: null,
    hcpTypes: [],
    isHcpTypesLoading: true,
    hcpTypesError: null,
    specialitiesMasterList: [],
    specIsLoading: false,
    specError: null,
};

const metaReducer = (state = initialData, action: any): MetaParams => {
    switch (action.type) {
        case FETCH_REGIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_REGIONS_SUCCESS:
            return {
                ...state,
                regions: action.payload.regions,
                isLoading: false,
            };
        case GET_FACILITY_DOCUMENTS:
            return {
                ...state,
            };
        case SET_FACILITY_DOCUMENTS:
            return {
                ...state,
                documents: action.payload.documents,
            };
        case FETCH_REGIONS_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false,
            };

        case FETCH_HCP_TYPES_REQUEST:
            return {
                ...state,
                isHcpTypesLoading: true
            }
        case FETCH_HCP_TYPES_SUCCESS:
            return {
                ...state,
                hcpTypes: action.payload.hcpTypes,
                isHcpTypesLoading: false
            }
        case FETCH_HCP_TYPES_FAILURE:
            return {
                ...state,
                hcpTypesError: action.payload.hcpTypesError,
                isHcpTypesLoading: false,
            };
        case GET_SPECIALITIES_REQUEST:
            return {
                ...state,
                specIsLoading: true,
                specError: null,
            };
        case GET_SPECIALITIES_SUCCESS:
            return {
                ...state,
                specialitiesMasterList: action.payload,
                specIsLoading: false,
            };
        case GET_SPECIALITIES_FAILURE:
            return {
                ...state,
                specIsLoading: false,
                specError: action.payload,
            };
        default:
            return state;
    }
};

export default metaReducer;
