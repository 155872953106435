import {Button, Checkbox, Tooltip} from "@material-ui/core";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import DialogComponent from "../../../../components/DialogComponent";
// import NoDataCardComponent from "../../../../components/NoDataCardComponent";
import {ENV, ImageConfig} from "../../../../constants";
import {CssTextField} from "../../../../constants/data/styles";
import {ApiService, CommonService, Communications} from "../../../../helpers";
// import ShiftFilter from "../../filters/ShiftFilter";
import RejectShiftComponent from "../../rejectShift/RejectShiftComponent";
import AddPaymentDetailsComponent from "./addPaymentDetails/AddPaymentDetailsComponent";
import "./ClosedShiftsListScreen.scss";
import PendingConcernConfirmationBox from "./pending-concern-confirm-closed/PendingConcernConfirmationBox";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";
import ShiftFilterDrawerComponent from "../../shift-filter-drawer/ShiftFilterDrawerComponent";
import DrawerComponent from "../../../../components/drawer/DrawerComponent";
import {useSessionStorage} from "../../../../components/useSessionStorage";


const [firstDayOfMonth, previousDate] = CommonService.getFirstDayAndFortyFiveDayUsingDate();
const ClosedShiftsScreen = () => {
    // const [hcpTypes, setHcpTypes] = useState<any | null>(null);
    const location=useLocation();
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    // const [regions, setRegions] = useState<any>([]);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [isAddPaymentDetailsOpen, setIsAddPaymentDetailsOpen] = useState<boolean>(false);
    const [isRejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);

    const [isTheSelectedShiftPendingConcern,] = useState<any>(false);
    // setIsTheSelectedShiftPendingConcern
    const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
    const [isPendingConcernOpen, setIsPendingConcernOpen] = useState<boolean>(false);
    // const [shiftRequirementType, setRequirementType] = useLocalStorage<any>("closed-shift-requirement-type", "facility");
    const [selectedRowData,setSelectedRowData]=useState<any>([]);
    const [prevLocation,setPrevLocation]=useState<string>("");
    const [filterData, setFilterData] = useSessionStorage<any>("vitaClosedShiftFilterData",{
        search: '',
        sort: {},
        status: 'closed',
        limit: 10,
        start_date: CommonService.changeToUtcDate(previousDate),
        end_date:CommonService.changeToUtcDate(firstDayOfMonth),
    })
    useEffect(() => {
        // Detect page change by comparing current location with previous location
        if (location.pathname !== prevLocation) {
            setPrevLocation(location.pathname); // Update the previous location state

            // Only reset the filterData when navigating different page
            if (location.pathname.startsWith('/closedShifts') && (!filterData.start_date && !filterData.end_date)) {
                let updatedFilterData={...filterData};
                updatedFilterData.start_date=CommonService.changeToUtcDate(previousDate);
                updatedFilterData.end_date=CommonService.changeToUtcDate(firstDayOfMonth);
                delete updatedFilterData.new_shifts;
                setFilterData(updatedFilterData);
            }
        }
    }, [location, filterData, setFilterData, prevLocation]);

    // const [isHcpSentHome, setIsHcpSentHome] = useLocalStorage<any>("isHcpSentHome", "");
    // const [shiftSearch, setShiftSearch] = useState<string>("");
    // const [hcpPaymentModeValueForClosedShift, setHcpPaymentModeValueForClosedShifts] = useLocalStorage<any>("hcpPaymentModeValueForClosedShift", '');

    // const [isPendingConcern, setIsPendingConcern] = useLocalStorage<any>("isPendingConcernForClosedShift", "");
    // const [isAllselected, setIsAllSelected] = useState<boolean>(false);
    // const [selectedCount, setSelectedCount] = useState<any>(-1);
    // const [selectedHcps, setSelectedHcps] = useLocalStorage<any[]>("selectedHcps", []);
    // const [selectedFacilities, setSelectedFacilities] = useLocalStorage<any[]>("selectedFacilities", []);
    // const [selectedTimeTypes, setSelectedTimeTypes] = useLocalStorage<any[]>("selectedTimeTypes", []);
    // const [selectedSalaryCreditTypes, setSelectedSalaryCreditTypes] = useLocalStorage<any[]>("selectedSalaryCreditTypes", []);
    // const [isHcpPayment, setIsHcpPayment] = useLocalStorage<any>("isHcpPayment", "");
    // const [dateRange, setDateRange] = useLocalStorage<any[]>("dateRange", [previousDate, firstDayOfMonth]);
    // const [paymentDateRange, setPaymentDateRange] = useLocalStorage<any[]>("paymentDateRange", [null, null]);
    // const [selectedApprovedHcps, setSelectedApprovedHcps] = useLocalStorage<any[]>("selectedApprovedHcps", []);


    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};

        // if (selectedRegion.length > 0) {
        //     payload.regions = selectedRegion;
        // }

        if (filterData?.type !== '') {
            payload.type = filterData?.type;
        }

        if (filterData?.facilities?.length > 0) {
            payload.facilities = filterData?.facilities;
        }
        if (filterData?.hcp_types?.length > 0) {
            payload.hcp_types = filterData?.hcp_types;
        }

        if (filterData?.hcp_user_ids?.length > 0) {
            payload.hcp_user_ids = filterData?.hcp_user_ids;
        }

        if (filterData?.is_pending_concern !== "") {
            payload.is_pending_concern = filterData?.is_pending_concern;
        }

        payload.status = ['closed'];

        if (filterData?.is_hcp_paid !== "") {
            payload.is_hcp_paid = filterData?.is_hcp_paid;
        }

        if (filterData?.gusto_type !== "") {
            payload.gusto_type = filterData?.gusto_type;
        }

        if (filterData?.is_sent_home !== "") {
            payload.is_sent_home = filterData?.is_sent_home;
        }

        if (filterData?.search !== "") {
            payload.search = filterData?.search;
        }

        if (filterData?.hcp_pay_start_date || filterData?.hcp_pay_end_date) {
            let startDate = moment(filterData?.hcp_pay_start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.hcp_pay_end_date).format("YYYY-MM-DD");

            if (!filterData?.hcp_pay_end_date) {
                payload.hcp_pay_start_date = startDate;
                payload.hcp_pay_end_date = startDate;
            } else {
                payload.hcp_pay_start_date = startDate;
                payload.hcp_pay_end_date = endDate;
            }
        }

        if (filterData?.salary_credit?.length > 0) {
            payload.salary_credit = filterData?.salary_credit;
        }


        if (filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");
            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        } else {
            let today = moment(new Date()).format("YYYY-MM-DD");
            payload.new_shifts = today;
        }

        if (filterData?.shift_types?.length > 0) {
            payload.shift_types = filterData?.shift_types;
        }

        ApiService.post(ENV.API_URL + "shift/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [filterData]);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);

    useEffect(() => getFacilityData(), [getFacilityData]);

    //
    // if (list?.table?.data) {
    //     list?.table?.data?.sort((a: any, b: any) => {
    //         return Date.parse(b.created_at) - Date.parse(a.created_at);
    //     });
    // }


    const openPaymentDetails = useCallback(() => {
        setIsAddPaymentDetailsOpen(true);
    }, []);

    const afterConfirm = useCallback(() => {
        let updatedFilterData = {...filterData};
        setFilterData(updatedFilterData)
        setIsAddPaymentDetailsOpen(false);
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, [filterData,setFilterData]);

    const cancelPaymentDetails = useCallback(() => {
        setIsAddPaymentDetailsOpen(false);
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const openRejectShift = useCallback(() => {
        setRejectShiftOpen(true);
    }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        // setSelectedCount(-1);
        let updatedFilterData = {...filterData};
        setFilterData(updatedFilterData)
        setSelectedShifts([]);
    }, [filterData, setSelectedShifts,setFilterData]);

    const clearFilterValues = useCallback(() => {
        setFilterData((prevFilterData: any) => {
            const updatedFilterData = {
                ...prevFilterData,
            }
            // Dynamically remove keys if they exist
            const keysToRemove = [
                "start_date",
                "end_date",
                "hcp_pay_start_date",
                "hcp_pay_end_date",
                "facilities",
                "hcp_types",
                "shift_types",
                "salary_credit",
                "is_hcp_paid",
                "is_pending_concern",
                "is_sent_home",
                "type",
                "hcp_user_ids",
                "gusto_type"
            ];

            keysToRemove.forEach((key: any) => delete updatedFilterData[key]);
            updatedFilterData.status='closed';
            updatedFilterData.start_date=CommonService.changeToUtcDate(previousDate);
            updatedFilterData.end_date=CommonService.changeToUtcDate(firstDayOfMonth);

            return updatedFilterData;
        })
    }, [setFilterData]);

    const resetFilters = () => {
        clearFilterValues();
    };

    useEffect(() => {
        Communications.pageTitleSubject.next("Closed");
        Communications.pageBackButtonSubject.next(null);
    }, []);


    const openPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(true);
    }, []);

    const cancelMarkingPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(false);
        // setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmMarkingPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(false);
        // setSelectedCount(-1);
        return new Promise((resolve, reject) => {
            ApiService.patch(ENV.API_URL + "shift/markConcern", {
                shift_ids: selectedShifts
            })
                .then((res: any) => {
                    resolve(res);
                    CommonService.showToast(res?.message || "success", "success");
                    setSelectedShifts([]);
                    let updatedFilterData = {...filterData};
                    setFilterData(updatedFilterData);
                })
                .catch((err) => {
                    CommonService.showToast(err.error || err.message || 'Error', "error")
                    console.log(err);
                    reject(err);
                });
        });
    }, [selectedShifts, filterData,setFilterData]);

    const closedShiftsListColumn: TableColumnsType = useMemo(() => {
        return [
            // {
            //     title: '',
            //     width: 100,
            //     render: (item: any) => {
            //         const missingDocuments = item["document_status"]?.filter((doc: any) => doc.is_required && !doc.is_uploaded);
            //         return (
            //             <div style={{display: 'flex', minWidth: '50px', maxWidth: '80px'}}>
            //                 {missingDocuments?.length > 0 ?
            //                     <Tooltip
            //                         arrow
            //                         title={
            //                             <div className="missing-documents-container">
            //                                 <div className="tooltip">
            //                                     <h3>Missing staff documents</h3>
            //                                     <ul>
            //                                         {missingDocuments?.map((doc: any) => (
            //                                             <li key={doc.key}>{doc.title}</li>
            //                                         ))}
            //                                     </ul>
            //                                 </div>
            //                             </div>
            //                         }
            //                     >
            //                         <Button style={{
            //                             color: "red",
            //                             cursor: "pointer",
            //                         }} to={`/hcp/edit/` + item["hcp_details"]?._id}
            //                                 component={Link}>{(missingDocuments && missingDocuments?.length > 0) &&
            //                             <WarningIcon/>}</Button>
            //                     </Tooltip> :
            //                     <div>
            //
            //                     </div>
            //                 }
            //             </div>
            //         );
            //     },
            // },
            {
                title: 'Facility Name',
                width: 250,
                sorter: (a: any, b: any) => (a?.facility?.name || '').localeCompare(b?.facility?.name || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 20 ?
                                <Tooltip title={item?.facility?.name || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Staff Name',
                width: 250,
                sorter: (a: any, b: any) => {
                    const nameA = (a?.hcp_details?.first_name + " " + a?.hcp_details?.last_name || '').trim();
                    const nameB = (b?.hcp_details?.first_name + " " + b?.hcp_details?.last_name || '').trim();
                    return nameA.localeCompare(nameB);
                },
                render: (item: any) => {
                    return (
                        <div>
                            {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)?.length > 20 ?
                                <Tooltip
                                    title={(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {(item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name) || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Clock In',
                width: 100,
                // sorter: (a: any, b: any) => {
                //     const timeA = a["time_breakup"]?.check_in_time || '';
                //     const timeB = b["time_breakup"]?.check_in_time || '';
                //     return timeA.localeCompare(timeB);
                // },
                render: (item: any) => {
                    const checkinTime: any = item["time_breakup"]?.check_in_time ? CommonService.startTime(item["time_breakup"]?.check_in_time) : "";
                    const result = CommonService.compareCheckIn(item["expected"], item["time_breakup"]);
                    return (
                        <div>
                            <Tooltip
                                title={`${result.isBefore ? `Staff has clocked in earlier than shift start time by ${result.hours} hour(s) and ${result.minutes} minute(s).` : "Staff has clocked in on time or after the shift start"}`}>
                                <p style={{color: `${result.isBefore ? "red" : ""}`}}>
                                    {checkinTime}
                                </p>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Break In',
                width: 100,
                // sorter: (a: any, b: any) => {
                //     const timeA = a["time_breakup"]?.break_timings?.[0]?.break_in_time || '';
                //     const timeB = b["time_breakup"]?.break_timings?.[0]?.break_in_time || '';
                //     return timeA.localeCompare(timeB);
                // },
                render: (item: any) => {
                    const breakInTime: any = item["time_breakup"]?.break_timings?.length > 0 ? CommonService.startTime(item["time_breakup"]?.break_timings[0]?.break_in_time) : "N/A";
                    return (
                        <div>
                            {breakInTime}
                        </div>
                    )
                }
            },
            {
                title: 'Break Out',
                width: 120,
                // sorter: (a: any, b: any) => {
                //     const timeA = a["time_breakup"]?.break_timings?.[0]?.break_out_time || '';
                //     const timeB = b["time_breakup"]?.break_timings?.[0]?.break_out_time || '';
                //     return timeA.localeCompare(timeB);
                // },
                render: (item: any) => {
                    const breakOutTime: any = item["time_breakup"]?.break_timings?.length > 0 ? (item["time_breakup"]?.break_timings[0]?.break_out_time ?  CommonService.startTime(item["time_breakup"]?.break_timings[0]?.break_out_time):'N/A') : "N/A";
                    return (
                        <div>
                            {breakOutTime}
                        </div>
                    )
                }
            },
            {
                title: 'Clock Out',
                width: 130,
                // sorter: (a: any, b: any) => {
                //     const timeA = a["time_breakup"]?.check_out_time || '';
                //     const timeB = b["time_breakup"]?.check_out_time || '';
                //     return timeA.localeCompare(timeB);
                // },
                render: (item: any) => {
                    const checkOutTime: any = item["time_breakup"]?.check_out_time ? CommonService.startTime(item["time_breakup"]?.check_out_time) : "N/A";
                    const checkOutResult = CommonService.compareCheckOut(item["expected"], item["time_breakup"])
                    return (
                        <div>
                            <Tooltip
                                title={`${checkOutResult.isAfter ? `Staff has clocked out later than shift end time by ${checkOutResult.hours} hour(s) and ${checkOutResult.minutes} minute(s).` : "Staff has clocked out  on time or before the shift end"}`}>
                                <p style={{color: `${checkOutResult.isAfter ? "red" : ""}`}}>
                                    {checkOutTime}
                                </p>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Payment Date',
                width: 140,
                sorter: (a: any, b: any) => {
                    const dateA = new Date(a["hcp_pay_date"]).getTime();
                    const dateB = new Date(b["hcp_pay_date"]).getTime();
                    return dateA - dateB;
                },
                render: (item: any) => {
                    const payment_date = item["hcp_pay_date"] ? moment(item["hcp_pay_date"]).format("MM-DD-YYYY") : "NA";
                    return (
                        <div>
                            {payment_date}
                        </div>
                    )
                }
            },
            {
                title: 'Shift Date',
                width: 120,
                sorter: (a: any, b: any) => {
                    const dateA = new Date(a["shift_date"]).getTime();
                    const dateB = new Date(b["shift_date"]).getTime();
                    return dateA - dateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.getUtcDate(item["shift_date"]) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Staff Type',
                width: 170,
                sorter: (a: any, b: any) => (a?.hcp_type || '').localeCompare(b?.hcp_type || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Type',
                width: 200,
                sorter: (a: any, b: any) => (a?.shift_type || '').localeCompare(b?.shift_type || ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_type?.length > 20 ?
                                <Tooltip title={item?.shift_type || 'N/A'}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.shift_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.shift_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Is Pending Concern',
                width: 170,
                render: (item: any) => {
                    const isPendingConcern = item?.hasOwnProperty('is_pending_concern') && item["is_pending_concern"];
                    return (
                        <div>
                            {isPendingConcern ? "Yes" : "No"
                                // <div className={'mrg-right-10'}>
                                //     <WarningIcon className={'mrg-top-5 mrg-right-5'}
                                //                  color={'error'}/>
                                // </div>:
                                // <div>No</div>
                            }
                        </div>
                    )
                }
            },
            {
                title: "Staff Sent Home",
                width: 160,
                render: (item: any) => {
                    const isHcpSentHome = item?.hasOwnProperty('is_sent_home') ? (item["is_sent_home"] === true ? "Yes" : "No") : "No";
                    return (
                        <div className={`${isHcpSentHome === 'Yes' ? 'success' : 'danger'}`}>
                            {isHcpSentHome}
                        </div>
                    )
                }
            },
            {
                title: 'Staff Paid',
                width: 100,
                render: (item: any) => {
                    return (
                        <div className={`${item["is_hcp_paid"] === true ? "success" : "danger"}`}>
                            {(item["is_hcp_paid"] === true ? "Yes" : "No") || "N/A"}
                        </div>
                    )
                }
            },

            {
                title: 'Timesheet',
                width: 120,
                render: (item: any) => {
                    const isTimeSheetUploaded =
                        item?.hasOwnProperty("is_timesheet_uploaded") && item["is_timesheet_uploaded"];
                    return (
                        <div className={'display-center'}>
                            {isTimeSheetUploaded ? (
                                <Checkbox checked={isTimeSheetUploaded}/>
                            ) : (
                                <Checkbox checked={Boolean(isTimeSheetUploaded)}/>
                            )}
                        </div>
                    )
                }
            },
            {
                title: 'Facility Confirmation',
                width: 180,
                render: (item: any) => {
                    const isFacilityApproved =
                        item?.hasOwnProperty("is_facility_approved") && item["is_facility_approved"];
                    return (
                        <div className={'display-center'}>
                            {isFacilityApproved ? (
                                <Checkbox checked={isFacilityApproved}/>
                            ) : (
                                <Checkbox checked={Boolean(isFacilityApproved)}/>
                            )}
                        </div>
                    )
                }
            },
            {
                title: 'View Details',
                width: 140,
                render: (_: any, item: any, rowIndex: number) => {
                    return (
                        <div className={'display-center'}>
                            <Link
                                to={"/closedShifts/view/" + item["_id"]}
                                id={"link_hospital_details" + rowIndex}
                                style={{color: '#1e1e1e'}}
                            >
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </Link>
                        </div>
                    )
                }
            }
        ]
    }, [])


    return (
        <div className="closed-shifts screen crud-layout pdd-30">
            <DialogComponent open={isAddPaymentDetailsOpen} cancel={cancelPaymentDetails} headerLabel={"Payment Details"}>
                <AddPaymentDetailsComponent
                    cancel={cancelPaymentDetails}
                    confirm={afterConfirm}
                    selectedShifts={selectedShifts}
                />
            </DialogComponent>

            <DialogComponent isEditableDialog={true} open={isPendingConcernOpen} cancel={cancelMarkingPendingConcern}>
                <PendingConcernConfirmationBox selectedShifts={selectedShifts}
                                               cancelMarkingPendingConcern={cancelMarkingPendingConcern}
                                               confirmMarkingPendingConcern={confirmMarkingPendingConcern}/>
            </DialogComponent>

            <DialogComponent open={isRejectShiftOpen} cancel={cancelRejectShift} headerLabel={selectedShifts && selectedShifts?.length > 1 ? "Cancel Shifts" : "Cancel Shift"}>
                <RejectShiftComponent
                    isMultipleReasons={true}
                    cancel={cancelRejectShift}
                    confirm={confirmRejectShift}
                    selectedShifts={selectedShifts}
                />
            </DialogComponent>

            <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                <ShiftFilterDrawerComponent
                    filterData={filterData}
                    setFilterData={setFilterData}
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    isFacilityListLoading={isFacilityListLoading}
                    noStatus={true}
                    isClosed={true}
                    resetFilters={resetFilters}
                    facilityList={facilityList}
                    hcpTypes={hcpTypes}
                />
            </DrawerComponent>
            <div className="custom-border pdd-10 pdd-top-0 pdd-bottom-20 mrg-top-0">
                <div className="mrg-left-5 filter">
                    <div className="table-header-actions mrg-bottom-10">
                        <div className="d-flex">
                            <div className="d-flex position-relative">
                                {!filterData.search ? (
                                    <div className={"search_icon"}>
                                        <SearchRounded/>
                                    </div>
                                ) : (
                                    <div className={"search_icon"}>
                                        <ClearIcon
                                            onClick={(event) => {
                                                setFilterData({...filterData, search: ''})
                                            }}
                                            id="clear_facility_search"
                                        />
                                    </div>
                                )}
                                <div>
                                    <CssTextField
                                        defaultValue={""}
                                        className="search-cursor searchField"
                                        id="input_search_facility"
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setFilterData({...filterData, search: value});
                                        }}
                                        value={filterData.search}
                                        variant={"outlined"}
                                        size={"small"}
                                        type={"text"}
                                        placeholder={"Search Shift"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="actions d-flex">
                            <div className={'mrg-right-20'}>
                                <Tooltip title={"Pending Concern"}>
                                    <Button variant={"contained"} color={"primary"}
                                            disabled={
                                                    filterData?.is_pending_concern ||
                                                    selectedShifts?.length === 0 ||
                                                    selectedRowData?.some((row:any)=>row?.is_pending_concern === true)
                                                }
                                            onClick={openPendingConcern}>
                                        &nbsp;&nbsp;Pending Concern&nbsp;&nbsp;
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className="paydate-btn">
                                <Button
                                    onClick={openPaymentDetails}
                                    disabled={(isTheSelectedShiftPendingConcern || selectedShifts?.length === 0)}
                                    className={`${(isTheSelectedShiftPendingConcern) ? "btn-disabled" : ""}`}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Add Payment
                                </Button>
                            </div>
                            <div className="mrg-left-20">
                                <Tooltip title={"Cancel Closed Shift"}>
									<span>
										<Button
                                            variant={"contained"}
                                            color={"primary"}
                                            disabled={selectedShifts?.length === 0}
                                            // disabled={selectedCount === -1}
                                            onClick={openRejectShift}
                                        >
											&nbsp;&nbsp;Cancel Shift&nbsp;&nbsp;
										</Button>
									</span>
                                </Tooltip>
                            </div>
                            <div className={'mrg-left-20'}>
                                <Button
                                    variant={"outlined"} id={'add_facility_btn'}
                                    color={"primary"}
                                    onClick={() => {
                                        setOpenFilterDrawer(true)
                                    }}
                                >
                                    &nbsp;&nbsp;Filters&nbsp;&nbsp;
                                </Button>
                            </div>
                            <div className="mrg-left-20">
                                <Tooltip title="Download Shifts List">
                                    <Button variant={"outlined"} color="primary" onClick={handleDownload}
                                            className={!isDownloading ? "" : "has-loading-spinner"}
                                            disabled={isDownloading}>
                                        &nbsp;Download
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="actions"></div>
                </div>
                <div className={'closed-table-wrapper'}>
                    <AntTableComponent
                        url={ENV.API_URL + 'shift/closed'}
                        method={'post'}
                        extraPayload={filterData}
                        columns={closedShiftsListColumn}
                        noDataText={'No Closed Shift Found.'}
                        isRowSelection={true}
                        selectedRowKeys={selectedShifts}
                        setSelectedRowKeys={setSelectedShifts}
                        setSelectedRowData={setSelectedRowData}
                    />
                </div>
            </div>
        </div>
    );
};

export default ClosedShiftsScreen;
