import "./ShiftCardComponent.scss";
import {Tooltip,Button} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import moment from "moment";
import DialogComponentForMessageHcp from "../../../components/DialogComponentForMessageHcp";
import AddHcpForMessageComponent from "./AddHcpforMessageComponent";
import {CommonService} from "../../../helpers";
import {ENV} from "../../../constants";

interface ShiftCardComponentProps {
    shiftDetails: any;
    selectedFacility: any;
    hcpList: any;
    selectedHcpType: any;
}

const ShiftCardComponent = (props: ShiftCardComponentProps) => {
    const {shiftDetails, selectedFacility, hcpList, selectedHcpType} = props;
    const [open, setOpen] = useState<boolean>(false);
    const [isHcpListLoading, setIsHcpListLoading] = useState<any>(true);
    const [search, setSearch] = useState<any>(null);
    const [filteredHcp, setFilteredHcp] = useState<any>(null);
    const [distanceRangeValue, setDistanceRangeValue] = useState<any>(100);

    let timer: number | undefined;

    function debounce(fn: Function, delay: number): void {
        clearTimeout(timer);
        timer = setTimeout(fn, delay);
    }

    const handleSearchHcpData = useCallback(() => {
        setIsHcpListLoading(true);
        let url = `facility/${selectedFacility?._id}/hcpNearBy`;
        let payload: any = {};
        payload.is_approved = true;
        if(search){
            payload.search = search;
        }
        payload.hcp_types = [shiftDetails?.hcp_type];
        if(distanceRangeValue>=0){
            payload.miles=distanceRangeValue;
        }
        CommonService._api.post(ENV.API_URL + url, payload).then((resp) => {
            if(resp?.data){
                setFilteredHcp(resp?.data);
                setIsHcpListLoading(false);
            }
        }).catch((err) => {
            console.log(err);
            setIsHcpListLoading(true);
        });
        // eslint-disable-next-line
    }, [search, selectedHcpType,shiftDetails,distanceRangeValue,selectedFacility]);



    return (
        <>
            <div className={'shift-card-component'}>
                <DialogComponentForMessageHcp maxWidth={'md'} cancel={() => {
                    setOpen(false);
                    setSearch("")
                }} open={open}>
                    <AddHcpForMessageComponent debounce={debounce} handleSearchHcpData={handleSearchHcpData} setDistanceRangeValue={setDistanceRangeValue} distanceRangeValue={distanceRangeValue} search={search} setSearch={setSearch} shiftDetails={shiftDetails} selectedFacility={selectedFacility}
                                               setOpen={setOpen} hcpList={hcpList} selectedHcpType={selectedHcpType} isHcpListLoading={isHcpListLoading} filteredHcp={filteredHcp}/>
                </DialogComponentForMessageHcp>
                <div className={'shift-header-wrapper mrg-bottom-10'}>
                    <Tooltip title={`${shiftDetails?.title}`}>
                        <div className={'shift-title flex-1'}>{shiftDetails?.title}</div>
                    </Tooltip>
                    <div style={{display: "flex"}}>
                        <div className={'shift-card-text mrg-top-3'}>Staff Approved:</div>
                        <div className={'shift-card-value'}>&nbsp;{shiftDetails?.approved_hcps || 0}</div>
                    </div>
                </div>
                <div className={'mrg-bottom-10'} style={{display: "flex"}}>
                    <div style={{width: "70%"}}>
                        <div className={'shift-card-text'}>Shift Time</div>
                        <div
                            className={'shift-card-value'}>{moment.utc(shiftDetails?.shift_timings?.start_time).format('hh:mm A')} - {moment.utc(shiftDetails?.shift_timings?.end_time).format('hh:mm A')}</div>
                    </div>
                    <div style={{width: "30%"}}>
                        <div className={'shift-card-text'}>Shift Date</div>
                        <div
                            className={'shift-card-value'}>{moment.utc(shiftDetails?.shift_date).format('MM/DD/YYYY')}</div>
                    </div>
                </div>
                <div className={'mrg-bottom-10'} style={{display: "flex"}}>
                    <div style={{width: "70%"}}>
                        <div className={'shift-card-text'}>No. of Staff Required</div>
                        <div className={'shift-card-value'}>{shiftDetails?.hcp_count}</div>
                    </div>
                    <div style={{width: "30%"}}>
                        <div className={'shift-card-text'}>Shift Type</div>
                        <div className={'shift-card-value'}>{shiftDetails?.shift_type}</div>
                    </div>
                </div>
                <div style={{width: "100%"}}>
                    <Button fullWidth={true} size={'small'} variant={"contained"} color="primary" onClick={() => {
                        setOpen(true);
                       handleSearchHcpData();
                    }}>
                        &nbsp;Message Staff
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ShiftCardComponent;
