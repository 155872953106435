import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {DateRangeOutlined} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import {CommonService} from "../../../../../helpers";
import "./GenerateInvoiceFilterDrawerComponent.scss";
import {useLocalStorage} from "../../../../../components/useLocalStorage";
import {ENV, ImageConfig} from "../../../../../constants";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../../store/reducers";
import {AllShiftStatusList, timeTypesForFilters} from "../../../../../constants/data";
import moment from "moment";

const [firstDayOfMonth, lastDayOfMonth] = CommonService.getFirstDayAndLastDayUsingDate();
const useStyles = makeStyles({
    autocomplete: {
        '& .MuiInputBase-input': {
            height: '14px', // Adjust the height value as needed
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingTop: '4px', // Adjust the padding value as needed
            paddingBottom: '4px', // Adjust the padding value as needed
        },
    },
    option: {
        '&[aria-selected="true"]': {
            backgroundColor: '#41D6C3',
            color: 'white',
        },
    },
});


export interface GenerateInvoiceFilteDrawerComponentProps {
    setOpenFilterDrawer:any;
    filterData:any;
    setFilterData:any;
    selectedFacility: any;
    setSelectedFacility: any;
    // dateRange: any;
    // setDateRange: any;
    // selectedHcps: any;
    // setSelectedHcps: any;
    // setSelectedTimeTypes: any;
    // selectedTimeTypes: any;
    // selectedStatusTypes: any;
    // setSelectedStatusTypes: any;
    isAllStatusTypesSelected: any;
    setIsAllStatusTypesSelected: any;
    isPreviewingTheTimesheet: any;
}

const GenerateInvoiceFilteDrawerComponent = (props: PropsWithChildren<GenerateInvoiceFilteDrawerComponentProps>) => {
    const classes = useStyles();
    const [facilityList, setFacilityList] = useState<any>([]);
    const selectedFacility = props?.selectedFacility;
    const setSelectedFacility = props?.setSelectedFacility;
    const {
        // setSelectedStatusTypes,
        isPreviewingTheTimesheet,
        // selectedStatusTypes,
        isAllStatusTypesSelected,
        setIsAllStatusTypesSelected,
        filterData,
        setFilterData,
    } = props;
    // const dateRange = props?.dateRange;
    // const setDateRange = props?.setDateRange;
    const setOpenFilterDrawer = props?.setOpenFilterDrawer;
    // const [startDate, endDate] = dateRange;
    const [isAllTimeTypesSelected, setisAllTimeTypesSelected] = useLocalStorage('isAllTimeTypesSelectedinGenerate', false);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [isAllHcpTypeSelected, setIsAllHcpTypeSelected] = useLocalStorage('isAllHcpTypeSelectedGenerate', false);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);

    const facilityListInit = useCallback(() => {
        let facilityUrl = "facility/lite";
        let facilityPayload: any = {};
        setIsFacilityListLoading(true);
        CommonService._api.post(ENV.API_URL + facilityUrl, facilityPayload).then((resp) => {
            setFacilityList(resp?.data);
            setIsFacilityListLoading(false);
        }).catch((err) => {
            setIsFacilityListLoading(false);
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const clearFilterValues = useCallback(() => {
        // setDateRange([firstDayOfMonth, lastDayOfMonth]);
        setSelectedFacility(null);
        // setSelectedHcps([]);
        // setSelectedTimeTypes([]);
        // setSelectedStatusTypes([]);
        let updatedFilterData={...filterData};
        updatedFilterData.start_date = moment(firstDayOfMonth).format("YYYY-MM-DD");
        updatedFilterData.end_date=moment(lastDayOfMonth).format("YYYY-MM-DD");
        delete updatedFilterData.hcp_types;
        delete updatedFilterData.status;
        delete updatedFilterData.shift_types;
        setFilterData(updatedFilterData);
    },[filterData,setFilterData,setSelectedFacility]);

    const resetFilters = () => {
        clearFilterValues();
    };

    useEffect(() => {
        facilityListInit();
    }, [facilityListInit]);


    const formatFilterMenuListToHandleObj = useCallback((options: any[], isAllMenuItemSelected = false): any[] => {
        let menuList = options;
        //  console.log(options);
        const allOptions = options.filter((item) => {
            return item.code !== "Select All" && item.code !== "Clear All";
        });

        if (isAllMenuItemSelected) {
            menuList = [{name: "Clear All", code: "Clear All"}, ...allOptions];
        }

        if (!isAllMenuItemSelected) {
            menuList = [{name: "Select All", code: "Select All"}, ...allOptions];
        }

        return menuList;
    }, []);



    const formatFilterMenuList = useCallback((options: any[], isAllMenuItemSelected = false): any[] => {
        let menuList = options?.map((option: any) => option?.code);

        if (isAllMenuItemSelected) {
            menuList = ["Clear All", ...menuList];
        }
        if (!isAllMenuItemSelected) {
            menuList = ["Select All", ...menuList];
        }
        return menuList;
    }, []);


    const handleFilterValueSelectToHandleObj = useCallback(
        (
            newValues: any,
            options: any[],
            setIsAllMenuItemsSelected: any,
            key: string, // The key to dynamically update in filterData
        ) => {
            const isSelectAll = newValues.some((f: any) => f.code === "Select All");
            const isClearAll = newValues.some((f: any) => f.code === "Clear All");

            if (newValues.length === 0) {
                setIsAllMenuItemsSelected(false);
                // Remove the key from filterData if no values are selected
                setFilterData((prevData: any) => {
                    const { [key]: removedKey, ...rest } = prevData;
                    return rest;
                });
            }

            if (isSelectAll || isClearAll) {
                if (isSelectAll) {
                    setIsAllMenuItemsSelected(true);
                    const allOptions = options.filter((item) => {
                        return item.code !== "Select All" && item.code !== "Clear All";
                    });

                    // Add all options to filterData under the specified key
                    setFilterData((prevData: any) => ({
                        ...prevData,
                        [key]: allOptions.map((item) => item.code), // Map to codes
                    }));
                }

                if (isClearAll) {
                    setIsAllMenuItemsSelected(false);

                    // Remove the key from filterData
                    setFilterData((prevData: any) => {
                        const { [key]: removedKey, ...rest } = prevData;
                        return rest;
                    });
                }

                return;
            }


            // Dynamically update filterData with the selected values
            setFilterData((prevData: any) => {
                // If no new values, remove the key from filterData
                if (newValues.length === 0) {
                    const { [key]: removedKey, ...rest } = prevData;
                    return rest;
                }

                // Otherwise, add or update the key in filterData
                return {
                    ...prevData,
                    [key]: newValues.map((item:any) => item.code), // Map to codes
                };
            });
        },
        [setFilterData]
    );

    const handleDateRangeSelection = useCallback((dateRange: [Date | null, Date | null]) => {
        const [newStartDate, newEndDate] = dateRange || [];

        const startDateFormatted = newStartDate
            ? moment(newStartDate).format("YYYY-MM-DD")
            : null;
        const endDateFormatted = newEndDate
            ? moment(newEndDate || newStartDate).format("YYYY-MM-DD")
            : null;

        const updatedFilterData={...filterData}


        if (startDateFormatted) {
            updatedFilterData.start_date = startDateFormatted;
            // Clear the end_date if the user picks a new start_date
            if (filterData.start_date !== startDateFormatted) {
                delete updatedFilterData.end_date;
            }
        }

        if(endDateFormatted){
            updatedFilterData.end_date=endDateFormatted;
        }


        if (!startDateFormatted && !endDateFormatted) {
            delete updatedFilterData.start_date;
            delete updatedFilterData.end_date;
        }


        setFilterData(updatedFilterData);
    }, [filterData, setFilterData]);

    const handleAutocompleteChange = useCallback((
        event: any,
        selectedValues: any[],
        options: any[],
        key: string,
        setIsAllMenuItemsSelected: any
    ) => {
        if (selectedValues.includes("Select All")) {
            // Handle "Select All" selection
            const allCodes = options.map((option) => option.code);
            setIsAllMenuItemsSelected(true);
            setFilterData((prevData: any) => ({
                ...prevData,
                [key]: allCodes,
            }));
            return;
        }

        if (selectedValues.includes("Clear All")) {
            // Handle "Clear All" selection
            setIsAllMenuItemsSelected(false);
            setFilterData((prevData: any) => {
                const { [key]: removedKey, ...rest } = prevData;
                return rest;
            });
            return;
        }

        // Normalize the selected values to codes
        const normalizedValues = selectedValues.map((item: any) =>
            typeof item === "object" ? item.code : item
        );

        // Update the filterData
        setFilterData((prevData: any) => {
            if (normalizedValues.length === 0) {
                const { [key]: removedKey, ...rest } = prevData;
                return rest;
            }
            return {
                ...prevData,
                [key]: normalizedValues,
            };
        });
    },[setFilterData])


    return (
        <div className="facility-filter-component">
            <div className={'facility-filter-header-wrapper'}>
                <div className={'facility-filter-header'}>
                    <div className={'facility-filter-title'}>Filters</div>
                    <div className={'facility-filter-clear-all'} onClick={resetFilters}>Clear All</div>
                </div>
                <div
                    className={'facility-filter-close-btn'}
                    onClick={() => setOpenFilterDrawer(false)}
                >
                    <img src={ImageConfig.CrossIcon} alt={'cross-icon'}/>
                </div>
            </div>
            <div className={'facility-filter-fields-wrapper'}>
                <div className="facility-filters">
                    <Autocomplete
                        PaperComponent={({children}) => <Paper
                            style={{color: "#1e1e1e"}}>{children}</Paper>}
                        value={selectedFacility}
                        options={facilityList}
                        disabled={isFacilityListLoading}
                        getOptionLabel={(option: any) => option.name}
                        placeholder={"Select Facility"}
                        id="input_select_status"
                        className={classes.autocomplete}
                        onChange={($event, value) => {
                            setSelectedFacility(value);
                        }}
                        renderInput={(params) => <TextField {...params} id="select_status"
                                                            variant="outlined"
                                                            placeholder={"Facility"}/>}
                    />
                    {isFacilityListLoading && (
                        <div className="loading-text-wrapper">
                            <span className="loading-text">loading...</span>
                        </div>
                    )}
                </div>
                {selectedFacility && (filterData?.start_date || filterData?.end_date) && !isPreviewingTheTimesheet && <>
                    <div>
                        <Autocomplete
                            disableClearable
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            value={formatFilterMenuList(timeTypesForFilters, isAllTimeTypesSelected)?.filter((item:any)=>filterData?.shift_types?.includes(item))}
                            options={formatFilterMenuList(timeTypesForFilters, isAllTimeTypesSelected)}
                            id="input_select_time_types"
                            disableCloseOnSelect
                            classes={{
                                root: classes.autocomplete,
                                option: classes.option,
                            }}
                            renderTags={(value, getTagProps) => {
                                const selectedCount = value.length;
                                return selectedCount > 0 ? (
                                    <span {...getTagProps({ index: 0 })}>
                                    {selectedCount} selected
                                </span>
                                ) : null;
                            }}
                            renderInput={(params) => {
                                const placeholder = filterData?.shift_types?.length === 0 || !filterData?.shift_types
                                    ? "Shift Types"
                                    : "";
                                return <TextField {...params} id="select_hcpType" variant="outlined" placeholder={placeholder} />;
                            }}
                            onChange={(e, newValue) =>
                                handleAutocompleteChange(
                                    e,
                                    newValue,
                                    timeTypesForFilters,
                                    "shift_types",
                                    setisAllTimeTypesSelected
                                )
                            }
                        />
                    </div>
                    {!isPreviewingTheTimesheet &&
                        <div>
                            <Autocomplete
                                disableClearable
                                className={classes.autocomplete}
                                PaperComponent={({children}) => <Paper
                                    style={{color: "#1e1e1e"}}>{children}</Paper>}
                                multiple
                                id="input_select_status"
                                // value={selectedStatusTypes}
                                value={AllShiftStatusList?.filter((item:any)=>filterData?.status?.includes(item?.code))}
                                getOptionLabel={(option) => option?.name}
                                getOptionSelected={(option, value) => option.name === value?.name}
                                options={formatFilterMenuListToHandleObj(AllShiftStatusList, isAllStatusTypesSelected)}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        handleFilterValueSelectToHandleObj(
                                            newValue,
                                            AllShiftStatusList,
                                            setIsAllStatusTypesSelected,
                                            "status"
                                        );
                                    }
                                }}
                                classes={{
                                    option: classes.option,
                                }}
                                // renderTags={() => null}
                                renderTags={(value, getTagProps) => {
                                    const selectedCount = value.length;
                                    return selectedCount > 0 ? (
                                        <span {...getTagProps({ index: 0 })}>
                                            {selectedCount} selected
                                        </span>
                                    ) : null;
                                }}
                                renderInput={(params) => {
                                    const placeholder = filterData?.status?.length === 0 || !filterData?.status
                                        ? "Status"
                                        : "";
                                    return <TextField {...params} id="select_hcpType" variant="outlined" placeholder={placeholder} />;
                                }}
                                disableCloseOnSelect
                            />
                        </div>}
                    <div>
                        <Autocomplete
                            disableClearable
                            className={classes.autocomplete}
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            id="input_select_hcps"
                            value={formatFilterMenuList(hcpTypes, isAllHcpTypeSelected)?.filter((item:any)=>filterData?.hcp_types?.includes(item)) || []}
                            options={formatFilterMenuList(hcpTypes, isAllHcpTypeSelected)}
                            onChange={(e, newValue) =>
                                // handleFilterValueSelect(newValue, hcpTypes, setIsAllHcpTypeSelected, e, "hcp_types")
                                handleAutocompleteChange(
                                e,
                                newValue,
                                hcpTypes,
                                "hcp_types",
                                setIsAllHcpTypeSelected
                                )
                            }
                            // renderTags={() => null}
                            classes={{
                                option: classes.option,
                            }}
                            renderTags={(value, getTagProps) => {
                                const selectedCount = value.length;
                                return selectedCount > 0 ? (
                                    <span {...getTagProps({ index: 0 })}>
                                        {selectedCount} selected
                                    </span>
                                ) : null;
                            }}
                            renderInput={(params) => {
                                const placeholder = filterData?.hcp_types?.length === 0 || !filterData?.hcp_types
                                    ? "Staff Types"
                                    : "";
                                return <TextField {...params} id="select_hcpType" variant="outlined" placeholder={placeholder} />;
                            }}
                            disableCloseOnSelect
                        />
                    </div>
                </>}
                <div className="form-field-right">
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Select Date"
                        className="custom-date-wrapper"
                        selectsRange={true}
                        startDate={filterData.start_date && new Date(`${filterData.start_date}T00:00:00`) }
                        endDate={filterData.end_date && new Date(`${filterData.end_date}T00:00:00`)}
                        onChange={(update) => {
                            // setDateRange(update);
                            handleDateRangeSelection(update);
                        }}
                        isClearable={true}
                    />
                    {!filterData?.start_date && !filterData?.end_date &&
                        <DateRangeOutlined className="custom-date-icon-wrapper" fontSize="medium" color="action"/>}
                </div>
            </div>
        </div>
    );
};

export default GenerateInvoiceFilteDrawerComponent;