import React from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import "./LoginScreen.scss";
import {Link} from "react-router-dom";
import FormLabel from "@material-ui/core/FormLabel";
import {TextField} from "formik-material-ui";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {ENV} from "../../../constants";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from '@material-ui/icons';
import CommonService from "../../../helpers/common-service";
import {loginUser} from "../../../store/actions/auth.action";

let isEmail = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');
//^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$
const loginFormValidation = Yup.object({
    email: Yup.string()
        .email("Invalid Email")
        .matches(isEmail, "Invalid Email")
        .required("Required"),
    password: Yup.string().required("Required").min(6, "Password should be minimum 6 characters"),
});


const LoginScreen = (props: any) => {
    const dispatch = useDispatch();
    const [values, setValues] = React.useState({showPassword: false});

    const onLogin = (payload: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        const USER_EMAIL = payload?.email?.toLowerCase();
        const user_data = {
            email: USER_EMAIL,
            password: payload.password
        };
        CommonService._api.post(ENV.API_URL + "login", user_data).then((resp) => {
            setSubmitting(false);
            dispatch(loginUser(resp.data.user, resp.data.token));
        }).catch((err) => {
            CommonService.handleErrors(setErrors, err);
            setSubmitting(false);
        });
    };

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    return (
        <div className="main-auth-wrapper login-screen screen pdd-0">
            <div className="">
                <div className="auth-header">Welcome!</div>
                <p className={"auth-subheader"}>Log in to continue</p>
            </div>
            <Formik
                initialValues={{email: "", password: ""}}
                validateOnChange={true}
                validationSchema={loginFormValidation}
                onSubmit={onLogin}
            >
                {({isSubmitting, isValid}) => (

                    <Form className={"loginFormHolder form-holder"}>
                        <div className="form-field position-relative mrg-bottom-20">
                            <FormLabel className={"form-label"}>Email ID</FormLabel>
                            <Field
                                name="email"
                                type={"text"}
                                component={TextField}
                                size={"small"}
                                variant={"outlined"}
                                id={"login_username"}
                                color={"primary"}
                                placeholder={"Enter Email ID"}
                                className="input-cursor"
                            />
                        </div>

                        <div className="form-field position-relative">
                            <FormLabel className={"form-label"}>Password</FormLabel>
                            <Field
                                name={"password"}
                                component={TextField}
                                type={values.showPassword ? "text" : "password"}
                                placeholder={"Enter Password"}
                                max={16}
                                id={"login_password"}
                                variant={"outlined"}
                                color={"primary"}
                                className="input-cursor"
                            />
                            <div className={"eye_btn_wrapper"}>
                                <IconButton
                                    size={"small"}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    id="login_password_show_hide_btn_icon"
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </div>
                        </div>
                        <div className="form-link">
                            <div className="forgot-password-holder" id="link_forgot_password">
                                <Link className="forgot-password-wrapper" to="/forgot-password">
                                    Forgot Password?
                                </Link>
                            </div>
                        </div>
                        <div className="form-field mrg-top-40 position-relative">
                            <Button
                                disabled={isSubmitting || !isValid}
                                fullWidth
                                style={{width: "100%"}}
                                variant={"contained"}
                                id="login_button"
                                type={"submit"}
                                size={"large"}
                                className={isSubmitting ? 'login-button has-loading-spinner' : 'login-button'}
                            >
                                {isSubmitting ? "Logging in" : "Login"}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default LoginScreen;

