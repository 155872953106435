import "./EditGeneratedInvoiceDetails.scss";
import React, {useCallback, useEffect, useState} from "react";
import {ApiService, CommonService, Communications} from "../../../../../helpers";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import {Link, useParams} from "react-router-dom";
import {ENV} from "../../../../../constants";
import LoaderComponent from "../../../../../components/LoaderComponent";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogComponent from "../../../../../components/DialogComponent";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import Button from "@material-ui/core/Button";
import {Tooltip} from "@material-ui/core";
import * as Yup from "yup";
import {DatePicker} from "formik-material-ui-pickers";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    tableBody: {
        height: 'auto',
        overflowY: 'auto',
    },
}));
const table1 = ["Customer Number", "Invoice No", "Invoice Date", "Action"];
const table2 = ["Comment", "Action"];
const table3 = ["Address"];
const table4 = ["Address", "Action"];
const table5 = ["Reference - PO#", "START DATE", "END DATE", "ADDL.", "INVOICE DESCRIPTION", "DOC.DATE", "Action"];
const table6 = ["ITEM", "INVOICE DETAILS", "DATE", "CURR. ($)", "REG AMT", "OT AMT", "DT AMT", "TOTAL AMOUNT", "Action", " "];
const table7 = ["Name", "Address", "Attn", "Contact", "Action"];
const table8 = ["Name", "Account", "Address", "Action"];
const table9 = ["item", "Invoice details", "Date", "Total Amount", "Action"];

const EditGeneratedInvoiceDetails = () => {
    const classes = useStyles();
    const [invoiceDetails, setInvoiceDetails] = useState<any>(null);
    const [isInvoiceDetailsLoading, setIsInvoiceDetailsLoading] = useState<any>(false);
    const params = useParams<{ id: string }>();
    const {id} = params;
    const [openComment, setOpenComment] = useState<boolean>(false);
    const [openBillingTo, setOpenBillingTo] = useState<boolean>(false);
    const [openChecksPayable, setOpenChecksPayable] = useState<boolean>(false);
    const [openRemittancePayable, setOpenRemittancePayable] = useState<boolean>(false);
    const [openTimesheetEdit, setOpenTimesheetEdit] = useState<boolean>(false);
    const [timeSheetData, setTimeSheetData] = useState<any>(null);
    const [formattedText, setFormattedText] = useState('');
    const [sortedTimesheets, setSortedTimesheets] = useState<any>([]);


    useEffect(() => {
        const sortedData = invoiceDetails?.timesheets?.sort((a: any, b: any) => {
            if (a?.shift_date === b?.shift_date) {
                // If dates are equal, sort by combined name
                const aFullName = `${a?.hcp?.first_name} ${a?.hcp?.last_name}`.trim();
                const bFullName = `${b?.hcp?.first_name} ${b?.hcp?.last_name}`.trim();
                return aFullName.localeCompare(bFullName);
            } else {
                // Otherwise, sort by date
                return new Date(a.shift_date).getTime() - new Date(b.shift_date).getTime();
            }
        });
        setSortedTimesheets(sortedData);
    }, [invoiceDetails]);

    useEffect(() => {
        function breakTextByCharacterCount(text: string, charCount: number) {
            let newText = '';
            let count = 0;

            for (let i = 0; i < text?.length; i++) {
                newText += text[i];
                count++;

                if (count === charCount) {
                    newText += '\n';
                    count = 0;
                }
            }

            return newText;
        }

        const formattedText = breakTextByCharacterCount(invoiceDetails?.comment, 100);
        setFormattedText(formattedText);
    }, [invoiceDetails?.comment]);


    const viewInvoiceDetails = useCallback(() => {
        setIsInvoiceDetailsLoading(true);
        CommonService._api.get(ENV.API_URL + `invoice/${id}`).then((resp) => {
            setInvoiceDetails(resp?.data);
            setIsInvoiceDetailsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsInvoiceDetailsLoading(false);
        });
    }, [id]);

    useEffect(() => {
        viewInvoiceDetails();
    }, [viewInvoiceDetails]);


    useEffect(() => {
        Communications.pageTitleSubject.next("Edit Invoice Details");
        Communications.pageBackButtonSubject.next(`/generatedInvoiceViewDetails/${id}`);
    }, [id]);


    const cancelOpenComment = useCallback(() => {
        setOpenComment(false);
    }, []);

    const cancelOpenBillingTo = useCallback(() => {
        setOpenBillingTo(false);
    }, []);


    const cancelChecksPayable = useCallback(() => {
        setOpenChecksPayable(false);
    }, []);


    const deleteTimesheet = useCallback((id: any) => {
        CommonService._api.delete(ENV.API_URL + 'timesheet/' + id).then((resp) => {
            CommonService.showToast(resp?.message || "Deleted Successfully", "success");
            viewInvoiceDetails();
        }).catch((err) => {
            CommonService.showToast(err?.error, "error");
        });
    }, [viewInvoiceDetails]);

    const cancelRemitancePayable = useCallback(() => {
        setOpenRemittancePayable(false);
    }, []);

    const CancelTimeSheetOpen = useCallback(() => {
        setOpenTimesheetEdit(false);
    }, []);

    const onEditComment = useCallback((item: any, {setSubmitting, setErrors, resetForm}) => {
        let payload: any = {
            ...item,
            billing_to: {
                name: item?.billing_to?.name,
                street: item?.billing_to?.street,
                city: item?.billing_to?.city,
                state: item?.billing_to?.state,
                zip_code: item?.billing_to?.zip_code,
                country: item?.billing_to?.country,
                attn: item?.billing_to?.attn,
            },
            checks_payable: {
                name: item?.checks_payable?.name,
                street: item?.checks_payable?.street,
                city: item?.checks_payable?.city,
                state: item?.checks_payable?.state,
                zip_code: item?.checks_payable?.zip_code,
                country: item?.checks_payable?.country,
                ein: item?.checks_payable?.ein,
                attn: item?.checks_payable?.attn,
                contact: item?.checks_payable?.contact
            },
            finance_charge: {
                details: item?.finance_charge?.details,
                date: CommonService.getUtcDate(item?.finance_charge?.date),
                amount: Number(item?.finance_charge?.amount).toFixed(2),
            }
        };

        ApiService.put(ENV.API_URL + "invoice/" + id, payload)
            .then((resp: any) => {
                // console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || "Success", "success");
                    setOpenComment(false);
                    setOpenBillingTo(false);
                    setOpenChecksPayable(false);
                    setOpenRemittancePayable(false);
                    viewInvoiceDetails();
                } else {
                    setSubmitting(false);
                    setOpenComment(false);
                }
            })
            .catch((err) => {
                //console.log(err);
                setOpenComment(false);
                setOpenBillingTo(false);
                setOpenRemittancePayable(false);
                setOpenChecksPayable(false);
                CommonService.handleErrors(setErrors, err);
                setSubmitting(false);
                CommonService.showToast(err.msg || "Error", "error");
            });

    }, [id, viewInvoiceDetails]);

    const onTimeSheetEdit = useCallback((item: any, {setSubmitting, setErrors, resetForm}) => {

        // if (Number(item?.reg_hrs) + Number(item?.ot_hrs) + Number(item?.dt_hrs) > timeSheetData?.shift_hours) {
        //     CommonService.showToast("Total Hours cannot be greater than Total Shift Hours", "error");
        //     setSubmitting(false);
        //     return;
        // }
        // if (Number(item?.reg_hrs) + Number(item?.ot_hrs) + Number(item?.dt_hrs) < timeSheetData?.shift_hours) {
        //     CommonService.showToast("Total Hours cannot be less than Total Shift Hours", "error");
        //     setSubmitting(false);
        //     return;
        // }

        let payload: any = {
            ...item,
            // ot_amount: item?.ot_hrs * timeSheetData?.total_hourly_rate * 1.5,
            // dt_amount: item?.dt_hrs * timeSheetData?.total_hourly_rate * 2,
            // reg_amount: item?.reg_hrs * timeSheetData?.total_hourly_rate,
            // total_amount: item?.ot_hrs * timeSheetData?.total_hourly_rate * 1.5 + item?.dt_hrs * timeSheetData?.total_hourly_rate * 2 + item?.reg_hrs * timeSheetData?.total_hourly_rate,
            ot_amount: Number(item?.ot_amount),
            reg_amount: Number(item?.reg_amount),
            dt_amount: Number(item?.dt_amount),
            total_amount: Number(item?.reg_amount) + Number(item?.ot_amount) + Number(item?.dt_amount),
        };
        ApiService.put(ENV.API_URL + "timesheet/" + timeSheetData?._id, payload)
            .then((resp: any) => {
                // console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || "Success", "success");
                    setOpenTimesheetEdit(false);
                    viewInvoiceDetails();
                } else {
                    setSubmitting(false);
                    setOpenComment(false);
                }
            })
            .catch((err) => {
                //console.log(err);
                setOpenTimesheetEdit(false);
                CommonService.handleErrors(setErrors, err);
                setSubmitting(false);
                CommonService.showToast(err.msg || "Error", "error");
            });
    }, [timeSheetData, viewInvoiceDetails]);

    const InvoiceGeneratedInitialState = {
            invoice_number: invoiceDetails?.invoice_number,
            invoice_generated_date: invoiceDetails?.invoice_generated_date,
            comment: invoiceDetails?.comment || "The enclosed health/individual’s information was reproduced by Vitawerks, Inc.. The confidentiality of this information is protected by federal and other\n" +
            "law. These reproductions are intended exclusively for the requested, authorized, purpose and cannot be redistributed for other purposes without the written consent of the person to whom they pertain.\n" +
            "If you have questions regarding these records or any other information provided or requested from Vitawerks, Inc. Call 309 472 3148",
            reference: invoiceDetails?.reference,
            addl: invoiceDetails?.addl,
            description: invoiceDetails?.description,
            billing_to: {
                name: invoiceDetails?.billing_to?.name,
                street: invoiceDetails?.billing_to?.street,
                city: invoiceDetails?.billing_to?.city,
                state: invoiceDetails?.billing_to?.state,
                zip_code: invoiceDetails?.billing_to?.zip_code,
                country: invoiceDetails?.billing_to?.country,
                attn: invoiceDetails?.billing_to?.attn,
            },
            checks_payable: {
                name: invoiceDetails?.checks_payable?.name,
                street: invoiceDetails?.checks_payable?.street,
                city: invoiceDetails?.checks_payable?.city,
                state: invoiceDetails?.checks_payable?.state,
                zip_code: invoiceDetails?.checks_payable?.zip_code,
                country: invoiceDetails?.checks_payable?.country,
                ein: invoiceDetails?.checks_payable?.ein,
                attn: invoiceDetails?.checks_payable?.attn,
                contact: invoiceDetails?.checks_payable?.contact
            },
            send_remittance: {
                name: invoiceDetails?.send_remittance?.name,
                account: invoiceDetails?.send_remittance?.account,
                city: invoiceDetails?.send_remittance?.city,
                state: invoiceDetails?.send_remittance?.state,
                zip_code: invoiceDetails?.send_remittance?.zip_code,
                street: invoiceDetails?.send_remittance?.street,
                electronic: invoiceDetails?.send_remittance?.electronic
            },
            finance_charge: {
                details: invoiceDetails?.finance_charge?.details,
                date: invoiceDetails?.finance_charge?.date ? invoiceDetails?.finance_charge?.date : null,
                amount: Number(invoiceDetails?.finance_charge?.amount) || 0,
                //     ?.toLocaleString('en-US', {
                //     style: 'currency',
                //     currency: 'USD',
                //     minimumFractionDigits: 2,
                //     maximumFractionDigits: 2,
                // }),
            },
        }
    ;

    const editCommentformValidtaion = Yup.object({
        comment: Yup.string()
            .typeError("Comment must be a text")
            .trim("Empty space not allowed")
            .required("Comment is required"),
    });
    const editReferenceFormValidation = Yup.object({
        reference: Yup.string().typeError(" must be a text")
            .min(1, "min 1 letter")
            .trim("empty space not allowed")
            .required("Required")
            .max(50, "max limit 50")
            // eslint-disable-next-line
            .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                , "alphanumeric & special characters only "),
        addl: Yup.string().typeError(" must be a text")
            .min(1, "min 1 letter")
            .trim("empty space not allowed")
            .required("Required")
            .max(50, "max limit 50")
            // eslint-disable-next-line
            .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                , "alphanumeric & special characters only "),
        // description: Yup.string().typeError(" must be a text")
        //     .min(1, "min 1 letter")
        //     .trim("empty space not allowed")
        //     .required("Required")
        //     .max(50, "max limit 50")
        //     // eslint-disable-next-line
        //     .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
        //         , "alphanumeric & special characters only "),
    });

    const editInvoiceFormValidation = Yup.object({
        invoice_number: Yup.string().typeError(" must be a text")
            .min(1, "min 1 letter")
            .trim("empty space not allowed")
            .required("Required")
            .max(50, "max limit 50")
            // eslint-disable-next-line
            .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                , "alphanumeric & special characters only "),
        invoice_generated_date: Yup.string().nullable().typeError(" must be a date"),

    });

    const editFinanceChargeFormValidation = Yup.object({
        // "details": "finance charge",
        // "date": "2023-07-12",
        // "amount": 10
        finance_charge: Yup.object({
            details: Yup.string().typeError(" must be a text"),
            date: Yup.string().nullable().typeError(" must be a date"),
            amount: Yup.string().nullable(),
        }),
    });

    const billingToFormValidation = Yup.object({
        billing_to: Yup.object({
            name: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            street: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            city: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            zip_code: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            state: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            country: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            attn: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter").trim("empty space not allowed")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
        }),
    });
    const checkPayableFormValidation = Yup.object({
        checks_payable: Yup.object({
            name: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            state: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            country: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            street: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            city: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            zip_code: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            ein: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            attn: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            contact: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
        }),
    });
    const RemittanceFormValidation = Yup.object({
        send_remittance: Yup.object({
            name: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            account: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            city: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            state: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            zip_code: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            street: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
            electronic: Yup.string().typeError(" must be a text")
                .min(1, "min 1 letter")
                .trim("empty space not allowed")
                .required("Required")
                .max(50, "max limit 50")
                // eslint-disable-next-line
                .matches(/[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
                    , "alphanumeric & special characters only "),
        })

    });

    const timesheetInitialState = {
        hcp: {
            first_name: timeSheetData?.hcp?.first_name,
            last_name: timeSheetData?.hcp?.last_name,
            hcp_type: timeSheetData?.hcp?.hcp_type,
            worker_name: timeSheetData?.hcp?.first_name + " " + timeSheetData?.hcp?.last_name
        },
        //  reg_amount: timeSheetData?.reg_hrs * timeSheetData?.total_hourly_rate,
        reg_hrs: timeSheetData?.reg_hrs,
        ot_hrs: timeSheetData?.ot_hrs,
        dt_hrs: timeSheetData?.dt_hrs,
        reg_amount: timeSheetData?.reg_amount,
        ot_amount: timeSheetData?.ot_amount,
        dt_amount: timeSheetData?.dt_amount,
        total_hourly_rate: timeSheetData?.total_hourly_rate || 0,
        late_request_premium: timeSheetData?.late_request_premium || 0,
        shift_differential: timeSheetData?.shift_differential || 0,
        hazard_pay: timeSheetData?.hazard_pay || 0,
        ot_holiday_hours: timeSheetData?.ot_holiday_hours || 0,


        //  ot_amount: timeSheetData?.ot_hrs * timeSheetData?.total_hourly_rate * 1.5,
        //   dt_amount: timeSheetData?.dt_hrs * timeSheetData?.total_hourly_rate * 2,
        shift_type: timeSheetData?.shift_type,
        //total_amount: timeSheetData?.reg_amount + timeSheetData?.ot_amount + timeSheetData?.dt_amount
        // total_hourly_rate: timeSheetData?.total_hourly_rate
    };

    const timeSheetFormValidation = Yup.object({
        reg_hrs: Yup.number()
            .typeError('Regular hours must be a number')
            .min(0, 'Regular hours must be greater than or equal to 0')
            .max(8, 'Regular hours must be less than or equal to 8')
            .required('Regular hours are required'),
        ot_hrs: Yup.number()
            .typeError('Ot hours must be a number')
            .min(0, 'Ot hours must be greater than or equal to 0')
            .max(4, 'Ot hours must be less than or equal to 4'),
        dt_hrs: Yup.number()
            .typeError('Dt hours must be a number')
            .min(0, 'Dt hours must be greater than or equal to 0'),
        total_hourly_rate: Yup.number()
            .typeError('Hourly Rate must be a number')
            .min(0, 'Hourly Rate must be greater than or equal to 0'),
        late_request_premium: Yup.number()
            .typeError('Late Request premium must be a number')
            .min(0, 'Late Request premium must be greater than or equal to 0'),
        shift_differential: Yup.number()
            .typeError('Shift Differential premium must be a number')
            .min(0, 'Shift Differential premium must be greater than or equal to 0'),
        hazard_pay: Yup.number()
            .typeError('Hazard Pay must be a number')
            .min(0, 'Hazard Pay must be greater than or equal to 0'),
        ot_holiday_hours: Yup.number()
            .typeError('OT / Holiday Hours must be a number')
            .min(0, 'OT / Holiday Hours must be greater than or equal to 0'),

    });
    const timeSheetFormValidation2 = Yup.object({
        reg_hrs: Yup.number()
            .typeError('Regular hours must be a number')
            .min(0, 'Regular hours must be greater than or equal to 0')
            .max(12, 'Regular hours must be less than or equal to 12')
            .required('Regular hours are required'),
        ot_hrs: Yup.number()
            .typeError('Ot hours must be a number')
            .min(0, 'Ot hours must be greater than or equal to 0')
            .max(4, 'Ot hours must be less than or equal to 4'),
        dt_hrs: Yup.number()
            .typeError('Dt hours must be a number')
            .min(0, 'Dt hours must be greater than or equal to 0'),
        total_hourly_rate: Yup.number()
            .typeError('Hourly Rate must be a number')
            .min(0, 'Hourly Rate must be greater than or equal to 0'),
        late_request_premium: Yup.number()
            .typeError('Late Request premium must be a number')
            .min(0, 'Late Request premium must be greater than or equal to 0'),
        shift_differential: Yup.number()
            .typeError('Shift Differential premium must be a number')
            .min(0, 'Shift Differential premium must be greater than or equal to 0'),
        hazard_pay: Yup.number()
            .typeError('Hazard Pay must be a number')
            .min(0, 'Hazard Pay must be greater than or equal to 0'),
        ot_holiday_hours: Yup.number()
            .typeError('OT / Holiday Hours must be a number')
            .min(0, 'OT / Holiday Hours must be greater than or equal to 0'),
    });


    if (isInvoiceDetailsLoading) {
        return (
            <div className="pending-shiftsrequirment-view screen pdd-100">
                <LoaderComponent position="block"/>
            </div>
        );
    }

    return (
        <>
            <div className={'screen  crud-layout pdd-50 edit-generated-invoice'}>
                <DialogComponent isEditableDialog={true} maxWidth={false} headerLabel={"Edit Comment"}
                                 cancel={cancelOpenComment} open={openComment}>
                    <div>
                        <div className="pdd-right-25 pdd-left-25">
                            <Formik
                                initialValues={InvoiceGeneratedInitialState}
                                validateOnChange={true}
                                validationSchema={editCommentformValidtaion}
                                onSubmit={onEditComment}
                            >
                                {({isSubmitting, isValid}) => (
                                    <Form className="">
                                        <Field autoComplete="off" className="mrg-top-20" component={TextField}
                                               fullWidth
                                               label={"Comment"}
                                               multiline name="comment" rows={6} variant="outlined"
                                               placeholder="Type in your comments*"/>
                                        <div className="sms-blast-btn mrg-top-20 mrg-bottom-25">
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <div className={"mrg-right-20"}>
                                                    <Button
                                                        color={"primary"}
                                                        variant={"outlined"} id="sms_blast_button"
                                                        size={"medium"} fullWidth={true} onClick={() => {
                                                        setOpenComment(false);
                                                    }}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Tooltip title={isSubmitting ? "Saving" : "Save"}>
                                                        <Button disabled={isSubmitting || !isValid}
                                                                color={"primary"}
                                                                variant={"contained"} id="sms_blast_button"
                                                                className={isSubmitting ? "has-loading-spinner" : ""}
                                                                type="submit" size={"medium"} fullWidth={true}>
                                                            {isSubmitting ? "Saving" : "Save"}
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent headerLabel={"Edit Billing To"} isEditableDialog={true} cancel={cancelOpenBillingTo}
                                 open={openBillingTo}>
                    <div>
                        <div className="sms-blast-message pdd-20">
                            <Formik
                                initialValues={InvoiceGeneratedInitialState}
                                validateOnChange={true}
                                validationSchema={billingToFormValidation}
                                onSubmit={onEditComment}
                            >
                                {({isSubmitting, isValid}) => (
                                    <Form className="">
                                        <div className="input-container d-flex">
                                            <Field className={"mrg-right-10"} variant="outlined" name="billing_to.name"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="Facility Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_facility_name"/>
                                            <Field variant="outlined" name="billing_to.street"
                                                   type={"text"} component={TextField}
                                                   label="Street Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_street_name"/>
                                        </div>

                                        <Field className={"mrg-top-20"} variant="outlined" name="billing_to.attn"
                                               type={"text"} component={TextField}
                                               label="Attn Name*" fullWidth autoComplete="off"
                                               id="input_facility_edit_attn"/>

                                        <div className="input-container d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="billing_to.city"
                                                   type={"text"} component={TextField}
                                                   label="City Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_city_name"/>
                                            <Field className={"mrg-top-20"} variant="outlined" name="billing_to.state"
                                                   type={"text"} component={TextField}
                                                   label="State Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_state_name"/>
                                        </div>
                                        <div className="input-container d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="billing_to.zip_code"
                                                   type={"text"} component={TextField}
                                                   label="Zip Code Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_zip_code_name"/>
                                            <Field className={"mrg-top-20"} variant="outlined" name="billing_to.country"
                                                   type={"text"} component={TextField}
                                                   label="Country Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_country_name"/>
                                        </div>
                                        <div className="sms-blast-btn mrg-top-20">
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <div className={"mrg-right-20"}>
                                                    <Button
                                                        color={"primary"}
                                                        variant={"outlined"} id="sms_blast_button"
                                                        size={"medium"} fullWidth={true} onClick={() => {
                                                        setOpenBillingTo(false);
                                                    }}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Tooltip title={isSubmitting ? "Saving" : "Save"}>
                                                        <Button disabled={isSubmitting || !isValid}
                                                                color={"primary"}
                                                                variant={"contained"} id="sms_blast_button"
                                                                className={isSubmitting ? "has-loading-spinner" : ""}
                                                                type="submit" size={"medium"} fullWidth={true}>
                                                            {isSubmitting ? "Saving" : "Save"}
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent headerLabel={"Edit Check Payable"} isEditableDialog={true} cancel={cancelChecksPayable}
                                 open={openChecksPayable}>
                    <div className={'check-payable-edit-container'}>
                        <div className="sms-blast-message pdd-20">
                            <Formik
                                initialValues={InvoiceGeneratedInitialState}
                                validateOnChange={true}
                                validationSchema={checkPayableFormValidation}
                                onSubmit={onEditComment}
                            >
                                {({isSubmitting, isValid}) => (
                                    <Form className="">
                                        <div className="d-flex">
                                            <Field className={"mrg-right-10"} variant="outlined"
                                                   name="checks_payable.name" type={"text"}
                                                   component={TextField}
                                                   label="Facility Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_facility_name"/>
                                            <Field variant="outlined"
                                                   name="checks_payable.street"
                                                   type={"text"} component={TextField}
                                                   label="Street Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_street_name"/>
                                        </div>
                                        <div className="d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="checks_payable.city"
                                                   type={"text"} component={TextField}
                                                   label="City Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_city_name"/>
                                            <Field className={"mrg-top-20"} variant="outlined"
                                                   name="checks_payable.state"
                                                   type={"text"} component={TextField}
                                                   label="State Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_state_name"/>
                                        </div>
                                        <div className="d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="checks_payable.zip_code"
                                                   type={"text"} component={TextField}
                                                   label="Zip Code Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_zip_code_name"/>
                                            <Field className={"mrg-top-20"} variant="outlined"
                                                   name="checks_payable.country"
                                                   type={"text"} component={TextField}
                                                   label="Country Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_country_name"/>
                                        </div>
                                        <div className="d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="checks_payable.ein"
                                                   type={"text"} component={TextField}
                                                   label="Ein Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_country_name"/>
                                            <Field className={"mrg-top-20"} variant="outlined"
                                                   name="checks_payable.attn"
                                                   type={"text"} component={TextField}
                                                   label="Attn. Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_country_name"/>
                                        </div>
                                        <Field className={"mrg-top-20"} variant="outlined" name="checks_payable.contact"
                                               type={"text"} component={TextField}
                                               label="Contact*" fullWidth autoComplete="off"
                                               id="input_facility_edit_country_name"/>
                                        <div className="sms-blast-btn mrg-top-20">
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <div className={"mrg-right-20"}>
                                                    <Button
                                                        color={"primary"}
                                                        variant={"outlined"} id="sms_blast_button"
                                                        size={"medium"} fullWidth={true} onClick={() => {
                                                        setOpenChecksPayable(false);
                                                    }}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Tooltip title={isSubmitting ? "Saving" : "Save"}>
                                                        <Button disabled={isSubmitting || !isValid}
                                                                color={"primary"}
                                                                variant={"contained"} id="sms_blast_button"
                                                                className={isSubmitting ? "has-loading-spinner" : ""}
                                                                type="submit" size={"medium"} fullWidth={true}>
                                                            {isSubmitting ? "Saving" : "Save"}
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent headerLabel={'Edit Remittance Payable'} isEditableDialog={true}
                                 cancel={cancelRemitancePayable} open={openRemittancePayable}>
                    <div className={'edit-remittance-container'}>
                        <div className="sms-blast-message pdd-20">
                            <Formik
                                initialValues={InvoiceGeneratedInitialState}
                                validateOnChange={true}
                                validationSchema={RemittanceFormValidation}
                                onSubmit={onEditComment}
                            >
                                {({isSubmitting, isValid}) => (
                                    <Form className="">
                                        <div className="d-flex ">
                                            <Field className={"mrg-right-10"} variant="outlined"
                                                   name="send_remittance.name" type={"text"}
                                                   component={TextField}
                                                   label="Facility Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_facility_name"/>
                                            <Field variant="outlined"
                                                   name="send_remittance.street"
                                                   type={"text"} component={TextField}
                                                   label="Street Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_street_name"/>
                                        </div>
                                        <div className="d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="send_remittance.city"
                                                   type={"text"} component={TextField}
                                                   label="City Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_city_name"/>
                                            <Field className={"mrg-top-20"} variant="outlined"
                                                   name="send_remittance.state"
                                                   type={"text"} component={TextField}
                                                   label="State Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_state_name"/>
                                        </div>
                                        <div className="d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="send_remittance.zip_code"
                                                   type={"text"} component={TextField}
                                                   label="Zip Code Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_zip_code_name"/>
                                            <Field className={"mrg-top-20"} variant="outlined"
                                                   name="send_remittance.electronic"
                                                   type={"text"} component={TextField}
                                                   label="Electronic Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_country_name"/>
                                        </div>
                                        <Field className={"mrg-top-20 "} variant="outlined"
                                               name="send_remittance.account"
                                               type={"text"} component={TextField}
                                               label="Account*" fullWidth autoComplete="off"
                                               id="input_facility_edit_country_name"/>
                                        <div className="sms-blast-btn mrg-top-20">
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <div className={"mrg-right-20"}>
                                                    <Button
                                                        color={"primary"}
                                                        variant={"outlined"} id="sms_blast_button"
                                                        size={"medium"} fullWidth={true} onClick={() => {
                                                        setOpenRemittancePayable(false);
                                                    }}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Tooltip title={isSubmitting ? "Saving" : "Save"}>
                                                        <Button disabled={isSubmitting || !isValid}
                                                                color={"primary"}
                                                                variant={"contained"} id="sms_blast_button"
                                                                className={isSubmitting ? "has-loading-spinner" : ""}
                                                                type="submit" size={"medium"} fullWidth={true}>
                                                            {isSubmitting ? "Saving" : "Save"}
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent headerLabel={"Edit Shift Details"} isEditableDialog={true} cancel={CancelTimeSheetOpen}
                                 open={openTimesheetEdit}>
                    {/*(Total Shift Hour {timeSheetData?.shift_hours})*/}
                    <div className={'timesheet-edit-dialog'}>
                        <div className="sms-blast-message pdd-20">
                            <Formik
                                initialValues={timesheetInitialState}
                                validateOnChange={true}
                                validationSchema={(timeSheetData?.shift_type === "AM" || timeSheetData?.shift_type === "PM" || timeSheetData?.shift_type === "NOC") ? timeSheetFormValidation : timeSheetFormValidation2}
                                onSubmit={onTimeSheetEdit}
                            >
                                {({isSubmitting, isValid, values}) => (
                                    <Form className="">
                                        <div className="d-flex">
                                            <Field className={"mrg-right-10"} disabled={true} variant="outlined"
                                                   name="hcp.worker_name"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="Worker Name*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_hcp_first_name"/>
                                            <Field variant="outlined" name="shift_type"
                                                   type={"text"}
                                                   disabled={true}
                                                   component={TextField}
                                                   label="Shift Type" fullWidth autoComplete="off"
                                                   id="input_facility_edit_hcp_first_name"/>
                                        </div>
                                        <div className="d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="hcp.hcp_type"
                                                   type={"text"}
                                                   disabled={true}
                                                   component={TextField}
                                                   label="Staff Type" fullWidth autoComplete="off"
                                                   id="input_facility_edit_hcp_first_name"/>
                                            <Field className={"mrg-top-20"} variant="outlined" name="reg_hrs"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="Reg Hrs*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_hcp_first_name"/>
                                        </div>
                                        <div className="d-flex">
                                            {/*{!(values?.reg_hrs < 8) && <>*/}
                                            <Field
                                                className={`mrg-top-20 mrg-right-10`}
                                                variant="outlined"
                                                name="ot_hrs"
                                                type={"text"}
                                                component={TextField}
                                                label="OT Hrs*" fullWidth autoComplete="off"
                                                id="input_facility_edit_hcp_first_name"/>
                                            {/*</>}*/}
                                            {/*{!(values?.ot_hrs < 4) && <>*/}
                                            <Field className={"mrg-top-20"} variant="outlined" name="dt_hrs"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="DT Hrs*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_hcp_first_name"/>
                                            {/*</>}*/}
                                        </div>

                                        {/*//implementing new feature*/}


                                        <div className="d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="total_hourly_rate"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="Hourly Rate" fullWidth autoComplete="off"
                                                   id="input_facility_edit_hourly_rate"/>
                                            <Field className={"mrg-top-20"} variant="outlined"
                                                   name="late_request_premium"
                                                   type={"text"}
                                                   component={TextField}
                                                   placeholder={"Enter "}
                                                   label="Late Request Premium" fullWidth autoComplete="off"
                                                   id="input_facility_edit_late_request_premium"/>
                                        </div>


                                        <div className="d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="shift_differential"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="Shift Differential" fullWidth autoComplete="off"
                                                   id="input_facility_edit_shift_differential"/>
                                            <Field className={"mrg-top-20"} variant="outlined" name="hazard_pay"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="Hazard Pay" fullWidth autoComplete="off"
                                                   id="input_facility_edit_hazard_pay"/>
                                        </div>


                                        <div className="d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="ot_holiday_hours"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="OT / Holiday Hours" fullWidth autoComplete="off"
                                                   id="input_facility_edit_hcp_holiday_hours"/>
                                            <Field className={"mrg-top-20"} variant="outlined" name="reg_amount"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="Regular Amt*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_hcp_reg_amount"/>
                                        </div>


                                        <div className="d-flex">
                                            <Field className={"mrg-top-20 mrg-right-10"} variant="outlined"
                                                   name="ot_amount"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="OT Amt*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_hcp_ot_amount"/>
                                            <Field className={"mrg-top-20"} variant="outlined" name="dt_amount"
                                                   type={"text"}
                                                   component={TextField}
                                                   label="DT Amt*" fullWidth autoComplete="off"
                                                   id="input_facility_edit_dt_amount"/>
                                        </div>


                                        <div className="sms-blast-btn mrg-top-20">
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <div className={"mrg-right-20"}>
                                                    <Button
                                                        color={"primary"}
                                                        variant={"outlined"} id="sms_blast_button"
                                                        size={"medium"} fullWidth={true} onClick={() => {
                                                        setOpenTimesheetEdit(false);
                                                    }}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Tooltip title={isSubmitting ? "Saving" : "Save"}>
                                                        <Button
                                                            disabled={isSubmitting || !isValid}
                                                            color={"primary"}
                                                            variant={"contained"} id="sms_blast_button"
                                                            className={isSubmitting ? "has-loading-spinner" : ""}
                                                            type="submit" size={"medium"} fullWidth={true}>
                                                            {isSubmitting ? "Saving" : "Save"}
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </DialogComponent>

                {/*<div className={"custom-border pdd-10"}>*/}
                <Formik
                    initialValues={InvoiceGeneratedInitialState}
                    validateOnChange={true}
                    validationSchema={editInvoiceFormValidation}
                    onSubmit={onEditComment}
                >
                    {({isSubmitting, isValid}) => (
                        <Form className="">
                            <TableContainer component={Paper} className={`doc-table-responsive custom-paper`}>
                                <Table stickyHeader
                                       className="mat-table table">
                                    <TableHead className={"mat-thead"}>
                                        <TableRow className={"mat-tr"}>
                                            {table1.map((column: any, columnIndex: any) => (
                                                <TableCell
                                                    className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                    key={"header-col-" + columnIndex}>
                                                    {column}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                                  key={`row-1`}>
                                            <TableCell
                                                className="mat-td mat-td-hcp-name">{invoiceDetails?.facility?.uid}
                                            </TableCell>
                                            <TableCell>
                                                <Field variant="outlined" name="invoice_number" type={"text"}
                                                       component={TextField}
                                                       fullWidth autoComplete="off"
                                                       id="input_facility_edit_facility_name"/>
                                            </TableCell>
                                            <TableCell>
                                                {/*    {new Date(invoiceDetails?.created_at).toLocaleDateString("en-US", {*/}
                                                {/*    month: "2-digit",*/}
                                                {/*    day: "2-digit",*/}
                                                {/*    year: "numeric",*/}
                                                {/*})}*/}
                                                <Field variant="inline" orientation="landscape" openTo="date"
                                                       format="MM/dd/yyyy" views={["year", "month", "date"]}
                                                       inputVariant="outlined" component={DatePicker}
                                                       placeholder="MM/DD/YYYY" fullWidth autoComplete="off"
                                                       InputLabelProps={{shrink: true}} label="Date"
                                                       name="invoice_generated_date"/>

                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <Tooltip title={isSubmitting ? "Saving Notes" : "Save"}>
                                                        <Button disabled={isSubmitting || !isValid}
                                                                color={"primary"}
                                                                variant={"contained"} id="sms_blast_button"
                                                                className={isSubmitting ? "has-loading-spinner" : ""}
                                                                type="submit" size={"medium"} fullWidth={true}>
                                                            {isSubmitting ? "Saving" : "Save"}
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Form>
                    )}
                </Formik>


                <TableContainer component={Paper} className={`doc-table-responsive custom-paper mrg-top-20`}>
                    <div className={"mrg-10"} style={{fontWeight: 600, fontSize: "16px"}}>Comment</div>
                    <Table stickyHeader
                           className="mat-table table">
                        <TableHead className={"mat-thead"}>
                            <TableRow className={"mat-tr"}>
                                {table2.map((column: any, columnIndex: any) => (
                                    <TableCell
                                        className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                        key={"header-col-" + columnIndex}>
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                      key={`row-1`}>
                                <TableCell
                                    className="mat-td mat-td-hcp-name">
                                    <div
                                        style={{overflowWrap: "break-word"}}>
                                        {formattedText || "The enclosed health/individual’s information was reproduced by Vitawerks, Inc.. The confidentiality of this information is protected by federal and other\n" +
                                        "law. These reproductions are intended exclusively for the requested, authorized, purpose and cannot be redistributed for other purposes without the written consent of the person to whom they pertain.\n" +
                                        "If you have questions regarding these records or any other information provided or requested from Vitawerks, Inc. Call 309 472 3148"}
                                    </div>
                                </TableCell>
                                <TableCell className="mat-td mat-td-hcp-name">
                                    <EditIcon style={{cursor: "pointer", color: "#0C80E3"}} onClick={() => {
                                        setOpenComment(true);
                                    }}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} className={`doc-table-responsive custom-paper mrg-top-20`}>
                    <div className={"mrg-10"} style={{fontWeight: 600, fontSize: "16px"}}>Billing From</div>
                    <Table stickyHeader
                           className="mat-table table">
                        <TableHead className={"mat-thead"}>
                            <TableRow className={"mat-tr"}>
                                {table3.map((column: any, columnIndex: any) => (
                                    <TableCell
                                        className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                        key={"header-col-" + columnIndex}>
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                      key={`row-1`}>
                                <TableCell>
                                    {invoiceDetails?.billing_from?.name && (
                                        <span>{invoiceDetails.billing_from.name},&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_from?.street && (
                                        <span>{invoiceDetails.billing_from.street},&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_from?.city && (
                                        <span>{invoiceDetails.billing_from.city},&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_from?.state && (
                                        <span>{invoiceDetails.billing_from.state},&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_from?.zip_code && (
                                        <span>{invoiceDetails.billing_from.zip_code},&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_from?.ein && (
                                        <span>{invoiceDetails.billing_from.ein}</span>
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} className={`doc-table-responsive custom-paper mrg-top-20`}>
                    <div className={"mrg-10"} style={{fontWeight: 600, fontSize: "16px"}}>Billing To</div>
                    <Table stickyHeader
                           className="mat-table table">
                        <TableHead className={"mat-thead"}>
                            <TableRow className={"mat-tr"}>
                                {table4.map((column: any, columnIndex: any) => (
                                    <TableCell
                                        className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                        key={"header-col-" + columnIndex}>
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                      key={`row-1`}>
                                <TableCell><>
                                    {invoiceDetails?.billing_to?.name && (
                                        <span>{invoiceDetails.billing_to.name},&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_to?.attn && (
                                        <span>{invoiceDetails.billing_to.attn},&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_to?.street && (
                                        <span>{invoiceDetails.billing_to.street},&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_to?.city && (
                                        <span>{invoiceDetails.billing_to.city},&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_to?.state && (
                                        <span>{invoiceDetails.billing_to.state},&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_to?.zip_code && (
                                        <span>{invoiceDetails.billing_to.zip_code}&nbsp;</span>
                                    )}

                                    {invoiceDetails?.billing_to?.ein && (
                                        <span>{invoiceDetails.billing_to.ein}</span>
                                    )}
                                </>

                                </TableCell>
                                <TableCell>
                                    <EditIcon style={{cursor: "pointer", color: "#0C80E3"}} onClick={() => {
                                        setOpenBillingTo(true);
                                    }}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Formik
                    initialValues={InvoiceGeneratedInitialState}
                    validateOnChange={true}
                    validationSchema={editReferenceFormValidation}
                    onSubmit={onEditComment}
                >
                    {({isSubmitting, isValid}) => (
                        <Form className="">
                            <TableContainer component={Paper}
                                            className={`doc-table-responsive custom-paper mrg-top-20`}>
                                <Table stickyHeader
                                       className="mat-table table">
                                    <TableHead className={"mat-thead"}>
                                        <TableRow className={"mat-tr"}>
                                            {table5.map((column: any, columnIndex: any) => (
                                                <TableCell
                                                    className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                    key={"header-col-" + columnIndex}>
                                                    {column}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                                  key={`row-1`}>
                                            <TableCell>
                                                <Field variant="outlined" name="reference" type={"text"}
                                                       component={TextField}
                                                       fullWidth autoComplete="off"
                                                       id="input_facility_edit_facility_name"/>
                                            </TableCell>
                                            <TableCell>
                                                {invoiceDetails?.start_date ? CommonService.getUtcDate(invoiceDetails?.start_date) : ""}
                                            </TableCell>
                                            <TableCell>
                                                {invoiceDetails?.end_date ? CommonService.getUtcDate(invoiceDetails?.end_date) : ""}
                                            </TableCell>
                                            <TableCell>
                                                <Field variant="outlined" name="addl" type={"text"}
                                                       component={TextField}
                                                       fullWidth autoComplete="off"
                                                       id="input_facility_edit_facility_name"/>
                                            </TableCell>
                                            <TableCell>
                                                {/*<Field variant="outlined" name="description" type={"text"}*/}
                                                {/*       component={TextField}*/}
                                                {/*       fullWidth autoComplete="off"*/}
                                                {/*       id="input_facility_edit_facility_name"/>*/}
                                                {invoiceDetails?.reference + " " + invoiceDetails?.addl}
                                            </TableCell>
                                            <TableCell>
                                                {/*{new Date(invoiceDetails?.created_at).toLocaleDateString("en-US", {*/}
                                                {/*    month: "2-digit",*/}
                                                {/*    day: "2-digit",*/}
                                                {/*    year: "numeric",*/}
                                                {/*})}*/}
                                                {invoiceDetails?.invoice_generated_date &&
                                                    <>
                                                        {CommonService.getUtcDate(invoiceDetails?.invoice_generated_date)}
                                                    </>}
                                                {!invoiceDetails?.invoice_generated_date &&
                                                    <>
                                                        {CommonService.getUtcDate(invoiceDetails?.created_at)}
                                                    </>}
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <Tooltip title={isSubmitting ? "Saving Reference" : "Save"}>
                                                        <Button disabled={isSubmitting || !isValid}
                                                                color={"primary"}
                                                                variant={"contained"} id="sms_blast_button"
                                                                className={isSubmitting ? "has-loading-spinner" : ""}
                                                                type="submit" size={"medium"} fullWidth={true}>
                                                            {isSubmitting ? "Saving" : "Save"}
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Form>
                    )}
                </Formik>
                <TableContainer component={Paper} className={`doc-table-responsive custom-paper mrg-top-20`}>
                    <div className={"mrg-10"} style={{fontWeight: 600, fontSize: "16px"}}>Timesheet</div>
                    <Table stickyHeader
                           className="mat-table table">
                        <TableHead className={"mat-thead"}>
                            <TableRow className={"mat-tr"}>
                                {table6.map((column: any, columnIndex: any) => (
                                    <TableCell
                                        className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                        key={"header-col-" + columnIndex}>
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            {sortedTimesheets && sortedTimesheets?.map((timesheet: any, index: any) => {
                                return (
                                    <>
                                        <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                                  key={`row-1`}>
                                            <TableCell>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    {timesheet?.hcp?.hcp_type} Staffing {timesheet?.shift_type} Shift
                                                </div>
                                                <div>
                                                    {timesheet?.hcp?.first_name} {timesheet?.hcp?.last_name}
                                                </div>
                                                <div>
                                                    {
                                                        timesheet?.reg_hrs && <>
                                                            {`${timesheet?.reg_hrs}H REG ${(timesheet?.total_hourly_rate ? timesheet?.total_hourly_rate : 0)?.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })}`}
                                                        </>
                                                    }
                                                    {
                                                        timesheet?.ot_hrs && <>
                                                            {timesheet?.reg_hrs > 0 && <> | </>}
                                                            {`${timesheet?.ot_hrs}H OT ${(timesheet?.total_hourly_rate ? timesheet?.total_hourly_rate * 1.5 : 0)?.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })}`}
                                                        </>
                                                    }
                                                    {
                                                        timesheet?.dt_hrs > 0 && <>
                                                            {timesheet?.ot_hrs > 0 && <> | </>}
                                                            {`${timesheet?.dt_hrs}H DT ${(timesheet?.total_hourly_rate ? timesheet?.total_hourly_rate * 2 : 0)?.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })}`}
                                                        </>
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {CommonService.getUtcDate(timesheet?.shift_date)}
                                            </TableCell>
                                            <TableCell>
                                                USD
                                            </TableCell>
                                            <TableCell>
                                                {timesheet?.reg_amount?.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) || "$0"}
                                            </TableCell>
                                            <TableCell>
                                                {timesheet?.ot_amount?.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) || "$0"}
                                            </TableCell>
                                            <TableCell>
                                                {timesheet?.dt_amount?.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) || "$0"}
                                            </TableCell>
                                            <TableCell>
                                                {timesheet?.total_amount?.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) || "$0"}
                                            </TableCell>
                                            <TableCell>
                                                <EditIcon style={{cursor: "pointer", color: "#0C80E3"}} onClick={() => {
                                                    setOpenTimesheetEdit(true);
                                                    setTimeSheetData(timesheet);
                                                }}/>
                                            </TableCell>
                                            <TableCell>
                                                <DeleteIcon onClick={() => {
                                                    deleteTimesheet(timesheet?._id);
                                                }} style={{cursor: "pointer", color: "#F36969"}}/>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Formik
                    initialValues={InvoiceGeneratedInitialState}
                    validateOnChange={true}
                    validationSchema={editFinanceChargeFormValidation}
                    onSubmit={onEditComment}
                >
                    {({isSubmitting, isValid}) => (
                        <Form className="">
                            <TableContainer component={Paper}
                                            className={`doc-table-responsive custom-paper mrg-top-20`}>
                                <div className={"mrg-10"} style={{fontWeight: 600, fontSize: "16px"}}>Add Any Other
                                    Charge
                                </div>
                                <Table stickyHeader
                                       className="mat-table table">
                                    <TableHead className={"mat-thead"}>
                                        <TableRow className={"mat-tr"}>
                                            {table9.map((column: any, columnIndex: any) => (
                                                <TableCell
                                                    className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                    key={"header-col-" + columnIndex}>
                                                    {column}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                                  key={`row-1`}>
                                            <TableCell
                                                className="mat-td mat-td-hcp-name">1
                                            </TableCell>
                                            <TableCell>
                                                <Field variant="outlined" name="finance_charge.details" type={"text"}
                                                       component={TextField}
                                                       fullWidth autoComplete="off"
                                                       id="input_facility_edit_facility_name"/>
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    variant="inline"
                                                    orientation="landscape"
                                                    openTo="date"
                                                    format="MM/dd/yyyy"
                                                    views={["year", "month", "date"]}
                                                    inputVariant="outlined"
                                                    component={DatePicker}
                                                    placeholder="MM/DD/YYYY"
                                                    fullWidth
                                                    autoComplete="off"
                                                    InputLabelProps={{shrink: true}}
                                                    label="Date"
                                                    name="finance_charge.date"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field variant="outlined" name="finance_charge.amount" type={"text"}
                                                       component={TextField}
                                                       fullWidth autoComplete="off"
                                                       id="input_facility_edit_facility_name"/>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title={isSubmitting ? "Saving Finance Charge" : "Save"}>
                                                    <Button disabled={isSubmitting || !isValid}
                                                            color={"primary"}
                                                            variant={"contained"} id="sms_blast_button"
                                                            className={isSubmitting ? "has-loading-spinner" : ""}
                                                            type="submit" size={"medium"} fullWidth={true}>
                                                        {isSubmitting ? "Saving" : "Save"}
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Form>
                    )}
                </Formik>
                <TableContainer component={Paper} className={`doc-table-responsive custom-paper mrg-top-20`}>
                    <div className={"mrg-10"} style={{fontWeight: 600, fontSize: "16px"}}>Checks Payable / Remit to
                    </div>
                    <Table stickyHeader
                           className="mat-table table">
                        <TableHead className={"mat-thead"}>
                            <TableRow className={"mat-tr"}>
                                {table7.map((column: any, columnIndex: any) => (
                                    <TableCell
                                        className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                        key={"header-col-" + columnIndex}>
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                      key={`row-1`}>
                                <TableCell
                                    className="mat-td mat-td-hcp-name">{invoiceDetails?.checks_payable?.name}
                                </TableCell>
                                <TableCell>
                                    <>
                                        {invoiceDetails?.checks_payable?.street && (
                                            <span>{invoiceDetails.checks_payable.street},&nbsp;</span>
                                        )}

                                        {invoiceDetails?.checks_payable?.city && (
                                            <span>{invoiceDetails.checks_payable.city},&nbsp;</span>
                                        )}

                                        {invoiceDetails?.checks_payable?.state && (
                                            <span>{invoiceDetails.checks_payable.state},&nbsp;</span>
                                        )}

                                        {invoiceDetails?.checks_payable?.zip_code && (
                                            <span>{invoiceDetails.checks_payable.zip_code},&nbsp;</span>
                                        )}

                                        {invoiceDetails?.checks_payable?.ein && (
                                            <span>{invoiceDetails.checks_payable.ein}</span>
                                        )}
                                    </>

                                </TableCell>
                                <TableCell>{invoiceDetails?.checks_payable?.attn}
                                </TableCell>
                                <TableCell>{invoiceDetails?.checks_payable?.contact}
                                </TableCell>
                                <TableCell>
                                    <EditIcon style={{cursor: "pointer", color: "#0C80E3"}} onClick={() => {
                                        setOpenChecksPayable(true);
                                    }}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} className={`doc-table-responsive custom-paper mrg-top-20`}>
                    <div className={"mrg-10"} style={{fontWeight: 600, fontSize: "16px"}}>Send your Remittance to
                    </div>
                    <Table stickyHeader
                           className="mat-table table">
                        <TableHead className={"mat-thead"}>
                            <TableRow className={"mat-tr"}>
                                {table8.map((column: any, columnIndex: any) => (
                                    <TableCell
                                        className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                        key={"header-col-" + columnIndex}>
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                      key={`row-1`}>
                                <TableCell
                                    className="mat-td mat-td-hcp-name">{invoiceDetails?.send_remittance?.name}
                                </TableCell>
                                <TableCell>
                                    <div> Account:-{invoiceDetails?.send_remittance?.account}</div>
                                    <div>Electronic:-{invoiceDetails?.send_remittance?.electronic}</div>

                                </TableCell>
                                <TableCell>
                                    <>
                                        {invoiceDetails?.send_remittance?.street && (
                                            <span>{invoiceDetails.send_remittance.street},&nbsp;</span>
                                        )}

                                        {invoiceDetails?.send_remittance?.city && (
                                            <span>{invoiceDetails.send_remittance.city},&nbsp;</span>
                                        )}

                                        {invoiceDetails?.send_remittance?.state && (
                                            <span>{invoiceDetails.send_remittance.state},&nbsp;</span>
                                        )}

                                        {invoiceDetails?.send_remittance?.zip_code && (
                                            <span>{invoiceDetails.send_remittance.zip_code}</span>
                                        )}
                                    </>

                                </TableCell>
                                <TableCell>
                                    <EditIcon style={{cursor: "pointer", color: "#0C80E3"}} onClick={() => {
                                        setOpenRemittancePayable(true);
                                    }}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {/*</div>*/}
                <div className={"mrg-top-20 edit-invoice-button"}>
                    <Button
                        color={"primary"}
                        variant={"contained"} id="save_edit_generated_invoice_blast_button" size={"small"}
                        fullWidth={true}>
                        <Link to={"/generatedInvoiceViewDetails/" + id}
                              className="info-link"
                              style={{textDecoration: "none", color: "white"}}
                              id={"link_invoice_generated_details"}>
                            {"Save"}
                        </Link>
                    </Button>
                </div>
            </div>
        </>
    );
};
export default EditGeneratedInvoiceDetails;
