import {Button} from '@material-ui/core';
import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import "./PendingShiftsTakeoverComponent.scss";
import {StateParams} from "../../../../store/reducers";
import {useSelector} from "react-redux";
import {CommonService} from '../../../../helpers';
import {ENV} from '../../../../constants';
import LoaderComponent from '../../../../components/LoaderComponent';


export interface PendingShiftsTakeOverComponentProps {
    cancel: () => void;
    confirm: () => void;
    openViewShift: any;
    applicationId: string;
    requirementId: string;
    hcpId: string;
}

const PendingShiftsTakeoverComponent = (props: PropsWithChildren<PendingShiftsTakeOverComponentProps>) => {
    //const requirementId = props?.requirementId;
    const afterConfirm = props?.confirm;
    const applicationId = props?.applicationId;
    const {user} = useSelector((state: StateParams) => state.auth);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const hcpId = props?.hcpId;
    const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(false);

    const handleTakeOver = useCallback(() => {
        setIsLoading(true)
        let payload = {
            "takeover_by": user?._id
        }
        CommonService._api.patch(ENV.API_URL + `application/${applicationId}/takeOver`, payload).then((resp) => {
            CommonService.showToast(resp?.msg || "Success", "success");
            if (afterConfirm) {
                setIsLoading(false)
                afterConfirm();
            }
        }).catch((err) => {
            CommonService.showToast(err?.msg || "Error", "error");
            setIsLoading(false)
        });
    }, [afterConfirm, applicationId, user?._id])

    const init = useCallback(() => {
        setIsDetailsLoading(true)
        // config
        CommonService._api.get(ENV.API_URL + "hcp/" + hcpId).then((resp) => {
            setHcpDetails(resp.data);
            setIsDetailsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsDetailsLoading(false);
        });
    }, [hcpId]);
//  console.log(hcpId)
    useEffect(() => {
        init()
    }, [init])

    return <div className='pending-shifts-takeover'>
        {isDetailsLoading && (
            <div className="mrg-top-10 pdd-25">
                <LoaderComponent position="block"/>
            </div>
        )}
        {!isDetailsLoading && (<div className={"pdd-left-25 pdd-right-25 pdd-bottom-25"}>
            <div>
                <h2 className='title'>{hcpDetails?.first_name}&nbsp;{hcpDetails?.last_name}</h2>
                <p className='request'>Do you want to update the Shift Status ?</p>
            </div>
            <div className='mrg-top-20'>
                <Button variant={"outlined"} color="primary" size="large" fullWidth onClick={handleTakeOver}
                        disabled={isLoading}
                        className={isLoading ? "has-loading-spinner" : ""}>{isLoading ? "Taking Over" : "Take Over"}</Button>
            </div>
        </div>)}
    </div>;
}

export default PendingShiftsTakeoverComponent;
