// import CheckIcon from "@material-ui/icons/Check";
import { IconButton, Tooltip } from "@material-ui/core";
// import Paper from "@material-ui/core/Paper";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react";
import { useParams } from "react-router-dom";
import { TsDataListOptions, TsDataListState, TsDataListWrapperClass } from "../../../../../classes/ts-data-list-wrapper.class";
import DialogComponent from "../../../../../components/DialogComponent";
// import LoaderComponent from "../../../../../components/LoaderComponent";
// import NoDataCardComponent from "../../../../../components/NoDataCardComponent";
import { ENV } from "../../../../../constants";
import { ApiService } from "../../../../../helpers";
import RejectHcpApplicationComponent from "../../../pending/rejectHcp/RejectHcpApplicationComponent";
import "./PendingHcpApplicationComponent.scss";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";

export interface PendingHcpApplicationComponentProps {
  isAddOpen: Boolean;
  status: string;
}

const PendingHcpApplicationComponent = (props: PropsWithChildren<PendingHcpApplicationComponentProps>) => {
  const status = props?.status;
  const isAddOpen = props?.isAddOpen;
  const param = useParams<any>();
  const { id } = param;
  const [list, setList] = useState<TsDataListState | null>(null);
  const [isRejectOpen, setIsRejectOpen] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState<string>("");

  // eslint-disable-next-line
  const [hcpId, setHcpId] = useState<string>("");

  const init = useCallback(() => {
    const options = new TsDataListOptions(
      {
        webMatColumns: ["Staff Name", "Email", "Applied On", "Staff Type", "Staff Rate", "Actions"],
        mobileMatColumns: ["Staff Name", "Email", "Applied On", "Staff Type", "Staff Rate", "Actions"],
      },
      ENV.API_URL + "requirement/" + id + "/application?status=pending",
      setList,
      ApiService,
      "get"
    );
    let tableWrapperObj = new TsDataListWrapperClass(options);
    setList({ table: tableWrapperObj });
  }, [id]);


  useEffect(() => {
    init();
  }, [init, isAddOpen]);

  const openRejectApplication = useCallback((applicationId: any) => {
    setApplicationId(applicationId);
    setIsRejectOpen(true);
  }, []);

  const cancelRejectApplication = useCallback(() => {
    setIsRejectOpen(false);
  }, []);

  const confirmRejectApplication = useCallback(() => {
    setIsRejectOpen(false);
    init();
  }, [init]);

  const pendingListColumn:TableColumnsType=useMemo(()=>{
    return [
        {
            title: 'Staff Name',
            key: 'hcp_details',
            dataIndex: 'hcp_details',
            width:200,
            render: (hcp_details: any) => {
              return(
                  <div>
                    {(hcp_details?.first_name + hcp_details?.last_name)?.length > 20 ?
                      <Tooltip title={hcp_details?.first_name + " " + hcp_details?.last_name}>
                        <div className={'ellipses-for-table-data'}>
                          {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                        </div>
                      </Tooltip>:
                        <div>
                          {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                        </div>
                    }
                  </div>
              );
            }
        },
        {
            title: 'Email',
            key: 'hcp_details',
            dataIndex: 'hcp_details',
            width:200,
            render: (item: any) => {
              return (
                  <div className={'text-lowercase'}>
                    {(item?.email)?.length > 20 ?
                        <Tooltip title={item?.email}>
                          <div className={'ellipses-for-table-data'}>
                            {item?.email || 'N/A'}
                          </div>
                        </Tooltip> :
                        <div>
                          {item?.email || 'N/A'}
                        </div>
                    }
                  </div>
              )
            }
        },
      {
        title: 'Applied On',
        width:120,
        render:
            (created_at: any) => {
              return moment(created_at).format("MM-DD-YYYY") || 'N/A';
            }
      }
      ,
      {
        title: 'Staff Type',
        key: 'hcp_details',
        dataIndex: 'hcp_details',
        width:200,
        render:(item:any)=>{
          return (
              <div>
                {(item?.hcp_type)?.length > 20 ?
                    <Tooltip title={item?.hcp_type}>
                      <div className={'ellipses-for-table-data'}>
                        {item?.hcp_type || 'N/A'}
                      </div>
                    </Tooltip> :
                    <div>
                      {item?.hcp_type || 'N/A'}
                    </div>
                }
              </div>
          )
        }
      },
      {
        title: 'Staff Rate',
        key: 'hcp_details',
        dataIndex: 'hcp_details',
        width:100,
        render:
            (hcp_details: any) => {
              return hcp_details?.contract_details?.rate_per_hour || 'N/A';
            }
      },
      {
        title: 'Actions',
        width:100,
        render: (actions: any, record: any) => {
          return (
              <div>
                <Tooltip title={`Reject ${record["hcp_details"]?.first_name} ${record["hcp_details"]?.last_name} Application`}>
                    <IconButton onClick={() => openRejectApplication(record["_id"])} disabled={status === "cancelled"}>
                    <CancelIcon className="delete-icon" />
                    </IconButton>
                </Tooltip>
                </div>
            );
            }
        }
    ]
  },[openRejectApplication,status])

  return (
    <div className="pending-shift-applications-hcps-list">
      <DialogComponent open={isRejectOpen} cancel={cancelRejectApplication}>
        <RejectHcpApplicationComponent cancel={cancelRejectApplication} confirm={confirmRejectApplication} requirementId={id} applicationId={applicationId} />
      </DialogComponent>


      {/*{list && list.table && (*/}
      {/*  <>*/}
      {/*    <TableContainer component={Paper} className={"table-responsive"}>*/}
      {/*      {list && list.table?._isDataLoading && (*/}
      {/*        <div className="">*/}
      {/*          <LoaderComponent position="block" />*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*      <Table stickyHeader className="mat-table table shifts-requirment-pending-list-table">*/}
      {/*        <TableHead className={"mat-thead"}>*/}
      {/*          <TableRow className={"mat-tr"}>*/}
      {/*            {list?.table.matColumns.map((column: any, columnIndex: any) => (*/}
      {/*              <TableCell className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"} key={"header-col-" + columnIndex}>*/}
      {/*                {column}*/}
      {/*              </TableCell>*/}
      {/*            ))}*/}
      {/*          </TableRow>*/}
      {/*        </TableHead>*/}

      {/*        <TableBody className={"mat-tbody"}>*/}
      {/*          {list.table.canShowNoData() && <NoDataCardComponent tableCellCount={list.table.matColumns.length} />}*/}
      {/*          {list?.table.data.map((row: any, rowIndex: any) => {*/}

      {/*          const hcpRate = row["hcp_details"]["contract_details"]?.rate_per_hour*/}
      {/*            return (*/}
      {/*              <TableRow className="mat-tr" role="checkbox" tabIndex={-1} key={"row-"}>*/}
      {/*                <TableCell className="mat-td mat-td-hcp-name">*/}
      {/*                  {row["hcp_details"]?.first_name}&nbsp;{row["hcp_details"]?.last_name}*/}
      {/*                </TableCell>*/}
      {/*                <TableCell className="mat-td mat-td-hcp-email">{row["hcp_details"]?.email}</TableCell>*/}
      {/*                <TableCell className="mat-td mat-td-created-at">{moment(row["created_at"]).format("MM-DD-YYYY")}</TableCell>*/}
      {/*                <TableCell className="mat-td mat-td-hcp-type">{row["hcp_details"]?.hcp_type}</TableCell>*/}
      {/*                <TableCell className="mat-td mat-td-hcp-rate">{hcpRate ? hcpRate : (hcpRate === "" ? "NA" : hcpRate)  }</TableCell>*/}
      {/*                <TableCell className="mat-td mat-td-sticky mat-td-actions">*/}
      {/*                  <div>*/}
      {/*                    <Tooltip title={`Reject ${row["hcp_details"]?.first_name} ${row["hcp_details"]?.last_name} Application`}>*/}
      {/*                      <IconButton onClick={() => openRejectApplication(row["_id"])} disabled={status === "cancelled"}>*/}
      {/*                        <CancelIcon className="delete-icon" />*/}
      {/*                      </IconButton>*/}
      {/*                    </Tooltip>*/}
      {/*                  </div>*/}
      {/*                </TableCell>*/}
      {/*              </TableRow>*/}
      {/*            );*/}
      {/*          })}*/}
      {/*        </TableBody>*/}
      {/*      </Table>*/}
      {/*    </TableContainer>*/}
      {/*  </>*/}
      {/*)}*/}
      <div>
        <AntTableComponent
          data={list?.table?.data}
            extraPayload={list?.table?.data}
            columns={pendingListColumn}
            noDataText={'No Pending Staff Found.'}
        />
      </div>
    </div>
  );
};

export default PendingHcpApplicationComponent;
