import React, {PropsWithChildren} from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DialogContent} from "@material-ui/core";

export interface RemoveHCPComponentProps {
    confirmationText: any;
    cancel: any;
    confirm: any;
    notext: string;
    yestext: string;
    isConfirm: boolean;
    headerText?: any;
}

const ConfirmationDialogAction = (props: PropsWithChildren<RemoveHCPComponentProps>) => {
    return (
        <div>
            {/*<div>*/}
            {/*    {props?.headerText}*/}
            {/*</div>*/}
            <DialogTitle id="alert-dialog-slide-title">
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    fontWeight: 500,
                    fontSize: '20px',
                    lineHeight: "22px",
                    color: "#212B36",
                    fontStyle: "normal",
                }}>
                    <div>{props?.headerText}</div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{
                    maxWidth: "299px",
                    display: "flex",
                    justifyContent: "center",
                    color: '#6D6D6D',
                    margin: "auto",
                    fontWeight: 400,
                    fontSize: "14px",
                    textAlign: "center"
                }}>{props?.confirmationText}</div>
            </DialogContent>
            <DialogActions className={"pdd-20"}>
                <Button style={{width: "137px"}} onClick={props?.cancel} variant={"outlined"} color={"primary"}
                        className="pdd-left-25 pdd-right-25">
                    {props?.notext || "No, Cancel"}
                </Button>
                <Button style={{width: "137px"}} onClick={props?.confirm} variant={"contained"} color={"primary"}
                        disabled={props?.isConfirm}
                        className={props?.isConfirm ? "has-loading-spinner" : ""}>
                    {props?.yestext || "Yes, Confirm"}
                </Button>
            </DialogActions>;
        </div>
    )
        ;
};
export default ConfirmationDialogAction;