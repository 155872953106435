import React, { useRef } from "react";
import { Button, Typography } from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import "./FileUploadComponent.scss";

export interface FileUploadComponentProps {
    allowedTypes: string;
    maxSizeMB?: number;
    onFileSelected: (files: File[]) => void,
}

const FileUploadComponent = (props: FileUploadComponentProps) => {
    const { allowedTypes, maxSizeMB, onFileSelected } = props;
    const inputFileRef = useRef<HTMLInputElement | null>(null);

    const handleFileInputSelect = (e: any) => {
        const files = e.target.files; // FileList object.
        filesSelected(files);
        e.target.value = null;
    }

    const filesSelected = (files: any[]) => {
        files = Array.from(files);
        onFileSelected(files);
    }


    return (
        <div className={"file-upload-component"}>
            <div className={"file-image-wrapper"}>
                <div
                    className={"file-preview"}
                >
                    <CloudUploadOutlinedIcon />
                </div>
            </div>
            <div className={"file-upload-btn-wrapper"}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => inputFileRef.current?.click()}
                    style={{ marginRight: "8px" }}
                >
                    Upload
                </Button>
                <input
                    type="file"
                    ref={inputFileRef}
                    style={{ display: "none" }}
                    accept={allowedTypes}
                    onChange={handleFileInputSelect}
                />
                <Typography
                    variant="caption"
                    display="block"
                    style={{ marginTop: "8px", color: "#888" }}
                >
                    File type: {allowedTypes}
                    <br />
                    {maxSizeMB && `Max file size: ${maxSizeMB}MB`}
                </Typography>
            </div>
        </div>
    );
};

export default FileUploadComponent;
