import {Field, Form, Formik, FormikHelpers} from "formik";
import {Button, InputAdornment, Paper, TextField} from "@material-ui/core";
import {showDropDownBelowField} from "../../../../../constants/data/styles";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment/moment";
import {ApiService, CommonService} from "../../../../../helpers";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import React, {useCallback, useEffect, useState} from "react";
import DatePickers from "react-multi-date-picker";
import "./HomeHealthApprovedShiftCreation.scss"
import {ENV} from "../../../../../constants";
import {useHistory} from "react-router-dom";
import {home_shift_type, patientsGender} from "../../../../../constants/data";
import {DatePicker} from "formik-material-ui-pickers";
// import PhoneInputComponent from "../../../../../components/phoneInput/PhoneInputComponent";
import FilePickerComponent from "../../../../../components/file-picker/FilePickerComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {format} from "date-fns";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../../store/reducers";

interface HomeHealthProps {
    shiftTimings?: any
    facilityId?: any
}

const HomeApprovedShiftInitialState = {
    first_name: '',
    middle_name: '',
    last_name: '',
    gender_preferences: '',
    dob: null,
    patient_mrn: '',
    patient_street: '',
    patient_apt: '',
    patient_city: '',
    patient_state: '',
    patient_zip_code: '',
    patient_phone_number: '',
    patient_email_address: '',
    hcp_id: '',
    shift_dates: [],
    shift_start_time: '',
    shift_rate: '',
    latitude: 0,
    longitude: 0
};


const HomeHealthApprovedShiftCreationComponent = (props: HomeHealthProps) => {
    const {facilityId} = props;
    const [value, setValue] = useState<any>(null);
    const {isLoading,regions} = useSelector((state:StateParams) => state.meta);
    const [approvedHCPs, setApprovedHCPs] = useState([]);
    const history = useHistory();
    // const [state, setState] = useState<any>([]);
    const [timesheetFile, setTimesheetFile] = React.useState<any>([]);
    const [latitude, setLatitude] = useState<any>();
    const [longitude, setLongitude] = useState<any>();
    const [requestedBy, setRequestedBy] = useState<any>("");
    const [shiftDate, setShiftDate] = useState<any>([]);

    // const getState = useCallback(() => {
    //     CommonService._api
    //         .get(ENV.API_URL + "states")
    //         .then((resp) => {
    //             setState(resp.data || []);
    //             // setRegIsLoading(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);
    //
    // useEffect(() => {
    //     getState();
    // }, [getState]);


    // function handleDatePicker(value: any) {
    //     setValue(value);
    // }

    const handleDatePicker = useCallback((dates: any) => {
        // Ensure dates are in ISO 8601 format
        setValue(dates);
        const formattedDates = Array.isArray(dates)
            ? dates.map((date) => format(new Date(date), 'yyyy-MM-dd'))
            : format(new Date(dates), 'yyyy-MM-dd');
        console.log('Formatted Dates:', formattedDates);
        setShiftDate(formattedDates)
        // Handle the formatted dates (e.g., set state or pass to form)
    }, []);

    const onHomeAdd = useCallback((data: any, {setSubmitting, resetForm}: FormikHelpers<any>) => {
        console.log(data)

        if (!facilityId) {
            CommonService.showToast("Please select Facility");
            setSubmitting(false);
            return;
        }

        // let shift_dates = value?.map((item: any) => {
        //     let mm = item?.month?.number;
        //     let dd = item?.day;
        //     let yyyy = item?.year;
        //
        //     let shift_date = moment(`${yyyy}-${mm}-${dd}`).format("YYYY-MM-DD");
        //     return shift_date;
        // });

        const formData = new FormData();

        timesheetFile?.forEach((file: any, index: number) => {
            // Append each file with the same key ('image')
            formData.append(`documents[${index}]`, file);
        });

        shiftDate?.forEach((date: any, index: number) => {
            // Append each file with the same key ('image')
            formData.append(`shift_dates[${index}]`, moment(date).format("YYYY-MM-DD"));
        });


        formData.append("mode", "multiple");


        formData.append("facility_id", facilityId || "");
        formData.append("hcp_id", requestedBy.value || "");
        formData.append("hcp_count", "1");
        formData.append("requested_by", data.requested_by || "facility");
        // formData.append("shift_details", data.shift_details || "");
        // @ts-ignore
        formData.append("end_time", CommonService.convertHoursToMinutes(data.shift_start_time));
        // @ts-ignore
        formData.append("start_time", 0);
        // @ts-ignore
        // formData.append("end_time", CommonService.convertHoursToMinutes(data.shift_end_time));
        formData.append("shift_rate", data.shift_rate);
        // @ts-ignore


        formData.append("patient_details[first_name]", data.first_name || "");
        formData.append("patient_details[last_name]", data.last_name || "");
        formData.append("patient_details[middle_name]", data.middle_name || "");
        formData.append("patient_details[gender]", data.patients_gender || "");
        formData.append("patient_details[dob]", `${moment(data?.dob).format("YYYY-MM-DD")}`);
        formData.append("patient_details[mrn]", data.patient_mrn || "");
        formData.append("patient_details[contact_number]", "+1" + data.patient_phone_number || "");
        formData.append("patient_details[email]", data.patient_email_address || "");
        formData.append("home_requirements[gender_pref]", data.gender_preferences || "");
        formData.append("patient_details[address][street]", data.patient_street || "");
        formData.append("patient_details[address][apt]", data.patient_apt || "");
        formData.append("patient_details[address][city]", data.patient_city || "");
        formData.append("patient_details[address][state]", data.patient_state || "");
        formData.append("patient_details[address][zip_code]", data.patient_zip_code || "");
        formData.append("shift_type", data.shift_type || "");
        formData.append("patient_details.coordinates[0]", data.longitude || "");
        formData.append("patient_details.coordinates[1]", data.latitude || "");


        formData.append("type", "home");
        console.log(formData);

        ApiService.upload(ENV.API_URL + "requirement/home/preApprovedShifts", formData)
            .then((res) => {
                resetForm();
                setValue(null);
                CommonService.showToast(res.message || "Success", "success");
                history.push("/approvedShifts/list");
            })
            .catch((err) => {
                // CommonService.showToast(err?.error || "Error", "error");
                setSubmitting(false);
                if (typeof err?.error === 'string') {
                    CommonService.showToast(err?.error || "Error", "error")
                } else {
                    const formattedError = Object.entries(err?.error);
                    formattedError.map((err: any) => {
                        let hcpName = err[0];
                        let hcpDate = moment(err[1]).format("MM-DD-YYYY");
                        return CommonService.showToast(`${hcpName} already approved on ${hcpDate}` || "Error", "error");
                    });
                }


            });

    }, [facilityId, timesheetFile, shiftDate, requestedBy.value, history]);

    const getAllApprovedHcps = useCallback(() => {
        let payload: any = {
            is_approved: true,
        };
        CommonService._api
            .post(ENV.API_URL + "hcp/lite", payload)
            .then((resp) => {
                const approvedHcps = resp?.data?.map((item: any) => ({
                    label: `${item?.first_name} ${item?.last_name}`,
                    value: item?._id,
                }));
                setApprovedHCPs(approvedHcps || []);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getAllApprovedHcps()
    }, [getAllApprovedHcps]);


    const removeFile = (index: number) => {
        // Create a new array excluding the file at the given index
        const newTimesheetFile = [...timesheetFile];
        newTimesheetFile.splice(index, 1);
        // Update the timesheetFile state with the new array
        setTimesheetFile(newTimesheetFile);
    };


    async function getLatLong(street: any, city: any, state: any, zipcode: any) {
        // const address = `${street}, ${city}, ${state}, ${zipcode}, USA`;
        const url = `https://nominatim.openstreetmap.org/search?postalcode=${zipcode}&countrycodes=us&format=json&limit=1`;
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.length > 0) {
                const location = data[0];
                return {
                    latitude: location.lat,
                    longitude: location.lon,
                };
            } else {
                throw new Error('Geocoding failed: No results found');
            }
        } catch (error) {
            console.error('Error fetching geolocation:', error);
            return null;
        }
    }

    return (
        <>
            <div className={"home-health-approved-shift-component"}>
                <Formik initialValues={HomeApprovedShiftInitialState}
                        onSubmit={onHomeAdd}>
                    {({isSubmitting, isValid, resetForm, handleChange, setFieldValue, values, touched, errors}) => {
                        return (
                            <Form className={"form-holder"} id="shift-add-approved-form">
                                <div>
                                    {/*patient details wrapper*/}
                                    <div className={"mrg-top-30"}>
                                        <div className={'form-header-text'} onClick={() => {
                                            console.log(errors)
                                        }}>
                                            Patients Details
                                        </div>
                                        <div>
                                            <div className="shift-row mrg-top-20">
                                                <Field variant="outlined"
                                                       name="first_name"
                                                       type={"text"}
                                                       component={TextField}
                                                       required
                                                       InputLabelProps={{shrink: true}}
                                                       label="First Name" fullWidth autoComplete="off"
                                                       id="patients_details_first_name"
                                                       onChange={(e: any) => {
                                                           setFieldValue("first_name", e.target.value);
                                                       }}
                                                />
                                                <Field disabled={false} variant="outlined"
                                                       name="middle_name"
                                                       type={"text"}
                                                       component={TextField}
                                                       InputLabelProps={{shrink: true}}
                                                       label="Middle Name" fullWidth autoComplete="off"
                                                       id="patients_details_middle_name"
                                                       onChange={(e: any) => {
                                                           setFieldValue("middle_name", e.target.value);
                                                       }}
                                                />
                                                <Field disabled={false} variant="outlined"
                                                       name="last_name"
                                                       type={"text"}
                                                       required
                                                       InputLabelProps={{shrink: true}}
                                                       component={TextField}
                                                       label="Last Name" fullWidth autoComplete="off"
                                                       id="patients_details_last_name"
                                                       onChange={(e: any) => {
                                                           setFieldValue("last_name", e.target.value);
                                                       }}
                                                />
                                            </div>
                                            <div className="shift-row mrg-top-20">
                                                <Field disabled={false}
                                                       SelectProps={showDropDownBelowField}
                                                       id="input_shift_requirement_gender_preferences"
                                                       variant="outlined"
                                                       select
                                                       InputLabelProps={{shrink: true}}
                                                       name="gender_preferences"
                                                       component={TextField}
                                                       onChange={(e: any) => {
                                                           const genderPreferences = e.target.value;
                                                           setFieldValue("gender_preferences", genderPreferences);
                                                       }}
                                                       label="Gender Preferences"
                                                       fullWidth
                                                >
                                                    <MenuItem value="">Select Gender
                                                        Preference </MenuItem>
                                                    {patientsGender &&
                                                        patientsGender.map((item: any, index: any) => (
                                                            <MenuItem value={item.code} key={index}>
                                                                {item?.title}
                                                            </MenuItem>
                                                        ))}
                                                </Field>
                                                <Field
                                                    variant="inline"
                                                    orientation="landscape"
                                                    openTo="date"
                                                    disabled={false}
                                                    format="MM/dd/yyyy"
                                                    views={["year", "month", "date"]}
                                                    inputVariant="outlined"
                                                    component={DatePicker}
                                                    placeholder="MM/DD/YYYY"
                                                    fullWidth
                                                    required
                                                    autoComplete="off"
                                                    InputLabelProps={{shrink: true}}
                                                    label="DOB"
                                                    name="dob"
                                                />
                                                <Field variant="outlined" name="patient_mrn"
                                                       type={"text"}
                                                       component={TextField}
                                                       disabled={false}
                                                       InputLabelProps={{shrink: true}}
                                                       label="MRN" fullWidth autoComplete=" off"
                                                       id=" patients_details_last_name"
                                                       onChange={(e: any) => {
                                                           setFieldValue("patient_mrn", e.target.value);
                                                       }}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    {/*patient address details*/}
                                    <div className={"mrg-top-30"}>
                                        <div className={'form-header-text'}>
                                            Patient Address Details
                                        </div>
                                        <div>
                                            <div className="shift-row mrg-top-20">
                                                <Field disabled={false} variant="outlined"
                                                       name="patient_street"
                                                       type={"text"}
                                                       required
                                                       InputLabelProps={{shrink: true}}
                                                       component={TextField}
                                                       label="Street" fullWidth autoComplete="off"
                                                       id="patients_details_street_name"
                                                       onChange={(e: any) => {
                                                           setFieldValue("patient_street", e.target.value);
                                                       }}
                                                />
                                                <Field disabled={false} variant="outlined"
                                                       name="patient_apt"
                                                       type={"text"}
                                                       InputLabelProps={{shrink: true}}
                                                       component={TextField}
                                                       label="Apt/Suite" fullWidth autoComplete="off"
                                                       id="patients_details_apt_name"
                                                       onChange={(e: any) => {
                                                           setFieldValue("patient_apt", e.target.value);
                                                       }}
                                                />
                                                <Field disabled={false} variant="outlined"
                                                       name="patient_city"
                                                       type={"text"}
                                                       InputLabelProps={{shrink: true}}
                                                       component={TextField}
                                                       required
                                                       label="City" fullWidth autoComplete="off"
                                                       id="patients_details_city_name"
                                                       onChange={(e: any) => {
                                                           setFieldValue("patient_city", e.target.value);
                                                       }}
                                                />
                                            </div>
                                            <div className="shift-row  mrg-top-20">
                                                <Field SelectProps={showDropDownBelowField}
                                                       variant="outlined"
                                                       disabled={isLoading}
                                                       name="patient_state"
                                                       id="patients_details_state_name"
                                                       select
                                                       required
                                                       InputLabelProps={{shrink: true}}
                                                       type={"text"} component={TextField}
                                                       label="State"
                                                       fullWidth
                                                       autoComplete="off"
                                                       onChange={(e: any) => {
                                                           setFieldValue("patient_state", e.target.value);
                                                       }}
                                                >
                                                    {regions &&
                                                        regions.map((item: any, index: any) => (
                                                            <MenuItem value={item.code}
                                                                      key={"regions_" + index}
                                                                      id={"menu_hcp_add_region" + item.code}>
                                                                {item.code}
                                                            </MenuItem>
                                                        ))}
                                                </Field>
                                                <Field
                                                    inputProps={{
                                                        maxLength: 6,
                                                    }}
                                                    disabled={false}
                                                    variant="outlined"
                                                    name="patient_zip_code"
                                                    type={"text"}
                                                    component={TextField}
                                                    label="Zip Code*"
                                                    fullWidth
                                                    InputLabelProps={{shrink: true}}
                                                    autoComplete="off"
                                                    id="input_facility_add_address_zip_code"
                                                    onChange={(e: any) => {
                                                        setFieldValue("patient_zip_code", e.target.value);
                                                    }}
                                                />
                                                <Button variant={'contained'} color={"primary"}
                                                    //@ts-ignore
                                                        disabled={!values?.patient_zip_code}
                                                        onClick={() => {
                                                            //@ts-ignore
                                                            getLatLong('', '', '', values?.patient_zip_code)
                                                                .then((coords: any) => {
                                                                    setFieldValue("latitude", coords.latitude);
                                                                    setFieldValue("longitude", coords.longitude);
                                                                    setLatitude(coords.latitude);
                                                                    setLongitude(coords.longitude);
                                                                    CommonService.showToast("Latitude and Longitude Fetched", "success")
                                                                    // const googleMapsUrl = `https://www.google.com/maps?q=${coords.latitude},${coords.longitude}`;
                                                                    // window.open(googleMapsUrl, '_blank');
                                                                })
                                                                .catch((error) => CommonService.showToast("Not Able to fetch Latitude and Longitude due to geocode errors", "error"));
                                                        }}>
                                                    Check
                                                </Button>
                                                <Button variant={'contained'} color={"primary"}
                                                    //@ts-ignore
                                                        disabled={!values.latitude || !values.longitude}
                                                        onClick={() => {
                                                            //@ts-ignore
                                                            getLatLong('', '', '', values?.patient_zip_code)
                                                                .then((coords: any) => {
                                                                    //@ts-ignore
                                                                    if (values.latitude && values.longitude) {
                                                                        //@ts-ignore
                                                                        const googleMapsUrl = `https://www.google.com/maps?q=${values.latitude},${values.longitude}`;
                                                                        window.open(googleMapsUrl, '_blank');
                                                                    }
                                                                })
                                                                .catch((error) => console.error(error));
                                                        }}>
                                                    Map
                                                </Button>
                                            </div>
                                            <div className="shift-row mrg-top-20">

                                                {/*{errors.latitude && <div>{errors.latitude}</div>}*/}
                                                <div style={{position: 'relative', flex: 1}}>
                                                    <Field disabled={false} variant="outlined"
                                                           name="latitude"
                                                           required
                                                           value={latitude}
                                                           InputLabelProps={{shrink: true}}
                                                           type={"number"}
                                                           component={TextField}
                                                           label="Latitude" fullWidth autoComplete="off"
                                                           id="patients_details_street_name"
                                                           onChange={(e: any) => {
                                                               setLatitude(e.target.value)
                                                               setFieldValue("latitude", e.target.value);
                                                           }}

                                                    />

                                                    <div style={{
                                                        color: 'red',
                                                        marginTop: '0.25rem',
                                                        fontSize: '0.875rem',
                                                        minHeight: "20px",

                                                    }}>
                                                        {errors?.latitude || ""}
                                                    </div>

                                                </div>

                                                <div style={{position: 'relative', flex: 1}}>
                                                    <Field disabled={false} variant="outlined" required
                                                           name="longitude"
                                                           type={"number"}
                                                           value={longitude}
                                                           InputLabelProps={{shrink: true}}
                                                           component={TextField}
                                                           label="Longitude" fullWidth autoComplete="off"
                                                           id="patients_details_apt_name"
                                                           onChange={(e: any) => {
                                                               setLongitude(e.target.value)
                                                               setFieldValue("longitude", e.target.value);
                                                           }}
                                                    />
                                                    <div style={{
                                                        color: 'red',
                                                        marginTop: '0.25rem',
                                                        fontSize: '0.875rem',
                                                        minHeight: "20px",
                                                    }}>
                                                        {errors?.longitude || " "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*patient contact details*/}
                                    <div className={"mrg-top-30"}>
                                        <div className={'form-header-text'}>
                                            Patient Contact Details
                                        </div>
                                        <div>
                                            <div className="shift-row width-minor mrg-top-20">
                                                <div style={{flex: 1}}>
                                                    {/*<Field disabled={false}*/}
                                                    {/*       name={"patient_phone_number"}*/}
                                                    {/*       variant="outlined"*/}
                                                    {/*       inputProps={{maxLength: 10}}*/}
                                                    {/*       id="phone_number_patient"*/}
                                                    {/*       InputLabelProps={{shrink: true}}*/}
                                                    {/*       style={{font: "inherit"}}>*/}
                                                    {/*    {(field: FieldProps) => {*/}
                                                    {/*        return <PhoneInputComponent field={field}*/}
                                                    {/*                                    placeholder={"Phone Number*"}/>;*/}
                                                    {/*    }}*/}
                                                    {/*</Field>*/}
                                                    <Field
                                                        inputProps={{ maxLength: 10,
                                                            onInput: (e:any) => {
                                                                if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10);
                                                            },
                                                        }}
                                                        className="phone_number"
                                                        variant="outlined"
                                                        name={"patient_phone_number"}
                                                        type={"text"}
                                                        component={TextField}
                                                        label="Phone Number"
                                                        fullWidth
                                                        required
                                                        autoComplete="off"
                                                        id="phone_number_patient"
                                                        InputLabelProps={{shrink: true}}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    +1
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                                <div style={{flex: 1}}>
                                                    <Field disabled={false} variant="outlined"
                                                           name="patient_email_address"
                                                           type={"text"}
                                                           component={TextField}
                                                           label="Email Address" fullWidth
                                                           autoComplete="off"
                                                           InputLabelProps={{shrink: true}}
                                                           id="patients_details_emial_address_name"
                                                           onChange={(e: any) => {
                                                               setFieldValue("patient_email_address", e.target.value);
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"mrg-top-30"}>
                                        <div className={'form-header-text'}>
                                            Staff Details
                                        </div>
                                    </div>

                                    <div style={{width: '100%', marginBottom: "20px", marginTop: "20px"}}
                                         className={"search-input-box"}>
                                        <Autocomplete

                                            disableClearable
                                            PaperComponent={({children}) => <Paper
                                                style={{color: "#1e1e1e"}}>{children}</Paper>}
                                            value={requestedBy}
                                            options={approvedHCPs}
                                            getOptionLabel={(option: any) => option.label}
                                            placeholder={"Time Sheet"}
                                            id="input_select_status"
                                            // className="mrg-top-10"
                                            onChange={($event, value) => {
                                                setRequestedBy(value);
                                                console.log(value?.value);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} id="select_requested_by" variant="outlined"
                                                           placeholder={"Staff*"} required/>
                                            )}
                                            disableCloseOnSelect
                                        />
                                    </div>

                                    <div className="d-flex shift-third-row shift-row mrg-top-30 ">
                                        {/*<div className="shift-calender">*/}
                                        {/*    <Field*/}
                                        {/*        SelectProps={showDropDownBelowField}*/}
                                        {/*        id="input_shift_requirement_shift_timings"*/}
                                        {/*        variant="outlined"*/}
                                        {/*        select*/}
                                        {/*        required*/}
                                        {/*        name="hcp_id"*/}
                                        {/*        component={TextField}*/}
                                        {/*        label="Staff"*/}
                                        {/*        fullWidth*/}
                                        {/*        onChange={(e: any) => {*/}
                                        {/*            setFieldValue("hcp_id", e.target.value);*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        /!*<MenuItem value="">Select Shift Timing</MenuItem>*!/*/}
                                        {/*        {approvedHCPs.length > 0 &&*/}
                                        {/*            approvedHCPs?.map((item: any, index: any) => {*/}
                                        {/*                return (*/}
                                        {/*                    <MenuItem value={item?.code} key={index}>*/}
                                        {/*                        {item?.name}*/}
                                        {/*                    </MenuItem>*/}
                                        {/*                );*/}
                                        {/*            })}*/}
                                        {/*    </Field>*/}
                                        {/*</div>*/}

                                        <div className="shift-calender">
                                            <Field
                                                required
                                                inputClass="custom-input"
                                                className="rmdp-mobile"
                                                plugins={[<DatePanel eachDaysInRange/>]}
                                                format="MM/DD/YYYY"
                                                // range={mode === "range" ? true : false}
                                                multiple={true}
                                                onChange={handleDatePicker}
                                                value={value}
                                                variant="inline"
                                                inputVariant="outlined"
                                                placeholder={"Date(s)*"}
                                                id="input_shift_requirement_shift_datepicker"
                                                name="shift_dates"
                                                InputLabelProps={{shrink: true,}}
                                                component={DatePickers}
                                            />
                                        </div>
                                        {/*<DateRangeOutlined*/}
                                        {/*    style={handleShowHideCalender()}*/}
                                        {/*    className="date-icon"*/}
                                        {/*    fontSize="large"*/}
                                        {/*    color="action"*/}
                                        {/*/>*/}
                                        <div className="shift-calender">
                                            <Field disabled={false} fullWidth variant="outlined" type="time"
                                                   component={TextField}
                                                   required
                                                   name="shift_start_time" label="Visit Time"
                                                   InputLabelProps={{shrink: true}}
                                                   id="input_shift_add_shift_start_time"
                                                   onChange={(e: any) => {
                                                       setFieldValue("shift_start_time", e.target.value);
                                                   }}
                                            />
                                        </div>
                                        <div className="shift-calender">

                                            <Field disabled={false}
                                                   variant="outlined"
                                                   name={'shift_rate'}
                                                   type={"number"}
                                                   required
                                                   component={TextField}
                                                   placeholder={"Enter Value"}
                                                   label="Visit Rate"
                                                   InputLabelProps={{shrink: true}}
                                                   fullWidth autoComplete="off"
                                                   id="patients_details_open_positions"
                                                   InputProps={{
                                                       startAdornment: <InputAdornment
                                                           position="start">$</InputAdornment>,
                                                   }}
                                                   onChange={(e: any) => {
                                                       setFieldValue("shift_rate", e?.target.value);
                                                   }}
                                            />

                                        </div>

                                    </div>

                                    <div className="d-flex shift-third-row shift-row mrg-top-30 ">

                                        <div className="shift-calender">
                                            <Field
                                                SelectProps={showDropDownBelowField}
                                                id="input_shift_requirement_shift_type"
                                                variant="outlined"
                                                disabled={false}
                                                select
                                                name="shift_type"
                                                InputLabelProps={{shrink: true}}
                                                component={TextField}
                                                onChange={(e: any) => {
                                                    const shiftType = e.target.value;
                                                    console.log(e.target.value);
                                                    setFieldValue("shift_type", shiftType);
                                                }}
                                                label="Visit Type"
                                                fullWidth
                                                required
                                            >
                                                <MenuItem value="">Select Shift Type</MenuItem>
                                                {home_shift_type &&
                                                    home_shift_type.map((item: any, index: any) => (
                                                        <MenuItem value={item.code} key={index}>
                                                            {item?.title}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </div>
                                        <div className="shift-calender">

                                        </div>
                                        <div className="shift-calender">
                                        </div>
                                        <div className="shift-calender">
                                        </div>
                                    </div>


                                    <div
                                        className={'document-upload-wrapper d-flex justify-content-space-between mrg-top-20'}>
                                        <div>
                                            <div className={'form-header-text'}>
                                                Upload Documents
                                            </div>
                                            <div className={'file-upload-supported-text'}>
                                                Upload the relevant documents
                                            </div>
                                            <div className={'file-upload-supported-text'}>
                                                Supported formats : .pdf, .jpg, .jpeg, .png, .doc, .docx
                                            </div>
                                        </div>
                                        <div>
                                            <FilePickerComponent maxFileCount={3}
                                                                 height={'470px'}
                                                                 id={" sc_upload_btn"}
                                                                 btnText={" Select Files"}
                                                                 disabled={timesheetFile?.length === 3}
                                                                 multiple={true}
                                                                 showDropZone={false}
                                                                 onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                                                     if (acceptedFiles && acceptedFiles.length > 0) {
                                                                         const file = acceptedFiles[0];
                                                                         //   setTimesheetFile(file);
                                                                         setTimesheetFile([...timesheetFile, file]);
                                                                     }
                                                                 }}
                                                                 acceptedFileTypes={["pdf", "jpeg", "jpg", "png", "doc", "docx"]}
                                                                 uploadDescription={"(upload only one file)"}
                                                                 acceptedFilesText={"Only .pdf files are supported"}
                                            />
                                            <div
                                                className={'d-flex justify-content-end mrg-top-5 file-upload-supported-text'}>
                                                Max upload : 3
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                                        {timesheetFile && timesheetFile?.map((file: any, index: number) => {
                                            return (
                                                <>
                                                    <div
                                                        className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>
                                                        <div>  {file?.name}</div>
                                                        <div className={'cursor-pointer'}
                                                             onClick={() => {
                                                                 removeFile(index);
                                                             }}>
                                                            <DeleteIcon/>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <div className={'d-flex justify-content-center mrg-top-20'}>
                                        <Button
                                            type="submit"
                                            id="btn_add_shift_requirement_save"
                                            variant={"contained"}
                                            className={"normal"}
                                            color={"primary"}
                                            disabled={!values?.dob || !(values?.patient_zip_code?.length > 4)}
                                        >
                                            Create Approved Shift
                                        </Button>
                                    </div>
                                </div>

                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </>
    )
}

export default HomeHealthApprovedShiftCreationComponent
