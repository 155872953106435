import React, {useCallback, useEffect, useState, useMemo} from "react";
import {ApiService, CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import MenuComponent from "./MenuComponent";
import ViewStatusComponent from "../../../../../components/status-view-component/ViewStatusComponent";
import {TableColumnsType} from "antd";
import Tooltip from "@material-ui/core/Tooltip";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";
import {Button} from "@material-ui/core";
import DrawerComponent from "../../../../../components/drawer/DrawerComponent";
import GeneratedFilterDrawerComponent from "../generated-filters-drawer/GeneratedFilterDrawerComponent";
import {useSessionStorage} from "../../../../../components/useSessionStorage";

interface GeneratedInvoiceListTableProps {
    status?: any,
    value?: any,
    selectedFacilities?: any;
    startDate?: any;
    endDate?: any;
}

const GeneratedInvoiceListTable = (props: GeneratedInvoiceListTableProps) => {
    const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
    // const [status, setStatus] = useLocalStorage<any>("generatedInvoiceStatus", "");
    // const [dateRange, setDateRange] = useLocalStorage("generatedInvoiceDateRange", [null, null]);
    // const [startDate, setStartDate] = useLocalStorage("generatedTabStartDate", null);
    // const [endDate, setEndDate] = useLocalStorage("generatedTabEndDate", null);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    // const [selectedFacilities, setSelectedFacilities] = useLocalStorage<any[]>("selectedFacilitiesGenerated", []);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const [filterData, setFilterData] = useSessionStorage<any>("generatedInvoiceFilterData",{
        search: '',
        sort: {},
        limit: 10,
    })
    const [refreshkey,setRefreshKey]=useState<number>(0);

    useEffect(()=>{
        setFilterData(filterData)
    },[filterData,setFilterData])

    useEffect(() => {
            setRefreshKey(prevKey => prevKey + 1);
    }, [filterData]);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);


    const clearFilterValues = useCallback(() => {
        // setStatus("");
        // setSelectedFacilities([]);
        // setStartDate(null);
        // setEndDate(null);
        let updatedFilterData = {...filterData};
        delete updatedFilterData.facilities;
        delete updatedFilterData.start_date;
        delete updatedFilterData.end_date;
        delete updatedFilterData.status;
        setFilterData(updatedFilterData)
    }, [filterData,setFilterData]);

    const resetFilters = () => {
        clearFilterValues();
    };


    useEffect(() => {
        getFacilityData();
    }, [getFacilityData]);

    const generatedInvoiceListColumns: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Facility',
                width: 200,
                sorter: (a, b) => (a?.facility?.name ?? '').localeCompare(b?.facility?.name ?? ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.name?.length > 20 ?
                                <Tooltip title={item?.facility?.name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Customer No.',
                width: 180,
                sorter: (a, b) => (a?.facility?.uid ?? '').localeCompare(b?.facility?.uid ?? ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.facility?.uid?.length > 20 ?
                                <Tooltip title={item?.facility?.uid}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.facility?.uid || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.facility?.uid || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Invoice Number',
                width: 165,
                sorter: (a, b) => (a?.invoice_number ?? '').localeCompare(b?.invoice_number ?? ''),
                render: (item: any) => {
                    return (
                        <div>
                            {item?.invoice_number || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Invoice Date',
                width: 130,
                sorter: (a, b) => {
                    const dateA = a?.invoice_generated_date ? new Date(a?.invoice_generated_date).getTime() : new Date(a?.created_at).getTime();
                    const dateB = b?.invoice_generated_date ? new Date(b?.invoice_generated_date).getTime() : new Date(b?.created_at).getTime();
                    return dateA - dateB;
                },

                render: (item: any) => {
                    return (
                        <div>
                            {item?.invoice_generated_date &&
                                <>
                                    {CommonService.getUtcDate(item?.invoice_generated_date) || 'N/A'}
                                </>
                            }
                            {!item?.invoice_generated_date &&
                                <>
                                    {CommonService.getUtcDate(item?.created_at) || 'N/A'}
                                </>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Start Date',
                width: 120,
                sorter: (a, b) => new Date(a?.start_date).getTime() - new Date(b?.start_date).getTime(),
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.getUtcDate(item["start_date"]) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'End Date',
                width: 120,
                sorter: (a, b) => new Date(a?.end_date).getTime() - new Date(b?.end_date).getTime(),
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.getUtcDate(item["end_date"]) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Total Amount',
                width: 150,
                sorter: (a, b) => {
                    // Get total amount for both a and b, with proper handling for missing values
                    const totalA = (Number(a?.total_amount) || 0) + (Number(a?.finance_charge?.amount) || 0);
                    const totalB = (Number(b?.total_amount) || 0) + (Number(b?.finance_charge?.amount) || 0);

                    return totalA - totalB;
                },
                render: (item: any) => {
                    const totalAmount = Number(item["total_amount"] || 0) + (Number(item["finance_charge"]?.amount) ? Number(item["finance_charge"]?.amount) : 0);
                    return (
                        <div>
                            {item["type"] === "finance_invoice" &&
                                <div>
                                    {item["finance_charge"]?.amount ?
                                        Number(item["finance_charge"]?.amount)?.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) : "_"}
                                </div>
                            }
                            {item["type"] !== "finance_invoice" &&
                                <div
                                    className="mat-td mat-td-shift-type">{totalAmount ? totalAmount?.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }) : "$0.00"}</div>}
                        </div>
                    );
                },
            },
            {
                title: 'Status',
                width: 150,
                render: (item: any) => {
                    return (
                        <div className={`display-center ${item["status"]} pdd-right-20`}>
                            {item["status"] === "paid" &&
                                <ViewStatusComponent show={true} backgroundColor={"#BDE8CA"}
                                                     textColor={"#00534F"}>  {item["status"]}</ViewStatusComponent>}
                            {item["status"] === "unpaid" &&
                                <ViewStatusComponent show={true} backgroundColor={"#FFF7EA"}
                                                     textColor={"#DE561C"}>  {item["status"]}</ViewStatusComponent>}
                            {item["status"] === "cancelled" &&
                                <ViewStatusComponent show={true} backgroundColor={"#FFE2E9"}
                                                     textColor={"#DE1C4E"}>  {item["status"]}</ViewStatusComponent>}
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                width: 100,
                render: (item: any) => {
                    return (
                        <div className={'display-center'}>
                            <MenuComponent rowStatus={item["status"]} rowId={item["_id"]}
                                //invoiceList={item}
                                           filterData={filterData}
                                           setFilterData={setFilterData}/>
                        </div>
                    )
                }
            }

        ]
    }, [filterData,setFilterData])

    return (
        <>
            <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                <GeneratedFilterDrawerComponent
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    isFacilityListLoading={isFacilityListLoading}
                    // setSelectedFacilities={setSelectedFacilities}
                    facilityList={facilityList}
                    // selectedFacilities={selectedFacilities}
                    // status={status} setStatus={setStatus}
                    // dateRange={dateRange}
                    // startDate={startDate}
                    // setStartDate={setStartDate}
                    // endDate={endDate}
                    // setEndDate={setEndDate}
                    // setDateRange={setDateRange}
                    resetFilters={resetFilters}
                />
            </DrawerComponent>
            <div className="custom-border pdd-20  pdd-bottom-0">
                <div className={'d-flex align-items-center justify-content-space-between'}>
                    <h3>Invoices</h3>
                    <div>
                        <Button
                            variant={"outlined"} id={'add_facility_btn'}
                            color={"primary"}
                            onClick={() => {
                                setOpenFilterDrawer(true)
                            }}
                        >
                            &nbsp;&nbsp;Filters&nbsp;&nbsp;
                        </Button>
                    </div>
                </div>

                <div className={'mrg-top-20 generated-invoice-table-wrapper'}>
                    <AntTableComponent
                        url={ENV.API_URL + "invoice/list"}
                        method={'post'}
                        key={refreshkey}
                        extraPayload={filterData}
                        columns={generatedInvoiceListColumns}
                        noDataText={'No Generated Invoice Data Found.'}
                    />
                </div>
            </div>
        </>
    );
};
export default GeneratedInvoiceListTable;
