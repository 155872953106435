import React, { useState } from "react";
import "./AddFacilityBillDetailsComponent.scss";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { DatePicker } from "formik-material-ui-pickers";
import "./AddFacilityBillDetailsComponent.scss";
import { ApiService, CommonService } from "../../../../helpers";
import { ENV } from "../../../../constants";
import moment from "moment";
import { isHCPPaid as hcpPaidOptions} from "../../../../constants/data";
import { showDropDownBelowField } from "../../../../constants/data/styles";

interface AddFacilityBillDetailsComponentProps {
  cancel: () => void;
  confirm: () => void;
  selectedShifts: any[];
}

const paymentInitialState = {
  is_billed: "",
  invoice_date: null,
};

const paymentValidation = Yup.object({
  is_billed: Yup.mixed().required("Required").nullable(),
    invoice_date: Yup.date().nullable()
});

const AddFacilityBillDetailsComponent = (props: AddFacilityBillDetailsComponentProps) => {
  const [isFacilityPaid, setIsFacilityPaid] = useState<boolean>(false);
  const { selectedShifts, confirm, cancel } = props;

  const onAdd = (payment: any, { setSubmitting, setErrors, resetForm, setFieldValue }: FormikHelpers<any>) => {
    if(payment?.is_billed ){
      if(!payment?.invoice_date){
        setErrors({
          "invoice_date": "Required"
        })
        setSubmitting(false)
        return
      }
    }
    const payload = {
      shift_ids: selectedShifts,
      is_billed: payment?.is_billed,
      invoice_date: payment?.invoice_date ? moment(payment?.invoice_date).format("YYYY-MM-DD") : "",
    };

    ApiService.put(ENV.API_URL + "shift/facilityBillDetails", payload)
      .then((res: any) => {
        CommonService.showToast(res?.msg || "Success", "success");
        setSubmitting(false);
        confirm();
      })
      .catch((err) => {
        CommonService.showToast(err?.msg || err?.error || "Error", "error");
        setSubmitting(false);
      });
  };
  
  return (
    <div className="pdd-left-25 pdd-right-25 pdd-bottom-25">
      {/*<h2>Facility Billed Details</h2>*/}
      <div className="mrg-top-10">
        <Formik initialValues={paymentInitialState} validateOnChange={true} validationSchema={paymentValidation} onSubmit={onAdd}>
          {({ isSubmitting, handleSubmit, isValid, values, resetForm, setFieldValue }) => (
            <Form id="add-payment-form" className={"payment-name form-holder"}>
              <div className="add-input">
                <div className="facility-bill-details-container">
                  <Field
                    onChange={(e: any) => {
                      if (e.target.value === false) {
                        setFieldValue("invoice_date", null);
                      }
                      setIsFacilityPaid(e.target.value);
                      setFieldValue("is_billed", e.target.value);
                    }}
                    SelectProps={showDropDownBelowField}
                    select
                    variant="outlined"
                    fullWidth
                    component={TextField}
                    name="is_billed"
                    id="input_is_billed"
                    label="Billed/Invoiced"
                  >
                    <MenuItem value="">Select </MenuItem>
                    {hcpPaidOptions.map((item: any, index) => {
                      return (
                        <MenuItem value={item.value} key={index} id={"is_hcp_paid" + item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Field>

                  {isFacilityPaid && (
                    <Field
                      autoOk={true}
                      id="input_invoice_pay_date"
                      variant="inline"
                      orientation="landscape"
                      openTo="date"
                      format="MM/dd/yyyy"
                      views={["year", "month", "date"]}
                      inputVariant="outlined"
                      component={DatePicker}
                      placeholder="MM/DD/YYYY"
                      fullWidth
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      label="Invoice Date"
                      name="invoice_date"
                    />
                  )}
                </div>
              </div>

              <div className="mrg-top-40 text-align">
                <Button variant={"outlined"} id="cancel-employee-btn" size={"medium"} color={"primary"} onClick={() => cancel()}>
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant={"contained"}
                  type={"submit"}
                  size={"medium"}
                  color={"primary"}
                  className={isSubmitting ? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30" : "mrg-left-20 pdd-left-30 pdd-right-30"}
                >
                  {isSubmitting ? "Saving" : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddFacilityBillDetailsComponent;
