import { Button, FormControlLabel, Radio } from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import * as Yup from "yup";
import { ENV } from "../../../../constants";
// import { warningZone } from "../../../../constants/data";
import { ApiService } from "../../../../helpers";
import CommonService from "../../../../helpers/common-service";
import "./ShowShiftRateOnMobileComponent.scss";
interface ShowShiftRateOnMobileComponentProps {
	cancel: () => void;
	confirm: () => void;
	selectedShifts?: any[];
	singleShiftId?: string;
	shiftDetails?: any
	type: "multiple" | "single";
}


const ShowShiftRateOnMobileComponent = (props: ShowShiftRateOnMobileComponentProps) => {
	const { selectedShifts, confirm, cancel, type, singleShiftId ,shiftDetails} = props;

	const shiftRateInitialState = {
		is_shift_rate: String(shiftDetails?.is_shift_rate) ?? "",
		shift_rate: shiftDetails?.shift_rate ?? "",
		// hazard_rate: shiftDetails?.hazard_rate ?? "",
		// warning_type: shiftDetails?.warning_type ?? "",
	};

	const onlyNums =/^\d{1,3}(\.$|\.\d{1,3}$|$)/;
	const shiftFieldsValidation = Yup.object({
		is_shift_rate: Yup.boolean(),
		shift_rate: Yup.string().when("is_shift_rate", {
			is: true,
			then: Yup.string().required("Required")
		  }),
		hazard_rate: Yup.string(),
		warning_type: Yup.string(),

	});

	const onAdd = (data: any, { setSubmitting, setErrors, resetForm, setFieldValue }: FormikHelpers<any>) => {
		const { shift_rate, is_shift_rate } = data;

		let payload: any = {
			is_shift_rate: CommonService.str2bool(is_shift_rate),
			shift_rate,
			// hazard_rate: hazard_rate,
			// warning_type:warning_type
		};

		if (type === "multiple") {
			payload = {
			is_shift_rate: CommonService.str2bool(is_shift_rate),
			shift_rate,
			requirement_ids: selectedShifts,
			};
		}

		let apiEndPoint;
		if (type === "multiple") {
			apiEndPoint = ENV.API_URL + "requirement/edit";
		} else if (type === "single") {
			apiEndPoint = ENV.API_URL + "requirement/" + singleShiftId;
		} else {
			apiEndPoint = ENV.API_URL + "requirement";
		}

		ApiService.put(apiEndPoint, payload)
			.then((res: any) => {
				CommonService.showToast(res?.msg || "Success", "success");
				setSubmitting(false);
				confirm();
			})
			.catch((err) => {
				CommonService.showToast(err?.msg || err?.error || "Error", "error");
				setSubmitting(false);
			});
	};
	return (
		<div className="pdd-left-20 pdd-right-20 pdd-bottom-20 show-shift-rate-screen">
			{/*<p className="main-heading">Shift Details</p>*/}
			<div className="mrg-top-10 ">
				<Formik
					initialValues={shiftRateInitialState}
					validateOnChange={true}
					validationSchema={shiftFieldsValidation}
					onSubmit={onAdd}
				>
					{({ isSubmitting, handleSubmit, isValid, values, resetForm, setFieldValue }) => (
						<Form id="show-shift-rate-form" className={"shift-rate-form form-holder"}>
							<div className="show-shift-rate-container">
								<p className="sub-heading">Show Shift Rate on Mobile</p>
								<Field  onChange={(e:any)=> {
									setFieldValue('is_shift_rate',e.target.value);
									if(e.target.value === 'false') {
										setFieldValue('shift_rate','');
									}
								}}component={RadioGroup} name="is_shift_rate" className="shift-rate-radio-container">
									<FormControlLabel
										value="true"
										control={<Radio disabled={isSubmitting} />}
										label="Yes"
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="false"
										control={<Radio disabled={isSubmitting} />}
										label="No"
										disabled={isSubmitting}
									/>
								</Field>
							</div>

						{
							values.is_shift_rate === 'true' && 	<div className="shift-rate-container">
							<div className="shift-rate-text">
								<p className="sub-heading"> Shift Rate</p>
							</div>

							<Field
								name="shift_rate"
								//inputProps={{ maxLength: 5 }}
								value={values.shift_rate}
								component={TextField}
								variant={"outlined"}
								color={"primary"}
								onChange={(e: any) => {
									if (e.target.value === "" || onlyNums.test(e.target.value)) {
										setFieldValue("shift_rate", e.target.value);
									}
								}}
								disabled={!CommonService.str2bool(values?.is_shift_rate)}
								id="shift_rate"
								placeholder={"Enter Value"}
								className={'shift-rate-input-wrapper'}
							/>
						</div>
						}

						{/* {
							type === "single" && <>	<div className="shift-rate-container">
							<div className="shift-rate-text">
								<p className="sub-heading"> Hazard Rate</p>
							</div>

							<Field
								name="hazard_rate"
								inputProps={{ maxLength: 5 }}
								value={values.hazard_rate}
								component={TextField}
								variant={"outlined"}
								color={"primary"}
								onChange={(e: any) => {
									if (e.target.value === "" || onlyNums.test(e.target.value)) {
										setFieldValue("hazard_rate", e.target.value);
									}
								}}
								id="hazard_rate"
								placeholder={"Enter Value"}
							/>
						</div>

						<div className="shift-rate-container">
						<div className="shift-rate-text">
								<p className="sub-heading"> Warning Zone</p>
							</div>
							<div className="mrg-top-10">
								<Field required component={RadioGroup} name="warning_type">
									<div className="d-flex">
										{warningZone &&
											warningZone.map((item: any, index) => {
												return (
													<div>
														<FormControlLabel
															key={"input_add_shift_warning_type" + index}
															value={item.value}
															control={<Radio disabled={isSubmitting} />}
															disabled={isSubmitting}
															name="warning_type"
															label={item.label}
														/>
													</div>
												);
											})}
									</div>
								</Field>
							</div>
						</div></>
						} */}

							<div className="mrg-top-40 text-align">
								<Button
									variant={"outlined"}
									id="cancel-employee-btn"
									size={"medium"}
									color={"primary"}
									onClick={() => cancel()}
								>
									Cancel
								</Button>
								<Button
									disabled={isSubmitting}
									variant={"contained"}
									type={"submit"}
									size={"medium"}	
									color={"primary"}
									className={
										isSubmitting
											? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30"
											: "mrg-left-20 pdd-left-30 pdd-right-30"
									}
								>
									{isSubmitting ? "Saving" : "Save"}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default ShowShiftRateOnMobileComponent;