import {PerDiemAddShiftsValidation} from "../../AddShiftsValidation";
import {Field, Form, Formik, FormikHelpers} from "formik";
// import {TextField} from "formik-material-ui";
//import TextField from "@material-ui/core/TextField";
import {TextField as FormikTextFiled} from "formik-material-ui";
import TextField from "@material-ui/core/TextField";
import {Button, MenuItem, Paper} from "@material-ui/core";
import {ENV} from "../../../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {ApiService, CommonService} from "../../../../../helpers";
import moment from "moment/moment";
import {useHistory} from "react-router-dom";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePickers from "react-multi-date-picker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {format} from 'date-fns';

interface PerDiemProps {
    facilityId?: any
    shiftTimings?: any
}

interface ContactShiftItem {
    title: any,
    hcp_id: any,
    start_time: any,
    end_time: any,
    shift_type: any,
    shift_dates: any,
    shift_details: any,
}

let contractShiftInitialState: ContactShiftItem = {
    title: "",
    hcp_id: "",
    start_time: "",
    end_time: "",
    shift_type: "",
    shift_dates: [],
    shift_details: "",
};
const showDropDownBelowField: any = {
    MenuProps: {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        getContentAnchorEl: null,
    },
};

const PerDiemApprovedShiftCreationComponent = (props: PerDiemProps) => {
    const {facilityId, shiftTimings} = props;
    const [approvedHCPs, setApprovedHCPs] = useState([]);
    const [value, setValue] = useState<any>(null);
    const [shiftDate, setShiftDate] = useState<any>([]);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [requestedBy, setRequestedBy] = useState<any>("");
    const [isShiftTimingSelected,setIsShiftTimingSelected]=useState<boolean>(false);

    // function handleDatePicker(value: any) {
    //     setValue(value);
    // }
    useEffect(() => {
        if(facilityId){
            setIsShiftTimingSelected(false)
        }
    }, [facilityId]);

    const handleDatePicker = (dates: any) => {
        // Ensure dates are in ISO 8601 format
        setValue(dates);
        const formattedDates = Array.isArray(dates)
            ? dates.map((date) => format(new Date(date), 'yyyy-MM-dd'))
            : format(new Date(dates), 'yyyy-MM-dd');
        console.log('Formatted Dates:', formattedDates);
        setShiftDate(formattedDates)
        // Handle the formatted dates (e.g., set state or pass to form)
    };

    const formatShiftTimings = useCallback((item: any) => {
        let start = moment(CommonService.convertMinsToHrsMins(item?.shift_start_time), "hh:mm").format("LT");
        let end = moment(CommonService.convertMinsToHrsMins(item?.shift_end_time), "hh:mm").format("LT");
        let type = item?.shift_type;

        return `${start} - ${end} (${type}-Shift)`;
    }, []);

    const onPerDiemAdd = (data: any, {setSubmitting, setErrors, resetForm}: FormikHelpers<any>) => {
        setIsLoading(true)
        // let shift_dates = value.map((item: any) => {
        //     let mm = item?.month?.number;
        //     let dd = item?.day;
        //     let yyyy = item?.year;
        //
        //     let shift_date = moment(`${yyyy}-${mm}-${dd}`).format("YYYY-MM-DD");
        //     return shift_date;
        // });
        let payload: any = {
            ...data,
            shift_dates: shiftDate,
            type: "facility",
            facility_id: facilityId,
            hcp_id: requestedBy?.value
        }
        console.log(payload)
        ApiService.upload(ENV.API_URL + "requirement/facility/preApprovedShifts", payload)
            .then((res) => {
                resetForm();
                setValue(null);
                setIsLoading(false)
                CommonService.showToast(res.message || "Success", "success");
                history.push("/approvedShifts/list");
            })
            .catch((err) => {
                setIsLoading(false)
                // CommonService.showToast(err?.error || "Error", "error");
                setSubmitting(false);

                if (typeof err?.error === 'string') {
                    CommonService.showToast(err?.error || "Error", "error")
                } else {
                    const formattedError = Object.entries(err?.error);
                    formattedError.map((err: any) => {
                        let hcpName = err[0];
                        let hcpDate = moment(err[1]).format("MM-DD-YYYY");
                        return CommonService.showToast(`${hcpName} already approved on ${hcpDate}` || "Error", "error");
                    });
                }

            });


    };

    const getAllApprovedHcps = useCallback(() => {
        let payload: any = {
            is_approved: true,
        };
        CommonService._api
            .post(ENV.API_URL + "hcp/lite", payload)
            .then((resp) => {
                const approvedHcps = resp?.data?.map((item: any) => ({
                    label: `${item?.first_name} ${item?.last_name}`,
                    value: item?._id,
                }));
                setApprovedHCPs(approvedHcps || []);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getAllApprovedHcps()
    }, [getAllApprovedHcps]);

    return (
        <>
            <div className={'contract-travel-approved-shift-component'}>
                <Formik
                    onSubmit={onPerDiemAdd} initialValues={contractShiftInitialState}
                    validationSchema={PerDiemAddShiftsValidation}>
                    {({isSubmitting, isValid, resetForm, handleChange, setFieldValue, values}) => {
                        return (
                            <Form className={"form-holder"} id="shift-add-form">
                                <div>
                                    {/*<div className="shift-first-row shift-row ">*/}
                                    {/*    <Field*/}
                                    {/*        id="input_shift_requirement_title"*/}
                                    {/*        variant="outlined"*/}
                                    {/*        name="title"*/}
                                    {/*        component={TextField}*/}
                                    {/*        label="Title (30 characters)"*/}
                                    {/*        fullWidth*/}
                                    {/*        inputProps={{*/}
                                    {/*            maxLength: 30,*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="d-flex shift-third-row shift-row mrg-top-30 ">
                                        <div className="shift-calender">
                                            <Field
                                                required
                                                inputClass="custom-input"
                                                className="rmdp-mobile"
                                                plugins={[<DatePanel eachDaysInRange/>]}
                                                format="MM/DD/YYYY"
                                                // range={mode === "range" ? true : false}
                                                multiple={true}
                                                onChange={handleDatePicker}
                                                value={value}
                                                variant="inline"
                                                inputVariant="outlined"
                                                placeholder={"Date(s)*"}
                                                id="input_shift_requirement_shift_datepicker"
                                                name="shift_dates"
                                                InputLabelProps={{shrink: true,}}
                                                component={DatePickers}
                                            />
                                            {/*<DateRangeOutlined*/}
                                            {/*    style={handleShowHideCalender()}*/}
                                            {/*    className="date-icon"*/}
                                            {/*    fontSize="large"*/}
                                            {/*    color="action"*/}
                                            {/*/>*/}
                                        </div>

                                        <div className="shift-calender">
                                            <Field
                                                SelectProps={showDropDownBelowField}
                                                id="input_shift_requirement_shift_timings"
                                                variant="outlined"
                                                select
                                                required
                                                name="shift_timings"
                                                component={FormikTextFiled}
                                                label="Shift Timings and Type"
                                                fullWidth
                                                InputLabelProps={{shrink: true}}
                                                disabled={false}
                                                onChange={(e: any) => {
                                                    const selectedShiftTiming = e.target.value;
                                                    const isSelected = selectedShiftTiming;
                                                    setIsShiftTimingSelected(isSelected)
                                                    if (shiftTimings.length > 0) {
                                                        setIsShiftTimingSelected(true)
                                                        setFieldValue("start_time", selectedShiftTiming?.shift_start_time);
                                                        setFieldValue("end_time", selectedShiftTiming?.shift_end_time);
                                                        setFieldValue("shift_type", selectedShiftTiming?.shift_type);
                                                    }
                                                }}
                                            >
                                                <MenuItem value="">Select Shift Timing</MenuItem>
                                                {shiftTimings.length > 0 &&
                                                    shiftTimings?.map((item: any, index: any) => {
                                                        let shift = formatShiftTimings(item);
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {shift}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Field>

                                        </div>


                                    </div>
                                    <div className="d-flex shift-third-row shift-row mrg-top-30 ">

                                        <div className="shift-calender">
                                            {/*<Field*/}
                                            {/*    SelectProps={showDropDownBelowField}*/}
                                            {/*    id="input_shift_requirement_shift_timings"*/}
                                            {/*    variant="outlined"*/}
                                            {/*    select*/}
                                            {/*    required*/}
                                            {/*    name="hcp_id"*/}
                                            {/*    component={TextField}*/}
                                            {/*    label="Staff"*/}
                                            {/*    fullWidth*/}
                                            {/*    disabled={false}*/}
                                            {/*    onChange={(e: any) => {*/}
                                            {/*        setFieldValue("hcp_id", e.target.value);*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    /!*<MenuItem value="">Select Shift Timing</MenuItem>*!/*/}
                                            {/*    {approvedHCPs.length > 0 &&*/}
                                            {/*        approvedHCPs?.map((item: any, index: any) => {*/}
                                            {/*            return (*/}
                                            {/*                <MenuItem value={item?.code} key={index}>*/}
                                            {/*                    {item?.name}*/}
                                            {/*                </MenuItem>*/}
                                            {/*            );*/}
                                            {/*        })}*/}
                                            {/*</Field>*/}


                                            <div style={{width: '100%'}} className={"search-input-box"}>
                                                <Autocomplete

                                                    disableClearable
                                                    PaperComponent={({children}) => <Paper
                                                        style={{color: "#1e1e1e"}}>{children}</Paper>}
                                                    value={requestedBy}
                                                    options={approvedHCPs}
                                                    getOptionLabel={(option: any) => option.label}
                                                    placeholder={"Time Sheet"}
                                                    id="input_select_status"
                                                    // className="mrg-top-10"
                                                    onChange={($event, value) => {
                                                        setRequestedBy(value);
                                                        console.log(value?.value);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} id="select_requested_by" variant="outlined"
                                                                   placeholder={"Staff*"} required/>
                                                    )}
                                                    disableCloseOnSelect
                                                />
                                            </div>

                                        </div>

                                        <div className="shift-calender">
                                            <Field
                                                id="input_shift_requirement_title"
                                                variant="outlined"
                                                name="shift_rate"
                                                component={FormikTextFiled}
                                                label="Pay Rate ($)"
                                                fullWidth
                                                InputLabelProps={{shrink: true}}
                                                disabled={false}
                                                inputProps={{
                                                    maxLength: 30,
                                                }}
                                            />
                                        </div>


                                    </div>


                                    <div className="shift-third-row  mrg-top-30">
                                        <Field
                                            id="input_shift_requirement_shift_details"
                                            label="Shift Requirement Details"
                                            placeholder="Type Shift Details Here"
                                            variant="outlined"
                                            component={FormikTextFiled}
                                            type={"text"}
                                            name="shift_details"
                                            fullWidth
                                            disabled={false}
                                            multiline
                                            InputLabelProps={{shrink: true}}
                                            minRows={3}
                                            onChange={(e: any) => {
                                                setFieldValue("shift_details", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={'d-flex justify-content-center mrg-top-20'}>
                                    <Button
                                        disabled={isLoading || shiftDate?.length === 0 || !requestedBy || !isShiftTimingSelected}
                                        type="submit"
                                        id="btn_add_shift_requirement_save"
                                        variant={"contained"}
                                        className={"normal"}
                                        color={"primary"}
                                    >
                                        Create Approved Shift
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </>
    )
}
export default PerDiemApprovedShiftCreationComponent
