import "./FilePreviewComponent.scss";
import {Button, Typography} from "@material-ui/core";
import React, {useCallback} from "react";
import {PdfIcon} from "../../constants/ImageConfig";

interface FilePreviewComponentProps{
    allowedTypes: string;
    maxSizeMB?: number;
    uploadedFile?:any;
    previewFile?:(index:number,type?:string)=>void;
    deleteFile?:(index:number)=>void;
    type?:string;
}


const FilePreviewComponent=(props:FilePreviewComponentProps)=>{
    const {allowedTypes,maxSizeMB,uploadedFile,previewFile,deleteFile,type}=props;

    const handlePreview=useCallback((index:number,type?:string)=>{
        if(previewFile){
            previewFile(index,type);
        }
    },[previewFile])

    const handleDelete=useCallback((index:number)=>{
        if(deleteFile){
            deleteFile(index)
        }
    },[deleteFile])
    return (
        <div className={'file-preview-component'}>
            <div>
                {uploadedFile?.map((item:any,index:number)=>{
                    return (
                        <div key={index} className={'file-preview-image-wrapper'}>
                            {item?.file?.type === "image/jpg" || item?.file?.type === "image/png" || item?.file?.type === "image/jpeg" ? (
                                <img src={item?.file?.base64} alt="" className={'file-preview-image'}/>
                            ) : (
                                <img src={PdfIcon} alt="" className={'file-preview-image'}/>
                            )}
                        </div>
                    )
                })}
            </div>
            <div className={'file-preview-content'}>
                <div className={'file-preview-btn-wrapper'}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handlePreview(0,type)}
                        style={{ marginRight: "8px" }}
                    >
                        View
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleDelete(0)}
                        style={{ marginRight: "8px" }}
                    >
                        Delete
                    </Button>
                </div>
                <Typography
                    variant="caption"
                    display="block"
                    style={{ marginTop: "8px", color: "#888" }}
                >
                    File type: {allowedTypes}
                    <br />
                    {maxSizeMB && `Max file size: ${maxSizeMB}MB`}
                </Typography>
            </div>
        </div>
    )
}
export default FilePreviewComponent;