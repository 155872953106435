import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./HcpAttendanceComponent.scss";
import { CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
import moment from "moment/moment";
import EditScoreComponent from "./EditHcpScoreComponent";
import DialogComponent from "../../../../components/DialogComponent";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";

interface HcpAttendanceProps {
    hcpBasicDetails?: any;
}

const HcpAttendanceComponent = (props: HcpAttendanceProps) => {
    // const [list, setList] = useState<TsDataListState | null>(null);
    const {hcpBasicDetails} = props;
    const [hcpStats, setHcpStats] = useState<any>(null);
    // const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("hcpAttendancePageSizeIndex", 10);
    const [isHcpScoreEditOpen, setIsHcpScoreEditOpen] = useState(false);
    const handleHcpStatsApiCall = useCallback(() => {
        console.log(hcpBasicDetails);
        // config
        CommonService._api
            .get(ENV.API_URL + "hcp/" + hcpBasicDetails?.user_id + "/attendanceStats")
            .then((resp) => {
                setHcpStats(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [hcpBasicDetails]);

    useEffect(() => {
        handleHcpStatsApiCall();
    }, [handleHcpStatsApiCall, hcpBasicDetails]);

    const getClassName = (score: any) => {
        const scores = score?.toString();
        if (scores && scores?.length > 0) {
            if (scores?.includes('+')) {
                return "green";
            } else if (scores?.includes('-')) {
                return "red";
            } else {
                return "yellow";
            }
        } else {
            return "";
        }
    };

    const attendenceListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Attendance Score',
                width:100,
                render:(item:any)=>{
                    const className = getClassName(item["score"]);
                    return(
                        <div className={className}>
                            {item?.score  || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Title',
                width:200,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.shift_title || item?.shift_titles ? (item?.shift_title || item?.shift_titles) : "N/A"}
                        </div>
                    )
                }
            },
            {
                title:'Description',
                width:300,
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.description || 'N/A'}
                    </div>
                    )
                }
            },
            {
                title:'Created Date',
                width:140,
                render:(item:any)=>{
                    return (
                        <div>
                            {moment(item?.created_at).format('DD-MM-YYYY') || 'N/A'}
                        </div>
                    )
                }
            }
        ]
    },[]);


    return (
        <>
            <DialogComponent headerLabel={'Enter the score'} open={isHcpScoreEditOpen} cancel={() => {
                setIsHcpScoreEditOpen(false);
            }}>
                <EditScoreComponent
                    hcpId={hcpBasicDetails?.user_id}
                    hcpStats={hcpStats}
                    setIsHcpScoreEditOpen={setIsHcpScoreEditOpen}
                    handleHcpStatsApiCall={handleHcpStatsApiCall}
                    // getList={getList}
                />
            </DialogComponent>
            <div className="hcp-attendance-component d-flex  justify-content-space-between custom-border">
                <div className="flex-1">
                    <h4 className={'header-text'}>Total Score<span onClick={() => {
                        setIsHcpScoreEditOpen(true);
                    }} className={'edit-button-score mrg-left-10 cursor-pointer'}>
                    (Edit)
                    </span></h4>
                    <p className={
                        hcpStats?.total_score === undefined
                            ? "gray"
                            : hcpStats.total_score >= 75
                                ? "green"
                                : hcpStats.total_score > 25
                                    ? "yellow"
                                    : "red"
                    }>
                        {hcpStats && hcpStats?.total_score}
                    </p>
                </div>
                <div className="flex-1">
                    <h4 className={'header-text'}>Total Shifts</h4>
                    <p className={'header-text'}>{hcpStats && hcpStats?.total_shifts}</p>
                </div>
                <div className="flex-1">
                    <h4 className={'header-text'}>Upcoming Shifts</h4>
                    <p className={'header-text'}>
                        {hcpStats && hcpStats?.upcoming_shifts}
                    </p>
                </div>
                <div className="flex-1">
                    <h4 className={'header-text'}>NCNS</h4>
                    <p className={'header-text'}>{hcpStats && hcpStats?.total_ncns}</p>
                </div>
            </div>
            <div className={'mrg-top-30 hcp-attendance-table-component'}>
                <AntTableComponent
                    url={ENV.API_URL +  `hcp/${hcpBasicDetails?.user_id}/attendance`}
                    method={'get'}
                    columns={attendenceListColumn}
                    extraPayload={hcpStats}
                    isRowSelection={false}
                    noDataText={'No Data Found.'}
                />
            </div>
        </>
    );
};
export default HcpAttendanceComponent;