// import React, { PropsWithChildren } from 'react';
// import Button from '@material-ui/core/Button';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogTitle from '@material-ui/core/DialogTitle';
//
// export interface LeavePageConfirmationComponentProps {
//     confirmationText: any;
//     cancel: any;
//     confirm: any;
//     notext: string;
//     yestext: string;
// }
//
// const headerStyles: any = {
//     textAlign: 'center'
// }
//
// const headingStyle: any = {
//     color: '#1e1e1e',
// }
//
// const LeavePageConfirmationComponent = (props: PropsWithChildren<LeavePageConfirmationComponentProps>) => {
//     return <div className=' leave-page-confirmation-component'>
//         {/*pdd-20 pdd-bottom-10*/}
//         <DialogTitle id="alert-dialog-slide-title"
//             className={'alert-dialog-slide-title'} style={headerStyles}>
//             <span style={headingStyle} className={'alert-dialog-heading'}>Leave Page?</span>
//             <p>Changes you made might not be saved</p>
//         </DialogTitle>
//         <DialogActions className={'pdd-20'}>
//             <Button onClick={props?.cancel} size="large"
//                 variant={"outlined"}
//                 color={"primary"} className='pdd-left-25 pdd-right-25'>
//                 {props?.notext || 'Leave'}
//             </Button>
//             <Button onClick={props?.confirm} size="large"
//                 variant={"contained"}
//                 color={"primary"}>
//                 {props?.yestext || 'Cancel'}
//             </Button>
//         </DialogActions>
//     </div>;
// }
//
// export default LeavePageConfirmationComponent;

import React, { PropsWithChildren } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

export interface LeavePageConfirmationComponentProps {
    confirmationText?: string; // Text to display in the modal
    cancel: () => void;       // Cancel action handler
    confirm: () => void;      // Confirm action handler
    notext?: string;          // Text for the "Leave" button
    yestext?: string;         // Text for the "Cancel" button
    visible?:boolean;
}

const LeavePageConfirmationComponent = (props: PropsWithChildren<LeavePageConfirmationComponentProps>) => {
    return (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <ExclamationCircleOutlined style={{ color: '#faad14', fontSize: '20px' }} />
                    <span style={{ color: '#1e1e1e', display: 'block' }}>Leave Page?</span>
                </div>
            }
            open={props.visible}
            footer={null}
            centered
            onCancel={props.cancel}
            closable={false}
        >
            {/* Default confirmation text or user-provided */}
            <p
                style={{
                    fontSize:'18px',
                    fontWeight:500,
                }}
            >{props.confirmationText || 'Changes you made might not be saved'}</p>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
                <Button
                    onClick={props.cancel}
                    type="default"
                >
                    {props.notext || 'Leave'}
                </Button>
                <Button
                    onClick={props.confirm}
                    type="primary"
                    style={{background:'#10c4d3'}}
                >
                    {props.yestext || 'Cancel'}
                </Button>
            </div>
        </Modal>
    );
};

export default LeavePageConfirmationComponent;
