import {
    Button,
    FormControlLabel,
    FormLabel, InputAdornment,
    MenuItem,
    Paper,
    Radio,
    RadioGroup as NormalRadioGroup,
    TextField as NormalTextField,
} from "@material-ui/core";
import {DateRangeOutlined} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Field, FieldArray, Form, Formik, FormikHelpers, FormikProps} from "formik";
import {RadioGroup, TextField} from "formik-material-ui";
import moment from "moment";
import {nanoid} from "nanoid";
import React, {useCallback, useEffect, useRef, useState} from "react";
import DatePickers from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/layouts/mobile.css";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import LoaderComponent from "../../../components/LoaderComponent";
import LeavePageConfirmationComponent from "../../../components/shared/LeavePageConfirmationComponent";
import {ENV, ImageConfig} from "../../../constants";
import {
    calenderMode, contactTypeList, contractShiftType,
    genericAcknowledgement,
    home_shift_type, languagesList,
    patientsGender, shiftDurationList,
    weekDaysList
} from "../../../constants/data";
import {ApiService, CommonService, Communications} from "../../../helpers";
import {StateParams} from "../../../store/reducers";
import "./AddShiftsScreen.scss";
import {addShiftsValidation, ContractAddShiftsValidation, HomeAddShiftsValidation} from "./AddShiftsValidation";
import ReadOnlyShifts from "./ReadOnlyShifts";
import FilePickerComponent from "../../../components/file-picker/FilePickerComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import {DatePicker} from "formik-material-ui-pickers";
import ApprovedShiftCreationComponent from "./approved-shift-creation/ApprovedShiftCreationComponent";
import ShiftRateComponent from "./shift-holiday-rate-component/ShiftRateComponent";
import {format} from "date-fns";

interface PerDiem {
    type: string;
    rate: any;
    week_rate: any;
}

interface ContactShiftItem {
    temp_id?: string;
    title: string;
    hcp_type: string;
    mode: string;
    start_time: string | number;
    end_time: string | number;
    start_date: any;
    end_date: any;
    shift_type: string;
    warning_type: string;
    hcp_count: string;
    hcp_speciality: string;
    hazard_rate: string;
    shift_details: string;
    travel_per_diems: PerDiem[];
    day_per_week: string;
    contract_requirements: {
        cancel_policy: string,
        staff_requirements: string,
        job_details: string,
        job_benefits: string,
    }
}


let contractShiftInitialState: ContactShiftItem = {
    day_per_week: "",
    temp_id: "",
    title: "",
    hcp_type: "",
    mode: "",
    start_time: "",
    end_time: "",
    shift_type: "",
    warning_type: "",
    hcp_count: "",
    hcp_speciality: "",
    end_date: null,
    start_date: null,
    hazard_rate: "",
    shift_details: "",
    contract_requirements: {
        cancel_policy: "",
        staff_requirements: "",
        job_benefits: ""
        ,
        job_details: "",
    },
    travel_per_diems: [
        {
            type: 'Shift Rate',
            rate: "",
            week_rate: "",
        },
        {
            type: 'Housing',
            rate: "",
            week_rate: "",
        },
        {
            type: 'Meals',
            rate: "",
            week_rate: "",
        },
    ],
};

interface ShiftItem {
    temp_id?: string;
    title: string;
    hcp_type: string;
    mode: string;
    start_time: string | number;
    end_time: string | number;
    shift_dates: any;
    shift_type: string;
    warning_type: string;
    hcp_count: string;
    hcp_speciality: string;
    hazard_rate: string;
    shift_details: string;
}

interface HomeShiftItem {
    temp_id?: string;
    title: string;
    hcp_type: string;
    mode: string;
    start_time: string | number;
    end_time: string | number;
    shift_dates: any;
    shift_type: string;
    dob: any;
    shift_date: any;
    warning_type: string;
    hcp_count: string;
    hcp_speciality: string;
    language: string[];
    hazard_rate: string;
    shift_details: string;

}

let shiftInitialState: ShiftItem = {
    temp_id: "",
    title: "",
    hcp_type: "",
    mode: "",
    start_time: "",
    end_time: "",
    shift_type: "",
    shift_dates: [],
    warning_type: "",
    hcp_count: "",
    hcp_speciality: "",


    hazard_rate: "",
    shift_details: "",
};

let HomeShiftInitialState: HomeShiftItem = {
    temp_id: "",
    title: "",
    hcp_type: "",
    language: [],
    mode: "",
    start_time: "",
    end_time: "",
    dob: null,
    shift_type: "",
    shift_dates: [],
    shift_date: null,
    warning_type: "",
    hcp_count: "",
    hcp_speciality: "",
    hazard_rate: "",
    shift_details: "",
};


// const TEMPORARY_DISABLED = true;
//const onlyNums = /^\d{1,3}(\.$|\.\d{1,3}$|$)/;
const shiftCreationRequestedByData = [
    {
        name: "facility",
        code: "facility"
    },
    {
        name: "hcp",
        code: "hcp"
    }
];

const AddShiftsScreen = () => {
    const {user} = useSelector((state: StateParams) => state.auth);
    const history = useHistory();
    const [facilities, setFacilities] = useState<any[]>([]);
    const [facilityData, setFacilityData] = useState<any>([]);
    const [facilityId, setFacilityId] = useState<any>("");
    const [shifts, setShifts] = useState<any[]>([]);
    const [shiftTimings, setShiftTimings] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [shiftLoading, setShiftLoading] = useState<boolean>(false);
    const [isFacilityDataLoading, setIsFacilityDataLoading] = useState<boolean>(false);
    // const [hcpTypesLoading, setHcpTypesLoading] = useState<boolean>(true);
    const [facilityOffset, setFacilityOffset] = useState<any>(null);
    const [isShifts, setIsShifts] = useState<boolean>(false);
    const [doubleClick, setDoubleClick] = useState<boolean>(false);
    // const [hcpTypes, setHcpTypes] = useState<any>([]);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [value, setValue] = useState<any>(null);
    const [mode, setMode] = useState("");
    const [isShiftRate, setIsShiftRate] = useState<string>("false");
    const facilityRatesRef = useRef<any>({});
    const {isLoading,regions} = useSelector((state:StateParams) => state.meta);

    //for shift title automation
    const [facilityShortName, setFacilityShortName] = useState<string>("");
    const [currentHCPType, setCurrentHCPType] = useState<string>("");
    const [currentShiftType, setCurrentShiftType] = useState<string>("");

    //for specialities addition
    //  const [specialitiesMaster, setSpecialitiesMaster] = useState<any>([]);
    const {specialitiesMasterList, specIsLoading} = useSelector((state: StateParams) => state.meta);
    const [hcpTypeSpecialities, setHcpTypeSpecialities] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState<string>("open_shift");
    const [selectedRequirementCreationValue, setSelectedRequirementCreationValue] = useState<string>("shift_requirement_creation");
    //   const [specIsLoading, setSpecIsLoading] = useState<boolean>(true);
    const [timesheetFile, setTimesheetFile] = React.useState<any>([]);
    // const [state, setState] = useState<any>([]);
    const [selectedApprovedTypeValue, setSelectedApprovedTypeValue] = useState<string>("open_shift");
    const [totalShiftRate, setTotalShiftRate] = useState<any>(0);
    const [otherRate, setOtherRate] = useState<any>({});
    const formikRef = useRef<FormikProps<any> | null>(null);
    const [title, setTitle] = useState<string>('');
    const [shiftDate, setShiftDate] = useState<any>([]);

    const handleCancelAdd = useCallback(() => {
        setIsShifts(false);
        resetFacilityRates();
    }, []);

    // function handleDatePicker(value: any) {
    //     setValue(value);
    // }

    const handleDatePicker = useCallback((dates: any) => {
        // Ensure dates are in ISO 8601 format
        setValue(dates);
        const formattedDates = Array.isArray(dates)
            ? dates.map((date) => format(new Date(date), 'yyyy-MM-dd'))
            : format(new Date(dates), 'yyyy-MM-dd');
        console.log('Formatted Dates:', formattedDates);
        setShiftDate(formattedDates)
        // Handle the formatted dates (e.g., set state or pass to form)
    }, []);

    const getFacilityData = useCallback(() => {
        const payload = {is_active: true};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilities(res?.data || []);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, []);

    // const getState = useCallback(() => {
    //     CommonService._api
    //         .get(ENV.API_URL + "states")
    //         .then((resp) => {
    //             console.log(resp);
    //             setState(resp.data || []);
    //             // setRegIsLoading(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);
    //
    // useEffect(() => {
    //     getState();
    // }, [getState]);


    const composeShiftTitle = useCallback(() => {
        if (isFacilityDataLoading) {
            return "Loading Title";
        }
        let facility_short_name = facilityShortName;
        let hcp_type = currentHCPType;
        let shift_type = currentShiftType;

        return `${facility_short_name}_${hcp_type}_${shift_type}`;
    }, [currentHCPType, currentShiftType, facilityShortName, isFacilityDataLoading]);

    // const getFacilityShiftTimings = useCallback((facilityId: string) => {
    //     setShiftLoading(true);
    //     ApiService.get(ENV.API_URL + "facility/" + facilityId + "/shift")
    //         .then((res) => {
    //             setShiftTimings(res.data || []);
    //             setShiftLoading(false);
    //         })
    //         .catch((err) => {
    //             setShiftLoading(false);
    //         });
    // }, []);

    const getFacilityExtraData = useCallback((facilityId: string) => {
        setIsFacilityDataLoading(true);
        setShiftLoading(true);
        ApiService.get(ENV.API_URL + "facility/" + facilityId)
            .then((res) => {
                setFacilityData(res?.data);
                setFacilityOffset(res?.data?.timezone);
                setIsFacilityDataLoading(false);
                setShiftLoading(false);
                setFacilityShortName(res?.data?.short_name);
                setShiftTimings(res?.data?.shifts);

                const facilityRateData = {
                    rush_rate: res?.data?.conditional_rates?.rush?.rate,
                    noc_diff: res?.data?.diff_rates?.noc,
                    pm_diff: res?.data?.diff_rates?.pm,
                    weekend_rate: res?.data?.diff_rates?.weekend,
                };

                facilityRatesRef.current = facilityRateData;
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityDataLoading(false);
                setShiftLoading(false);
            });
    }, []);


    // useEffect(() => {
    //     if (facilityData?.show_shift_rate) {
    //         setIsShiftRate("true");
    //         const key = currentHCPType.toLowerCase();
    //         if (key in facilityData?.vw_hourly_base_rates) {
    //             console.log(typeof key);
    //             console.log(facilityData?.vw_hourly_base_rates[key]);
    //             setShiftRateValue(facilityData?.vw_hourly_base_rates[key]);
    //         } else {
    //             setShiftRateValue("");
    //         }
    //     }
    // }, [facilityData, currentHCPType]);

    useEffect(() => {
        if (facilityData?.show_shift_rate) {
            setIsShiftRate("true");
            const key = currentHCPType.toLowerCase();
            if (facilityData?.vw_hourly_base_rates && key in facilityData.vw_hourly_base_rates) {
                console.log(typeof key);
                console.log(facilityData.vw_hourly_base_rates[key]);
            } else {
            }
        }
    }, [facilityData, currentHCPType]);

    const handleFacilitySelect = (facility: any) => {
        setShiftTimings([]);
        resetFacilityRates();
        if (facility) {
            setFacilityId(facility?._id);
            // getFacilityShiftTimings(facility?._id);
            getFacilityExtraData(facility?._id);
        }
    };

    const formatShiftTimings = (item: any) => {
        let start = moment(CommonService.convertMinsToHrsMins(item?.shift_start_time), "hh:mm").format("LT");
        let end = moment(CommonService.convertMinsToHrsMins(item?.shift_end_time), "hh:mm").format("LT");
        let type = item?.shift_type;

        return `${start} - ${end} (${type}-Shift)`;
    };

    const onAddShiftRequirement = useCallback((shiftR: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                let data = await ApiService.post(ENV.API_URL + "requirement", shiftR);
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const addShiftsRequirement = useCallback(async () => {
        let promArray = [];
        try {
            for (let i = 0; i < shifts.length; i++) {
                promArray.push(onAddShiftRequirement(shifts[i]));
            }

            Promise.all(promArray)
                .then((resp) => {
                    CommonService.showToast(resp.length + " Shift Requirement Created" || "Success");
                    setTimeout(() => history.push("/shiftrequirementMaster/list"), 200);
                })
                .catch((err) => {
                    CommonService.showToast(err?.msg || err?.error || "Error", "error");
                    setDoubleClick(false);
                });
        } catch (error: any) {
            CommonService.showToast(error?.msg || "Error", "error");
            setDoubleClick(false);
            return error;
        }
    }, [shifts, onAddShiftRequirement, history]);

    const onSubmit = () => {
        addShiftsRequirement();
    };


    const onAdd = (data: any, {setSubmitting, setErrors, resetForm}: FormikHelpers<any>) => {
        //requested_by
        if (!facilityId) {
            CommonService.showToast("Please select Facility");
            setSubmitting(false);
            return;
        }

        if (!data.mode) {
            CommonService.showToast("Please select Date Mode", "error");
            setSubmitting(false);
            return;
        }

        // let shift_dates = value.map((item: any) => {
        //     let mm = item?.month?.number;
        //     let dd = item?.day;
        //     let yyyy = item?.year;
        //
        //     let shift_date = moment(`${yyyy}-${mm}-${dd}`).format("YYYY-MM-DD");
        //     return shift_date;
        // });

        let newShift;

        //check for absence of shift timings in shift req.
        if (shiftTimings.length === 0) {
            data.start_time = "";
            data.end_time = "";
            data.shift_type = "";

            return;
        }

        if (mode === "multiple") {
            newShift = {
                temp_id: nanoid(),
                title: composeShiftTitle(),
                mode: data.mode,
                hcp_type: data.hcp_type,
                hcp_speciality: data.hcp_speciality,
                facility_id: facilityId,
                requirement_owner_id: user._id,
                shift_dates: shiftDate,
                shift_type: data.shift_type,
                warning_type: data.warning_type,
                hcp_count: data.hcp_count,
                requested_by: data.requested_by,
                // hazard_rate: data.hazard_rate,
                shift_details: data.shift_details,
                start_time: data.start_time,
                end_time: data.end_time,
                price: {
                    inbound_price: "0",
                    outbound_price: "0",
                },
                hcp_hazard_rate: otherRate.hazard,
                hcp_noc_diff: otherRate.noc,
                hcp_pm_diff: otherRate.pm,
                hcp_weekend_rate: otherRate.weekend,
                hcp_rush_rate: otherRate.rush,
                shift_rate: totalShiftRate,
                is_rush_rate: otherRate?.is_rush_rate,
                is_noc_diff: otherRate?.is_noc_diff,
                is_pm_diff: otherRate?.is_pm_diff,
                is_weekend_rate: otherRate?.is_weekend_rate,
                is_hazard_rate: otherRate?.is_hazard_rate,
                is_holiday: otherRate?.is_holiday,
                is_shift_rate: CommonService.str2bool(isShiftRate),
                base_shift_rate: otherRate?.base_shift_rate,
            };
        } else if (mode === "range") {
            newShift = {
                temp_id: nanoid(),
                title: composeShiftTitle(),
                mode: data.mode,
                hcp_type: data.hcp_type,
                hcp_speciality: data.hcp_speciality,
                facility_id: facilityId,
                requested_by: data.requested_by,
                requirement_owner_id: user._id,
                start_date: shiftDate[0],
                end_date: shiftDate[1] ? shiftDate[1] : shiftDate[0],
                shift_type: data.shift_type,
                warning_type: data.warning_type,
                hcp_count: data.hcp_count,
                // hazard_rate: data.hazard_rate,
                base_shift_rate: otherRate?.base_shift_rate,
                shift_details: data.shift_details,
                start_time: data.start_time,
                end_time: data.end_time,
                price: {
                    inbound_price: "0",
                    outbound_price: "0",
                },
                hcp_hazard_rate: otherRate.hazard,
                hcp_noc_diff: otherRate.noc,
                hcp_pm_diff: otherRate.pm,
                hcp_weekend_rate: otherRate.weekend,
                hcp_rush_rate: otherRate.rush,
                shift_rate: totalShiftRate,
                is_rush_rate: otherRate?.is_rush_rate,
                is_noc_diff: otherRate?.is_noc_diff,
                is_pm_diff: otherRate?.is_pm_diff,
                is_weekend_rate: otherRate?.is_weekend_rate,
                is_hazard_rate: otherRate?.is_hazard_rate,
                is_holiday: otherRate?.is_holiday,
                is_shift_rate: CommonService.str2bool(isShiftRate),
            };
        }

        let totalShifts = [...shifts, newShift];

        setShifts(totalShifts);

        resetForm();
        setValue(null);
        handleCancelAdd();
    };

    const onHomeAdd = useCallback((data: any, {setSubmitting, resetForm}: FormikHelpers<any>) => {
        if (!facilityId) {
            CommonService.showToast("Please select Facility");
            setSubmitting(false);
            return;
        }

        const formData = new FormData();

        timesheetFile?.forEach((file: any, index: number) => {
            // Append each file with the same key ('image')
            formData.append(`documents[${index}]`, file);
        });


        formData.append("title", composeShiftTitle() || "");
        formData.append("mode", "multiple");
        formData.append("shift_dates[]", moment(data.shift_date).format("YYYY-MM-DD"));
        formData.append("hcp_type", data.hcp_type || "");
        formData.append("hcp_speciality", data.hcp_speciality || "");
        formData.append("facility_id", facilityId || "");
        formData.append("requirement_owner_id", user._id || "");
        formData.append("shift_type", data.shift_type || "");
        formData.append("hcp_count", "1");
        formData.append("requested_by", data.requested_by || "facility");
        // formData.append("shift_details", data.shift_details || "");
        // @ts-ignore
        formData.append("end_time", CommonService.convertHoursToMinutes(data.shift_start_time));
        // @ts-ignore
        formData.append("start_time", 0);
        // @ts-ignore
        // formData.append("end_time", CommonService.convertHoursToMinutes(data.shift_end_time));
        formData.append("shift_rate", data.shift_rate);
        // @ts-ignore
        formData.append("is_shift_rate", CommonService.str2bool(isShiftRate || false));

        formData.append("patient_details[first_name]", data.first_name || "");
        formData.append("patient_details[last_name]", data.last_name || "");
        formData.append("patient_details[middle_name]", data.middle_name || "");
        formData.append("patient_details[gender]", data.patients_gender || "");
        formData.append("patient_details[dob]", data.dob || "");
        formData.append("patient_details[mrn]", data.patient_mrn || "");
        formData.append("patient_details[contact_number]", "+1" + data.patient_phone_number || "");
        formData.append("patient_details[email]", data.patient_email_address || "");
        formData.append("home_requirements[gender_pref]", data.gender_preferences || "");
        data.language?.forEach((item: any, index: any) => {
            if (item !== "Other") {
                formData.append(`home_requirements[languages][${index}]`, item);
            } else if (item === "Other") {
                formData.append(`home_requirements[languages][${data?.language?.length}]`, data.other_language);
            }
        });
        formData.append("home_requirements[number_of_visits]", data.number_of_visits || "");
        formData.append("home_requirements[visit_patient_by]", data.visit_patient_by || "");
        formData.append("patient_details[address][street]", data.patient_street || "");
        formData.append("patient_details[address][apt]", data.patient_apt || "");
        formData.append("patient_details[address][city]", data.patient_city || "");
        formData.append("patient_details[address][state]", data.patient_state || "");
        formData.append("patient_details[address][zip_code]", data.patient_zip_code || "");
        formData.append("patient_details.coordinates[0]", data.longitude || "");
        formData.append("patient_details.coordinates[1]", data.latitude || "");


        formData.append("type", "home");
        console.log(formData);

        ApiService.upload(ENV.API_URL + "requirement/home", formData)
            .then((res) => {
                resetForm();
                setValue(null);
                handleCancelAdd();
                CommonService.showToast(res.message || "Success", "success");
                history.push("/shiftrequirementMaster/list");
            })
            .catch((err:any) => {
                CommonService.showToast(err?.msg || err?.error || "Error", "error");
            });

    }, [composeShiftTitle, timesheetFile, facilityId, isShiftRate, user._id, handleCancelAdd, history]);

    const onContractAdd = useCallback((data: any, {setSubmitting, resetForm}: FormikHelpers<any>) => {
        console.log(data)
        if (!facilityId) {
            CommonService.showToast("Please select Facility");
            setSubmitting(false);
            return;
        }


        let {travel_per_diem_rate, travel_per_diems, ...restData} = data;

        let payload: any = {
            ...restData,
            contract_requirements: {
                ...data.contract_requirements,
                weekly_earning: 0,
                travel_per_diems: data?.travel_per_diems?.map((perDiem: any) => ({
                    [perDiem.type]: perDiem.rate,
                    week_rate: perDiem.week_rate,
                }))
            }
        };


        payload.type = 'contract'
        payload.shift_rate = travel_per_diems[0]?.rate;
        payload.facility_id = facilityId;
        payload.title = composeShiftTitle();
        payload.requested_by = 'facility';
        payload.start_date = moment(data.start_date).format("YYYY-MM-DD");
        payload.end_date = moment(data.end_date).format("YYYY-MM-DD");

        console.log(payload);
        ApiService.upload(ENV.API_URL + "requirement/contract", payload)
            .then((res) => {
                resetForm();
                setValue(null);
                handleCancelAdd();
                CommonService.showToast(res.message || "Success", "success");
                history.push("/shiftrequirementMaster/list");
            })
            .catch((err) => {
                CommonService.showToast(err?.msg || err?.error || "Error", "error");
            });

    }, [composeShiftTitle, facilityId, handleCancelAdd, history]);

    const resetFacilityRates = () => {
        setIsShiftRate("true");
    };

    const openAdd = useCallback(() => {
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        history.push(`/shiftrequirementMaster/list`);
    }, [history]);

    useEffect(() => {
        console.log(selectedRequirementCreationValue)
        if (selectedRequirementCreationValue === "shift_requirement_creation") {
            setTitle('Add Per Diem Requirement')
            Communications.pageTitleSubject.next("Add Per Diem Requirement");
            setSelectedValue("open_shift")
        } else if (selectedRequirementCreationValue === "create_approved_shift") {
            setTitle('Add Per Diem Approved Shift')
            Communications.pageTitleSubject.next("Add Per Diem Approved Shift");
            setSelectedApprovedTypeValue("open_shift")
        }
    }, [selectedRequirementCreationValue]);

    useEffect(() => {
        if (selectedValue === 'open_shift') {
            Communications.pageTitleSubject.next("Add Per Diem Requirement");
            setTitle('Add Per Diem Requirement')
            Communications.pageBackButtonSubject.next('/shiftrequirementMaster/list');
            getFacilityData();
        } else if (selectedValue === 'home_health') {
            Communications.pageTitleSubject.next("Add Per Job / Visit Requirement");
            setTitle('Add Per Job / Visit Requirement')
            Communications.pageBackButtonSubject.next('/shiftrequirementMaster/list');
            getFacilityData();
        } else if (selectedValue === 'contract-travel') {
            Communications.pageTitleSubject.next("Add Contract / Travel Requirement");
            setTitle('Add Contract / Travel Requirement')
            Communications.pageBackButtonSubject.next('/shiftrequirementMaster/list');
            getFacilityData();
        }
        // getSpecialities();
    }, [getFacilityData, selectedValue]);

    useEffect(() => {
        if (selectedRequirementCreationValue === "create_approved_shift" && selectedApprovedTypeValue === "open_shift") {
            setTitle('Add Per Diem Approved Shift');
            Communications.pageTitleSubject.next("Add Per Diem Approved Shift");
            Communications.pageBackButtonSubject.next('/shiftrequirementMaster/list');
            getFacilityData();
        } else if (selectedRequirementCreationValue === "create_approved_shift" && selectedApprovedTypeValue === "home_health") {
            setTitle('Add Per Job / Visit Requirement');
            Communications.pageTitleSubject.next("Add Per Job / Visit Approved Shift");
            Communications.pageBackButtonSubject.next('/shiftrequirementMaster/list');
            getFacilityData();
        } else if (selectedRequirementCreationValue === "create_approved_shift" && selectedApprovedTypeValue === "contract-travel") {
            setTitle('Add Contract / Travel Requirement')
            Communications.pageTitleSubject.next("Add Contract / Travel Approved Shift");
            Communications.pageBackButtonSubject.next('/shiftrequirementMaster/list');
            getFacilityData();
        }
    }, [selectedApprovedTypeValue, getFacilityData, selectedRequirementCreationValue]);

    useEffect(() => {
        console.log('title', title)
    }, [title])

    const showDropDownBelowField: any = {
        MenuProps: {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            getContentAnchorEl: null,
        },
    };

    const handleShowHideCalender = () => {
        if (value) {
            if (value instanceof Array) {
                if (value.length === 0) {
                    return {display: "block"};
                } else {
                    return {display: "none"};
                }
            }
            return {
                display: "none",
            };
        }
    };

    const handleAddShiftRequirementOpen = () => {
        if (facilityId) {
            setIsShifts(true);
        } else {
            console.log("Please Select Facility Id");
        }
    };

    // const handleRushRate = (e: any) => {
    //     setIsRushRate(e.target.value);
    //     if (e.target.value === false) {
    //         setRushRate(0);
    //     } else {
    //         setRushRate(facilityRatesRef.current.rush_rate);
    //     }
    // };


    // const handleHolidayRate = (e: any) => {
    //     setIsHolidayRate(e.target.value);
    // };
    //
    // const handleShiftRateValue = (e: any) => {
    //     console.log(e.target.value)
    //     const onlyNums = /^(0\.([0-9]*[1-9]+)?|[1-9][0-9]*(\.[0-9]*)?)$/;
    //     console.log(onlyNums.test(e.target.value));
    //     if (e.target.value === "" || onlyNums.test(e.target.value)) {
    //         setShiftRateValue(e.target.value);
    //     }
    // };


    const handleHcpTypeChange = useCallback((hcp_type: any, setFieldValue: any) => {
        // console.log(key, facilityData?.hourly_base_rates[key], facilityData?.hourly_base_rates)
        const selectedSpeciality = specialitiesMasterList[hcp_type];
        setHcpTypeSpecialities(selectedSpeciality);
        setFieldValue('hcp_speciality', "");

    }, [specialitiesMasterList]);


    const removeFile = (index: number) => {
        // Create a new array excluding the file at the given index
        const newTimesheetFile = [...timesheetFile];
        newTimesheetFile.splice(index, 1);
        // Update the timesheetFile state with the new array
        setTimesheetFile(newTimesheetFile);
    };
    const handleChange = (event: any) => {
        console.log(event.target.value);
        setSelectedValue(event.target.value);
        setCurrentShiftType("");
        setCurrentHCPType('');
        setValue(null);
    };

    const handleShiftRequirementChange = useCallback((event: any) => {
        setSelectedRequirementCreationValue(event.target.value);
    }, [])

    if (loading) {
        return <LoaderComponent/>;
    }

    async function getLatLong(street: any, city: any, state: any, zipcode: any) {
        // const address = `${street}, ${city}, ${state}, ${zipcode}, USA`;
        const url = `https://nominatim.openstreetmap.org/search?postalcode=${zipcode}}&countrycodes=us&format=json&addressdetails=1&limit=1`;
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.length > 0) {
                const location = data[0];
                return {
                    latitude: location.lat,
                    longitude: location.lon,
                };
            } else {
                throw new Error('Geocoding failed: No results found');
            }
        } catch (error) {
            console.error('Error fetching geolocation:', error);
            return null;
        }
    }


    // @ts-ignore
    // @ts-ignore
    return (
        !loading && (
            <div className={'add-shift-component'}>
                <div className={'add-header-wrapper'}>
                    <div className={'add-header-back-wrapper'} onClick={openAdd}>
                        <img src={ImageConfig.BackIcon} alt="back"/>
                        <div className={'add-header-back'}>
                            Back
                        </div>
                    </div>
                    <div className={'add-header-title'}>{title || 'Add Shift'}</div>
                    {/*{selectedRequirementCreationValue === "shift_requirement_creation" &&*/}
                    {/*<div className={'add-header-btn-wrapper mrg-right-25'}>*/}
                    {/*    <Button*/}
                    {/*        id="btn_add_shift_requirement_delete"*/}
                    {/*        color={"primary"}*/}
                    {/*        variant={"outlined"}*/}
                    {/*        type="reset"*/}
                    {/*        disabled={!facilityId  && !isShifts}*/}
                    {/*        onClick={() => {*/}
                    {/*            formikRef?.current?.resetForm()*/}
                    {/*            setValue(null);*/}
                    {/*            setMode("");*/}
                    {/*            handleCancelAdd();*/}
                    {/*            setCurrentHCPType("");*/}
                    {/*            setIsShiftRate("true");*/}
                    {/*            if(selectedValue !== 'open_shift') {*/}
                    {/*                setSelectedValue("open_shift");*/}
                    {/*                setCurrentShiftType("")*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Delete*/}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*        //  disabled={timesheetFile?.length===0}*/}
                    {/*        type="submit"*/}
                    {/*        id="btn_add_shift_requirement_save"*/}
                    {/*        variant={"contained"}*/}
                    {/*        className={"normal"}*/}
                    {/*        disabled={!facilityId && !isShifts}*/}
                    {/*        color={"primary"}*/}
                    {/*        onClick={()=>{*/}
                    {/*            console.log(formikRef?.current?.values,"VALUES")*/}
                    {/*            formikRef?.current?.submitForm();*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Save*/}
                    {/*    </Button>*/}
                    {/*</div>}*/}
                </div>
                <div className="add-shifts screen pdd-30">
                    <LeavePageConfirmationComponent
                        visible={isAddOpen}
                        cancel={cancelAdd}
                        confirm={confirmAdd}
                        confirmationText={""}
                        notext={"Cancel"}
                        yestext={"Leave"}
                    />
                    <NormalRadioGroup aria-label="gender" defaultValue="shift_requirement_creation"
                                      name="radio-buttons-group"
                                      className="mrg-top-10">
                        <div className={'d-flex mrg-bottom-20'}>
                            {[
                                {
                                    name: "Add Shift Requirement",
                                    code: "shift_requirement_creation"
                                },
                                {
                                    name: "Create Approved Shift",
                                    code: "create_approved_shift"
                                },
                            ]?.map((item: any) => {
                                return (
                                    <FormControlLabel value={item?.code}
                                                      control={<Radio/>}
                                                      onChange={(event) => handleShiftRequirementChange(event)}
                                                      label={item?.name}/>
                                );
                            })}
                        </div>
                    </NormalRadioGroup>
                    {facilities !== null && (
                        <Autocomplete
                            disableClearable
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            options={facilities}
                            getOptionLabel={(option: any) => option.name}
                            getOptionSelected={(option: any, value) => option.name === value?.name}
                            placeholder={"Select Facility"}
                            id="input_select_facility"
                            onChange={($event, value) => {
                                handleFacilitySelect(value);
                            }}
                            renderInput={(params) => (
                                <NormalTextField
                                    {...params}
                                    id="select_region"
                                    variant="outlined"
                                    placeholder={"Select (or) Search Facility"}
                                />
                            )}
                        />
                    )}
                    {selectedRequirementCreationValue === "shift_requirement_creation" &&
                        <div>
                            <div className="shift-header-container mrg-top-10">
                                <p className="shift-header ">Shift Details</p>
                            </div>

                            {!isShifts ? (
                                <div className="shift-add-action pdd-top-30">
                                    <p
                                        id="btn_shift_requirement_add_shift"
                                        onClick={() => handleAddShiftRequirementOpen()}
                                        className={`add-shift-requirment-text ${!facilityId && "add-shift-requirment-text-light"}`}
                                    >
                                        + Add a Shift Requirement
                                    </p>
                                </div>
                            ) : (
                                <div className="">
                                    <NormalRadioGroup aria-label="gender" defaultValue="open_shift"
                                                      name="radio-buttons-group"
                                                      className="mrg-top-10">
                                        <div className={'d-flex mrg-bottom-20'}>
                                            {[{
                                                name: "Per Diem",
                                                code: "open_shift"
                                            }, {
                                                name: "Per Job / Visit",
                                                code: "home_health"
                                            }, {
                                                name: "Contract / Travel",
                                                code: "contract-travel"
                                            }]?.map((item: any) => {
                                                return (
                                                    <FormControlLabel value={item?.code}
                                                                      control={<Radio/>}
                                                                      onChange={(event) => handleChange(event)}
                                                                      label={item?.name}/>
                                                );
                                            })}
                                        </div>
                                    </NormalRadioGroup>
                                    {selectedValue === 'open_shift' &&
                                        <Formik initialValues={shiftInitialState} validationSchema={addShiftsValidation}
                                                onSubmit={onAdd} innerRef={formikRef}
                                        >
                                            {({
                                                  isSubmitting,
                                                  isValid,
                                                  resetForm,
                                                  handleChange,
                                                  setFieldValue,
                                                  values
                                              }) => {
                                                return (
                                                    <Form className={"form-holder"} id="shift-add-form">

                                                        <div>
                                                            <div className="shift-first-row shift-row ">
                                                                <Field
                                                                    disabled={true}
                                                                    value={composeShiftTitle()}
                                                                    id="input_shift_requirement_title"
                                                                    variant="outlined"
                                                                    name="title"
                                                                    component={TextField}
                                                                    label="Title (30 characters)"
                                                                    InputLabelProps={{shrink: true}}
                                                                    fullWidth
                                                                    inputProps={{
                                                                        maxLength: 30,
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="shift-row mrg-top-30">
                                                                <Field
                                                                    SelectProps={showDropDownBelowField}
                                                                    id="input_shift_requirement_hcp_type"
                                                                    variant="outlined"
                                                                    select
                                                                    InputLabelProps={{shrink: true}}
                                                                    name="hcp_type"
                                                                    component={TextField}
                                                                    onChange={(e: any) => {
                                                                        const hcpType = e.target.value;
                                                                        console.log(e.target.value);
                                                                        setFieldValue("hcp_type", hcpType);
                                                                        setCurrentHCPType(hcpType);
                                                                        handleHcpTypeChange(hcpType, setFieldValue);
                                                                    }}
                                                                    label="Staff Type*"
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="">Select Staff Type</MenuItem>
                                                                    {hcpTypes &&
                                                                        hcpTypes.map((item: any, index: any) => (
                                                                            <MenuItem value={item.code} key={index}>
                                                                                {item?.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                </Field>

                                                                <Field
                                                                    disabled={specIsLoading}
                                                                    SelectProps={showDropDownBelowField}
                                                                    id="input_shift_requirement_hcp_type_specalisation"
                                                                    variant="outlined"
                                                                    select
                                                                    name="hcp_speciality"
                                                                    InputLabelProps={{shrink: true}}
                                                                    component={TextField}
                                                                    onChange={(e: any) => {
                                                                        const hcpSpeciality = e.target.value;
                                                                        setFieldValue('hcp_speciality', hcpSpeciality);

                                                                    }}
                                                                    label="Staff Speciality*"
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="">Select Speciality</MenuItem>
                                                                    {hcpTypeSpecialities &&
                                                                        hcpTypeSpecialities.map((item: any, index: any) => (
                                                                            <MenuItem value={item.code} key={index}>
                                                                                {item.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                </Field>
                                                                <Field
                                                                    disabled={shiftLoading}
                                                                    SelectProps={showDropDownBelowField}
                                                                    id="input_shift_requirement_shift_timings"
                                                                    variant="outlined"
                                                                    select
                                                                    required
                                                                    name="shift_timings"
                                                                    InputLabelProps={{shrink: true}}
                                                                    component={TextField}
                                                                    label="Shift Timings and Type"
                                                                    fullWidth
                                                                    onChange={(e: any) => {
                                                                        const selectedShiftTiming = e.target.value;
                                                                        if (shiftTimings.length > 0) {
                                                                            setFieldValue("start_time", selectedShiftTiming?.shift_start_time);
                                                                            setFieldValue("end_time", selectedShiftTiming?.shift_end_time);
                                                                            setFieldValue("shift_type", selectedShiftTiming?.shift_type);
                                                                            setCurrentShiftType(selectedShiftTiming?.shift_type);
                                                                        }
                                                                    }}
                                                                >
                                                                    <MenuItem value="">Select Shift Timing</MenuItem>
                                                                    {shiftTimings.length > 0 &&
                                                                        shiftTimings?.map((item: any, index) => {
                                                                            let shift = formatShiftTimings(item);
                                                                            return (
                                                                                <MenuItem value={item} key={index}>
                                                                                    {shift}
                                                                                </MenuItem>
                                                                            );
                                                                        })}
                                                                </Field>
                                                            </div>

                                                            <div className="shift-row mrg-top-30">

                                                                <Field
                                                                    SelectProps={showDropDownBelowField}
                                                                    id="input_shift_requirement_hcp_type"
                                                                    variant="outlined"
                                                                    select
                                                                    required
                                                                    name="requested_by"
                                                                    component={TextField}
                                                                    InputLabelProps={{shrink: true}}
                                                                    onChange={(e: any) => {
                                                                        const RequestedBy = e.target.value;
                                                                        console.log(RequestedBy);
                                                                        setFieldValue("requested_by", RequestedBy);
                                                                        //  handleHcpTypeChange(RequestedBy, setFieldValue);
                                                                    }}
                                                                    label="Requested By"
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="">Select Requested By</MenuItem>
                                                                    {shiftCreationRequestedByData &&
                                                                        shiftCreationRequestedByData.map((item: any, index: any) => (
                                                                            <MenuItem value={item.code} key={index}>
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                </Field>

                                                                <Field
                                                                    InputProps={{
                                                                        inputProps: {min: 0},
                                                                    }}
                                                                    InputLabelProps={{shrink: true}}
                                                                    type="number"
                                                                    autoComplete="off"
                                                                    id="input_shift_requirement_no_of_hcps"
                                                                    variant="outlined"
                                                                    name="hcp_count"
                                                                    component={TextField}
                                                                    label="No of Staff*"
                                                                    fullWidth
                                                                />


                                                            </div>


                                                            <div className="shift-date-range-wrapper">
                                                                <div className="shift-mode">
                                                                    <div className="">
                                                                        <FormLabel className={"form-label"}>Date
                                                                            Mode*</FormLabel>
                                                                    </div>
                                                                    <div className="mrg-top-10">
                                                                        <Field
                                                                            required
                                                                            component={RadioGroup}
                                                                            name="mode"
                                                                            InputLabelProps={{shrink: true}}
                                                                            onChange={(e: any) => {
                                                                                setFieldValue("mode", e.target.value);
                                                                                setMode(e.target.value);
                                                                            }}
                                                                        >
                                                                            <div className="d-flex">
                                                                                {calenderMode &&
                                                                                    calenderMode.map((item: any, index) => {
                                                                                        return (
                                                                                            <div>
                                                                                                <FormControlLabel

                                                                                                    key={"input_hcp_add_more_important_preference" + index}
                                                                                                    value={item.value}
                                                                                                    control={<Radio
                                                                                                        disabled={isSubmitting}/>}
                                                                                                    disabled={isSubmitting}
                                                                                                    name="mode"
                                                                                                    label={item.label}
                                                                                                />
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                            </div>
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="shift-calender">
                                                                    <Field
                                                                        disabled={!mode ? true : false}
                                                                        required
                                                                        inputClass="custom-input"
                                                                        className="rmdp-mobile"
                                                                        plugins={[<DatePanel eachDaysInRange/>]}
                                                                        format="MM/DD/YYYY"
                                                                        range={mode === "range" ? true : false}
                                                                        multiple={mode === "multiple" ? true : false}
                                                                        onChange={handleDatePicker}
                                                                        value={value}
                                                                        InputLabelProps={{shrink: true}}
                                                                        variant="inline"
                                                                        inputVariant="outlined"
                                                                        placeholder={
                                                                            mode === "multiple"
                                                                                ? "Select Single (or) Multiple Dates"
                                                                                : mode === "range"
                                                                                    ? "Select Date Range"
                                                                                    : "Please Select Date Mode"
                                                                        }
                                                                        id="input_shift_requirement_shift_datepicker"
                                                                        name="shift_dates"
                                                                        component={DatePickers}
                                                                    />
                                                                    <DateRangeOutlined
                                                                        style={handleShowHideCalender()}
                                                                        className="date-icon"
                                                                        fontSize="large"
                                                                        color="action"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <ShiftRateComponent facilityData={facilityData}
                                                                                currentHCPType={currentHCPType}
                                                                                setTotalShiftRate={setTotalShiftRate}
                                                                                setOtherRate={setOtherRate}

                                                            />

                                                            <div className="shift-rate-container">
                                                                <div className="shift-text-container">

                                                                    <p className="shift-sub-text">
                                                                        Please Select Yes,if you want the Shift Rate to
                                                                        Reflect
                                                                        in
                                                                        Mobile
                                                                        Application.
                                                                        <br/>
                                                                        Please Select No,if you do not want the Shift Rate
                                                                        to
                                                                        Reflect in
                                                                        Mobile Application
                                                                    </p>
                                                                </div>
                                                                <div className="shift-rate-actions">
                                                                    <NormalRadioGroup name="shift_rate" value={isShiftRate}
                                                                                      onChange={(e: any) => {
                                                                                          setIsShiftRate(e.target.value)
                                                                                      }}>
                                                                        <div className="d-flex">
                                                                            {genericAcknowledgement &&
                                                                                genericAcknowledgement.map((item: any, index) => {
                                                                                    return (
                                                                                        <div>
                                                                                            <FormControlLabel
                                                                                                key={"input_add_shift_rate" + index}
                                                                                                value={item.value}
                                                                                                control={<Radio required
                                                                                                                disabled={
                                                                                                                    // !facilityData?.show_shift_rate ||
                                                                                                                    isSubmitting
                                                                                                                }
                                                                                                />}
                                                                                                disabled={
                                                                                                    // !facilityData?.show_shift_rate ||
                                                                                                    isSubmitting}
                                                                                                name="shift_rate"
                                                                                                label={item.label}
                                                                                            />
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </div>
                                                                    </NormalRadioGroup>
                                                                </div>
                                                            </div>
                                                            <div className="shift-third-row  mrg-top-30">
                                                                <Field
                                                                    id="input_shift_requirement_shift_details"
                                                                    label="Shift Requirement Details*"
                                                                    placeholder="Type Shift Details Here"
                                                                    variant="outlined"
                                                                    component={TextField}
                                                                    type={"text"}
                                                                    name="shift_details"
                                                                    fullWidth
                                                                    multiline
                                                                    minRows={3}
                                                                    InputLabelProps={{shrink: true}}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="add-shift-btn-grp mrg-top-30">
                                                            <Button
                                                                id="btn_add_shift_requirement_delete"
                                                                color={"primary"}
                                                                variant={"outlined"}
                                                                type="reset"
                                                                onClick={() => {
                                                                    resetForm();
                                                                    setValue(null);
                                                                    setMode("");
                                                                    handleCancelAdd();
                                                                    setCurrentHCPType("");
                                                                    setIsShiftRate("true");
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                            <Button
                                                                type="submit"
                                                                id="btn_add_shift_requirement_save"
                                                                variant={"contained"}
                                                                className={"normal"}
                                                                color={"primary"}
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                )
                                            }}
                                        </Formik>}

                                    {selectedValue === 'home_health' &&
                                        <Formik initialValues={HomeShiftInitialState}
                                                validationSchema={HomeAddShiftsValidation}
                                                innerRef={formikRef}
                                                onSubmit={onHomeAdd}>
                                            {({
                                                  isSubmitting,
                                                  isValid,
                                                  resetForm,
                                                  handleChange,
                                                  setFieldValue,
                                                  values
                                              }) => (
                                                <Form className={"form-holder"} id="shift-add-form">
                                                    <div>
                                                        <div className="shift-row mrg-top-30">
                                                            <Field
                                                                disabled={false}
                                                                SelectProps={showDropDownBelowField}
                                                                id="input_shift_requirement_hcp_type"
                                                                variant="outlined"
                                                                select
                                                                required
                                                                name="hcp_type"
                                                                InputLabelProps={{shrink: true}}
                                                                component={TextField}
                                                                onChange={(e: any) => {
                                                                    const hcpType = e.target.value;
                                                                    console.log(e.target.value);
                                                                    setFieldValue("hcp_type", hcpType);
                                                                    setCurrentHCPType(hcpType);
                                                                    handleHcpTypeChange(hcpType, setFieldValue);
                                                                }}
                                                                label="Staff Type"
                                                                fullWidth
                                                            >
                                                                <MenuItem value="">Select Staff Type</MenuItem>
                                                                {hcpTypes &&
                                                                    hcpTypes.map((item: any, index: any) => (
                                                                        <MenuItem value={item.code} key={index}>
                                                                            {item?.title}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Field>

                                                            <Field
                                                                disabled={specIsLoading}
                                                                SelectProps={showDropDownBelowField}
                                                                id="input_shift_requirement_hcp_type_specalisation"
                                                                variant="outlined"
                                                                select
                                                                name="hcp_speciality"
                                                                component={TextField}
                                                                InputLabelProps={{shrink: true}}
                                                                onChange={(e: any) => {
                                                                    const hcpSpeciality = e.target.value;
                                                                    setFieldValue('hcp_speciality', hcpSpeciality);

                                                                }}
                                                                label="Staff Speciality"
                                                                fullWidth
                                                            >
                                                                <MenuItem value="">Select Speciality</MenuItem>
                                                                {hcpTypeSpecialities &&
                                                                    hcpTypeSpecialities.map((item: any, index: any) => (
                                                                        <MenuItem value={item.code} key={index}>
                                                                            {item.title}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Field>

                                                            <Field
                                                                SelectProps={showDropDownBelowField}
                                                                id="input_shift_requirement_shift_type"
                                                                variant="outlined"
                                                                disabled={false}
                                                                select
                                                                required
                                                                name="shift_type"
                                                                component={TextField}
                                                                InputLabelProps={{shrink: true}}
                                                                onChange={(e: any) => {
                                                                    const shiftType = e.target.value;
                                                                    console.log(e.target.value);
                                                                    setFieldValue("shift_type", shiftType);
                                                                    setCurrentShiftType(shiftType);
                                                                }}
                                                                label="Visit Type"
                                                                fullWidth
                                                            >
                                                                <MenuItem value="">Select Shift Type</MenuItem>
                                                                {home_shift_type &&
                                                                    home_shift_type.map((item: any, index: any) => (
                                                                        <MenuItem value={item.code} key={index}>
                                                                            {item?.title}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Field>
                                                        </div>


                                                        <div className={"mrg-top-30"}>
                                                            <div className={'form-header-text'}>
                                                                Visit Patient By
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="shift-row justify-content-space-between mrg-top-30">

                                                            <Field
                                                                variant="inline"
                                                                orientation="landscape"
                                                                openTo="date"
                                                                format="MM/dd/yyyy"
                                                                views={["year", "month", "date"]}
                                                                inputVariant="outlined"
                                                                component={DatePicker}
                                                                placeholder="MM/DD/YYYY"
                                                                fullWidth
                                                                required
                                                                disabled={false}
                                                                autoComplete="off"
                                                                InputLabelProps={{shrink: true}}
                                                                label="Visit Date"
                                                                name="shift_date"
                                                            />
                                                            <Field disabled={false} fullWidth variant="outlined"
                                                                   type="time"
                                                                   required
                                                                   component={TextField}
                                                                   name="shift_start_time" label="Visit Time"
                                                                   InputLabelProps={{shrink: true}}
                                                                   id="input_shift_add_shift_start_time"/>

                                                            <Field disabled={false}
                                                                   variant="outlined"
                                                                   name={'shift_rate'}
                                                                   type={"number"}
                                                                   required
                                                                   component={TextField}
                                                                   placeholder={"Enter Value"}
                                                                   label="Visit Rate"
                                                                   fullWidth autoComplete="off"
                                                                   id="patients_details_open_positions"
                                                                   InputLabelProps={{shrink: true}}
                                                                   InputProps={{
                                                                       startAdornment: <InputAdornment
                                                                           position="start">$</InputAdornment>,
                                                                   }}
                                                                // onChange={(e: any) => {
                                                                //     setFieldValue(`travel_per_diems.${index}.total`, e)
                                                                // }}
                                                            />
                                                            {/*<Field*/}
                                                            {/*    SelectProps={showDropDownBelowField}*/}
                                                            {/*    id="input_shift_requirement_hcp_type"*/}
                                                            {/*    variant="outlined"*/}
                                                            {/*    disabled={false}*/}
                                                            {/*    select*/}
                                                            {/*    required*/}
                                                            {/*    name="requested_by"*/}
                                                            {/*    component={TextField}*/}
                                                            {/*    onChange={(e: any) => {*/}
                                                            {/*        const RequestedBy = e.target.value;*/}
                                                            {/*        console.log(RequestedBy);*/}
                                                            {/*        setFieldValue("requested_by", RequestedBy);*/}
                                                            {/*        //  handleHcpTypeChange(RequestedBy, setFieldValue);*/}
                                                            {/*    }}*/}
                                                            {/*    label="Requested By"*/}
                                                            {/*    fullWidth*/}
                                                            {/*>*/}
                                                            {/*    <MenuItem value="">Select Requested By</MenuItem>*/}
                                                            {/*    {shiftCreationRequestedByData &&*/}
                                                            {/*        shiftCreationRequestedByData.map((item: any, index: any) => (*/}
                                                            {/*            <MenuItem value={item.code} key={index}>*/}
                                                            {/*                {item.name}*/}
                                                            {/*            </MenuItem>*/}
                                                            {/*        ))}*/}
                                                            {/*</Field>*/}

                                                        </div>

                                                        {/*<div className="shift-rate-container">*/}
                                                        {/*    <div className="shift-text-container">*/}
                                                        {/*        <FormLabel className="shift-text">Visit Rate</FormLabel>*/}
                                                        {/*        <p className="shift-sub-text">*/}
                                                        {/*            Please Select Yes,if you want the Visit Rate to Reflect in*/}
                                                        {/*            Mobile*/}
                                                        {/*            Application.*/}
                                                        {/*            <br/>*/}
                                                        {/*            Please Select No,if you do not want the visit Rate to*/}
                                                        {/*            Reflect in*/}
                                                        {/*            Mobile Application*/}
                                                        {/*        </p>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="shift-rate-actions">*/}
                                                        {/*        <NormalRadioGroup name="shift_rate" value={isShiftRate}*/}
                                                        {/*                          onChange={handleShiftRate}>*/}
                                                        {/*            <div className="d-flex">*/}
                                                        {/*                {genericAcknowledgement &&*/}
                                                        {/*                    genericAcknowledgement.map((item: any, index) => {*/}
                                                        {/*                        return (*/}
                                                        {/*                            <div>*/}
                                                        {/*                                <FormControlLabel*/}
                                                        {/*                                    key={"input_add_shift_rate" + index}*/}
                                                        {/*                                    value={item.value}*/}
                                                        {/*                                    control={<Radio required*/}
                                                        {/*                                                    disabled={*/}
                                                        {/*                                                        // !facilityData?.show_shift_rate ||*/}
                                                        {/*                                                        isSubmitting}/>}*/}
                                                        {/*                                    disabled={false}*/}
                                                        {/*                                    name="shift_rate"*/}
                                                        {/*                                    label={item.label}*/}
                                                        {/*                                />*/}
                                                        {/*                            </div>*/}
                                                        {/*                        );*/}
                                                        {/*                    })}*/}
                                                        {/*            </div>*/}
                                                        {/*        </NormalRadioGroup>*/}
                                                        {/*        <NormalTextField*/}
                                                        {/*            fullWidth*/}
                                                        {/*            inputProps={{maxLength: 5}}*/}
                                                        {/*            onChange={handleShiftRateValue}*/}
                                                        {/*            value={shiftRateValue}*/}
                                                        {/*            id="input_shift_rate"*/}
                                                        {/*            required={isShiftRate !== "false"}*/}
                                                        {/*            variant="outlined"*/}
                                                        {/*            placeholder={"Visit Rate"}*/}
                                                        {/*            disabled={isShiftRate === "false"}*/}
                                                        {/*        />*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}

                                                        {/*patient details wrapper*/}
                                                        <div className={"mrg-top-30"}>
                                                            <div className={'form-header-text'}>
                                                                Patients Details
                                                            </div>
                                                            <div>
                                                                <div className="shift-row mrg-top-20">
                                                                    <Field disabled={false} variant="outlined"
                                                                           name="first_name"
                                                                           type={"text"}
                                                                           component={TextField}
                                                                           required
                                                                           label="First Name" fullWidth
                                                                           autoComplete="off"
                                                                           InputLabelProps={{shrink: true}}
                                                                           id="patients_details_first_name"/>
                                                                    <Field disabled={false} variant="outlined"
                                                                           name="middle_name"
                                                                           type={"text"}
                                                                           component={TextField}
                                                                           label="Middle Name" fullWidth
                                                                           autoComplete="off"
                                                                           InputLabelProps={{shrink: true}}
                                                                           id="patients_details_middle_name"/>
                                                                    <Field disabled={false} variant="outlined"
                                                                           name="last_name"
                                                                           type={"text"}
                                                                           component={TextField}
                                                                           label="Last Name" fullWidth
                                                                           autoComplete="off"
                                                                           InputLabelProps={{shrink: true}}
                                                                           id="patients_details_last_name"/>
                                                                </div>
                                                                <div className="shift-row mrg-top-20">
                                                                    <Field
                                                                        SelectProps={showDropDownBelowField}
                                                                        disabled={false}
                                                                        id="input_shift_requirement_shift_type"
                                                                        variant="outlined"
                                                                        select
                                                                        name="patients_gender"
                                                                        required
                                                                        InputLabelProps={{shrink: true}}
                                                                        component={TextField}
                                                                        onChange={(e: any) => {
                                                                            const shiftType = e.target.value;
                                                                            console.log(e.target.value);
                                                                            setFieldValue("patients_gender", shiftType);
                                                                        }}
                                                                        label="Gender"
                                                                        fullWidth
                                                                    >
                                                                        <MenuItem value="">Select Gender</MenuItem>
                                                                        {patientsGender &&
                                                                            patientsGender.map((item: any, index: any) => (
                                                                                <MenuItem value={item.code} key={index}>
                                                                                    {item?.title}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Field>
                                                                    <Field
                                                                        variant="inline"
                                                                        orientation="landscape"
                                                                        openTo="date"
                                                                        disabled={false}
                                                                        format="MM/dd/yyyy"
                                                                        views={["year", "month", "date"]}
                                                                        inputVariant="outlined"
                                                                        component={DatePicker}
                                                                        placeholder="MM/DD/YYYY"
                                                                        fullWidth
                                                                        autoComplete="off"
                                                                        InputLabelProps={{shrink: true}}
                                                                        label="DOB"
                                                                        name="dob"
                                                                    />
                                                                    <Field variant="outlined" name="patient_mrn"
                                                                           type={"text"}
                                                                           component={TextField}
                                                                           disabled={false}
                                                                           InputLabelProps={{shrink: true}}
                                                                           label="MRN" fullWidth autoComplete=" off"
                                                                           id=" patients_details_last_name"/>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {/*patient address details*/}
                                                        <div className={"mrg-top-30"}>
                                                            <div className={'form-header-text'}>
                                                                Patient Address Details
                                                            </div>
                                                            <div>
                                                                <div className="shift-row mrg-top-20">
                                                                    <Field disabled={false} variant="outlined"
                                                                           name="patient_street"
                                                                           type={"text"}
                                                                           required
                                                                           component={TextField}
                                                                           InputLabelProps={{shrink: true}}
                                                                           label="Street" fullWidth autoComplete="off"
                                                                           id="patients_details_street_name"/>
                                                                    <Field disabled={false} variant="outlined"
                                                                           name="patient_apt"
                                                                           type={"text"}
                                                                           component={TextField}
                                                                           label="Apt/Suite" fullWidth
                                                                           InputLabelProps={{shrink: true}}
                                                                           autoComplete="off"
                                                                           id="patients_details_apt_name"/>
                                                                    <Field disabled={false} variant="outlined"
                                                                           name="patient_city"
                                                                           type={"text"}
                                                                           component={TextField}
                                                                           required
                                                                           InputLabelProps={{shrink: true}}
                                                                           label="City" fullWidth autoComplete="off"
                                                                           id="patients_details_city_name"/>
                                                                </div>
                                                                <div className="shift-row  mrg-top-20">
                                                                    {/*<Field disabled={false} variant="outlined" name="patient_state"*/}
                                                                    {/*       type={"text"}*/}
                                                                    {/*       component={TextField}*/}
                                                                    {/*       required*/}
                                                                    {/*       label="State" fullWidth autoComplete="off"*/}
                                                                    {/*       id="patients_details_state_name"/>*/}
                                                                    <Field SelectProps={showDropDownBelowField}
                                                                           variant="outlined"
                                                                           name="patient_state"
                                                                           id="patients_details_state_name"
                                                                           select
                                                                           required
                                                                           disabled={isLoading}
                                                                           InputLabelProps={{shrink: true}}
                                                                           type={"text"} component={TextField}
                                                                           label="State"
                                                                           fullWidth
                                                                           autoComplete="off">
                                                                        {regions &&
                                                                            regions.map((item: any, index: any) => (
                                                                                <MenuItem value={item.code}
                                                                                          key={"region_" + index}
                                                                                          id={"menu_hcp_add_region" + item.code}>
                                                                                    {item.code}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Field>
                                                                    <Field
                                                                        inputProps={{
                                                                            maxLength: 6,
                                                                        }}
                                                                        variant="outlined"
                                                                        name="patient_zip_code"
                                                                        type={"text"}
                                                                        component={TextField}
                                                                        label="Zip Code"
                                                                        fullWidth
                                                                        required
                                                                        InputLabelProps={{shrink: true}}
                                                                        autoComplete="off"
                                                                        id="input_facility_add_address_zip_code"
                                                                        onChange={(e: any) => {
                                                                            setFieldValue("patient_zip_code", e.target.value);
                                                                        }}
                                                                    />
                                                                    <Button variant={'contained'}
                                                                        //@ts-ignore
                                                                            disabled={!values?.patient_zip_code}
                                                                            color={"primary"}
                                                                            onClick={() => {
                                                                                //@ts-ignore
                                                                                getLatLong('', '', '', values?.patient_zip_code)
                                                                                    .then((coords: any) => {
                                                                                        // setLatitude(coords.latitude)
                                                                                        // setLongitude(coords.longitude)
                                                                                        setFieldValue("latitude", coords.latitude);
                                                                                        setFieldValue("longitude", coords.longitude)
                                                                                        CommonService.showToast("Latitude and Longitude Fetched", "success")
                                                                                        // const googleMapsUrl = `https://www.google.com/maps?q=${coords.latitude},${coords.longitude}`;
                                                                                        // window.open(googleMapsUrl, '_blank');
                                                                                    })
                                                                                    .catch((error) => CommonService.showToast("Not Able to fetch Latitude and Longitude due to geocode errors", "error"));
                                                                            }}>
                                                                        Check
                                                                    </Button>
                                                                    <Button variant={'contained'} color={"primary"}
                                                                        //@ts-ignore
                                                                            disabled={!values.latitude || !values.longitude}
                                                                            onClick={() => {
                                                                                //@ts-ignore
                                                                                getLatLong('', '', '', values?.patient_zip_code)
                                                                                    .then((coords: any) => {
                                                                                        //@ts-ignore
                                                                                        if (values.latitude && values.longitude) {
                                                                                            //@ts-ignore
                                                                                            const googleMapsUrl = `https://www.google.com/maps?q=${values.latitude},${values.longitude}`;
                                                                                            window.open(googleMapsUrl, '_blank');
                                                                                        }
                                                                                    })
                                                                                    .catch((error) => console.error(error));
                                                                            }}>
                                                                        Map
                                                                    </Button>

                                                                </div>
                                                                <div className="shift-row mrg-top-20">
                                                                    <Field disabled={false} variant="outlined"
                                                                           name="latitude"
                                                                           required
                                                                        //  value={latitude}
                                                                           InputLabelProps={{shrink: true}}
                                                                           type={"text"}
                                                                           component={TextField}
                                                                           label="Latitude" fullWidth
                                                                           autoComplete="off"
                                                                           id="patients_details_street_name"/>
                                                                    <Field disabled={false} variant="outlined"
                                                                           name="longitude"
                                                                           type={"text"}
                                                                           required
                                                                           InputLabelProps={{shrink: true}}
                                                                        // value={longitude}
                                                                           component={TextField}
                                                                           label="Longitude" fullWidth
                                                                           autoComplete="off"
                                                                           id="patients_details_apt_name"/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*patient contact details*/}
                                                        <div className={"mrg-top-30"}>
                                                            <div className={'form-header-text'}>
                                                                Patient Contact Details
                                                            </div>
                                                            <div>
                                                                <div className="shift-row width-minor mrg-top-20">
                                                                    <div style={{flex: 1}}>
                                                                        <Field
                                                                            inputProps={{ maxLength: 10,
                                                                                onInput: (e:any) => {
                                                                                    if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10);
                                                                                },
                                                                            }}
                                                                            className="phone_number"
                                                                            variant="outlined"
                                                                            name={"patient_phone_number"}
                                                                            type={"number"}
                                                                            required
                                                                            component={TextField}
                                                                            label="Phone Number"
                                                                            fullWidth
                                                                            autoComplete="off"
                                                                            id="phone_number_patient"
                                                                            InputLabelProps={{shrink: true}}
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        +1
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                        {/*<Field disabled={false}*/}
                                                                        {/*       name={"patient_phone_number"}*/}
                                                                        {/*       variant="outlined"*/}
                                                                        {/*       inputProps={{maxLength: 10}}*/}
                                                                        {/*       id="phone_number_patient"*/}
                                                                        {/*       style={{font: "inherit"}}>*/}
                                                                        {/*    {(field: FieldProps) => {*/}
                                                                        {/*        return <PhoneInputComponent*/}
                                                                        {/*            field={field}*/}
                                                                        {/*            placeholder={"Phone Number*"}/>;*/}
                                                                        {/*    }}*/}
                                                                        {/*</Field>*/}
                                                                    </div>
                                                                    <div style={{flex: 1}}>
                                                                        <Field disabled={false} variant="outlined"
                                                                               name="patient_email_address"
                                                                               type={"text"}
                                                                               component={TextField}
                                                                               InputLabelProps={{shrink: true}}
                                                                               label="Email Address" fullWidth
                                                                               autoComplete="off"
                                                                               id="patients_details_emial_address_name"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*more-details*/}

                                                        <div className={"mrg-top-30"}>
                                                            <div className={'form-header-text'}>
                                                                More Details
                                                            </div>
                                                            <div>
                                                                {/* width-minor*/}
                                                                <div className="shift-row mrg-top-20">
                                                                    <Field disabled={false} variant="outlined"
                                                                           name="number_of_visits"
                                                                           type={"number"}
                                                                           component={TextField}
                                                                           label="Total Number of Visits" fullWidth
                                                                           autoComplete="off"
                                                                           InputLabelProps={{shrink: true}}
                                                                           id="patients_details_number_of_visits_name"/>
                                                                    <Field disabled={false}
                                                                           SelectProps={showDropDownBelowField}
                                                                           id="input_shift_requirement_gender_preferences"
                                                                           variant="outlined"
                                                                           select
                                                                           name="gender_preferences"
                                                                           InputLabelProps={{shrink: true}}
                                                                           component={TextField}
                                                                           onChange={(e: any) => {
                                                                               const genderPreferences = e.target.value;
                                                                               setFieldValue("gender_preferences", genderPreferences);
                                                                           }}
                                                                           label="Gender Preferences"
                                                                           fullWidth
                                                                    >
                                                                        <MenuItem value="">Select Gender
                                                                            Preference </MenuItem>
                                                                        {patientsGender &&
                                                                            patientsGender.map((item: any, index: any) => (
                                                                                <MenuItem value={item.code} key={index}>
                                                                                    {item?.title}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Field>

                                                                </div>
                                                                <div className="shift-row  mrg-top-20">

                                                                    <div style={{flex: 1}}>
                                                                        <Field
                                                                            disabled={false}
                                                                            SelectProps={{
                                                                                multiple: true,
                                                                                renderValue: (selected: any) => selected.map((value: any) => {
                                                                                    const selectedItem = languagesList.find(item => item.code === value);
                                                                                    return selectedItem ? selectedItem.title : '';
                                                                                }).join(', '),
                                                                                ...showDropDownBelowField, // Include any additional custom SelectProps
                                                                            }}
                                                                            id="input_shift_requirement_language_preferences"
                                                                            variant="outlined"
                                                                            select
                                                                            name="language"
                                                                            InputLabelProps={{shrink: true}}
                                                                            component={TextField}
                                                                            onChange={(e: any) => {
                                                                                const languages = e.target.value;
                                                                                console.log(languages);
                                                                                setFieldValue("language", languages);
                                                                                setFieldValue("other_language", "");
                                                                            }}
                                                                            label="Languages"
                                                                            fullWidth
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>Select Language</em>
                                                                            </MenuItem>
                                                                            {languagesList &&
                                                                                languagesList.map((item: any, index: any) => (
                                                                                    <MenuItem value={item.code}
                                                                                              key={index}>
                                                                                        {item.title}
                                                                                    </MenuItem>
                                                                                ))}
                                                                        </Field>

                                                                    </div>
                                                                    <div style={{flex: 1}}>
                                                                        {values?.language?.includes('Other') &&
                                                                            <Field variant="outlined"
                                                                                   name="other_language"
                                                                                   type={"text"}
                                                                                   disabled={false}
                                                                                   component={TextField}
                                                                                   InputLabelProps={{shrink: true}}
                                                                                   label="Specify Other Language"
                                                                                   fullWidth
                                                                                   autoComplete=" off"
                                                                                   id="other_language"/>
                                                                        }
                                                                        {/*{values.language.includes("Other") && (*/}
                                                                        {/*    <Field*/}
                                                                        {/*        variant="outlined"*/}
                                                                        {/*        name="other_language"*/}
                                                                        {/*        type="text"*/}
                                                                        {/*        disabled={false}*/}
                                                                        {/*        component={TextField}*/}
                                                                        {/*        label="Specify Other Language"*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*        autoComplete="off"*/}
                                                                        {/*        id="other_language"*/}
                                                                        {/*    />*/}
                                                                        {/*)}*/}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div
                                                            className={'document-upload-wrapper d-flex justify-content-space-between mrg-top-20'}>
                                                            <div>
                                                                <div className={'form-header-text'}>
                                                                    Upload Documents
                                                                </div>
                                                                <div className={'file-upload-supported-text'}>
                                                                    Upload the relevant documents
                                                                </div>
                                                                <div className={'file-upload-supported-text'}>
                                                                    Supported formats : .pdf, .jpg, .jpeg, .png, .doc,
                                                                    .docx
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <FilePickerComponent maxFileCount={3}
                                                                                     height={'470px'}
                                                                                     id={" sc_upload_btn"}
                                                                                     btnText={" Select Files"}
                                                                                     disabled={timesheetFile?.length === 3}
                                                                                     multiple={true}
                                                                                     showDropZone={false}
                                                                                     onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                                                                         if (acceptedFiles && acceptedFiles.length > 0) {
                                                                                             const file = acceptedFiles[0];
                                                                                             //   setTimesheetFile(file);
                                                                                             setTimesheetFile([...timesheetFile, file]);
                                                                                         }
                                                                                     }}
                                                                                     acceptedFileTypes={["pdf", "jpeg", "jpg", "png", "doc", "docx"]}
                                                                                     uploadDescription={"(upload only one file)"}
                                                                                     acceptedFilesText={"Only .pdf files are supported"}
                                                                />
                                                                <div
                                                                    className={'d-flex justify-content-end mrg-top-5 file-upload-supported-text'}>
                                                                    Max upload : 3
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                                                            {timesheetFile && timesheetFile?.map((file: any, index: number) => {
                                                                return (
                                                                    <>
                                                                        <div
                                                                            className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>
                                                                            <div>  {file?.name}</div>
                                                                            <div className={'cursor-pointer'}
                                                                                 onClick={() => {
                                                                                     removeFile(index);
                                                                                 }}>
                                                                                <DeleteIcon/>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                        {/*<div className="shift-third-row  mrg-top-30">*/}
                                                        {/*    <Field*/}
                                                        {/*        id="input_shift_requirement_shift_details"*/}
                                                        {/*        label="Shift Requirement Details"*/}
                                                        {/*        placeholder="Type Shift Details Here"*/}
                                                        {/*        variant="outlined"*/}
                                                        {/*        component={TextField}*/}
                                                        {/*        type={"text"}*/}
                                                        {/*        name="shift_details"*/}
                                                        {/*        disabled={false}*/}
                                                        {/*        fullWidth*/}
                                                        {/*        multiline*/}
                                                        {/*        minRows={4}*/}
                                                        {/*        maxRows={4}*/}
                                                        {/*    />*/}
                                                        {/*</div>*/}
                                                    </div>

                                                    <div className="add-shift-btn-grp mrg-top-30">
                                                        <Button
                                                            id="btn_add_shift_requirement_delete"
                                                            color={"primary"}
                                                            variant={"outlined"}
                                                            type="reset"
                                                            onClick={() => {
                                                                resetForm();
                                                                setValue(null);
                                                                setMode("");
                                                                handleCancelAdd();
                                                                setCurrentHCPType("");
                                                                setIsShiftRate("true");
                                                                setSelectedValue("open_shift");
                                                                setCurrentShiftType("")
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>
                                                        <Button
                                                            //  disabled={timesheetFile?.length===0}
                                                            type="submit"
                                                            id="btn_add_shift_requirement_save"
                                                            variant={"contained"}
                                                            className={"normal"}
                                                            color={"primary"}
                                                            disabled={!values?.shift_date}
                                                        >
                                                            Save
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>}

                                    {selectedValue === "contract-travel" &&
                                        <Formik
                                            innerRef={formikRef}
                                            onSubmit={onContractAdd} initialValues={contractShiftInitialState}
                                            validationSchema={ContractAddShiftsValidation}>
                                            {({
                                                  isSubmitting,
                                                  isValid,
                                                  resetForm,
                                                  handleChange,
                                                  setFieldValue,
                                                  values
                                              }) => (
                                                <Form className={"form-holder"} id="shift-add-form">
                                                    <div className="shift-open-container">
                                                        <div className={'form-header-text'}>
                                                            Opportunity Details
                                                        </div>
                                                        <div className="input-container mrg-top-20">
                                                            <Field
                                                                disabled={false}
                                                                SelectProps={showDropDownBelowField}
                                                                id="input_shift_requirement_hcp_type"
                                                                variant="outlined"
                                                                select
                                                                required
                                                                name="hcp_type"
                                                                InputLabelProps={{shrink: true}}
                                                                component={TextField}
                                                                onChange={(e: any) => {
                                                                    const hcpType = e.target.value;
                                                                    console.log(e.target.value);
                                                                    setFieldValue("hcp_type", hcpType);
                                                                    setCurrentHCPType(hcpType);
                                                                    handleHcpTypeChange(hcpType, setFieldValue);
                                                                }}
                                                                label="Staff Type"
                                                                fullWidth
                                                            >
                                                                <MenuItem value="">Select Staff Type</MenuItem>
                                                                {hcpTypes &&
                                                                    hcpTypes.map((item: any, index: any) => (
                                                                        <MenuItem value={item.code} key={index}>
                                                                            {item?.title}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Field>

                                                            <Field
                                                                disabled={specIsLoading}
                                                                SelectProps={showDropDownBelowField}
                                                                id="input_shift_requirement_hcp_type_specalisation"
                                                                variant="outlined"
                                                                select
                                                                InputLabelProps={{shrink: true}}
                                                                name="hcp_speciality"
                                                                component={TextField}
                                                                onChange={(e: any) => {
                                                                    const hcpSpeciality = e.target.value;
                                                                    setFieldValue('hcp_speciality', hcpSpeciality);
                                                                }}
                                                                label="Staff Speciality"
                                                                fullWidth
                                                            >
                                                                <MenuItem value="">Select Speciality</MenuItem>
                                                                {hcpTypeSpecialities &&
                                                                    hcpTypeSpecialities.map((item: any, index: any) => (
                                                                        <MenuItem value={item.code} key={index}>
                                                                            {item.title}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Field>
                                                            <Field disabled={false} variant="outlined" name="hcp_count"
                                                                   type={"number"}
                                                                   component={TextField}
                                                                   required
                                                                   InputLabelProps={{shrink: true}}
                                                                   label="# of Positions" fullWidth autoComplete="off"
                                                                   id="patients_details_open_positions"/>
                                                        </div>

                                                        <div className={'form-header-text mrg-top-30'}>
                                                            Job Details
                                                        </div>

                                                        <div className="input-container mrg-top-20">
                                                            <Field
                                                                SelectProps={showDropDownBelowField}
                                                                id="input_shift_requirement_shift_type"
                                                                variant="outlined"
                                                                disabled={false}
                                                                select
                                                                required
                                                                InputLabelProps={{shrink: true}}
                                                                name="contract_requirements.contract_type"
                                                                component={TextField}
                                                                onChange={(e: any) => {
                                                                    const shiftType = e.target.value;
                                                                    console.log(e.target.value);
                                                                    setFieldValue("contract_requirements.contract_type", shiftType);
                                                                }}
                                                                label="Job Type"
                                                                fullWidth
                                                            >
                                                                <MenuItem value="">Select Job Type</MenuItem>
                                                                {contractShiftType &&
                                                                    contractShiftType.map((item: any, index: any) => (
                                                                        <MenuItem value={item.code} key={index}>
                                                                            {item?.title}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Field>
                                                            <Field
                                                                SelectProps={showDropDownBelowField}
                                                                id="input_shift_requirement_shift_days_per_week"
                                                                variant="outlined"
                                                                disabled={false}
                                                                select
                                                                required
                                                                InputLabelProps={{shrink: true}}
                                                                name="contract_requirements.days_per_week"
                                                                component={TextField}
                                                                onChange={(e: any) => {
                                                                    const shiftType = e.target.value;
                                                                    console.log(e.target.value);
                                                                    setFieldValue("contract_requirements.days_per_week", shiftType);
                                                                }}
                                                                label="# of Days per Week"
                                                                fullWidth
                                                            >
                                                                {/*<MenuItem value="">Select Job Type</MenuItem>*/}
                                                                {weekDaysList &&
                                                                    weekDaysList.map((item: any, index: any) => (
                                                                        <MenuItem value={item.code} key={index}>
                                                                            {item?.title}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Field>
                                                            <Field
                                                                SelectProps={showDropDownBelowField}
                                                                id="input_shift_requirement_shift_type"
                                                                variant="outlined"
                                                                disabled={false}
                                                                select
                                                                required
                                                                InputLabelProps={{shrink: true}}
                                                                name="contract_requirements.shift_duration"
                                                                component={TextField}
                                                                onChange={(e: any) => {
                                                                    const shiftType = e.target.value;
                                                                    console.log(e.target.value);
                                                                    setFieldValue("contract_requirements.shift_duration", shiftType);
                                                                }}
                                                                label="Hours per Shift"
                                                                fullWidth
                                                            >
                                                                <MenuItem value=""> Select Duration</MenuItem>
                                                                {shiftDurationList &&
                                                                    shiftDurationList.map((item: any, index: any) => (
                                                                        <MenuItem value={item.code} key={index}>
                                                                            {item?.title}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Field>
                                                        </div>


                                                        <div className="input-container mrg-top-20">
                                                            <Field
                                                                SelectProps={showDropDownBelowField}
                                                                id="input_shift_requirement_shift_type"
                                                                variant="outlined"
                                                                disabled={false}
                                                                select
                                                                required
                                                                InputLabelProps={{shrink: true}}
                                                                name="shift_type"
                                                                component={TextField}
                                                                onChange={(e: any) => {
                                                                    const shiftType = e.target.value;
                                                                    console.log(e.target.value);
                                                                    setFieldValue("shift_type", shiftType);
                                                                    setCurrentShiftType(shiftType);
                                                                }}
                                                                label="Shift Type"
                                                                fullWidth
                                                            >
                                                                <MenuItem value="">Select Shift Type</MenuItem>
                                                                {contactTypeList &&
                                                                    contactTypeList.map((item: any, index: any) => (
                                                                        <MenuItem value={item.code} key={index}>
                                                                            {item?.title}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Field>
                                                            <Field
                                                                variant="inline"
                                                                orientation="landscape"
                                                                openTo="date"
                                                                format="MM/dd/yyyy"
                                                                views={["year", "month", "date"]}
                                                                inputVariant="outlined"
                                                                component={DatePicker}
                                                                placeholder="MM/DD/YYYY"
                                                                fullWidth
                                                                required
                                                                InputLabelProps={{shrink: true}}
                                                                disabled={false}
                                                                autoComplete="off"
                                                                label="Start Date"
                                                                name="start_date"
                                                            />
                                                            <Field
                                                                variant="inline"
                                                                orientation="landscape"
                                                                openTo="date"
                                                                format="MM/dd/yyyy"
                                                                views={["year", "month", "date"]}
                                                                inputVariant="outlined"
                                                                component={DatePicker}
                                                                placeholder="MM/DD/YYYY"
                                                                fullWidth
                                                                required
                                                                InputLabelProps={{shrink: true}}
                                                                disabled={false}
                                                                autoComplete="off"
                                                                label="End Date"
                                                                name="end_date"
                                                            />
                                                        </div>


                                                        <div className="input-container-minor mrg-top-20">
                                                            <Field disabled={false} variant="outlined"
                                                                   name="contract_requirements.no_of_weeks"
                                                                   type={"number"}
                                                                   component={TextField}
                                                                   required
                                                                   InputLabelProps={{shrink: true}}
                                                                   label="# of Weeks" fullWidth autoComplete="off"
                                                                   id="patients_details_open_positions"/>
                                                            <div>
                                                            </div>
                                                        </div>


                                                        <div className={'form-header-text mrg-top-30'}>
                                                            Rates
                                                        </div>

                                                        {/*patient details wrapper*/}
                                                        <div className={"mrg-top-20"}>
                                                            <div>
                                                                <FieldArray name="travel_per_diems">
                                                                    {({push, remove}) => (
                                                                        <div className="card">
                                                                            <div>
                                                                                {values?.travel_per_diems?.map((perDiem: any, index: number) => (
                                                                                    <div
                                                                                        className="shift-row mrg-top-20">
                                                                                        <Field
                                                                                            variant="outlined"
                                                                                            name={`travel_per_diems.${index}.type`}
                                                                                            type={"text"}
                                                                                            disabled={index < 3}
                                                                                            component={TextField}
                                                                                            InputLabelProps={{shrink: true}}
                                                                                            placeholder={'Enter value'}
                                                                                            //  label="Travel Per Diems*"
                                                                                            fullWidth autoComplete="off"
                                                                                            id="patients_details_open_positions"
                                                                                        />
                                                                                        {index === 0 &&
                                                                                            <Field disabled={false}
                                                                                                   variant="outlined"
                                                                                                   name={`travel_per_diems.${index}.rate`}
                                                                                                   type={"number"}
                                                                                                   component={TextField}
                                                                                                   InputLabelProps={{shrink: true}}
                                                                                                //  placeholder={""}
                                                                                                   label="$/hr"
                                                                                                   fullWidth
                                                                                                   autoComplete="off"
                                                                                                   id="patients_details_open_positions"
                                                                                                   InputProps={{
                                                                                                       startAdornment:
                                                                                                           <InputAdornment
                                                                                                               position="start">$</InputAdornment>,
                                                                                                   }}
                                                                                                // onChange={(e: any) => {
                                                                                                //     setFieldValue(`travel_per_diems.${index}.total`, e)
                                                                                                // }}
                                                                                            />}
                                                                                        {index > 0 &&
                                                                                            <Field disabled={false}
                                                                                                   variant="outlined"
                                                                                                   name={`travel_per_diems.${index}.rate`}
                                                                                                   type={"number"}
                                                                                                   component={TextField}
                                                                                                //  placeholder={"$/day"}
                                                                                                   label="$/day"
                                                                                                   fullWidth
                                                                                                   InputLabelProps={{shrink: true}}
                                                                                                   autoComplete="off"
                                                                                                   id="patients_details_open_positions"
                                                                                                   InputProps={{
                                                                                                       startAdornment:
                                                                                                           <InputAdornment
                                                                                                               position="start">$</InputAdornment>,
                                                                                                   }}
                                                                                                // onChange={(e: any) => {
                                                                                                //     setFieldValue(`travel_per_diems.${index}.total`, e)
                                                                                                // }}
                                                                                            />
                                                                                        }
                                                                                        <div className={'d-flex'}
                                                                                             style={{minWidth: "31.5%"}}>
                                                                                            <Field disabled={false}
                                                                                                   variant="outlined"
                                                                                                   name={`travel_per_diems.${index}.week_rate`}
                                                                                                   type={"number"}
                                                                                                   InputLabelProps={{shrink: true}}
                                                                                                   component={TextField}
                                                                                                   label="$/week"
                                                                                                   fullWidth
                                                                                                   autoComplete="off"
                                                                                                //  placeholder={'$/week'}
                                                                                                   id="patients_details_open_positions"
                                                                                                   InputProps={{
                                                                                                       startAdornment:
                                                                                                           <InputAdornment
                                                                                                               position="start">$</InputAdornment>,
                                                                                                   }}
                                                                                                // value={131}
                                                                                                //@ts-ignore
                                                                                                //  value={`${Number(values?.travel_per_diems[index]?.rate) * Number(values?.contract_requirements?.days_per_week) || 0}`}
                                                                                            />
                                                                                            {(values?.travel_per_diems?.length > 3 && index > 2) &&
                                                                                                <div
                                                                                                    className="ts-col-1 ts-justify-content-center ts-align-content-center mrg-top-10 mrg-left-10">
                                                                                                    <div
                                                                                                        onClick={() => {
                                                                                                            remove(index)
                                                                                                            // console.log(values)
                                                                                                        }}
                                                                                                        className={'cursor-pointer'}>
                                                                                                        <img
                                                                                                            src={ImageConfig.ContractDelete}
                                                                                                            alt={ImageConfig.ContractDelete}/>
                                                                                                    </div>
                                                                                                </div>}
                                                                                        </div>

                                                                                    </div>
                                                                                ))}
                                                                                <div
                                                                                    className={'mrg-bottom-10 mrg-top-20'}>
                                                                                    <div
                                                                                        className={'d-flex justify-content-space-between'}>
                                                                                        <div
                                                                                            className={'d-flex ts-justify-content-center align-items-center'}>
                                                                                            <Button
                                                                                                variant={"outlined"}
                                                                                                color={"primary"}

                                                                                                onClick={() =>
                                                                                                    push({
                                                                                                        type: '',
                                                                                                        rate: "",
                                                                                                        total: ""
                                                                                                    })
                                                                                                }
                                                                                            >
                                                                                                Add More
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </FieldArray>


                                                            </div>
                                                        </div>

                                                        {/*other-details*/}

                                                        {/*<div className="shift-rate-container">*/}
                                                        {/*    <div className="shift-text-container">*/}
                                                        {/*        <FormLabel className="shift-text">Shift Rate</FormLabel>*/}
                                                        {/*        <p className="shift-sub-text">*/}
                                                        {/*            Please Select Yes,if you want the Shift Rate to Reflect in*/}
                                                        {/*            Mobile*/}
                                                        {/*            Application.*/}
                                                        {/*            <br/>*/}
                                                        {/*            Please Select No,if you do not want the Shift Rate to*/}
                                                        {/*            Reflect in*/}
                                                        {/*            Mobile Application*/}
                                                        {/*        </p>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="shift-rate-actions">*/}
                                                        {/*        <NormalRadioGroup name="shift_rate" value={isShiftRate}*/}
                                                        {/*                          onChange={handleShiftRate}>*/}
                                                        {/*            <div className="d-flex">*/}
                                                        {/*                {genericAcknowledgement &&*/}
                                                        {/*                    genericAcknowledgement.map((item: any, index) => {*/}
                                                        {/*                        return (*/}
                                                        {/*                            <div>*/}
                                                        {/*                                <FormControlLabel*/}
                                                        {/*                                    key={"input_add_shift_rate" + index}*/}
                                                        {/*                                    value={item.value}*/}
                                                        {/*                                    control={<Radio required*/}
                                                        {/*                                                    disabled={*/}
                                                        {/*                                                        // !facilityData?.show_shift_rate ||*/}
                                                        {/*                                                        isSubmitting}/>}*/}
                                                        {/*                                    disabled={false}*/}
                                                        {/*                                    name="shift_rate"*/}
                                                        {/*                                    label={item.label}*/}
                                                        {/*                                />*/}
                                                        {/*                            </div>*/}
                                                        {/*                        );*/}
                                                        {/*                    })}*/}
                                                        {/*            </div>*/}
                                                        {/*        </NormalRadioGroup>*/}
                                                        {/*        <NormalTextField*/}
                                                        {/*            fullWidth*/}
                                                        {/*            inputProps={{maxLength: 5}}*/}
                                                        {/*            onChange={handleShiftRateValue}*/}
                                                        {/*            value={shiftRateValue}*/}
                                                        {/*            id="input_shift_rate"*/}
                                                        {/*            required={isShiftRate !== "false"}*/}
                                                        {/*            variant="outlined"*/}
                                                        {/*            placeholder={"Shift Rate"}*/}
                                                        {/*            disabled={isShiftRate === "false"}*/}
                                                        {/*        />*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}


                                                        <div className={'form-header-text mrg-top-30'}>
                                                            Cancel Policy*
                                                        </div>
                                                        <div className="shift-third-row  mrg-top-20">
                                                            <Field
                                                                id="input_shift_requirement_shift_details"
                                                                //label="Enter cancel policy"
                                                                placeholder="Enter cancel policy"
                                                                variant="outlined"
                                                                component={TextField}
                                                                type={"text"}
                                                                name="contract_requirements.cancel_policy"
                                                                disabled={false}
                                                                fullWidth
                                                                required
                                                                multiline
                                                                InputLabelProps={{shrink: true}}
                                                                minRows={4}
                                                                maxRows={4}
                                                            />
                                                        </div>

                                                        <div className={'form-header-text mrg-top-30'}>
                                                            Staff Requirement
                                                        </div>

                                                        <div className="shift-third-row  mrg-top-20">
                                                            <Field
                                                                id="input_shift_requirement_shift_details"
                                                                //  label="Shift Requirement Details"
                                                                placeholder="References, Skills, certifications, or required years of experience"
                                                                variant="outlined"
                                                                component={TextField}
                                                                type={"text"}
                                                                name="contract_requirements.staff_requirements"
                                                                disabled={false}
                                                                fullWidth
                                                                multiline
                                                                InputLabelProps={{shrink: true}}
                                                                minRows={4}
                                                                maxRows={4}
                                                            />
                                                        </div>

                                                        <div className={'form-header-text mrg-top-30'}>
                                                            Job Details
                                                        </div>

                                                        <div className="shift-third-row  mrg-top-30">
                                                            <Field
                                                                id="input_shift_requirement_shift_details"
                                                                //  label="Shift Requirement Details"
                                                                placeholder="Enter any relevant details about the job"
                                                                variant="outlined"
                                                                component={TextField}
                                                                type={"text"}
                                                                name="contract_requirements.job_details"
                                                                disabled={false}
                                                                fullWidth
                                                                InputLabelProps={{shrink: true}}
                                                                multiline
                                                                minRows={4}
                                                                maxRows={4}
                                                            />
                                                        </div>

                                                        <div className={'form-header-text mrg-top-30'}>
                                                            Job Benefits
                                                        </div>

                                                        <div className="shift-third-row  mrg-top-30">
                                                            <Field
                                                                id="input_shift_requirement_shift_details"
                                                                // label="Shift Requirement Details"
                                                                placeholder={'• Allied travel assignments are typically for 13 weeks and offer ​ generous packages that include: \n' +
                                                                    '• Competitive pay rates \n' +
                                                                    '• Medical, Dental, Vision \n' +
                                                                    '• 401(k) and Flex Spending \n' +
                                                                    '• Life Insurance \n' +
                                                                    '• Accident and Short-term Disability Coverage \n' +
                                                                    '• Free Continuing Education \n' +
                                                                    '• Competitive Housing Deal \n' +
                                                                    '• Refer a friend and earn extra cash!'}
                                                                variant="outlined"
                                                                component={TextField}
                                                                type={"text"}
                                                                name="contract_requirements.job_benefits"
                                                                disabled={false}
                                                                fullWidth
                                                                multiline
                                                                InputLabelProps={{shrink: true}}
                                                                minRows={9}
                                                                maxRows={9}
                                                            />
                                                        </div>


                                                    </div>

                                                    <div className="add-shift-btn-grp mrg-top-30">
                                                        <Button
                                                            id="btn_add_shift_requirement_delete"
                                                            color={"primary"}
                                                            variant={"outlined"}
                                                            type="reset"
                                                            onClick={() => {
                                                                resetForm();
                                                                setValue(null);
                                                                setMode("");
                                                                handleCancelAdd();
                                                                setCurrentHCPType("");
                                                                setIsShiftRate("true");
                                                                setSelectedValue("open_shift");
                                                                setCurrentShiftType("")
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>
                                                        <Button
                                                            //  disabled={timesheetFile?.length===0}
                                                            type="submit"
                                                            id="btn_add_shift_requirement_save"
                                                            variant={"contained"}
                                                            className={"normal"}
                                                            color={"primary"}
                                                            disabled={!values?.contract_requirements?.cancel_policy || !values?.start_date || !values?.end_date}
                                                        >
                                                            Save
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>}

                                </div>
                            )}

                            {shifts.length > 0 &&
                                shifts.map((item: ShiftItem, index: any) => (
                                    <ReadOnlyShifts
                                        setCurrentHCPType={setCurrentHCPType}
                                        mode={mode}
                                        facilityOffset={facilityOffset}
                                        key={index}
                                        item={item}
                                        shifts={shifts}
                                        setShifts={setShifts}
                                    />
                                ))}

                            {shifts.length > 0 && (
                                <div className="shift-actions mrg-top-30">
                                    <Button
                                        id="btn_add_shift_requirement_cancel_requirement"
                                        type="reset"
                                        size="large"
                                        variant={"outlined"}
                                        className={"normal"}
                                        color={"primary"}
                                        onClick={openAdd}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        id="btn_add_shift_requirement_save_requirement"
                                        disabled={doubleClick}
                                        onClick={() => {
                                            setDoubleClick(true);
                                            onSubmit();
                                        }}
                                        size="large"
                                        variant={"contained"}
                                        color={"primary"}
                                        className={doubleClick ? "has-loading-spinner" : ""}
                                    >
                                        {doubleClick ? "Saving Requirement" : "Save Requirement"}
                                    </Button>
                                </div>
                            )}
                        </div>
                    }
                    {
                        selectedRequirementCreationValue === "create_approved_shift" && <div>
                            <ApprovedShiftCreationComponent shiftTimings={shiftTimings} facilityId={facilityId}
                                                            setSelectedApprovedTypeValue={setSelectedApprovedTypeValue}
                                                            selectedApprovedTypeValue={selectedApprovedTypeValue}/>
                        </div>
                    }

                    <ScrollToTop smooth color="#10c4d3"/>
                </div>
            </div>
        )
    );
};

export default AddShiftsScreen;
