import './ModalComponent.scss';
import {Modal} from "antd";
import React, {ReactNode} from "react";

interface ModalComponentProps {
    open: boolean;
    title?: string;
    classname?: string;
    onCancel: () => void;
    closable?: boolean;
    footer?: ReactNode;
    onSuccess?: () => void;
    successText?: string;
    width?: number | string;
    keyboard?: boolean;
    loading?: boolean;
    centered?: boolean;
    confirmLoading?: boolean;
    closeIcon?: ReactNode;
}

const ModalComponent = (props: React.PropsWithChildren<ModalComponentProps>) => {
    const {
        open,
        title,
        classname,
        closable,
        footer,
        onSuccess,
        onCancel,
        successText,
        keyboard,
        loading,
        centered,
        closeIcon,
        confirmLoading,
        children
    } = props;

    const width = props.width || 500;

    return (
        <Modal
            data-test-id={'modal-component'}
            className={"modal-component" + classname ? classname : ''}
            open={open}
            closable={closable}
            onCancel={onCancel}
            onOk={onSuccess}
            width={width}
            okText={successText}
            footer={footer}
            keyboard={keyboard}
            title={title}
            centered={centered}
            closeIcon={closeIcon}
            loading={loading}
            confirmLoading={confirmLoading}
            zIndex={999999999}
        >
            {children}
        </Modal>
    )
}
export default ModalComponent;


//Usage:

//
//                                         <AntButton onClick={() => setModalVisible(true)}>
//                                             Open Modal
//                                         </AntButton>
//                                         <ModalComponent title={"Modal Title"}
//                                                         open={modalVisible}
//                                                         successText={"Save"}
//                                                         onSuccess={()=>setModalVisible(false)}
//                                                         onCancel={() => setModalVisible(false)}
//                                                         closable={true}
//                                         >
//                                             <p>Modal Content goes here...</p>
//                                         </ModalComponent>
