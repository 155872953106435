import { Button } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { SearchRounded } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useParams } from "react-router";
import animationData from "../../../../../assets/animations/NoData.json";
import LoaderComponent from "../../../../../components/LoaderComponent";
import { ENV } from "../../../../../constants";
import { CssTextField, useStyles } from "../../../../../constants/data/styles";
import { CommonService } from "../../../../../helpers";
import "./AddHcpToNC.scss";


export interface addHcpComponentProps {
  cancel: () => void;
  confirm: () => void;
  hcp_type: any;
  getList: () => void;
}



const AddHcpToNC = (props: PropsWithChildren<addHcpComponentProps>) => {
  const params = useParams<{ id: string }>();
  const { id } = params;
  const getList = props?.getList;
  const afterCancel = props?.cancel;
  // const hcp_type = props?.hcp_type;
  const afterConfirm = props?.confirm;
  const [selectedHcps, setSelectedHcps] = useState<any>([]);
  const classes = useStyles();
  const [hcpList, sethcpList] = React.useState<any>(null);
  const [isSubmitting, setSubmitting] = useState<boolean>(true);
  const [searchHcp, setSearchHcp] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSelectedCount, setIsSelectedCount] = useState<any>(-1);

  const defaultOptions = {
    animationData,
  };

  const init = useCallback(() => {
    setIsLoading(true);
    let payload: any = {
      is_approved: true,
      is_nc_assigned: false,
      is_active:true,
    };
    if (searchHcp !== "") {
      payload = {
        is_approved: true,
        search: searchHcp,
        is_nc_assigned: false,
      is_active:true,

      };
    }


    CommonService._api
      .post(ENV.API_URL + "hcp/lite", payload)
      .then((resp) => {
        sethcpList(resp?.data);
        setIsLoading(false);
      })
      .catch((err) => {
       // console.log(err);
        setIsLoading(false);
      });
  }, [searchHcp]);

  const handleChange = (event: any) => {
    let index = selectedHcps.indexOf(event.target.value);
    let tempHcps = [];
    if (index > -1) {
      setIsSelectedCount(selectedHcps?.length - 1 === 0 ? -1 : selectedHcps?.length - 1);
      tempHcps = selectedHcps.filter((item: any) => item !== event.target.value);
    } else {
      setIsSelectedCount(selectedHcps?.length + 1);
      tempHcps = [...selectedHcps, event.target.value];
    }
    setSelectedHcps(tempHcps);
  };

  const cancel = (resetForm: any) => {
    if (afterCancel) {
      afterCancel();
    }
  };

  const onAdd = () => {
    setSubmitting(false);
    let payload = {
      hcp_ids: selectedHcps,
    };
    CommonService._api
      .post(ENV.API_URL + "user/" + id + "/linkNC", payload)
      .then((resp) => {
        CommonService.showToast(resp?.msg || "Success", "success");
        if (afterConfirm) {
          setSubmitting(true);
          afterConfirm();
          getList();
        }
      })
      .catch((err) => {
        console.log(err);
        CommonService.showToast(err.error || "Error", "error");
        setSubmitting(true);
      });
  };

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className="add-hcp-requirment">
      <div className={classes.paper}>
        <h2 className={classes.title}>Add Staff to NC</h2>
        <FormLabel component="legend" className="mrg-left-0">
          Select Staff
        </FormLabel>
        <div id="alert-dialog-title">
          <div className="mrg-top-20">
            <div>
              <div className="d-flex">
                <div className="d-flex position-relative">
                  <CssTextField
                    defaultValue={""}
                    onChange={(event) => {
                      setSearchHcp(event?.target?.value);
                    }}
                    className="searchField"
                    variant={"outlined"}
                    size={"small"}
                    type={"text"}
                    placeholder={"Search Staff"}
                    value={searchHcp}
                  />
                  {searchHcp === "" ? (
                    <div className={"search_icon"}>
                      <SearchRounded />
                    </div>
                  ) : (
                    <div className={"search_icon"}>
                      <ClearIcon onClick={(event) => setSearchHcp("")} id="clear_hcp_search" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mrg-top-20">
            {isLoading && <LoaderComponent position="block" />}
            {hcpList?.map((item: any) => {
              return (
                <div key={item?._id}>
                  <FormControlLabel value={item?._id} control={<Checkbox />} onChange={(event) => handleChange(event)} label={item?.first_name + " " + item?.last_name} />
                </div>
              );
            })}
          </div>
        </div>

        {!isLoading && hcpList?.length === 0 && (
          <div className={"display-flex flex-one mrg-top-20 mrg-bottom-20"}>
            <Lottie width={400} height={400} speed={1} options={defaultOptions} />
          </div>
        )}

        {hcpList && hcpList.length > 0 ? (
          <div className={classes.assignNcActions}>
            <Button type={"submit"} size="large" variant={"outlined"} className={"normal"} onClick={cancel}>
              Back
            </Button>
            <Button type={"submit"} color={"primary"} size="large" disabled={isSelectedCount === -1 || !isSubmitting} variant={"contained"} className={!isSubmitting ? "mrg-left-30 has-loading-spinner" : "mrg-left-30"} onClick={onAdd}>
              {!isSubmitting ? "Assigning" : "Assign"}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AddHcpToNC;
