import React, { useEffect, useMemo} from "react";
import { withStyles } from "@material-ui/core/styles";
import { AddRounded, SearchRounded } from "@material-ui/icons";
import { Button, TextField, Tooltip } from "@material-ui/core";
import { CommonService, Communications} from "../../../helpers";
import {ENV, ImageConfig} from "../../../constants";
import ClearIcon from "@material-ui/icons/Clear";
import { Link } from "react-router-dom";
import "./EmployeeListScreen.scss";
import AccessControlComponent from "../../../components/AccessControl";
import { ADMIN } from "../../../helpers/common-service";
import {fetchRegionsRequest} from "../../../store/actions/meta.action";
import {useDispatch} from "react-redux";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../components/ant-table-component/AntTableComponent";
import {useSessionStorage} from "../../../components/useSessionStorage";

const CssTextField = withStyles({
    root: {
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: "#10c4d3",
            },
        },
    },
})(TextField);

const EmployeeListScreen = () => {
    const dispatch=useDispatch()
    const [filterData,setFilterData]=useSessionStorage<any>("vitaEmployeeFilterData",{
        search:'',
        sort:{}
    })


    useEffect(() => {
        Communications.pageTitleSubject.next("Team Members");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    useEffect(()=>{
        dispatch(fetchRegionsRequest())
    },[dispatch])

    const employeeListColumns:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Name',
                width:250,
                sorter: (a: any, b: any) => {
                    const nameA = `${a?.first_name ?? ''} ${a?.last_name ?? ''}`.toLowerCase();
                    const nameB = `${b?.first_name ?? ''} ${b?.last_name ?? ''}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                },
                render:(item:any)=>{
                    return(
                        <div>
                            {(item?.first_name + item?.last_name)?.length > 20 ?
                            <Tooltip title={item?.first_name + " " + item?.last_name}>
                                <div  className={'ellipses-for-table-data'}>
                                    {item?.first_name + " " + item?.last_name || 'N/A'}
                                </div>
                            </Tooltip>
                            :
                            <div>
                                {item?.first_name + " " + item?.last_name || 'N/A'}
                            </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:"Contact Number",
                key:'contact_number',
                dataIndex:'contact_number',
                width:150,
                // sorter: (a: any, b: any) => (a?.contact_number ?? '').localeCompare(b?.contact_number ?? ''),
                render:(item:any)=>{
                    return (
                        <div>
                            {CommonService.formatPhoneNumber(item?.replace(/^\+1/,'')) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Email',
                key:'email',
                dataIndex:'email',
                width:200,
                sorter: (a: any, b: any) => (a?.email ?? '').localeCompare(b?.email ?? ''),
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.length > 15 ?
                            <Tooltip title={item} className={'text-lowercase'}>
                                <div className={'ellipses-for-table-data text-lowercase'}>
                                    {item}
                                </div>
                            </Tooltip>:
                                <div className={'text-lowercase'}>
                                    {item || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Role',
                key:'role',
                dataIndex:'role',
                width:100,
                sorter: (a: any, b: any) => (a?.role ?? '').localeCompare(b?.role ?? ''),
                render:(item:any)=>{
                    return (
                        <div>
                            {item || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Status',
                key:'is_active',
                dataIndex:'is_active',
                width:100,
                render:(item:any)=>{
                    return (
                        <div>
                            {item ? "Active":"Inactive"}
                        </div>
                    )
                }
            },
            {
                title:'View Details',
                width:130,
                fixed:'right',
                render:(item:any)=>{
                    return (
                        <div className={'display-center'}>
                            <Link to={`/employee/view/${item?._id}`}
                                  style={{color: '#1e1e1e'}}
                            >
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </Link>
                        </div>
                    )
                }
            }
        ]
    },[])


    return (
        <>
            <div className={"ac-list screen crud-layout pdd-30"}>
                <div className="custom-border pdd-10  pdd-top-20 pdd-bottom-0">
                    <div className="header">
                        <div className="mrg-left-5 filter">
                            <div>
                                <div className="d-flex">
                                    <div className="d-flex position-relative">
                                        {!filterData.search ? (
                                            <div className={"search_icon"}>
                                                <SearchRounded />
                                            </div>
                                        ) : (
                                            <div className={"search_icon"}>
                                                <ClearIcon
                                                    onClick={(event) => {
                                                        setFilterData({...filterData, search: '', page: 1})
                                                    }}
                                                    id="clear_hcp_search"
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <CssTextField
                                                defaultValue={""}
                                                className="search-cursor searchField"
                                                id="input_search_hcp"
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setFilterData({...filterData, search: value, page: 1});
                                                }}
                                                value={filterData?.search}
                                                variant={"outlined"}
                                                size={"small"}
                                                type={"text"}
                                                placeholder={"Search Employee"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="action d-flex">
                            <div className="mrg-left-20">
                               <AccessControlComponent role={[ADMIN]}>
                               <Tooltip title="Add New Employee">
                                    <Button variant={"contained"} color={"primary"} component={Link} to={`/employee/add`}>
                                        <AddRounded />
                                        &nbsp;&nbsp;Add New&nbsp;&nbsp;
                                    </Button>
                                </Tooltip>
                               </AccessControlComponent>
                            </div>
                        </div>
                    </div>
                    <div className={'employee-table-component'}>
                        <AntTableComponent
                            url={ENV.API_URL + "user/list"}
                            method={"post"}
                            columns={employeeListColumns}
                            extraPayload={filterData}
                            isRowSelection={false}
                            noDataText={filterData.search ? 'Oops! It seems like there are no Employee Available for the name you have searched.' : 'No Employee Data Found.'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployeeListScreen;