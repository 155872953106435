import React, {PropsWithChildren} from 'react';
import {ImageConfig} from '../../../constants';
import './auth-layout.scss';

export interface AuthLayoutProps {

}

const AuthLayout = (props: PropsWithChildren<AuthLayoutProps>) => {

    return (
        <div className={'auth-layout'}>
            <div className={'auth-layout-wrapper'}>
                <div className={'auth-poster-wrapper'}
                     style={{backgroundImage: 'url(' + ImageConfig.AuthPoster + ')'}}
                >
                    <div className={'auth-poster-image-wrapper'}>
                        <div className={'auth-poster-logo'}>
                            <img width={150} height={30} src={ImageConfig.Logo} alt={'vitawerks'}/>
                        </div>

                    </div>
                    <div className={'auth-poster-card'}>
                        <div>
                            {/*<img src={ImageConfig.HcpManagementIcon} alt={'hcp-icon'}/>*/}
                        </div>
                        <div className={'auth-poster-header'}>
                            Your ultimate tool for
                            <span className={'auth-poster-header-highlight'}>{" "}effective management {" "}</span>
                            and
                            <span className={'auth-poster-header-highlight'}>{" "} staffing. {" "}</span>
                        </div>
                        <div className={'auth-poster-subheader'}>
                            Track shifts in real-time from approval to completion.
                        </div>
                    </div>
                </div>
                <div className={'auth-content-wrapper'}>
                    <div className={'auth-main-wrapper'}>
                        <div className={'auth-main-content-wrapper'}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default AuthLayout;
