import {Button, IconButton, MenuItem, Tooltip} from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import React, {useCallback, useMemo, useState} from "react";
import VitawerksConfirmComponent from "../../../../components/VitawerksConfirmComponent";
import { ENV } from "../../../../constants";
import { shiftType } from "../../../../constants/data";
import { ApiService, CommonService } from "../../../../helpers";
import { shiftFormValidation } from "../../add/ShiftAddComponent/ShiftTimingFormValidation";
// import ReadOnlyRow from "./ReadOnlyRow";
import "./ShiftEditComponent.scss";
import {TableColumnsType} from "antd";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";

type ShiftEditComponentProps = {
  timezone: any;
  onAddShift: any;
  facilityId: string;
  shiftTimings: any;
  init:any
};

interface ShiftAddType {
  shift_start_time: string;
  shift_end_time: string;
  shift_type: string;
}

const shiftInitalState: ShiftAddType = {
  shift_start_time: "",
  shift_end_time: "",
  shift_type: "",
};

const ShiftEditComponent = ({ init,timezone, facilityId, shiftTimings, onAddShift }: ShiftEditComponentProps) => {
  const [isShifts, setIsShifts] = useState<boolean>(false);
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const [shiftId, setShiftId] = useState<any>(null);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const showDropDownBelowField = {
    MenuProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    },
  };

  const onAdd = (shift: ShiftAddType, { setSubmitting, setErrors, resetForm }: FormikHelpers<ShiftAddType>) => {
    const newShiftTimings = {
      shift_start_time: CommonService.convertHoursToMinutes(shift?.shift_start_time),
      shift_end_time: CommonService.convertHoursToMinutes(shift?.shift_end_time),
      shift_type: shift?.shift_type,
    };

    onAddShift(newShiftTimings)
      .then(() => {
        init()
        handleCloseShiftForm();
        resetForm();
      })
      .catch((err: any) => {
       // console.log(err);
        CommonService.showToast(err?.msg || "Error", "error");
      });
  };

  const handleCloseShiftForm = () => {
    setIsShifts(false);
  };

  const handleDeleteClick = useCallback(
    (shiftId: number) => {
      setIsConfirm(true);
      ApiService.delete(ENV.API_URL + "facility/" + facilityId + "/shift/" + shiftId)
        .then((resp: any) => {
          CommonService.showToast("Facility Shift Timing Deleted", "success");
          init()
          setIsConfirm(false);
          setIsAddOpen(false);
        })
        .catch((err) => {
          //console.log(err);
          setIsConfirm(false);
        });
    },
    [facilityId,init]
  );

  const openAdd = useCallback((id: any) => {
    setShiftId(id);
    setIsAddOpen(true);
  }, []);

  const cancelAdd = useCallback(() => {
    setIsAddOpen(false);
  }, []);

  const confirmAdd = useCallback(() => {
    handleDeleteClick(shiftId);
  }, [shiftId, handleDeleteClick]);

  const shiftTimingListColumn:TableColumnsType=useMemo(()=>{
    return [
      {
        title: "Start Time",
        dataIndex: "shift_start_time",
        key: "shift_start_time",
        width:50,
        render: (item: any) => {
          return (
              <div>
                {moment(CommonService.convertMinsToHrsMins(item), "hh:mm").format("LT") || 'N/A'}
              </div>
          )
        },
      },
      {
        title:'End Time',
        dataIndex: "shift_end_time",
        key: "shift_end_time",
        width: 50,
        render:(item:any)=>{
          return (
              <div>
                {moment(CommonService.convertMinsToHrsMins(item), "hh:mm").format("LT") || 'N/A'}
              </div>
          )
        }
      },
      {
        title: "Shift Type",
        dataIndex: "shift_type",
        key: "shift_type",
        width:200,
        render:(item:any)=>{
          return (
              <div>
                {item || 'N/A'}
              </div>
          )
        }
      },
      {
        title:'Duration',
        width:40,
        render:(item:any)=>{
          let start_time_to_cal = CommonService.convertMinsToHrsMins(item.shift_start_time);
          let end_time_to_cal = CommonService.convertMinsToHrsMins(item.shift_end_time);

          const start_time_to_get_duration = moment().startOf("day").add(item.shift_start_time, "minutes");
          let end_time_to_get_duration = moment().startOf("day").add(item.shift_end_time, "minutes");
          if (start_time_to_cal > end_time_to_cal) {
            end_time_to_get_duration = moment(end_time_to_get_duration).add(1, "day");
          }
          return (
              <div>
                {CommonService.durationBetweenTimeStamps(start_time_to_get_duration, end_time_to_get_duration) || 'N/A'}
              </div>
          )
        }
      },
      {
        title:'Delete',
        width:60,
        render:(item:any)=>{
          return (
              <Tooltip title={`Delete Shift Timings`}>
                <IconButton onClick={() => openAdd(item?._id)}>
                  <DeleteIcon className="delete-icon" />
                </IconButton>
              </Tooltip>
          )
        }
      }
    ]
  },[openAdd]);

  return (
    <div className="shift-add-container">
      {/*<DialogComponent open={isAddOpen} cancel={cancelAdd}>*/}
      {/*</DialogComponent>*/}
      <VitawerksConfirmComponent open={isAddOpen}  cancel={cancelAdd} isConfirm={isConfirm} confirm={confirmAdd} text1="Want to delete" hcpname={"Shift Timings"} groupname={""} confirmationText={""} notext={"Back"} yestext={"Delete"} />
      {isShifts ? (
        <Formik initialValues={shiftInitalState} validateOnChange={true} validationSchema={shiftFormValidation} onSubmit={onAdd}>
          {({ isSubmitting, handleSubmit, isValid, resetForm }) => (
            <Form className={"form-holder"}>
              <div className="shift-add-input">
                <Field fullWidth variant="outlined" type="time" component={TextField} name="shift_start_time" label="Shift Start Time" InputLabelProps={{ shrink: true }} id="input_shift_add_shift_start_time" />
                <Field fullWidth variant="outlined" type="time" component={TextField} name="shift_end_time" label="Shift End Time" InputLabelProps={{ shrink: true }} id="input_shift_add_shift_end_time" />
                <Field select fullWidth SelectProps={showDropDownBelowField} variant="outlined" component={TextField} name="shift_type" label="Shift Type" id="input_shift_add_shift_type">
                  <MenuItem value="">Select Shift Type</MenuItem>
                  {shiftType.length > 0 &&
                    shiftType.map((item: any, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Field>
              </div>
              <div className="shift-add-btn-grp">
                <Button id="btn_add_shift_cancel" color="primary" variant="outlined" onClick={handleCloseShiftForm}>
                  Delete
                </Button>
                <Button disabled={isSubmitting} id="btn_add_shift_save" variant="contained" color="primary" type="submit" className={isSubmitting ? "has-loading-spinner" : ""}>
                  {isSubmitting ? "Saving" : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="shift-add-action">
          <Tooltip title={"Add New Shift Timing"}>
            <p onClick={() => setIsShifts(true)} id="btn_add_shift_submit" className="generic-add-multiple">
              + Add a Shift
            </p>
          </Tooltip>
        </div>
      )}
      {shiftTimings.length > 0 && (
          <div className={'mrg-top-20'}>
              <AntTableComponent
              columns={shiftTimingListColumn}
              data={shiftTimings}
              extraPayload={shiftTimings}
              noDataText={'No Shift Timings Found'}
              />
          </div>
          // <Table className="mrg-top-50">
          //   <TableHead className={"mat-thead"}>
          //     <TableRow className={"mat-tr"}>
          //       <th>Start Time</th>
          //       <th>End Time</th>
          //       <th>Shift Time</th>
          //       <th>Duration</th>
          //     </TableRow>
          //   </TableHead>
          //   <TableBody className={"mat-tbody"}>
          //     {shiftTimings.map((shiftTiming: any) => (
          //         <ReadOnlyRow timezone={timezone} key={shiftTiming?._id} shiftTimings={shiftTiming} openAdd={openAdd} />
          //     ))}
          //   </TableBody>
          // </Table>
      )}

    </div>
  );
};

export default ShiftEditComponent;
