import moment from "moment";
import React from "react";
import NoDataToShowCardComponent from "../../../../components/NoDataToShowCardComponent";
import { CommonService } from "../../../../helpers";

const HcpVolunteerExperienceComponent = (props: any) => {
  const volunteerExperience = props?.volunteerExperience;
  const sortedExpData = volunteerExperience && CommonService.sortDatesByLatest(volunteerExperience, "start_date");
  const filteredExpData = sortedExpData?.filter((data: any) => data?.exp_type === "volunteer");

  return (
    <div>
      {volunteerExperience?.length > 0 ? (
          filteredExpData?.map((item: any, index: any) => {
          return (
            <div className={index !== 0 ? "mrg-top-30" : ""}>
              <h4 className="title-count ">Volunteer Experience {index + 1}</h4>
              <div className="d-flex">
                <div className="flex-1">
                  <h4>Organization Name</h4>
                  <p>{item?.facility_name || 'N/A'}</p>
                </div>
                <div className="flex-1">
                  <h4>Location</h4>
                  <p>{item?.location || 'N/A'}</p>
                </div>
                <div className="flex-1">
                  <h4>Speciality</h4>
                  <p>{item?.specialisation || 'N/A'}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="flex-1">
                  <h4>Start and End Date</h4>
                  <p>
                    {item?.start_date ? moment(item?.start_date).format("MMMM, YYYY") : "N/A"}&nbsp;-&nbsp;{item?.end_date ? moment(item?.end_date).format("MMMM, YYYY") : "N/A"}
                  </p>
                </div>
                <div className="flex-1">
                  <h4>Position Title</h4>
                  <p>{item?.position_title || 'N/A'}</p>
                </div>
                <div className="flex-1">
                  <h4>Still Working Here</h4>
                  <p>{(item?.still_working_here === '0' || item?.still_working_here === 0) ? "NO" : "YES"}</p>

                </div>
              </div>
              <div className="d-flex">
                <div className="flex-1">
                  <h4>Skill</h4>
                  <p>{item?.skills ? item?.skills : "N/A"} </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <NoDataToShowCardComponent />
      )}
    </div>
  );
};

export default HcpVolunteerExperienceComponent;
