import {Menu} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";
import React, {useCallback} from "react";
import {CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export interface MenuItemProps {
    // invoiceList: any,
    rowId: any,
    rowStatus: any,
    filterData?: any,
    setFilterData?: any

}

const MenuComponent = (props: MenuItemProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const rowId = props?.rowId;
    const rowStatus = props?.rowStatus;
    const {filterData, setFilterData} = props;
    const handleClose = useCallback((note: any) => {
        setAnchorEl(null);
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const markTheInvoiceAsPaid = useCallback((id: any) => {
        CommonService._api.patch(ENV.API_URL + `invoice/${id}/markAsPaid`)
            .then((resp) => {
                console.log("API Success Response:", resp);
                setFilterData({...filterData})
                CommonService.showToast(resp?.message || "Success", "success");
            })
            .catch((err) => {
                console.error("API Error Response:", err);
                CommonService.showToast(err?.error || err?.message || "Error", "error");
            });

    }, [filterData, setFilterData]);

    const handleTimesheetDownload = useCallback(() => {
        ///invoice/:_id/timesheetDownload  -> POST for timesheets download
        CommonService._api.post(ENV.API_URL + `invoice/${rowId}/timesheetDownload`).then((res) => {
            if (res?.data) {
                const link = document.createElement("a");
                link?.setAttribute("href", res?.data);
                document.body.appendChild(link);
                link.click();
                setAnchorEl(null);
            } else {
                CommonService.showToast(res?.msg || "No Data to Download", "info");
                setAnchorEl(null);
            }
        })
            .catch((err) => {
                CommonService.showToast(err?.msg || "Error", "error");
                setAnchorEl(null);
            });
    }, [rowId]);

    const markShiftCancel = useCallback((id: any) => {
        // CommonService._api.patch(ENV.API_URL + `invoice/${id}/cancel`).then((resp) => {
        //     invoiceList();
        //     CommonService.showToast(resp?.message || "Success", "success");
        // }).catch((err) => {
        //     CommonService.showToast(err?.error || "Error", "error");
        // });
        CommonService._api.patch(ENV.API_URL + `invoice/${id}/cancel`)
            .then((resp) => {
                console.log("API Success Response:", resp);
                setFilterData({...filterData})
                CommonService.showToast(resp?.message || "Success", "success");
            })
            .catch((err) => {
                console.error("API Error Response:", err);
                CommonService.showToast(err?.error || err?.message || "Error", "error");
            });
    }, [filterData, setFilterData]);
    return (
        <>
            <MoreVertIcon style={{cursor: "pointer"}} onClick={(event: any) => {
                handleClick(event);
            }}/>
            <Menu id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                      'aria-labelledby': 'basic-button',
                  }}>
                <MenuItem onClick={() => {
                    setAnchorEl(null);
                }}>
                    <Link to={"/generatedInvoiceViewDetails/" + rowId}
                          className="info-link"
                          style={{textDecoration: "none", color: "black"}}
                          id={"link_invoice_generated_details"}>
                        {"View Details"}
                    </Link>
                </MenuItem>
                {rowStatus === "unpaid" && <>
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        markTheInvoiceAsPaid(rowId);
                    }}>Mark As Paid</MenuItem>
                    <Link to={"/EditGeneratedInvoiceViewDetails/" + rowId}
                          className="info-link"
                          style={{textDecoration: "none", color: "black"}}
                          id={"link_invoice_generated_details"}><MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                        }}>Edit</MenuItem>
                    </Link>
                </>}
                <MenuItem onClick={() => {
                    handleTimesheetDownload();
                }}>Download CSV</MenuItem>
                {rowStatus === "unpaid" && <>
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        markShiftCancel(rowId);
                    }}>
                        <div style={{color: "red"}}>
                            Cancel
                        </div>
                    </MenuItem>
                </>}
            </Menu>
        </>
    );
};
export default MenuComponent;
