import React, {useCallback, useEffect, useRef, useState} from 'react';
import './AntTableComponent.scss';
import CommonService from "../../helpers/common-service";
import _ from "lodash";
import {TSAPIResponseType} from "../../helpers/api-service";
import {Table, TableProps} from "antd";

export const AXIOS_REQUEST_CANCELLED = 'AXIOS_REQUEST_CANCELLED';

interface DataType {
    key: React.Key;
}

type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

interface AntTableComponentProps {
    extraPayload?: any;
    method?: any;
    url?: any;
    isRowSelection?: any;
    columns?: any;
    selectedRowKeys?: any;
    setSelectedRowKeys?: any
    noDataText?: any;
    data?: any[];
    setSelectedRowData?:any;
}


const AntTableComponent = (props: AntTableComponentProps) => {
    const {
        url,
        method,
        extraPayload,
        isRowSelection,
        columns,
        selectedRowKeys,
        setSelectedRowKeys,
        noDataText,
        setSelectedRowData,
        data: propData
    } = props;
    //   const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});
    const APICallSubscription = useRef<any>(null);
    const [data, setData] = useState<any>([]);

    useEffect(()=>{
        if(extraPayload?.search){
            setPagination((prev)=>({
                ...prev,
                current:1
            }))
        }
    },[extraPayload?.search])

    const getListData = useCallback(() => {
        if (propData) {
            // If propData is available, skip API call and set it directly
            setData(propData.map((item, index) => ({...item, key: item?._id || index})));
            setPagination((prev) => ({
                ...prev,
                total: propData.length,
            }));
            return;
        }
        const cancelTokenSource = CommonService.getCancelToken();
        const payload = _.cloneDeep({...extraPayload, page: pagination.current, limit: pagination.pageSize});
        let apiCall = method === "post" ? CommonService._api.post : CommonService._api.get;
        if (APICallSubscription.current) {
            APICallSubscription.current.cancel();
        }
        APICallSubscription.current = cancelTokenSource;
        setIsDataLoading(true);
        apiCall(url, payload, {}, {cancelToken: cancelTokenSource.token})
            .then((response: TSAPIResponseType) => {
                if (response.data?.length > 0) {
                    const modifiedData = response.data.map((item: any) => {
                        const {...rest} = item;
                        return {
                            ...rest,
                            key: item._id,
                        };
                    });
                    // Update the data and pagination
                    setData(modifiedData);
                    setPagination((prev) => ({
                        ...prev,
                        total: response.data.total || 0, // Ensure this matches the total number of records
                    }));
                }else{
                    const modifiedData = response.data.docs.map((item: any) => {
                        const {...rest} = item;
                        return {
                            ...rest,
                            key: item._id,
                        };
                    });
                    // Update the data and pagination
                    setData(modifiedData);
                    setPagination((prev) => ({
                        ...prev,
                        total: response.data.total || 0, // Ensure this matches the total number of records
                    }));
                }
                setIsDataLoading(false);
            })
            .catch((error: any) => {
                if (error.reason !== AXIOS_REQUEST_CANCELLED) {
                    setData([]);
                    setIsDataLoading(false);
                }
            });
        //eslint-disable-next-line
    }, [extraPayload, method, url, pagination.current, pagination.pageSize]);

    useEffect(() => {
        getListData();
    }, [getListData]);


    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
            // Check if all rows are selected
            if (setSelectedRowData) {
                setSelectedRowData(selectedRows);
            }
            const allKeys = data.map((item: any) => item.key);
            if (newSelectedRowKeys.length === allKeys.length) {
                // All rows selected
                setSelectedRowKeys(allKeys);
            } else {
                // Handle partial selection
                setSelectedRowKeys(newSelectedRowKeys);
            }
        },
        preserveSelectedRowKeys: true,
    };


    const handleTableChange = useCallback((pagination: any) => {
        setPagination(pagination);
    }, []);


    const loadingRow = (
        <tr>
            <td colSpan={columns.length}
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '450%'}}>
                <div className={'data-loading-wrapper'}>
                    <div className="loader" style={{
                        color: '#1e1e1e'
                    }}>
                        Loading...
                    </div>
                </div>
            </td>
        </tr>
    );

    return (
        <div className="responsive-table-container">
            <Table
                bordered
                size={'middle'}
                rowSelection={isRowSelection ? rowSelection : undefined}
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '25', "50", "100"],
                    total: pagination.total,
                    position: ['bottomLeft'],
                    showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                onChange={handleTableChange}  // This will trigger on page change or page size change
                scroll={{y: 240, x: '100%'}}
                locale={{
                    emptyText: isDataLoading ? '' : noDataText ? noDataText : "No Data Found", // Show loadingRow if loading
                }}
                loading={isDataLoading ? {
                    indicator: loadingRow,
                    spinning: isDataLoading
                } : false}
            />
        </div>
    );
};

export default AntTableComponent;

//use case
// if there is no selection with checkbox
// <AntTableComponent columns={columns}/>

// if there is  selection with checkbox
// <AntTableComponent columns={columns} isRowSelection={true}/>

