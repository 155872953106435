// import React from "react";
// import {AnyObject} from "yup/lib/object";
// import "./ShiftTimeline.scss";
//
// const ShiftTimeline = (props: any) => {
//     const basicDetails = props?.basicDetails
//     const tConvert = (time: AnyObject) => {
//         // Check correct time format and split into components
//         time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
//
//         if (time.length > 1) {
//             // If time format correct
//             time = time.slice(1); // Remove full string match value
//             time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
//             time[0] = +time[0] % 12 || 12; // Adjust hours
//         }
//         return time.join(""); // return adjusted time or original string
//     };
//
//     const dateFormat = (date: any) => {
//         const [year, month, day] = date?.split("-");
//         if (year && month && day) {
//             return month + "-" + day + "-" + year;
//         } else {
//             return "--";
//         }
//     };
//
//     return (
//         <div className="timeline ">
//             <div className="wrap">
//                 <div className="points">
//                     <div className="dot">
//                         <span className="dot-title">Clock-In</span>
//                         <span className="dot-time">
//               {props?.timeBreakup?.check_in_time ? dateFormat(props?.timeBreakup?.check_in_time.slice(0, 10)) : "--"}
//                             <br/>
//                             {props?.timeBreakup?.check_in_time ? tConvert((props?.timeBreakup?.check_in_time).slice(11, 16)) : "--"}
//                             <br/>
//                             {
//                                 basicDetails?.check_in_location &&
//                                 <>
//                                     Lat:&nbsp;{basicDetails?.check_in_location?.coordinates && basicDetails?.check_in_location?.coordinates[1]}
//
//                                 </>
//                             }
//                             <br/>
//                             {
//                                 basicDetails?.check_in_location &&
//                                 <>
//                                     Long:&nbsp;{basicDetails?.check_in_location?.coordinates && basicDetails?.check_in_location?.coordinates[0]}
//
//                                 </>
//                             }
//             </span>
//                     </div>
//                     <div className="gap-container">
//                         {props?.timeBreakup?.break_timings?.map((item: any, index: number) => {
//                             return (
//                                 <div key={"shift-timeline-" + index} className='gap-container'>
//                                     <div className="small-dot">
//                                         <span className="small-dot-title"> Start Break</span>
//                                         <span className="small-dot-time">
//                     {item?.break_in_time ? dateFormat((item?.break_in_time).slice(0, 10)) : "--"}
//                                             <br/>
//                                             {item?.break_in_time ? tConvert((item?.break_in_time).slice(11, 16)) : "--"}
//                                             <div>
//                                                 {
//                                                     item?.break_in_location &&
//                                                     <>
//                                                         Lat:&nbsp;{item?.break_in_location?.coordinates && item?.break_in_location?.coordinates[1]}
//
//                                                     </>
//                                                 }
//                                                 <br/>
//                                                 {
//                                                     item?.break_in_location &&
//                                                     <>
//                                                         Long:&nbsp;{item?.break_in_location?.coordinates && item?.break_in_location?.coordinates[0]}
//
//                                                     </>
//                                                 }
//                                            </div>
//                   </span>
//
//                                     </div>
//                                     <div className="small-dot">
//                                         <span className="small-dot-title">End Break</span>
//                                         <span className="small-dot-time">
//                     {item?.break_out_time ? dateFormat((item?.break_out_time).slice(0, 10)) : "--"}
//                                             <br/>
//                                             {item?.break_out_time ? tConvert((item?.break_out_time).slice(11, 16)) : "--"}
//                                             <div>
//                                                 {
//                                                     item?.break_out_location &&
//                                                     <>
//                                                         Lat:&nbsp;{item?.break_out_location?.coordinates && item?.break_out_location?.coordinates[1]}
//
//                                                     </>
//                                                 }
//                                                 <br/>
//                                                 {
//                                                     item?.break_out_location &&
//                                                     <>
//                                                         Long:&nbsp;{item?.break_out_location?.coordinates && item?.break_out_location?.coordinates[0]}
//                                                     </>
//                                                 }
//                                            </div>
//                   </span>
//                                     </div>
//                                 </div>
//                             );
//                         })}
//                     </div>
//                     <div className="dot">
//                         <span className="dot-title">Clock-Out</span>
//                         <span className="dot-time">
//               {props?.timeBreakup?.check_out_time ? dateFormat((props?.timeBreakup?.check_out_time).slice(0, 10)) : "--"}
//                             <br/>
//                             {props?.timeBreakup?.check_out_time ? tConvert((props?.timeBreakup?.check_out_time).slice(11, 16)) : "--"}
//                             <br/>
//                             {
//                                 basicDetails?.check_out_location &&
//                                 <>
//                                     Lat:&nbsp;{basicDetails?.check_out_location?.coordinates && basicDetails?.check_out_location?.coordinates[1]}
//
//                                 </>
//                             }
//                             <br/>
//                             {
//                                 basicDetails?.check_out_location &&
//                                 <>
//                                     Long:&nbsp;{basicDetails?.check_out_location?.coordinates && basicDetails?.check_out_location?.coordinates[0]}
//
//                                 </>
//                             }
//             </span>
//                     </div>
//                 </div>
//             </div>
//
//             <div className="timeline-hours ">
//                 <p>{JSON.stringify(props?.time_breakup?.check_in_time)}</p>
//                 <p>{JSON.stringify(props?.time_breakup?.check_out_time)}</p>
//             </div>
//         </div>
//     );
// };
//
// export default ShiftTimeline;

import React from "react";
import {Typography} from "antd";
import {ClockCircleOutlined} from "@ant-design/icons";
import "./ShiftTimeline.scss";
import {CommonService} from "../../../helpers";

const {Title, Text} = Typography;

interface Location {
    coordinates: [number, number];
}

interface BreakTiming {
    break_in_time: string;
    break_out_time: string;
    break_in_location?: Location;
    break_out_location?: Location;
}

interface TimeBreakup {
    check_in_time?: string;
    check_out_time?: string;
    break_timings?: BreakTiming[];
}

interface ShiftTimelineProps {
    basicDetails?: {
        check_in_location?: Location;
        check_out_location?: Location;
        is_checkout_within_200m?:boolean;
    };
    timeBreakup?: TimeBreakup;
    isCheckoutWithIn?:boolean;
}

const ShiftTimeline: React.FC<ShiftTimelineProps> = ({basicDetails, timeBreakup}) => {
    // const formatTime = (time: string) => {
    //     const date = new Date(time);
    //     const hours = date.getHours();
    //     const minutes = date.getMinutes();
    //     const period = hours >= 12 ? "PM" : "AM";
    //     return `${hours % 12 || 12}:${minutes.toString().padStart(2, "0")} ${period}`;
    // };

    const formatTime = (time: any) => {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return CommonService.startTime(time) // return adjusted time or original string
    };

    // const formatDate = (time: any) => {
    //     const date = new Date(time);
    //     return date.toLocaleDateString("en-US", {day: "2-digit", month: "2-digit", year: "numeric"});
    // };
    const formatDate = (date: any) => {
        // const [year, month, day] = date?.split("-");
        // if (year && month && day) {
        //     return month + "-" + day + "-" + year;
        // } else {
        //     return "--";
        // }
        return CommonService.getUtcDate(date)
    };
    const renderLocation = (location?: Location, label?: string) => {
        const isOutsideLocation = !basicDetails?.is_checkout_within_200m && label === "Clock-Out";

        return location ? (
            <Text
                type="secondary"
                className={`${isOutsideLocation ? 'outside-location' : 'location'}`}
            >
                <div>
                    {/* <EnvironmentOutlined /> */}
                    {location.coordinates &&
                        <>
                            Lat: {location.coordinates[1]}
                        </>
                    }
                </div>
                <div>
                    {location.coordinates &&
                        <>
                            Long: {location.coordinates[0]}
                        </>
                    }
                </div>
            </Text>
        ) : (
            <Text type="secondary" className="location">--</Text>
        );
    };
    const renderEvent = (label: string, time?: string, location?: Location) => (
        <div className="timeline-event">
            <div className="event-content">
                <ClockCircleOutlined className="icon"/>
                <Title level={5} className="event-title">{label}</Title>
                <Text className="event-time">{time ? formatTime(time) : "N/A"}</Text>
                <br/>
                {time && <Text className="event-date">{formatDate(time)}</Text>}
                <br/>
                {renderLocation(location, label)}
            </div>
        </div>
    );

    return (
        <div className="shift-timeline">
            <div className={'d-flex justify-content-center align-items-center'}>
                {/*<Title level={4} className="timeline-title" onClick={() => {*/}
                {/*    console.log(timeBreakup)*/}
                {/*}}>Shift Timeline</Title>*/}
                <div className="timeline-container">
                    {/* Clock-In */}
                    {renderEvent("Clock-In", timeBreakup?.check_in_time, basicDetails?.check_in_location)}

                    {/* Break Timings */}
                    {timeBreakup?.break_timings?.map((breakItem, index) => (
                        <React.Fragment key={index}>
                            <div className="timeline-connector"/>
                            {renderEvent("Start Break", breakItem.break_in_time, breakItem.break_in_location)}
                            <div className="timeline-connector"/>
                            {renderEvent("End Break", breakItem.break_out_time, breakItem.break_out_location)}
                        </React.Fragment>
                    ))}

                    {/* Clock-Out */}
                    <div className="timeline-connector"/>
                    {renderEvent("Clock-Out", timeBreakup?.check_out_time, basicDetails?.check_out_location)}
                </div>
            </div>
        </div>
    );
};

export default ShiftTimeline;
