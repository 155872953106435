import React from "react";
import { Button, Modal } from "antd";

export interface VitawerksConfirmComponentProps {
  confirmationText: any;
  cancel: any;
  confirm: any;
  notext: string;
  yestext: string;
  text1: string;
  hcpname: string;
  groupname: string;
  open: boolean;
  isShiftType?: boolean;
  isConfirm?:boolean;
}

const VitawerksConfirmComponent = (props: VitawerksConfirmComponentProps) => {
  return (
      <Modal
          title={
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              {props.isShiftType ? (
                  <p>{props.text1} ?</p>
              ) : (
                  <>
                    <p>{props.text1}</p>
                    {props.hcpname && <p>"{props.hcpname}"</p>}
                    {props.confirmationText && <p>{props.confirmationText}</p>}
                    {props.groupname && <p>{props.groupname}</p>}
                    {/*<p>?</p>*/}
                  </>
              )}
            </div>
          }
          open={props.open}
          onCancel={props.cancel}
          footer={null}
          closable={false}
          centered
      >
        <div style={{ display: "flex", justifyContent: "center", gap: "16px" }}>
          <Button onClick={props.cancel} type="default">
            {props.notext || "No, Cancel"}
          </Button>
          <Button
              onClick={props.confirm}
              type="primary"
              style={{background:'#10c4d3'}}
              loading={props.isConfirm}
              disabled={props.isConfirm}
          >
            {props.yestext || "Yes, Confirm"}
          </Button>
        </div>
      </Modal>
  );
};

export default VitawerksConfirmComponent;
