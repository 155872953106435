import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import * as React from "react";
import { useCallback } from "react";
import {ImageConfig} from "../../constants";

interface DrawerComponentProps {
    isOpen: boolean;
    title?: string;
    showClose?: boolean;
    onClose?: (reason: string) => void;
    className?: string;
    hideBackdrop?: boolean;
    closeOnEsc?: boolean;
    closeOnBackDropClick?: boolean;
    direction?: "top" | "bottom" | "left" | "right";
    closeButtonId?: string;
    headerActions?: React.ReactNode;
}

const useStyles = makeStyles({
    root: {
        zIndex: 1300,
    },
    drawerContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth:'400px',
        minWidth:'400px'
    },
    drawerHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        borderBottom: '1px solid #ddd',
    },
    drawerTitle: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
    },
    drawerBody: {
        flex: 1,
        padding: '16px',
        overflow: 'auto',
    },
    drawerClose: {
        cursor: 'pointer',
    },
});

const DrawerComponent = (props: React.PropsWithChildren<DrawerComponentProps>) => {
    const classes = useStyles();
    const {
        isOpen,
        hideBackdrop,
        className,
        title,
        onClose,
        showClose,
        closeButtonId,
        children,
        headerActions,
    } = props;
    const direction = props.direction || "right";
    const closeOnEsc = props.closeOnEsc !== undefined ? props.closeOnEsc : true;
    const closeOnBackDropClick = props.closeOnBackDropClick !== undefined ? props.closeOnBackDropClick : true;

    const handleOnClose = useCallback(
        (event: React.SyntheticEvent, reason: string) => {
            if (reason === "backdropClick" && !closeOnBackDropClick) {
                return;
            }

            if (reason === "escapeKeyDown" && !closeOnEsc) {
                return;
            }

            if (onClose) {
                onClose(reason);
            }
        },
        [closeOnBackDropClick, closeOnEsc, onClose]
    );

    return (
        <Drawer
            open={isOpen}
            anchor={direction}
            onClose={(event) => handleOnClose(event as any, "backdropClick")}
            classes={{
                paper: className,
            }}
            ModalProps={{
                disableBackdropClick: !closeOnBackDropClick,
                disableEscapeKeyDown: !closeOnEsc,
                hideBackdrop: hideBackdrop,
            }}
        >
            <div className={classes.drawerContainer}>
                {(title || showClose || headerActions) && (
                    <div className={classes.drawerHeader}>
                        <div className={classes.drawerTitle}>{title}</div>
                        <div className="drawer-header-actions">
                            {headerActions && <> {headerActions} </>}
                            {showClose && (
                                <div
                                    className={classes.drawerClose}
                                    id={closeButtonId}
                                    onClick={(event) => {
                                        handleOnClose(event as any, "closeBtnClick");
                                    }}
                                >
                                    {/* Replace with your close icon */}
                                    <div>
                                       <img src={ImageConfig.CrossIcon} alt="close" />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className={classes.drawerBody}>{children}</div>
            </div>
        </Drawer>
    );
};

export default DrawerComponent;
