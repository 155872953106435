

export const FIELDS=[
    { name: "hourly_base_rates.slp", label: "SLP Rate ($/hr)", id: "input_facility_add_hourly_base_rates_slp" },
    { name: "hourly_base_rates.scrub_tech", label: "Scrub Tech Rate ($/hr)", id: "input_facility_add_hourly_base_rates_scrub_tech" },
    { name: "hourly_base_rates.lna", label: "LNA Rate ($/hr)", id: "input_facility_add_hourly_base_rates_lna" },
    { name: "hourly_base_rates.cna_lna", label: "CNA/LNA Rate ($/hr)", id: "input_facility_add_hourly_base_rates_cna_lna" },
    { name: "hourly_base_rates.medical_assistant", label: "Medical Assistant Rate ($/hr)", id: "input_facility_add_hourly_base_rates_medical_assistant" },
    { name: "hourly_base_rates.marriage_family_therapist", label: "Marriage Family Therapist Rate ($/hr)", id: "input_facility_add_hourly_base_rates_marriage_family_therapist" },
    { name: "hourly_base_rates.clinical_social_worker", label: "Clinical Social Worker Rate ($/hr)", id: "input_facility_add_hourly_base_rates_clinical_social_worker" },
    { name: "hourly_base_rates.psychologist", label: "Psychologist Rate ($/hr)", id: "input_facility_add_hourly_base_rates_psychologist" },
    { name: "hourly_base_rates.pharmacist", label: "Pharmacist Rate ($/hr)", id: "input_facility_add_hourly_base_rates_pharmacist" },
    { name: "hourly_base_rates.pharmacy_technician", label: "Pharmacy Technician Tech Rate ($/hr)", id: "input_facility_add_hourly_base_rates_pharmacy_technician" },
    { name: "hourly_base_rates.care_giver", label: "Care Giver Rate ($/hr)", id: "input_facility_add_hourly_base_rates_care_giver" },
    { name: "hourly_base_rates.med_tech", label: "Med Tech Rate ($/hr)", id: "input_facility_add_hourly_base_rates_med_tech" },
    { name: "hourly_base_rates.spd_tech", label: "SPD Tech Rate ($/hr)", id: "input_facility_add_hourly_base_rates_spd_tech" },
    { name: "hourly_base_rates.dsp", label: "DSP Rate ($/hr)", id: "input_facility_add_hourly_base_rates_dsp" },
    { name: "hourly_base_rates.pss", label: "PSS Rate ($/hr)", id: "input_facility_add_hourly_base_rates_pss" },
    { name: "hourly_base_rates.crma", label: "CRMA Rate ($/hr)", id: "input_facility_add_hourly_base_rates_crma" },
    { name: "hourly_base_rates.rn_supervisor", label: "RN Supervisor Rate ($/hr)", id: "input_facility_add_hourly_base_rates_rn_supervisor" },
    { name: "hourly_base_rates.caregiver_dsp", label: "Caregiver DSP Rate ($/hr)", id: "input_facility_add_hourly_base_rates_caregiver_dsp" },
    { name: "hourly_base_rates.caregiver_pss", label: "Caregiver PSS Rate ($/hr)", id: "input_facility_add_hourly_base_rates_caregiver_pss" },
    { name: "hourly_base_rates.caregiver_crma", label: "Caregiver CRMA Rate ($/hr)", id: "input_facility_add_hourly_base_rates_caregiver_crma" },
    { name: "hourly_base_rates.cls", label: "CLS Rate ($/hr)", id: "input_facility_add_hourly_base_rates_cls" },
    { name: "hourly_base_rates.rn_speciality1", label: "RN Speciality 1 Rate ($/hr)", id: "input_facility_add_hourly_base_rates_rn_speciality1" },
    { name: "hourly_base_rates.rn_speciality2", label: "RN Speciality 2 Rate ($/hr)", id: "input_facility_add_hourly_base_rates_rn_speciality2" },
    { name: "hourly_base_rates.rn_travel", label: "RN Travel Rate ($/hr)", id: "input_facility_add_hourly_base_rates_rn_travel" },
    { name: "hourly_base_rates.emt", label: "EMT Rate ($/hr)", id: "input_facility_add_hourly_base_rates_emt" },
    { name: "hourly_base_rates.monitor_tech", label: "Monitor Tech Rate ($/hr)", id: "input_facility_add_hourly_base_rates_monitor_tech" },
    { name: "hourly_base_rates.orst", label: "ORST Rate ($/hr)", id: "input_facility_add_hourly_base_rates_orst" },
    { name: "hourly_base_rates.radiology_tech", label: "Radiology Tech Rate ($/hr)", id: "input_facility_add_hourly_base_rates_radiology_tech" },
    { name: "hourly_base_rates.lpn", label: "LPN Rate ($/hr)", id: "input_facility_add_hourly_base_rates_lpn" },
    { name: "hourly_base_rates.physical_therapist", label: "Physical Therapist Rate ($/hr)", id: "input_facility_add_hourly_base_rates_physical_therapist"},
    { name: "hourly_base_rates.occupational_therapist", label: "Occupational Therapist Rate ($/hr)", id: "input_facility_add_hourly_base_rates_occupational_therapist",},
    { name: "hourly_base_rates.chha", label: "CHHA Rate ($/hr)", id: "input_facility_add_hourly_base_rates_chha" },
    { name: "hourly_base_rates.cna_chha", label: "CNA/CHHA Rate ($/hr)", id: "input_facility_add_hourly_base_rates_cna_chha" },
    { name: "hourly_base_rates.evaluation", label: "Evaluation (RN/OT/PT/ST)",id: "input_facility_add_diff_rates_evaluation",},
    {name: "hourly_base_rates.start_of_care",label: "Start of Care (RN/OT/PT/ST)",id: "input_facility_add_diff_rates_start_of_care",},
    {name: "hourly_base_rates.rn_routine_vist",label: "Routine visit (RN)",id: "input_facility_add_diff_rates_pm",},
    {name: "hourly_base_rates.lvn_routine_vist",label: "Routine visit (LVN)",id: "input_facility_add_diff_rates_lvn_routine_vist",},
    {name: "hourly_base_rates.ot_pt_st_routine_vist",label: "Routine visit (OT/PT/ST)",id: "input_facility_add_diff_rates_ot_pt_st_routine_vist",
    },
    {name: "hourly_base_rates.ota_pta_sta_routine_vist",label: "Routine visit (OTA/PTA/STA)",id: "input_facility_add_diff_rates_ota_pta_sta_routine_vist",
    },
    {
        name: "hourly_base_rates.cna_routine_vist",
        label: "Routine visit (CNA)",
        id: "input_facility_add_diff_rates_cna_routine_vist",
    },
    {
        name: "hourly_base_rates.caregiver_chha_routine_vist",
        label: "Routine visit (CareGiver/CHHA)",
        id: "input_facility_add_diff_rates_caregiver_chha_routine_vist",
    },
    {
        name: "hourly_base_rates.discharge",
        label: "Discharge",
        id: "input_facility_add_diff_rates_discharge",
    },
    {
        name: "hourly_base_rates.recertification",
        label: "Recertification",
        id: "input_facility_add_diff_rates_recertification",
    },
    {
        name: "hourly_base_rates.resumption_of_care",
        label: "Resumption of care",
        id: "input_facility_add_diff_rates_resumption_of_care",
    },
    {
        name: "conditional_rates.rush.hours",
        label: "Rush Hours",
        id: "input_facility_add_conditional_rates_rush_hours",
    },
    {
        name: "conditional_rates.rush.rate",
        label: "Rush Rate ($/hr)",
        id: "input_facility_add_conditional_rates_rush_rate",
    },
    {
        name: "conditional_rates.cancellation_before.hours",
        label: "Cancellation Before Hours",
        id: "input_facility_add_conditional_rates_cancellation_before_hours",
    },
    {
        name: "conditional_rates.cancellation_before.rate",
        label: "Cancellation Before Rate ($/hr)",
        id: "input_facility_add_conditional_rates_cancellation_before_rate",
    },
    {
        name: "conditional_rates.shift_early_completion.hours",
        label: "Shift Early Completion Hours",
        id: "input_facility_add_conditional_rates_shift_early_completion_hours",
    },
    {
        name: "conditional_rates.shift_early_completion.rate",
        label: "Shift Early Completion Rate ($/hr)",
        id: "input_facility_add_conditional_rates_shift_early_completion_rate",
    },
    {
        name: "hourly_base_rates.bsw",
        label: "BSW Rate ($/hr)",
        id: "input_facility_add_diff_rates_bsw",
    },
    {
        name: "hourly_base_rates.lpn_lvn",
        label: "LVN/LPN ($/hr)",
        id: "input_facility_add_diff_rates_lpn_lvn",
    },
    {
        name:"conditional_rates.overtime.hours",
        label:"OT Hours (hr/day)",
        id:"input_facility_add_conditional_rates.overtime.hours"
    },
    {
        name:"conditional_rates.overtime.rate",
        label:"OT Rate($/hr)",
        id:"input_facility_add_conditional_rates_overtime_rate"
    }
]