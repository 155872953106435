import "./FilePickerComponent.scss";
import {useDropzone} from "react-dropzone";
import {useCallback, useState} from "react";
import {Button} from "@material-ui/core";
import CommonService from "../../helpers/common-service";
import {ImageConfig} from "../../constants";
import {UploadOutlined} from "@ant-design/icons";


interface FilePickerComponentProps {
    showDropZone?: boolean;
    id?: string;
    uploadDescription?: string;
    acceptedFilesText?: string;
    onFilesDrop?: (acceptedFiles: any[], rejectedFiles: any[]) => void;
    acceptedFileTypes?: IFileType[];
    multiple?: boolean;
    maxFileCount?: number;
    maxFileSizeInMB?: number;
    disabled?: boolean;
    height?: string;
    btnText?: string;
    isBulkUpload?: boolean;
}

const TOO_MANY_FILES_ERROR_CODE = "too-many-files";
const INVALID_FILE_TYPE_ERROR_CODE = "file-invalid-type";
const LARGE_FILE_TYPE_ERROR_CODE = "file-too-large";

type IFileType = "png" | "jpg" | "jpeg" | "pdf" | "mp4" | "avi" | "csv" | "doc" | "docx" | "xlsx";

const fileTypeMappings: any = {
    "png": 'image/png',
    "jpg": 'image/jpg',
    "jpeg": 'image/jpeg',
    "pdf": "application/pdf",
    "mp4": {
        type: "video/mp4",
        extensions: ['.mp4', '.MP4']
    },
    "avi": {
        type: "video/avi",
        extensions: ['.avi', '.AVI']
    },
    "csv": "text/csv",
    "doc": "application/msword", // Mapping for doc files
    "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "xlsx": {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        extensions: ['.xlsx', '.XLSX']
    }
};

const FilePickerComponent = (props: FilePickerComponentProps) => {

    const getConvertedFileTypes = useCallback((acceptedFileTypes: IFileType[] | undefined) => {
        const mappings: any = {};
        acceptedFileTypes?.forEach((fileType) => {
            const mapping = fileTypeMappings[fileType];
            mappings[mapping?.type || mapping] = mapping?.extensions || [];
        });
        return mappings;
    }, []);

    const [acceptedFileTypes] = useState(getConvertedFileTypes(props.acceptedFileTypes));

    const {acceptedFilesText, uploadDescription, id, disabled, maxFileCount, onFilesDrop, multiple, btnText} = props;
    const showDropZone = props.showDropZone !== undefined ? props.showDropZone : true;
    const maxFileSizeInMB = props.maxFileSizeInMB !== undefined ? props.maxFileSizeInMB : 100;
    const height = props.height || '200px';
    const isBulkUpload = props.isBulkUpload

    const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
        // console.log("acceptedFiles", acceptedFiles);
        console.log("rejectedFiles", rejectedFiles);

        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result;
                console.log(binaryStr);
            };
            reader.readAsArrayBuffer(file);
        });

        if (rejectedFiles) {
            let maxCountErrorShown = false;
            let invalidFileTypeErrorShown = false;
            rejectedFiles.forEach((item: any) => {
                const itemErrorCodes = item.errors && item.errors.map((error: any) => error.code);
                if (itemErrorCodes?.includes(TOO_MANY_FILES_ERROR_CODE)) {
                    if (!maxCountErrorShown) {
                        CommonService.showToast('Maximum allowed files: ' + maxFileCount, 'error');
                        maxCountErrorShown = true;
                    }
                }
                if (itemErrorCodes?.includes(INVALID_FILE_TYPE_ERROR_CODE)) {
                    if (!invalidFileTypeErrorShown) {
                        CommonService.showToast('Please select valid file type', 'error');
                        invalidFileTypeErrorShown = true;
                    }
                }
                if (itemErrorCodes?.includes(LARGE_FILE_TYPE_ERROR_CODE)) {
                    if (!invalidFileTypeErrorShown) {
                        CommonService.showToast('Please select file within specified size', 'error');
                        invalidFileTypeErrorShown = true;
                    }
                }
            });
        }
        if (onFilesDrop) {
            onFilesDrop(acceptedFiles, rejectedFiles);
        }
    }, [maxFileCount, onFilesDrop]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject
    } = useDropzone({
        onDrop,
        accept: acceptedFileTypes,
        multiple: multiple,
        maxFiles: maxFileCount,
        disabled: disabled,
        maxSize: maxFileSizeInMB * 1024 * 1024
    });

    return (
        <>
            {
                showDropZone &&
                <div className={`file-picker-wrapper ${isDragActive ? "drag-active" : ""}`}
                     style={{height: height}}
                     {...getRootProps()} >
                    <input id={id} {...getInputProps()} />
                    <div className="file-dnd-icon">
                        <ImageConfig.ProfileIcon/>
                    </div>
                    <div className="file-dnd-title">
                        <span className={"file-dnd-title__dnd"}>Drag and drop or</span> <span
                        className={"file-dnd-title__select"}>browse files</span>
                    </div>
                    {uploadDescription && <div className="file-dnd-sub-title">
                        {uploadDescription}
                    </div>}
                    {acceptedFilesText && <div className="accepted-files-type-text">
                        {acceptedFilesText}
                    </div>}
                    {
                        isDragReject && <div className="">
                        </div>
                    }
                </div>
            }
            {
                (!showDropZone && !isBulkUpload) && <div {...getRootProps()}>
                    <input id={id} {...getInputProps()} />
                    <Button color={"primary"}
                            variant={"contained"}
                            disabled={disabled}
                    >
                        {btnText ? btnText : ""}
                    </Button>
                </div>
            }
            {
                isBulkUpload && <>
                    <div className="upload-container">
                        <div {...getRootProps()} className="upload-box">
                            <input id={id} {...getInputProps()} />
                            <UploadOutlined className="upload-icon"/>
                            <p className="upload-title">Upload Excel sheet</p>
                            <p className="upload-description">Supported file: .xlsx</p>
                        </div>
                    </div>
                </>
            }
        </>
    );

};

export default FilePickerComponent;

// ****************************** USAGE ****************************** //

// <FilePickerComponent
//     acceptedFileTypes={{
//         'image/*': []
//     }}
//     maxFileCount={2}
//     acceptedFilesText={"PNG, JPG and JPEG files are allowed"}/>

// ****************************** USAGE ****************************** //
