import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {DateRangeOutlined} from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {PropsWithChildren, useCallback} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import {CommonService, localStore} from "../../../../../helpers";
import "./GeneratedFilterDrawerComponent.scss";
import {useLocalStorage} from "../../../../../components/useLocalStorage";
import {ImageConfig} from "../../../../../constants";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    option: {
        '&[aria-selected="true"]': {
            backgroundColor: '#41D6C3',
            color: 'white',
        },
    },
}));


export interface GeneratedFilterDrawerComponentProps {
    setOpenFilterDrawer:any;
    filterData:any;
    setFilterData:any;
    facilityList: any;
    isFacilityListLoading: any;
    resetFilters:any;
}

const GeneratedFilterDrawerComponent = (props: PropsWithChildren<GeneratedFilterDrawerComponentProps>) => {
    const classes=useStyles();
    const statusList = [
        {name: "Paid", code: "paid"},
        {name: "Unpaid", code: "unpaid"},
        {name: "Cancelled", code: "cancelled"}
    ];
    const {
        setOpenFilterDrawer,
        resetFilters,
        isFacilityListLoading,
        facilityList,
        filterData,
        setFilterData,
    } = props;

    const [isAllFacilitiesSelected, setIsAllFacilitiesSelected] = useLocalStorage(
        "isAllFacilitiesSelectedGenerated",
        false
    );

    const formattedFacilityList = facilityList
        ? facilityList.map((item: any) => ({
            code: item?._id,
            name: item?.name,
        }))
        : [];

    const formatFilterMenuList = useCallback(
        (options: any[], isAllMenuItemSelected = false): any[] => {
            const allOptions = options.filter(
                (item) => item.code !== "Select All" && item.code !== "Clear All"
            );

            if (isAllMenuItemSelected) {
                return [{ name: "Clear All", code: "Clear All" }, ...allOptions];
            }

            return [{ name: "Select All", code: "Select All" }, ...allOptions];
        },
        []
    );

    const handleFilterValueChange = useCallback(
        (newValues: any[], options: any[], key: string) => {
            const isSelectAll = newValues.some((f: any) => f.code === "Select All");
            const isClearAll = newValues.some((f: any) => f.code === "Clear All");

            if (newValues.length === 0 || isClearAll) {
                setIsAllFacilitiesSelected(false);
                setFilterData((prevData: any) => {
                    const { [key]: removedKey, ...rest } = prevData;
                    return rest;
                });
                return;
            }

            if (isSelectAll) {
                setIsAllFacilitiesSelected(true);
                const allOptions = options.filter(
                    (item) => item.code !== "Select All" && item.code !== "Clear All"
                );
                setFilterData((prevData: any) => ({
                    ...prevData,
                    [key]: allOptions.map((item) => item.code),
                }));
                return;
            }

            setFilterData((prevData: any) => ({
                ...prevData,
                [key]: newValues.map((item: any) => item.code),
            }));
        },
        [setFilterData,setIsAllFacilitiesSelected]
    );

    const handleStatus = useCallback(
        (selectedOption: any) => {
            setFilterData((prevData: any) => ({
                ...prevData,
                status: selectedOption?.code || null,
            }));
        },
        [setFilterData]
    );

    const handleDateChange = useCallback(
        (date: any, key: string) => {
            console.log(date,"DATE")
            setFilterData((prevData: any) => ({
                ...prevData,
                [key]: date ? CommonService.convertToDateFromTimestamp(date) : null,
            }));
        },
        [setFilterData]
    );


    return (
        <div className="facility-filter-component">
            <div className={'facility-filter-header-wrapper'}>
                <div className={'facility-filter-header'}>
                    <div className={'facility-filter-title'}>Filters</div>
                    <div className={'facility-filter-clear-all'} onClick={resetFilters}>Clear All</div>
                </div>
                <div
                    className={'facility-filter-close-btn'}
                    onClick={() => setOpenFilterDrawer(false)}
                >
                    <img src={ImageConfig.CrossIcon} alt={'cross-icon'}/>
                </div>
            </div>
            <div className={'facility-filter-fields-wrapper'}>
                <div className="facility-filters">
                    <Autocomplete
                        disableClearable
                        disabled={isFacilityListLoading}
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        getOptionLabel={(option: any) => option?.name}
                        getOptionSelected={(option: any, value: any) => option?.name === value?.name}
                        id="input_select_facility"
                        // value={selectedFacilities}
                        value={formattedFacilityList.filter((item: any) =>
                            filterData?.facilities?.includes(item.code)
                        )}
                        options={formatFilterMenuList(
                            formattedFacilityList,
                            isAllFacilitiesSelected
                        )}
                        onChange={(e, newValue) =>
                            handleFilterValueChange(newValue, formattedFacilityList, "facilities")
                        }
                        // renderTags={() => null}
                        classes={{
                            option: classes.option,
                        }}
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({ index: 0 })}>
                                    {selectedCount} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.facilities?.length === 0 || !filterData?.facilities
                                ? "Facilities"
                                : "";
                            return <TextField {...params} id="select_hcpType" variant="outlined" placeholder={placeholder} />;
                        }}

                        disableCloseOnSelect
                    />
                    {isFacilityListLoading && (
                        <div className="loading-text-wrapper">
                            <span className="loading-text">loading...</span>
                        </div>
                    )}
                </div>
                <div>
                    <Autocomplete
                        PaperComponent={({children}) => <Paper
                            style={{color: "#1e1e1e"}}>{children}</Paper>}
                        // value={status}
                        value={
                            statusList.find((option: any) => option.code === filterData?.status) ||
                            null
                        }
                        options={statusList}
                        getOptionLabel={(option: any) => option.name}
                        placeholder={"Select Status"}
                        id="input_select_status"
                        classes={{
                            option: classes.option,
                        }}
                        onChange={($event, value) => {
                            // setStatus(value);
                            handleStatus(value);
                            // console.log(value);
                        }}
                        renderInput={(params) => <TextField {...params} id="select_status"
                                                            variant="outlined"
                                                            placeholder={"Status"}/>}
                    />
                </div>
                <div className="start-date-filter">
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Start Date"
                        className="custom-date-input"
                        selected={filterData?.start_date && new Date(filterData?.start_date)}
                        //  selectsRange={true}
                        // startDate={new Date()}
                        //endDate={endDate && new Date(endDate)}
                        onChange={(date) => handleDateChange(date, "start_date")}
                        // isClearable={true}
                    />

                    {filterData?.start_date &&
                        <CancelIcon style={{cursor: "pointer"}} className={"custom-date-icon"}
                                    onClick={() => {
                                        let updatedFilterData = {...filterData};
                                        delete updatedFilterData?.start_date;
                                        setFilterData(updatedFilterData)
                                    }}
                        />}
                    {!filterData?.start_date &&
                        <DateRangeOutlined className="custom-date-icon" fontSize="small" color="action"/>}
                </div>

                <div className="end-date-filter">
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="End Date"
                        className="custom-date-input"
                        selected={filterData?.end_date && new Date(filterData?.end_date)}
                        onChange={(date) => handleDateChange(date, "end_date")}
                    />
                    {filterData?.end_date &&
                        <CancelIcon style={{cursor: "pointer"}} className={"custom-date-icon"}
                                    onClick={() => {
                                        let updatedFilterData = {...filterData};
                                        delete updatedFilterData?.end_date;
                                        setFilterData(updatedFilterData)
                                    }}
                        />}
                    {!filterData?.end_date &&
                        <DateRangeOutlined className="custom-date-icon" fontSize="small" color="action"/>}
                </div>
            </div>
        </div>
    );
};

export const clearGeneratedFilters = () => {
    localStore.removeItem("generatedInvoiceStatus");
    localStore.removeItem("selectedFacilitiesGenerated");
    localStore.removeItem("generatedTabStartDate");
    localStore.removeItem("generatedTabEndDate");
};

export default GeneratedFilterDrawerComponent;