import "./GeneratedInvoiceViewDetails.scss";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {CommonService, Communications} from "../../../../../helpers";
import {ENV, ImageConfig} from "../../../../../constants";
import {Link, useHistory, useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import LoaderComponent from "../../../../../components/LoaderComponent";
// @ts-ignore
// import html2pdf from "html2pdf.js";
import PDFFileForTimesheet from "../PdfViewer";
import DialogComponent from "../../../../../components/DialogComponent";
import ConfirmationDialogAction from "../../../../../components/confirmation-component/ConfirmationDialogAction";

const GeneratedInvoiceViewDetails = () => {
    const [invoiceDetails, setInvoiceDetails] = useState<any>(null);
    const [isInvoiceDetailsLoading, setIsInvoiceDetailsLoading] = useState<any>(false);
    const params = useParams<{ id: string }>();
    const {id} = params;
    const printableRef = useRef(null);
    const [totalHrs, setTotalHrs] = useState<any>(0);
    const [sortedTimesheets, setSortedTimesheets] = useState<any>([]);
    const [openGeneratePopup, setGeneratePopUpOpen] = useState<boolean>(false);
    const [isConfirmMarking, setIsConfirmMarking] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        const sortedData = invoiceDetails?.timesheets?.sort((a: any, b: any) => {
            if (a?.shift_date === b?.shift_date) {
                // If dates are equal, sort by combined name
                const aFullName = `${a?.hcp?.first_name} ${a?.hcp?.last_name}`.trim();
                const bFullName = `${b?.hcp?.first_name} ${b?.hcp?.last_name}`.trim();
                return aFullName.localeCompare(bFullName);
            } else {
                // Otherwise, sort by date
                return new Date(a.shift_date).getTime() - new Date(b.shift_date).getTime();
            }
        });
        setSortedTimesheets(sortedData);
    }, [invoiceDetails]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Invoice Details");
        Communications.pageBackButtonSubject.next("/accounting");
    }, []);

    const viewInvoiceDetails = useCallback(() => {
        setIsInvoiceDetailsLoading(true);
        CommonService._api.get(ENV.API_URL + `invoice/${id}`).then((resp) => {
            setInvoiceDetails(resp?.data);
            setIsInvoiceDetailsLoading(false);
        }).catch((err) => {
            console.log(err);
            history.push("/accounting");
            setIsInvoiceDetailsLoading(false);
        });
    }, [id,history]);

    useEffect(() => {
        viewInvoiceDetails();
    }, [viewInvoiceDetails]);

    const markShiftCancel = useCallback(() => {
        CommonService._api.patch(ENV.API_URL + `invoice/${id}/cancel`).then((resp) => {
            CommonService.showToast(resp?.message || "Success", "success");
            setIsConfirmMarking(false);
            setGeneratePopUpOpen(false);
            viewInvoiceDetails();
        }).catch((err) => {
            CommonService.showToast(err?.error || "Error", "error");
            setIsConfirmMarking(false);
            setGeneratePopUpOpen(false);
        });
    }, [viewInvoiceDetails, id]);

    const calculateTotalHrs = () => {
        let totalHrs = 0;
        invoiceDetails?.timesheets?.forEach((item: any) => {
            totalHrs += Number(item.reg_hrs) + Number(item.dt_hrs) + Number(item.ot_hrs);
        });
        setTotalHrs(totalHrs);
    };


    useEffect(() => {
        if (invoiceDetails) {
            calculateTotalHrs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceDetails]);

    const totalAmountSum = (invoiceDetails?.timesheets || []).reduce((sum: number, obj: any) => sum + obj.total_amount, 0);
    const TotalAmount = Number(totalAmountSum).toFixed(2);

    const cancelOpenGeneratePopup = useCallback(() => {
        setGeneratePopUpOpen(false);
    }, []);

    const handleTimesheetDownload = useCallback(() => {
        ///invoice/:_id/timesheetDownload  -> POST for timesheets download
        CommonService._api.post(ENV.API_URL + `invoice/${id}/timesheetDownload`).then((res) => {
            if (res?.data) {
                const link = document.createElement("a");
                link?.setAttribute("href", res?.data);
                document.body.appendChild(link);
                link.click();
            } else {
                CommonService.showToast(res?.msg || "No Data to Download", "info");
            }
        })
            .catch((err) => {
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [id]);


    if (isInvoiceDetailsLoading) {
        return (
            <div className="pending-shiftsrequirment-view screen pdd-100">
                <LoaderComponent position="block"/>
            </div>
        );
    }


    return (
        <>
            <div ref={printableRef}
                 className={'screen generated-invoice-view-details-component crud-layout pdd-50'}>
                <DialogComponent isEditableDialog={true} cancel={cancelOpenGeneratePopup} open={openGeneratePopup}>
                    <ConfirmationDialogAction headerText={"CANCEL INVOICE"} cancel={cancelOpenGeneratePopup}
                                              confirm={markShiftCancel} confirmationText={`Are you sure you want to cancel 
Invoice No. ${invoiceDetails?.invoice_number} ?`} notext={"NO"} yestext={"YES"} isConfirm={isConfirmMarking}/>
                </DialogComponent>
                <div ref={printableRef} style={{display: "flex", justifyContent: "space-between"}}>
                    <div onClick={() => {
                        calculateTotalHrs();
                    }} style={{marginRight: "150px", display: "flex", alignItems: "center", fontWeight: 700}}>Invoice
                        Summary
                    </div>
                    <div style={{display: "flex"}}>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            className={"mrg-right-10"}
                            onClick={handleTimesheetDownload}
                        >
                            Download CSV
                        </Button>

                        <Button
                            color={"primary"}
                            variant={"outlined"}
                        >
                            <PDFFileForTimesheet TotalAmount={TotalAmount} totalHrs={totalHrs}
                                                 invoiceDetails={invoiceDetails} sortedTimesheets={sortedTimesheets}/>
                        </Button>

                        {!invoiceDetails?.finance_charge?.amount && invoiceDetails?.status !== "cancelled" && invoiceDetails?.status !== "paid" &&
                            <div className={"mrg-left-15"}>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                >
                                    <Link to={"/EditGeneratedInvoiceViewDetails/" + id}
                                          style={{textDecoration: "none", color: "#10C4D2"}}
                                          id={"link_invoice_generated_details"}>Add Any Other Charge
                                    </Link>
                                </Button>
                            </div>}
                        {invoiceDetails?.status !== "cancelled" && invoiceDetails?.status !== "paid" &&
                            <div className={"mrg-left-15"}>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={() => {
                                        setGeneratePopUpOpen(true);
                                    }}
                                >
                                    Cancel Invoice
                                </Button>
                            </div>}
                        {invoiceDetails?.status === "unpaid" &&
                            <div className={"mrg-left-15"}>
                                <Button
                                    color={"primary"}
                                    variant={"contained"}
                                >
                                    <Link to={"/EditGeneratedInvoiceViewDetails/" + id}
                                          style={{textDecoration: "none", color: "white"}}
                                          id={"link_invoice_generated_details"}>Edit Invoice Details
                                    </Link>
                                </Button>
                            </div>

                        }
                    </div>
                </div>
                <div>
                    <div className="custom-border pdd-10  pdd-bottom-0 mrg-top-30 ">
                        <div className="brand">
                            <div style={{color: "black"}}>
                                <img src={ImageConfig.Logo} alt={'vitawerks'}/>
                            </div>
                        </div>
                        <div className={"d-flex interior-border pdd-10 mrg-top-10"}
                             style={{justifyContent: "space-between"}}>
                            <div>
                                Customer No:{invoiceDetails?.facility?.uid}
                            </div>
                            <div>
                                Invoice No:{invoiceDetails?.invoice_number}
                            </div>
                            <div>
                                {invoiceDetails?.invoice_generated_date &&
                                    <>
                                        Invoice
                                        Date:{CommonService.getUtcDate(invoiceDetails?.invoice_generated_date)}
                                    </>}
                                {!invoiceDetails?.invoice_generated_date &&
                                    <>
                                        Invoice Date:{CommonService.getUtcDate(invoiceDetails?.created_at)}
                                    </>}

                            </div>
                        </div>
                        <div className={'interior-border pdd-10 mrg-top-10'}>
                            <div className="billing-form">
                                <div>
                                    <div className="title">Billing From</div>
                                    <div className="billing-details">
                                        <div className="detail">
                                            <div className="label"
                                                 style={{color: "black"}}>{invoiceDetails?.billing_from?.name}
                                            </div>
                                        </div>
                                        <div className="detail">
                                            <span className="label">{invoiceDetails?.billing_from?.street}</span>
                                        </div>
                                        <div className="detail">
                                        <span
                                            className="label">{invoiceDetails?.billing_from?.city}, </span>{invoiceDetails?.billing_from?.state}, {invoiceDetails?.billing_from?.zip_code}
                                        </div>
                                        <div className="detail">
                                            <span className="label">EIN:</span>{invoiceDetails?.billing_from?.ein}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="title">Billing To</div>
                                    <div className="billing-details">
                                        <div className="detail">
                                            <div className="label"
                                                 style={{color: "black"}}>{invoiceDetails?.billing_to?.name}
                                            </div>
                                        </div>
                                        <div className="detail">
                                            <span className="label">{invoiceDetails?.billing_to?.street}</span>
                                        </div>
                                        <div className="detail">
                                        <span
                                            className="label">{invoiceDetails?.billing_to?.city}, </span>{invoiceDetails?.billing_to?.state}, {invoiceDetails?.billing_to?.zip_code}
                                        </div>
                                        <div className="detail">
                                            <span className="label">ATTN:</span>{invoiceDetails?.billing_to?.attn}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'mrg-top-10'}>
                            <div className="data-grid">
                                <div className="row">
                                    <div className="col col-width">Reference - PO#</div>
                                    <div className="col col-width">START DATE</div>
                                    <div className="col col-width">END DATE</div>
                                    <div className="col col-width">ADDL.</div>
                                    <div className="col col-width">INVOICE DESCRIPTION</div>
                                    <div className="col col-width">DOC.DATE</div>
                                </div>
                                <div className="row">
                                    <div className="col col-width">{invoiceDetails?.reference}</div>
                                    <div
                                        className="col col-width">
                                        {CommonService.getUtcDate(invoiceDetails?.start_date)}
                                    </div>
                                    <div
                                        className="col col-width">
                                        {CommonService.getUtcDate(invoiceDetails?.end_date)}
                                    </div>
                                    <div className="col col-width">{invoiceDetails?.addl}</div>
                                    <div
                                        className="col col-width">{invoiceDetails?.reference + " " + invoiceDetails?.addl}</div>
                                    <div
                                        className="col col-width">
                                        {invoiceDetails?.invoice_generated_date &&
                                            <>
                                                {CommonService.getUtcDate(invoiceDetails?.invoice_generated_date)}
                                            </>}
                                        {!invoiceDetails?.invoice_generated_date &&
                                            <>
                                                {CommonService.getUtcDate(invoiceDetails?.created_at)}
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'mrg-top-10'}>
                            <div className="data-grid">
                                <div className="row">
                                    <div className="col col-width">ITEM</div>
                                    <div className="col col-width">ITEM DETAILS</div>
                                    <div className="col col-width">TOTAL HOURS</div>
                                    <div className="col col-width">CURR. ($)</div>
                                    <div className="col col-width">TOTAL AMOUNT</div>
                                </div>
                                <div className="row">
                                    <div className="col col-width">1</div>
                                    <div className="col col-width">{invoiceDetails?.reference}</div>
                                    <div className="col col-width">{totalHrs ? totalHrs.toFixed(2) : ""}</div>
                                    <div className="col col-width">USD</div>
                                    <div
                                        className="col col-width"> {(Number(TotalAmount) + Number(invoiceDetails?.finance_charge?.amount ? invoiceDetails?.finance_charge?.amount : 0))?.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}</div>
                                </div>
                                <div className={'interior-border pdd-15'} style={{wordWrap: "break-word"}}>
                                    {invoiceDetails?.comment || "The enclosed health/individual’s information was reproduced by Vitawerks, Inc.. The confidentiality of this information is protected by federal and other\n" +
                                        "law. These reproductions are intended exclusively for the requested, authorized, purpose and cannot be redistributed for other purposes without the written consent of the person to whom they pertain.\n" +
                                        "If you have questions regarding these records or any other information provided or requested from Vitawerks, Inc. Call 309 472 3148"}
                                </div>
                            </div>
                        </div>
                        <div className={'interior-border pdd-10 mrg-top-10'}>
                            <div className="billing-form">
                                <div>
                                    <div className="title">Checks Payable / Remit to:</div>
                                    <div className="billing-details">
                                        {invoiceDetails?.checks_payable?.name && (
                                            <div className="detail">
                                                <div className="label" style={{color: "black"}}>
                                                    {invoiceDetails.checks_payable.name}
                                                </div>
                                            </div>
                                        )}

                                        {invoiceDetails?.checks_payable?.street && (
                                            <div className="detail">
            <span className="label">
                {invoiceDetails.checks_payable.street}
            </span>
                                            </div>
                                        )}

                                        {(invoiceDetails?.checks_payable?.city || invoiceDetails?.checks_payable?.state || invoiceDetails?.checks_payable?.zip_code) && (
                                            <div className="detail">
            <span className="label">
                {invoiceDetails.checks_payable.city ? `${invoiceDetails.checks_payable.city}, ` : ""}
                {invoiceDetails.checks_payable.state ? `${invoiceDetails.checks_payable.state}, ` : ""}
                {invoiceDetails.checks_payable.zip_code || ""}
            </span>
                                            </div>
                                        )}

                                        {invoiceDetails?.checks_payable?.attn && (
                                            <div className="detail">
                                                <span className="label">ATTN: </span>
                                                {invoiceDetails.checks_payable.attn}
                                            </div>
                                        )}

                                        {invoiceDetails?.checks_payable?.ein && (
                                            <div className="detail">
                                                <span className="label">EIN: </span>
                                                {invoiceDetails.checks_payable.ein}
                                            </div>
                                        )}
                                    </div>

                                </div>
                                <div>
                                    <div className="title">Please SEND your remittance to :</div>
                                    <div className="billing-details">
                                        {invoiceDetails?.send_remittance?.name && (
                                            <div className="detail">
                                                <div className="label" style={{color: "black"}}>
                                                    {invoiceDetails.send_remittance.name}
                                                </div>
                                            </div>
                                        )}

                                        {invoiceDetails?.send_remittance?.street && (
                                            <div className="detail">
            <span className="label">
                {invoiceDetails.send_remittance.street}
            </span>
                                            </div>
                                        )}

                                        {(invoiceDetails?.send_remittance?.city || invoiceDetails?.send_remittance?.state || invoiceDetails?.send_remittance?.zip_code) && (
                                            <div className="detail">
            <span className="label">
                {invoiceDetails.send_remittance.city ? `${invoiceDetails.send_remittance.city}, ` : ""}
                {invoiceDetails.send_remittance.state ? `${invoiceDetails.send_remittance.state}, ` : ""}
                {invoiceDetails.send_remittance.zip_code || ""}
            </span>
                                            </div>
                                        )}

                                        {invoiceDetails?.send_remittance?.account && (
                                            <div className="detail">
                                                <span className="label">Account: </span>
                                                {invoiceDetails.send_remittance.account}
                                            </div>
                                        )}

                                        {invoiceDetails?.send_remittance?.electronic && (
                                            <div className="detail">
                                                <span className="label">Electronic: </span>
                                                {invoiceDetails.send_remittance.electronic}
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="custom-border pdd-10 pdd-bottom-0 mrg-top-30">
                        <div className="brand">
                            <img src={ImageConfig.Logo} alt={'vitawerks'}/>
                        </div>
                        <div className={"d-flex interior-border pdd-10 mrg-top-10"}
                             style={{justifyContent: "space-between"}}>
                            <div>
                                Customer No:{invoiceDetails?.facility?.uid}
                            </div>
                            <div>
                                Invoice No:{invoiceDetails?.invoice_number}
                            </div>
                            <div>
                                {invoiceDetails?.invoice_generated_date &&
                                    <>
                                        Invoice
                                        Date:{CommonService.getUtcDate(invoiceDetails?.invoice_generated_date)}
                                    </>}
                                {!invoiceDetails?.invoice_generated_date &&
                                    <>
                                        Invoice Date:{CommonService.getUtcDate(invoiceDetails?.created_at)}
                                    </>}

                            </div>
                        </div>
                        <div className={'interior-border pdd-10 mrg-top-10'}>
                            <div className="billing-form">
                                <div>
                                    <div className="title">Billing From</div>
                                    <div className="billing-details">
                                        <div className="detail">
                                            <div className="label"
                                                 style={{color: "black"}}>{invoiceDetails?.billing_from?.name}
                                            </div>
                                        </div>
                                        <div className="detail">
                                            <span className="label">{invoiceDetails?.billing_from?.street}</span>
                                        </div>
                                        <div className="detail">
                                        <span
                                            className="label">{invoiceDetails?.billing_from?.city}, </span>{invoiceDetails?.billing_from?.state}, {invoiceDetails?.billing_from?.zip_code}
                                        </div>
                                        <div className="detail">
                                            <span className="label">EIN:</span>{invoiceDetails?.billing_from?.ein}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="title">Billing To</div>
                                    <div className="billing-details">
                                        <div className="detail">
                                            <div className="label"
                                                 style={{color: "black"}}>{invoiceDetails?.billing_to?.name}
                                            </div>
                                        </div>
                                        <div className="detail">
                                            <span className="label">{invoiceDetails?.billing_to?.street}</span>
                                        </div>
                                        <div className="detail">
                                        <span
                                            className="label">{invoiceDetails?.billing_to?.city}, </span>{invoiceDetails?.billing_to?.state}, {invoiceDetails?.billing_to?.zip_code}
                                        </div>
                                        <div className="detail">
                                            <span className="label">ATTN:</span>{invoiceDetails?.billing_to?.attn}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'interior-border pdd-15 mrg-top-10'} style={{wordWrap: "break-word"}}>
                            {invoiceDetails?.comment || "The enclosed health/individual’s information was reproduced by Vitawerks, Inc.. The confidentiality of this information is protected by federal and other\n" +
                                "law. These reproductions are intended exclusively for the requested, authorized, purpose and cannot be redistributed for other purposes without the written consent of the person to whom they pertain.\n" +
                                "If you have questions regarding these records or any other information provided or requested from Vitawerks, Inc. Call 309 472 3148"}
                        </div>
                        <div className={'mrg-top-10'}>
                            <div className="data-grid">
                                <div className="row">
                                    <div className="col col-width">Reference - PO#</div>
                                    <div className="col col-width">START DATE</div>
                                    <div className="col col-width">END DATE</div>
                                    <div className="col col-width">ADDL.</div>
                                    <div className="col col-width">INVOICE DESCRIPTION</div>
                                    <div className="col col-width">DOC.DATE</div>
                                </div>
                                <div className="row">
                                    <div className="col col-width">{invoiceDetails?.reference}</div>
                                    <div
                                        className="col col-width">{invoiceDetails?.start_date ? CommonService.getUtcDate(invoiceDetails?.start_date) : ""}
                                    </div>
                                    <div
                                        className="col col-width">
                                        {CommonService.getUtcDate(invoiceDetails?.end_date)}
                                    </div>
                                    <div className="col col-width">{invoiceDetails?.addl}</div>
                                    <div
                                        className="col col-width">{invoiceDetails?.reference + " " + invoiceDetails?.addl}</div>
                                    <div
                                        className="col col-width">      {invoiceDetails?.invoice_generated_date &&
                                        <>
                                            {CommonService.getUtcDate(invoiceDetails?.invoice_generated_date)}
                                        </>}
                                        {!invoiceDetails?.invoice_generated_date &&
                                            <>
                                                {CommonService.getUtcDate(invoiceDetails?.created_at)}
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'mrg-top-10'}>
                            <div className="data-grid">
                                <div className="row">
                                    <div className="col">ITEM</div>
                                    <div className="col flex-two">INVOICE DETAILS</div>
                                    <div className="col ">DATE</div>
                                    <div className="col ">CURR. ($)</div>
                                    <div className="col ">REG AMT</div>
                                    <div className="col ">OT AMT</div>
                                    <div className={'col '}>DT AMT</div>
                                    <div className={'col '}>TOTAL AMOUNT</div>
                                </div>
                                {sortedTimesheets && sortedTimesheets?.map((timesheet: any, index: any) => {
                                    return (
                                        <>
                                            <div className="row">
                                                <div className="col ">{index + 1}</div>
                                                <div
                                                    className="col flex-two ">
                                                    <div>
                                                        {timesheet?.hcp?.hcp_type} Staffing {timesheet?.shift_type} Shift
                                                    </div>
                                                    <div>
                                                        {timesheet?.hcp?.first_name} {timesheet?.hcp?.last_name}
                                                    </div>
                                                    <div>
                                                        {
                                                            timesheet?.reg_hrs && <>
                                                                {`${timesheet?.reg_hrs}H REG ${(timesheet?.total_hourly_rate ? timesheet?.total_hourly_rate : 0)?.toLocaleString('en-US', {
                                                                    style: 'currency',
                                                                    currency: 'USD',
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}`}
                                                            </>
                                                        }
                                                        {
                                                            timesheet?.ot_hrs && <>
                                                                {timesheet?.reg_hrs > 0 && <> | </>}
                                                                {`${timesheet?.ot_hrs}H OT ${(timesheet?.total_hourly_rate ? timesheet?.total_hourly_rate * 1.5 : 0)?.toLocaleString('en-US', {
                                                                    style: 'currency',
                                                                    currency: 'USD',
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}`}
                                                            </>
                                                        }
                                                        {
                                                            timesheet?.dt_hrs > 0 && <>
                                                                {timesheet?.ot_hrs > 0 && <> | </>}
                                                                {`${timesheet?.dt_hrs}H DT ${(timesheet?.total_hourly_rate ? timesheet?.total_hourly_rate * 2 : 0)?.toLocaleString('en-US', {
                                                                    style: 'currency',
                                                                    currency: 'USD',
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}`}
                                                            </>
                                                        }
                                                    </div>
                                                </div>


                                                <div
                                                    className="col ">{CommonService?.getUtcDate(timesheet?.shift_date) || "NA"}</div>

                                                <div className="col ">USD</div>

                                                <div
                                                    className="col "> {timesheet?.reg_amount ? timesheet?.reg_amount?.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) : "$0"}</div>
                                                <div
                                                    className="col "> {timesheet?.ot_amount ? timesheet?.ot_amount?.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) : "$0"}</div>
                                                <div
                                                    className="col ">{timesheet?.dt_amount ? timesheet?.dt_amount?.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) : "$0"}</div>
                                                <div
                                                    className="col ">{timesheet?.total_amount ? timesheet?.total_amount?.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) : "$0"}</div>
                                            </div>
                                        </>
                                    );
                                })}
                                {(invoiceDetails?.finance_charge?.details || invoiceDetails?.finance_charge?.date || invoiceDetails?.finance_charge?.amount) &&
                                    <div className="row">
                                        <div className="col ">{sortedTimesheets?.length + 1}</div>
                                        <div
                                            className="col flex-two ">
                                            {invoiceDetails?.finance_charge?.details}
                                        </div>
                                        <div
                                            className="col ">{invoiceDetails?.finance_charge?.date ? CommonService.getUtcDate(invoiceDetails?.finance_charge?.date) : null}</div>
                                        <div className="col ">USD</div>
                                        <div className="col ">-</div>
                                        <div className="col "> -</div>
                                        <div className="col ">-</div>
                                        <div
                                            className="col ">{
                                            Number(invoiceDetails?.finance_charge?.amount)?.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}

                                        </div>
                                    </div>}

                            </div>
                        </div>
                        {/*{(invoiceDetails?.finance_charge?.details || invoiceDetails?.finance_charge?.date || invoiceDetails?.finance_charge?.amount) &&*/}
                        {/*    <div className={'mrg-top-10'}>*/}
                        {/*        <div className="data-grid">*/}
                        {/*            <div className="row">*/}
                        {/*                <div className="col col-width">ITEM</div>*/}
                        {/*                <div className="col col-width">INVOICE DETAILS</div>*/}
                        {/*                <div className="col col-width">DATE</div>*/}
                        {/*                <div className="col col-width">AMOUNT</div>*/}
                        {/*            </div>*/}
                        {/*            <div className="row">*/}
                        {/*                <div className="col col-width">1</div>*/}
                        {/*                <div className="col col-width">{invoiceDetails?.finance_charge?.details}</div>*/}
                        {/*                <div*/}
                        {/*                    className="col col-width">{invoiceDetails?.finance_charge?.date ? CommonService.getUtcDate(invoiceDetails?.finance_charge?.date) : null}</div>*/}
                        {/*                <div className="col col-width">{*/}
                        {/*                    Number(invoiceDetails?.finance_charge?.amount)?.toLocaleString('en-US', {*/}
                        {/*                        style: 'currency',*/}
                        {/*                        currency: 'USD',*/}
                        {/*                        minimumFractionDigits: 2,*/}
                        {/*                        maximumFractionDigits: 2,*/}
                        {/*                    })}*/}

                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>}*/}
                        <div className={'interior-border pdd-10 mrg-top-10'}>
                            <div className="billing-form">
                                <div>
                                    <div className="title">Checks Payable / Remit to:</div>
                                    <div className="billing-details">
                                        {invoiceDetails?.checks_payable?.name && (
                                            <div className="detail">
                                                <div className="label" style={{color: "black"}}>
                                                    {invoiceDetails.checks_payable.name}
                                                </div>
                                            </div>
                                        )}

                                        {invoiceDetails?.checks_payable?.street && (
                                            <div className="detail">
            <span className="label">
                {invoiceDetails.checks_payable.street}
            </span>
                                            </div>
                                        )}

                                        {(invoiceDetails?.checks_payable?.city || invoiceDetails?.checks_payable?.state || invoiceDetails?.checks_payable?.zip_code) && (
                                            <div className="detail">
            <span className="label">
                {invoiceDetails.checks_payable.city ? `${invoiceDetails.checks_payable.city}, ` : ""}
                {invoiceDetails.checks_payable.state ? `${invoiceDetails.checks_payable.state}, ` : ""}
                {invoiceDetails.checks_payable.zip_code || ""}
            </span>
                                            </div>
                                        )}

                                        {invoiceDetails?.checks_payable?.attn && (
                                            <div className="detail">
                                                <span className="label">ATTN: </span>
                                                {invoiceDetails.checks_payable.attn}
                                            </div>
                                        )}

                                        {invoiceDetails?.checks_payable?.ein && (
                                            <div className="detail">
                                                <span className="label">EIN: </span>
                                                {invoiceDetails.checks_payable.ein}
                                            </div>
                                        )}
                                    </div>

                                </div>
                                <div>
                                    <div className="title">Please SEND your remittance to :</div>
                                    <div className="billing-details">
                                        {invoiceDetails?.send_remittance?.name && (
                                            <div className="detail">
                                                <div className="label" style={{color: "black"}}>
                                                    {invoiceDetails.send_remittance.name}
                                                </div>
                                            </div>
                                        )}

                                        {invoiceDetails?.send_remittance?.street && (
                                            <div className="detail">
            <span className="label">
                {invoiceDetails.send_remittance.street}
            </span>
                                            </div>
                                        )}

                                        {(invoiceDetails?.send_remittance?.city || invoiceDetails?.send_remittance?.state || invoiceDetails?.send_remittance?.zip_code) && (
                                            <div className="detail">
            <span className="label">
                {invoiceDetails.send_remittance.city ? `${invoiceDetails.send_remittance.city}, ` : ""}
                {invoiceDetails.send_remittance.state ? `${invoiceDetails.send_remittance.state}, ` : ""}
                {invoiceDetails.send_remittance.zip_code || ""}
            </span>
                                            </div>
                                        )}

                                        {invoiceDetails?.send_remittance?.account && (
                                            <div className="detail">
                                                <span className="label">Account: </span>
                                                {invoiceDetails.send_remittance.account}
                                            </div>
                                        )}

                                        {invoiceDetails?.send_remittance?.electronic && (
                                            <div className="detail">
                                                <span className="label">Electronic: </span>
                                                {invoiceDetails.send_remittance.electronic}
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
        ;
};
export default GeneratedInvoiceViewDetails;
