import "./GenerateInvoiceComponent.scss";
// import GenerateFilters from "./filter-for-generate-invoice/GenerateFilters";
// import { useState} from "react";
// import {CommonService} from "../../../../helpers";
// import {ENV} from "../../../../constants";
import GenerateTimeSheetList from "./timesheet-list/GenerateTimeSheetList";
// import {useLocalStorage} from "../../../../components/useLocalStorage";
// import {
//     TsDataListState,
// } from "../../../../classes/ts-data-list-wrapper.class";
//
// const [firstDayOfMonth, lastDayOfMonth] = CommonService.getFirstDayAndLastDayUsingDate();

const GenerateInvoiceComponent = () => {
    // const [selectedFacility, setSelectedFacility] = useLocalStorage<any>("selectedFacilityGenerate", null);
    // const [dateRange, setDateRange] = useState([firstDayOfMonth, lastDayOfMonth]);
    // const [isGenerateInvoiceListLoading, setIsGenerateInvoiceListLoading] = useState<any>(false);
    // const [shiftTimeShiftList, setShiftTimeShiftList] = useState<TsDataListState | null>(null);
    //    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("GeneratePageSizeIndex", 100);
    // const [selectedStatusTypes, setSelectedStatusTypes] = useState<any[]>([]);
    //  const [isPreviewingTheTimesheet, setIsPreviewingTheTimesheet] = useState<any>(false);


    // const timeshiftList = useCallback(() => {
    //     setIsGenerateInvoiceListLoading(true);
    //     setShiftTimeShiftList(null);
    //     let url = `facility/${selectedFacility?._id}/prospectiveTimesheets`;
    //     let payload: any = {};
    //     if (dateRange[0] || dateRange[1]) {
    //         let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
    //         let endDate = moment(dateRange[1]).format("YYYY-MM-DD");
    //         if (selectedStatusTypes.length > 0) {
    //             payload.status = selectedStatusTypes.map((item: any) => item?.code);
    //         }
    //         if (selectedHcps.length > 0) {
    //             payload.hcp_types = selectedHcps;
    //         }
    //         if (selectedTimeTypes.length > 0) {
    //             payload.shift_types = selectedTimeTypes;
    //         }
    //         if (!dateRange[1]) {
    //             payload.start_date = startDate;
    //             payload.end_date = startDate;
    //         } else {
    //             payload.start_date = startDate;
    //             payload.end_date = endDate;
    //         }
    //     } else {
    //         // CommonService.showToast("Please select date", "info");
    //         return;
    //     }
    //     if (!selectedFacility) {
    //         //   CommonService.showToast("Please select Facility", "info");
    //         return;
    //     }
    //     payload.is_invoice_generated = false;
    //     // CommonService._api.post(ENV.API_URL + `facility/${selectedFacility?._id}/prospectiveTimesheets`, payload).then((resp) => {
    //     //     setShiftTimeShiftList(resp?.data?.docs);
    //     //     setIsGenerateInvoiceListLoading(false);
    //     // }).catch((err) => {
    //     //    // console.log(err);
    //     //     setIsGenerateInvoiceListLoading(false);
    //     // });
    //     const options = new TsDataListOptions(
    //         {
    //             // @ts-ignore
    //             pagination: {
    //                 ...shiftTimeShiftList?.table?.pagination,
    //                 pageSize: pageSizeIndex,
    //             },
    //             extraPayload: payload,
    //             // webMatColumns: role === "super_admin" ? ["Created On", "Facility Name", "Region", "Contact Number", "Active / Inactive", "Actions"] : ["Created On", "Facility Name", "Region", "Contact Number", "Status", "Actions"],
    //             // mobileMatColumns: role === "super_admin" ? ["Created On", "Facility Name", "Region", "Contact Number", "Active / Inactive", "Actions"] : ["Created On", "Facility Name", "Region", "Contact Number", "Status", "Actions"],
    //         },
    //         ENV.API_URL + url,
    //         setShiftTimeShiftList,
    //         ApiService,
    //         "post"
    //     );
    //     let tableWrapperObj = new TsDataListWrapperClass(options);
    //     setShiftTimeShiftList({table: tableWrapperObj});
    //     // eslint-disable-next-line
    // }, [selectedFacility, dateRange, selectedHcps, selectedTimeTypes, pageSizeIndex, selectedStatusTypes]);
    //
    // useEffect(() => {
    //     timeshiftList();
    // }, [timeshiftList]);


    return (
        <>
            <div className={"generating-invoice-component"}>
                {/*<div className={"filter-wrapper"}>*/}
                {/*    <GenerateFilters isPreviewingTheTimesheet={isPreviewingTheTimesheet} setIsAllStatusTypesSelected={setIsAllStatusTypesSelected}*/}
                {/*                     isAllStatusTypesSelected={isAllStatusTypesSelected}*/}
                {/*                     setSelectedStatusTypes={setSelectedStatusTypes}*/}
                {/*                     selectedStatusTypes={selectedStatusTypes} selectedTimeTypes={selectedTimeTypes}*/}
                {/*                     setSelectedTimeTypes={setSelectedTimeTypes}*/}
                {/*                     selectedHcps={selectedHcps} setSelectedHcps={setSelectedHcps}*/}
                {/*                     timeshiftList={timeshiftList} dateRange={dateRange} setDateRange={setDateRange}*/}
                {/*                     selectedFacility={selectedFacility} setSelectedFacility={setSelectedFacility}/>*/}
                {/*</div>*/}
                <div className={"generate-timesheet-list mrg-top-50"}>
                    <GenerateTimeSheetList />
                </div>

            </div>
        </>
    );
};
export default GenerateInvoiceComponent;