import "./GenerateTimesheetList.scss";
import {ApiService, CommonService} from "../../../../../helpers";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ENV, ImageConfig} from "../../../../../constants";
import moment from "moment";
import Button from "@material-ui/core/Button";
import DialogComponent from "../../../../../components/DialogComponent";
import {useHistory} from "react-router-dom";
import ConfirmationDialogAction from "../../../../../components/confirmation-component/ConfirmationDialogAction";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {DatePicker} from "formik-material-ui-pickers";
import {Tooltip} from "@material-ui/core";
import * as Yup from "yup";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";
import DrawerComponent from "../../../../../components/drawer/DrawerComponent";
import GenerateInvoiceFilteDrawerComponent
    from "../generate-invoice-filter-drawer/GenerateInvoiceFilterDrawerComponent";
import {useSessionStorage} from "../../../../../components/useSessionStorage";

const [firstDayOfMonth, lastDayOfMonth] = CommonService.getFirstDayAndLastDayUsingDate();


interface GenerateTimesheetProps {
}


const GenerateTimeSheetList = (props: GenerateTimesheetProps) => {
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const history = useHistory();
    const [openGeneratePopup, setGeneratePoopupOpen] = useState<boolean>(false);
    const [isConfirmMarking, setIsConfirmMarking] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [generateTimesheetMode, setGenerateTimesheetMode] = useState<"edit" | "default">("default");
    const [addFinanceChargePopupOpen, setAddFinanceChargePopupOpen] = useState(false);
    const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
    const [selectedFacility, setSelectedFacility] = useSessionStorage<any>("selectedFacilityGenerate", null);
    // const [dateRange, setDateRange] = useState([firstDayOfMonth, lastDayOfMonth]);
    // const [selectedHcps, setSelectedHcps] = useLocalStorage<any[]>("selectedHcpsGeberate", []);
    // const [selectedTimeTypes, setSelectedTimeTypes] = useLocalStorage<any[]>("selectedTimeTypesGenerate", []);
    // const [selectedStatusTypes, setSelectedStatusTypes] = useState<any[]>([]);
    const [isAllStatusTypesSelected, setIsAllStatusTypesSelected] = useState<any>(false);
    const [isPreviewingTheTimesheet, setIsPreviewingTheTimesheet] = useState<any>(false);
    const [filterData, setFilterData] = useSessionStorage<any>("generateTimeSheetFilterData",{
        search: '',
        sort: {},
        is_invoice_generated: false,
        start_date: moment(firstDayOfMonth).format("YYYY-MM-DD"),
        end_date: moment(lastDayOfMonth).format("YYYY-MM-DD"),
    })

    const [previewFilterData, setPreviewFilterData] = useState<any>({})


    const cancelOpenGeneratePopup = useCallback(() => {
        setGeneratePoopupOpen(false);
    }, []);

    const cancelOpenAddFinancePopup = useCallback(() => {
        setAddFinanceChargePopupOpen(false);
    }, []);

    const generateInvoice = useCallback(() => {
        let payload: any = {
            is_invoice_generated: true,
        };
        if (filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");

            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        if (selectedShifts) {
            payload.shift_ids = selectedShifts;
        }
        if (filterData?.status?.length > 0) {
            // payload.status = selectedStatusTypes.map((item: any) => item?.code);
            payload.status=filterData?.status;
        }
        CommonService._api.post(ENV.API_URL + `facility/${selectedFacility?._id}/invoice`, payload).then((resp) => {
            if (generateTimesheetMode === "edit") {
                history.push(`/EditGeneratedInvoiceViewDetails/${resp?.data[0]?.invoice_id}`);
            } else {
                history.push(`/generatedInvoiceViewDetails/${resp?.data[0]?.invoice_id}`);
            }
            setIsConfirmMarking(false);
            setGeneratePoopupOpen(false);
            CommonService.showToast("success", "success");
        }).catch((err) => {
            setGeneratePoopupOpen(false);
            setIsConfirmMarking(false);
        });
    }, [selectedFacility, selectedShifts, history, generateTimesheetMode,filterData]);

    useEffect(() => {
        if (generateTimesheetMode === "edit") {
            setGeneratePoopupOpen(true);
        }
    }, [generateTimesheetMode]);


    useEffect(() => {
        if (selectedFacility || filterData?.start_date || filterData?.end_date) {
            setIsPreviewingTheTimesheet(false);
            setSelectedShifts([]);
        }
        // eslint-disable-next-line
    }, [selectedFacility, filterData?.start_date,filterData?.end_date]);

    const handleInvoiceDownload = useCallback(() => {
        let payload: any = {};
        if (selectedShifts) {
            payload.shift_ids = selectedShifts;
        }
        CommonService._api.post(ENV.API_URL + `invoice/previewDownload`, payload).then((res) => {
            if (res?.data) {
                const link = document.createElement("a");
                link?.setAttribute("href", res?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService.showToast(res?.msg || "No Data to Download", "info");
                setIsDownloading(false);
            }
        })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [selectedShifts]);

    const InvoiceGeneratedInitialState = {

            finance_charge: {
                details: "",
                date: null,
                amount: "",
            },
        }
    ;

    const editFinanceChargeFormValidation = Yup.object({
        finance_charge: Yup.object({
            details: Yup.string().typeError(" must be a text").required("Invoice Details is required"),
            date: Yup.string().nullable().typeError(" must be a date").required("Date is required"),
            amount: Yup.string().nullable().required("Amount is required"),
        }),
    });

    const onEditFinanceCharge = useCallback((item: any, {setSubmitting, setErrors, resetForm}) => {
        console.log(selectedFacility);
        let payload: any = {
            ...item,
            finance_charge: {
                details: item?.finance_charge?.details,
                date: item?.finance_charge?.date,
                amount: parseFloat(item?.finance_charge?.amount.replace('$', ''))
            }
        };
        if (filterData?.start_date || filterData?.end_date) {
            let startDate = moment(filterData?.start_date).format("YYYY-MM-DD");
            let endDate = moment(filterData?.end_date).format("YYYY-MM-DD");

            if (!filterData?.end_date) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }

        ApiService.post(ENV.API_URL + "facility/" + selectedFacility?._id + "/financeInvoice", payload)
            .then((resp: any) => {
                // console.log(resp);
                if (resp && resp.success) {
                    setAddFinanceChargePopupOpen(false);
                    history.push(`/EditGeneratedInvoiceViewDetails/${resp?.data?._id}`);
                    CommonService.showToast(resp.msg || "Success", "success");
                } else {
                    setAddFinanceChargePopupOpen(false);
                    setSubmitting(false);
                }
            })
            .catch((err) => {
                CommonService.handleErrors(setErrors, err);
                setAddFinanceChargePopupOpen(false);
                setSubmitting(false);
                CommonService.showToast(err.msg || "Error", "error");
            });

    }, [selectedFacility, history, filterData?.start_date,filterData?.end_date]);


    const generateInvoiceListColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'S.no',
                width: 100,
                render: (_: any, item: any, rowIndex: number) => {
                    return (
                        <div>
                            {rowIndex + 1}
                        </div>
                    )
                }
            },
            {
                title: 'Shift Date',
                width: 120,
                sorter: (a: any, b: any) => {
                    // Convert shift_date to Date objects and compare
                    const dateA = new Date(a["shift_date"]).getTime();
                    const dateB = new Date(b["shift_date"]).getTime();
                    return dateA - dateB;  // For ascending order
                },
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.getUtcDate(item["shift_date"]) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Staff Type',
                width: 200,
                sorter: (a: any, b: any) => {
                    const staffTypeA = a?.hcp?.hcp_type || '';
                    const staffTypeB = b?.hcp?.hcp_type || '';
                    return staffTypeA.localeCompare(staffTypeB); // Sort alphabetically
                },
                render: (item: any) => {
                    return (
                        <div>
                            {item?.hcp?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp?.hcp_type}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp?.hcp_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.hcp?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift',
                width: 180,
                sorter: (a: any, b: any) => {
                    const shiftA = a?.shift_type || '';
                    const shiftB = b?.shift_type || '';
                    return shiftA.localeCompare(shiftB); // Sort alphabetically
                },
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_type?.length > 20 ?
                                <Tooltip title={item?.shift_type}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.shift_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.shift_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Hours',
                width: 140,
                sorter: (a: any, b: any) => a?.shift_hours - b?.shift_hours,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_hours || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Worker Name',
                width: 200,
                sorter: (a: any, b: any) => {
                    const nameA = (a?.hcp?.first_name + a?.hcp?.last_name) || '';
                    const nameB = (b?.hcp?.first_name + b?.hcp?.last_name) || '';
                    return nameA.localeCompare(nameB); // Sort alphabetically
                },
                render: (item: any) => {
                    return (
                        <div>
                            {(item?.hcp?.first_name + item?.hcp?.last_name)?.length > 20 ?
                                <Tooltip title={item?.hcp?.first_name + " " + item?.hcp?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp?.first_name + " " + item?.hcp?.last_namee || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.hcp?.first_name + " " + item?.hcp?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Clock In',
                width: 100,
                // sorter: (a: any, b: any) => {
                //     const checkInA = a?.time_breakup?.check_in_time || '';
                //     const checkInB = b?.time_breakup?.check_in_time || '';
                //     return checkInA.localeCompare(checkInB); // Sort alphabetically
                // },
                render: (item: any) => {
                    return (
                        <div>
                            {item?.time_breakup?.check_in_time ?
                                CommonService.startTime(item?.time_breakup?.check_in_time)
                                :
                                <div style={{color: "red"}}>
                                    {CommonService.startTime(item?.expected?.shift_start_time)}
                                </div>}
                        </div>
                    )
                }
            },
            {
                title: 'Clock Out',
                width: 120,
                // sorter: (a: any, b: any) => {
                //     const checkOutA = a?.time_breakup?.check_out_time || '';
                //     const checkOutB = b?.time_breakup?.check_out_time || '';
                //     return checkOutA.localeCompare(checkOutB); // Sort alphabetically
                // },
                render: (item: any) => {
                    return (
                        <div>
                            {item["time_breakup"]?.check_out_time ? CommonService.endTime(item["time_breakup"]?.check_out_time) :
                                <div style={{color: "red"}}>
                                    {CommonService.endTime(item?.expected?.shift_end_time)}
                                </div>}
                        </div>
                    )
                }
            },
            {
                title: 'Hourly Rate',
                width: 130,
                sorter: (a: any, b: any) => {
                    const rateA = Number(a["hourly_rate"]);
                    const rateB = Number(b["hourly_rate"]);
                    return rateA - rateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {Number(item["hourly_rate"])?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || (0)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                }
            },
            {
                title: 'Late Request Premium',
                width: 200,
                sorter: (a: any, b: any) => {
                    const rateA = Number(a["rush_rate"]);
                    const rateB = Number(b["rush_rate"]);
                    return rateA - rateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {Number(item["rush_rate"])?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || (0)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                }
            },
            {
                title: 'Shift Differential',
                width: 165,
                sorter: (a: any, b: any) => {
                    const rateA = Number(a["shift_diff"]);
                    const rateB = Number(b["shift_diff"]);
                    return rateA - rateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {Number(item["shift_diff"])?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                }
            },
            {
                title: 'Hazard Pay',
                width: 150,
                sorter: (a: any, b: any) => {
                    const rateA = Number(a["hazard_rate"]);
                    const rateB = Number(b["hazard_rate"]);
                    return rateA - rateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {Number(item["hazard_rate"])?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || 0}
                        </div>
                    )
                }
            },
            {
                title: 'OT/Holiday Hours',
                width: 165,
                sorter: (a: any, b: any) => {
                    const otHrsA = !isNaN(Number(a["ot_hrs"])) ? Number(a["ot_hrs"]) : 0;
                    const otHrsB = !isNaN(Number(b["ot_hrs"])) ? Number(b["ot_hrs"]) : 0;
                    const dtHrsA = !isNaN(Number(a["dt_hrs"])) ? Number(a["dt_hrs"]) : 0;
                    const dtHrsB = !isNaN(Number(b["dt_hrs"])) ? Number(b["dt_hrs"]) : 0;
                    return (otHrsA + dtHrsA) - (otHrsB + dtHrsB);
                },
                render: (item: any) => {
                    const otHrs = !isNaN(Number(item["ot_hrs"])) ? Number(item["ot_hrs"]) : 0;
                    const dtHrs = !isNaN(Number(item["dt_hrs"])) ? Number(item["dt_hrs"]) : 0;
                    return (
                        <div>
                            {(otHrs + dtHrs).toFixed(2)}
                        </div>
                    )
                }
            },
            {
                title: 'Break',
                width: 180,
                render: (item: any) => {
                    return (
                        <div>
                            {item["time_breakup"].break_timings?.length > 0 ? item["time_breakup"].break_timings?.map((breaks: any, index: any) => {
                                const {
                                    start_time,
                                    end_time
                                } = CommonService.getUtcTimeInAMPM(breaks?.break_in_time, breaks?.break_out_time);
                                return (
                                    <>
                                        <div style={{
                                            marginBottom: "10px",
                                            color: "red"
                                        }}>
                                            {start_time + "-" + end_time}
                                        </div>
                                    </>
                                );
                            }) : <>
                                {item?.time_breakup?.check_in_time ? <>No Break</> : <>30
                                    Minutes</>}
                            </>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Total Hourly Rate',
                width: 175,
                sorter: (a: any, b: any) => {
                    const rateA = Number(a["total_hourly_rate"]);
                    const rateB = Number(b["total_hourly_rate"]);
                    return rateA - rateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {Number(item["total_hourly_rate"])?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || (0)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                }
            },
            {
                title: 'Regular Pay',
                width: 150,
                render: (item: any) => {
                    return (
                        <div style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>
                            $0.00
                        </div>
                    )
                }
            },
            {
                title: 'OT Pay',
                width: 100,
                render: (item: any) => {
                    return (
                        <div style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>
                            $0.00
                        </div>
                    )
                }
            },
            {
                title: 'DT Pay',
                width: 100,
                render: (item: any) => {
                    return (
                        <div style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>
                            $0.00
                        </div>
                    )
                }
            },
            {
                title: 'Total Amount',
                width: 120,
                render: (item: any) => {
                    return (
                        <div style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>
                            $0.00
                        </div>
                    )
                }
            },
            {
                title: 'Shift Status',
                width: 150,
                render: (item: any) => {
                    return (
                        <div className={`${item["status"]}`}>
                            {item["status"] === "pending" ? "Approved" : item["status"]?.charAt(0)?.toUpperCase() + item["status"]?.slice(1)}
                        </div>
                    )
                }
            }
        ]
    }, [isPreviewingTheTimesheet])
    const generatePreviewInvoiceListColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'S.no',
                width: 100,
                render: (_: any, item: any, rowIndex: number) => {
                    return (
                        <div>
                            {rowIndex + 1}
                        </div>
                    )
                }
            },
            {
                title: 'Shift Date',
                width: 120,
                sorter: (a: any, b: any) => {
                    const dateA = new Date(a["shift_date"]).getTime();
                    const dateB = new Date(b["shift_date"]).getTime();
                    return dateA - dateB;  // For ascending order
                },
                render: (item: any) => {
                    return (
                        <div>
                            {CommonService.getUtcDate(item["shift_date"]) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Staff Type',
                width: 200,
                sorter: (a: any, b: any) => {
                    const staffTypeA = a?.hcp?.hcp_type || '';
                    const staffTypeB = b?.hcp?.hcp_type || '';
                    return staffTypeA.localeCompare(staffTypeB); // Sort alphabetically
                },
                render: (item: any) => {
                    return (
                        <div>
                            {item?.hcp?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp?.hcp_type}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp?.hcp_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.hcp?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift',
                width: 180,
                sorter: (a: any, b: any) => {
                    const shiftA = a?.shift_type || '';
                    const shiftB = b?.shift_type || '';
                    return shiftA.localeCompare(shiftB); // Sort alphabetically
                },
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_type?.length > 20 ?
                                <Tooltip title={item?.shift_type}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.shift_type || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.shift_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Shift Hours',
                width: 130,
                sorter: (a: any, b: any) => a?.shift_hours - b?.shift_hours,
                render: (item: any) => {
                    return (
                        <div>
                            {item?.shift_hours || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title: 'Worker Name',
                width: 200,
                sorter: (a: any, b: any) => {
                    const nameA = (a?.hcp?.first_name + a?.hcp?.last_name) || '';
                    const nameB = (b?.hcp?.first_name + b?.hcp?.last_name) || '';
                    return nameA.localeCompare(nameB); // Sort alphabetically
                },
                render: (item: any) => {
                    return (
                        <div>
                            {(item?.hcp?.first_name + item?.hcp?.last_name)?.length > 20 ?
                                <Tooltip title={item?.hcp?.first_name + " " + item?.hcp?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp?.first_name + " " + item?.hcp?.last_namee || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item?.hcp?.first_name + " " + item?.hcp?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Clock In',
                width: 100,
                sorter: (a: any, b: any) => {
                    const checkInA = a?.time_breakup?.check_in_time || '';
                    const checkInB = b?.time_breakup?.check_in_time || '';
                    return checkInA.localeCompare(checkInB); // Sort alphabetically
                },
                render: (item: any) => {
                    return (
                        <div>
                            {item?.time_breakup?.check_in_time ?
                                CommonService.startTime(item?.time_breakup?.check_in_time)
                                :
                                <div style={{color: "red"}}>
                                    {CommonService.startTime(item?.expected?.shift_start_time)}
                                </div>}
                        </div>
                    )
                }
            },
            {
                title: 'Clock Out',
                width: 120,
                sorter: (a: any, b: any) => {
                    const checkOutA = a?.time_breakup?.check_out_time || '';
                    const checkOutB = b?.time_breakup?.check_out_time || '';
                    return checkOutA.localeCompare(checkOutB); // Sort alphabetically
                },
                render: (item: any) => {
                    return (
                        <div>
                            {item["time_breakup"]?.check_out_time ? CommonService.endTime(item["time_breakup"]?.check_out_time) :
                                <div style={{color: "red"}}>
                                    {CommonService.endTime(item?.expected?.shift_end_time)}
                                </div>}
                        </div>
                    )
                }
            },
            {
                title: 'Hourly Rate',
                width: 130,
                sorter: (a: any, b: any) => {
                    const rateA = Number(a["hourly_rate"]);
                    const rateB = Number(b["hourly_rate"]);
                    return rateA - rateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {Number(item["hourly_rate"])?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || (0)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                }
            },
            {
                title: 'Late Request Premium',
                width: 200,
                sorter: (a: any, b: any) => {
                    const rateA = Number(a["rush_rate"]);
                    const rateB = Number(b["rush_rate"]);
                    return rateA - rateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {Number(item["rush_rate"])?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || (0)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                }
            },
            {
                title: 'Shift Differential',
                width: 165,
                sorter: (a: any, b: any) => {
                    const rateA = Number(a["shift_diff"]);
                    const rateB = Number(b["shift_diff"]);
                    return rateA - rateB;
                },
                render: (item: any) => {
                    return (
                        <div>
                            {Number(item["shift_diff"])?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                }
            },
            {
                title: 'Hazard Pay',
                width: 150,
                render: (item: any) => {
                    return (
                        <div>
                            {Number(item["hazard_rate"])?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || 0}
                        </div>
                    )
                },
                sorter: (a: any, b: any) => Number(a["hazard_rate"]) - Number(b["hazard_rate"]),
            },
            {
                title: 'OT/Holiday Hours',
                width: 165,
                render: (item: any) => {
                    const otHrs = !isNaN(Number(item["ot_hrs"])) ? Number(item["ot_hrs"]) : 0;
                    const dtHrs = !isNaN(Number(item["dt_hrs"])) ? Number(item["dt_hrs"]) : 0;
                    return (
                        <div>
                            {(otHrs + dtHrs).toFixed(2)}
                        </div>
                    )
                },
                sorter: (a: any, b: any) => {
                    const aHours = (!isNaN(Number(a["ot_hrs"])) ? Number(a["ot_hrs"]) : 0) + (!isNaN(Number(a["dt_hrs"])) ? Number(a["dt_hrs"]) : 0);
                    const bHours = (!isNaN(Number(b["ot_hrs"])) ? Number(b["ot_hrs"]) : 0) + (!isNaN(Number(b["dt_hrs"])) ? Number(b["dt_hrs"]) : 0);
                    return aHours - bHours;
                },
            },
            {
                title: 'Break',
                width: 180,
                render: (item: any) => {
                    return (
                        <div>
                            {item["time_breakup"].break_timings?.length > 0 ? item["time_breakup"].break_timings?.map((breaks: any, index: any) => {
                                const {
                                    start_time,
                                    end_time
                                } = CommonService.getUtcTimeInAMPM(breaks?.break_in_time, breaks?.break_out_time);
                                return (
                                    <>
                                        <div style={{
                                            marginBottom: "10px",
                                            color: "red"
                                        }}>
                                            {start_time + "-" + end_time}
                                        </div>
                                    </>
                                );
                            }) : <>
                                {item?.time_breakup?.check_in_time ? <>No Break</> : <>30
                                    Minutes</>}
                            </>
                            }
                        </div>
                    )
                },
                sorter: (a: any, b: any) => {
                    return (a["time_breakup"].break_timings?.length ?? 0) - (b["time_breakup"].break_timings?.length ?? 0);
                },
            },
            {
                title: 'Total Hourly Rate',
                width: 175,
                render: (item: any) => {
                    return (
                        <div>
                            {Number(item["total_hourly_rate"])?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || (0)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                },
                sorter: (a: any, b: any) => Number(a["total_hourly_rate"]) - Number(b["total_hourly_rate"]),
            },
            {
                title: 'Regular Pay',
                width: 150,
                render: (item: any) => {
                    return (
                        <div style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>
                            {Number(item?.reg_amount)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || Number(item?.reg_amount)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || (0)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                },
                sorter: (a: any, b: any) => Number(a?.reg_amount) - Number(b?.reg_amount),
            },
            {
                title: 'OT Pay',
                width: 100,
                render: (item: any) => {
                    return (
                        <div style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>
                            {Number(item?.ot_amount)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || Number(item?.ot_amount)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || (0)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                },
                sorter: (a: any, b: any) => Number(a?.ot_amount) - Number(b?.ot_amount),
            },
            {
                title: 'DT Pay',
                width: 100,
                render: (item: any) => {
                    return (
                        <div style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>
                            {Number(item?.dt_amount)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || Number(item?.dt_amount)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || (0)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                },
                sorter: (a: any, b: any) => Number(a?.dt_amount) - Number(b?.dt_amount),
            },
            {
                title: 'Total Amount',
                width: 140,
                render: (item: any) => {
                    return (
                        <div style={isPreviewingTheTimesheet ? {color: "#0C80E3"} : {}}>
                            {Number(item?.total_amount)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || (0)?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    )
                },
                sorter: (a: any, b: any) => Number(a?.total_amount) - Number(b?.total_amount),
            },
            {
                title: 'Shift Status',
                width: 150,
                render: (item: any) => {
                    return (
                        <div className={`${item["status"]}`}>
                            {item["status"] === "pending" ? "Approved" : item["status"]?.charAt(0)?.toUpperCase() + item["status"]?.slice(1)}
                        </div>
                    )
                },
            }
        ]
    }, [isPreviewingTheTimesheet])


    return (
        <>
            <>
                <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                    <GenerateInvoiceFilteDrawerComponent
                        setOpenFilterDrawer={setOpenFilterDrawer}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        isPreviewingTheTimesheet={isPreviewingTheTimesheet}
                        setIsAllStatusTypesSelected={setIsAllStatusTypesSelected}
                        isAllStatusTypesSelected={isAllStatusTypesSelected}
                        // setSelectedStatusTypes={setSelectedStatusTypes}
                        // selectedStatusTypes={selectedStatusTypes} selectedTimeTypes={selectedTimeTypes}
                        // setSelectedTimeTypes={setSelectedTimeTypes}
                        // selectedHcps={selectedHcps} setSelectedHcps={setSelectedHcps}
                        // dateRange={dateRange} setDateRange={setDateRange}
                        selectedFacility={selectedFacility} setSelectedFacility={setSelectedFacility}/>
                </DrawerComponent>
                <DialogComponent isEditableDialog={true} cancel={cancelOpenGeneratePopup} open={openGeneratePopup}>
                    <ConfirmationDialogAction headerText={"GENERATE INVOICE"} cancel={cancelOpenGeneratePopup}
                                              confirm={generateInvoice} confirmationText="Are you sure you want to generate invoices for
the selected Timesheets?" notext={"NO"} yestext={"YES"} isConfirm={isConfirmMarking}/>
                </DialogComponent>
                <DialogComponent isEditableDialog={true} cancel={cancelOpenAddFinancePopup}
                                 open={addFinanceChargePopupOpen}>
                    <Formik
                        initialValues={InvoiceGeneratedInitialState}
                        validateOnChange={true}
                        validationSchema={editFinanceChargeFormValidation}
                        onSubmit={onEditFinanceCharge}
                    >
                        {({isSubmitting, isValid}) => (
                            <Form className="">
                                <div className={"mrg-10"} style={{fontWeight: 600, fontSize: "16px"}}>Add Any Other
                                    Charge
                                </div>
                                <div style={{maxWidth: "580px"}}>
                                    <Field variant="outlined" name="finance_charge.details"
                                           className={"mrg-10"}
                                           type={"text"}
                                        // label={'Invoice Details'}
                                           placeholder={'Enter Invoice Details'}
                                           component={TextField}
                                           fullWidth autoComplete="off"
                                           id="input_facility_edit_facility_name"/>
                                    <Field
                                        variant="inline"
                                        className={"mrg-10"}
                                        orientation="landscape"
                                        openTo="date"
                                        format="MM/dd/yyyy"
                                        views={["year", "month", "date"]}
                                        inputVariant="outlined"
                                        component={DatePicker}
                                        placeholder="MM/DD/YYYY"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                        label="Date"
                                        name="finance_charge.date"
                                    />

                                    <Field variant="outlined" name="finance_charge.amount" type={"text"}
                                           component={TextField}
                                           className={"mrg-10"}
                                           fullWidth autoComplete="off"
                                        //  placeholder={'Amount'}
                                           placeholder={"Enter Amount"}
                                           id="input_facility_edit_facility_name"/>


                                    <div className={"mrg-bottom-20"} style={{display: "flex", marginLeft: "20%"}}>
                                        <Button onClick={() => {
                                            setAddFinanceChargePopupOpen(false);
                                        }}
                                                color={"primary"}
                                                style={{width: "150px"}}
                                                variant={"outlined"} id="sms_blast_button"
                                                className={"mrg-right-10"} size={"small"} fullWidth={true}>
                                            Cancel
                                        </Button>
                                        <Tooltip title={isSubmitting ? "Saving Finance Charge" : "Save"}>
                                            <Button disabled={isSubmitting || !isValid}
                                                    color={"primary"}
                                                    style={{width: "150px"}}
                                                    variant={"contained"} id="sms_blast_button"
                                                    className={isSubmitting ? "has-loading-spinner" : ""}
                                                    type="submit" size={"small"} fullWidth={true}>
                                                {isSubmitting ? "Saving" : "Save"}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogComponent>
                <div className="generate-component custom-border pdd-20">
                    <div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black"
                        }}>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                {isPreviewingTheTimesheet &&
                                    <img onClick={() => {
                                        setIsPreviewingTheTimesheet(false);
                                    }} style={{cursor: "pointer"}} className={"mrg-right-10"} src={ImageConfig.BackIcon}
                                         alt={''}/>}
                                <h5>
                                    {`Timesheet `} <span
                                    className={"selected-text"}>({selectedShifts?.length} Selected)</span></h5>
                            </div>
                            {!isPreviewingTheTimesheet && <>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    {selectedFacility?._id && selectedShifts?.length === 0 &&
                                        <Button
                                            color={"primary"}
                                            variant={"outlined"}
                                            onClick={() => {
                                                setAddFinanceChargePopupOpen(true);
                                            }}
                                            className={'login-button mrg-right-10'}
                                        >
                                            Generate Without Shifts
                                        </Button>}
                                    <Button
                                        disabled={selectedShifts?.length === 0}
                                        color={"primary"}
                                        variant={"contained"}
                                        onClick={() => {
                                            setIsPreviewingTheTimesheet(true)
                                            setPreviewFilterData({
                                                ...previewFilterData,
                                                shift_ids: selectedShifts
                                            })
                                        }}
                                        className={'login-button'}
                                    >
                                        {/*{isSubmitting ? "Logging in" : "Login"}*/}
                                        CALCULATE
                                    </Button>
                                    <Button
                                        variant={"outlined"} id={'add_facility_btn'}
                                        color={"primary"}
                                        className={'mrg-left-10'}
                                        onClick={() => {
                                            setOpenFilterDrawer(true)
                                        }}
                                    >
                                        &nbsp;&nbsp;Filters&nbsp;&nbsp;
                                    </Button>
                                </div>
                            </>
                            }
                            {isPreviewingTheTimesheet && <>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <Button
                                        disabled={selectedShifts?.length === 0}
                                        color={"primary"}
                                        variant={"outlined"}
                                        onClick={() => {
                                            setGenerateTimesheetMode("edit");
                                            //  generateInvoice();
                                        }}
                                        className={'login-button'}
                                    >
                                        {/*{isSubmitting ? "Logging in" : "Login"}*/}
                                        Edit Details
                                    </Button>
                                    <Button
                                        disabled={selectedShifts?.length === 0}
                                        color={"primary"}
                                        variant={"outlined"}
                                        onClick={() => {
                                            setGeneratePoopupOpen(true);
                                            //  generateInvoice();
                                        }}
                                        className={'login-button mrg-left-10'}
                                    >
                                        {/*{isSubmitting ? "Logging in" : "Login"}*/}
                                        Generate Invoice
                                    </Button>
                                    <Button
                                        disabled={selectedShifts?.length === 0}
                                        color={"primary"}
                                        variant={"outlined"}
                                        onClick={() => {
                                            handleInvoiceDownload();
                                        }}
                                        className={'login-button mrg-left-10'}
                                    >
                                        {isDownloading ? "Downloading Timesheet" : " Download Timesheet"}
                                    </Button>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                    <div>
                        {isPreviewingTheTimesheet &&
                            <>
                                <AntTableComponent
                                    url={ENV.API_URL + `invoice/generatePreview`}
                                    method={'post'}
                                    columns={generatePreviewInvoiceListColumn}
                                    extraPayload={previewFilterData}
                                />
                            </>}
                        {!isPreviewingTheTimesheet &&
                            <AntTableComponent
                                url={selectedFacility?._id ? ENV.API_URL + `facility/${selectedFacility?._id}/prospectiveTimesheets`: null}
                                method={'post'}
                                columns={generateInvoiceListColumn}
                                isRowSelection={true}
                                selectedRowKeys={selectedShifts}
                                setSelectedRowKeys={setSelectedShifts}
                                extraPayload={filterData}
                            />}

                    </div>

                </div>
            </>
        </>
    )
        ;
};
export default GenerateTimeSheetList;
