export const FETCH_REGIONS_REQUEST = "meta/FETCH_REGIONS_REQUEST";
export const FETCH_REGIONS_SUCCESS = "meta/FETCH_REGIONS_SUCCESS";
export const FETCH_REGIONS_FAILURE = "meta/FETCH_REGIONS_FAILURE";
export const FETCH_HCP_TYPES_REQUEST = "meta/FETCH_HCP_TYPES_REQUEST";
export const FETCH_HCP_TYPES_SUCCESS = "meta/FETCH_HCP_TYPES_SUCCESS";
export const FETCH_HCP_TYPES_FAILURE = "meta/FETCH_HCP_TYPES_FAILURE";
export const GET_SPECIALITIES_REQUEST = 'GET_SPECIALITIES_REQUEST';
export const GET_SPECIALITIES_SUCCESS = 'GET_SPECIALITIES_SUCCESS';
export const GET_SPECIALITIES_FAILURE = 'GET_SPECIALITIES_FAILURE';
export const GET_FACILITY_DOCUMENTS = 'GET_FACILITY_DOCUMENTS';
export const SET_FACILITY_DOCUMENTS = "SET_FACILITY_DOCUMENTS";

export const getFacilityDocuments = (facilityId: any) => {
    return {type: GET_FACILITY_DOCUMENTS, facilityId};
};
export const setFacilityDocuments = (documents: any[]) => ({type: SET_FACILITY_DOCUMENTS, payload: {documents}});
export const fetchRegionsRequest = () => ({type: FETCH_REGIONS_REQUEST});
export const fetchRegionsSuccess = (regions: any[]) => ({type: FETCH_REGIONS_SUCCESS, payload: {regions}});
export const fetchRegionsFailure = (error: any) => ({type: FETCH_REGIONS_FAILURE, payload: {error}});


export const fetchHcpTypeRequest = () => ({type: FETCH_HCP_TYPES_REQUEST});
export const fetchHcpTypeSuccess = (hcpTypes: any[]) => ({type: FETCH_HCP_TYPES_SUCCESS, payload: {hcpTypes}});
export const fetchHcpTypeFailure = (hcpTypeError: any) => ({type: FETCH_HCP_TYPES_FAILURE, payload: {hcpTypeError}});


export const getSpecialitiesRequest = () => ({type: GET_SPECIALITIES_REQUEST});

export const getSpecialitiesSuccess = (hcpSpecialitiesList: any[]) => ({
    type: GET_SPECIALITIES_SUCCESS,
    payload: hcpSpecialitiesList,
});

export const getSpecialitiesFailure = (hcpSpecialitiesError: any) => ({
    type: GET_SPECIALITIES_FAILURE,
    payload: hcpSpecialitiesError,
});
