import React, {PropsWithChildren} from 'react';
import './add-layout.scss';

interface AddLayoutProps {

}
const AddLayout = (props: PropsWithChildren<AddLayoutProps>) => {
    return (
        <div className={'add-layout'}>
            <div className={'add-layout-wrapper'}>
                <div className={'add-layout-content'}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
export default AddLayout;