import './MoreFieldsDrawerComponent.scss';
import React, {useCallback, useEffect, useState} from 'react';
import {Button, Checkbox, TextField} from "@material-ui/core";


interface MoreFieldsDrawerComponentProps {
    fields:any;
    setOpenDrawer:any;
    setOptionalFields:any;
    optionalFields:any;
    setFieldValue:any;
}

const MoreFieldsDrawerComponent = (props: React.PropsWithChildren<MoreFieldsDrawerComponentProps>) => {
    const {fields,setOpenDrawer,setOptionalFields,optionalFields,setFieldValue}=props;
    const [searchField,setSearchField]=useState<string>('')
    const [addedField, setAddedField] = useState<any>([]);

    useEffect(() => {
        if (optionalFields && optionalFields.length > 0) {
            const matchedFields = fields.filter((field:any) =>
                optionalFields.some((optional:any) => optional.id === field.id)
            );
            setAddedField(matchedFields);
        }
    }, [optionalFields, fields]);



    const handleFieldSearch = (event:any) => {
        setSearchField(event.target.value);
    };

    const handleCheckboxChange = useCallback((field:any) => {
        setAddedField((prev:any) => {
            if (prev.some((item:any) => item.id === field.id)) {
                setFieldValue(field.name, '');
                return prev.filter((item:any) => item.id !== field.id);
            } else {
                return [...prev, field];
            }
        });
    },[setFieldValue]);


    const filteredFields = fields.filter((field:any) =>
        field?.label?.toLowerCase().includes(searchField.toLowerCase())
    );

    const handleAddField=useCallback(()=>{
        setOpenDrawer(false);
        setOptionalFields(addedField);
    },[addedField,setOpenDrawer,setOptionalFields])

    const handleClearAll=useCallback(()=>{
        addedField.forEach((field: any) => {
            setFieldValue(field.name, '');
        });
        setAddedField([]);
        setOptionalFields([]);
    },[addedField,setFieldValue,setOptionalFields])



    return (
        <div className={'more-fields-drawer-component'}>
            <div>
                <TextField
                    id="outlined-basic"
                    label="Search Field"
                    variant="outlined"
                    className={'more-fields-search'}
                    value={searchField}
                    onChange={handleFieldSearch}
                />
            </div>
            <div className={'more-fields-option-wrapper'}>
                {filteredFields?.map((field:any)=> (
                    <div
                        key={field.id}
                    >
                        <Checkbox id={`cb_${field.id}`}
                                  checked={addedField.some((item:any) => item.id === field.id)}
                                  onChange={() => handleCheckboxChange(field)}/>
                        <span>
                        {field.label}
                            </span>
                    </div>
                ))}
            </div>
            <div className={'more-fields-btn-wrapper'}>
                <Button
                    size="medium"
                    className={'more-fields-btn'}
                    variant={"outlined"}
                    color={"primary"}
                    id="btn_facility_add_submit"
                    onClick={handleClearAll}
                >
                    Clear All
                </Button>
                <Button
                    size="medium"
                    className={'more-fields-btn'}
                    variant={"contained"}
                    color={"primary"}
                    id="btn_facility_add_submit"
                    onClick={handleAddField}
                >
                    Add Fields
                </Button>
            </div>
        </div>
    )
}

export default  MoreFieldsDrawerComponent